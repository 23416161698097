import { initMonitoring } from '@bloomays-lib/common.monitoring/browser';
import { ILogger } from '@bloomays-lib/types.shared';
import config from '../services/configService';

export const initMonit = (logger: ILogger): void => {
  const conf = config();
  initMonitoring({
    useReact: true,
    useReplay: true,
    service: conf.service,
    release: conf.release,
    env: conf.env,
    sentryDSN: conf.monitoring.sentry.dsn,
    datadog: {
      allowedTracingOrigins: [import.meta.env.VITE_BLOOMERS_API],
      datadogAPIKey: conf.monitoring.datadog.apiKey,
      datadogRUMAppId: conf.monitoring.datadog.rumAppId,
    },
    logger,
  });
};
