import RawCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RawCardHeader from '@mui/material/CardHeader';
import RawPermIdentityIcon from '@mui/icons-material/PermIdentity';
import RawMailOutlineIcon from '@mui/icons-material/MailOutline';
import RawFileOpenIcon from '@mui/icons-material/FileOpen';
import RawPhoneIcon from '@mui/icons-material/Phone';
import RawWorkOutlineIcon from '@mui/icons-material/WorkOutline';
import RawHomeWorkIcon from '@mui/icons-material/HomeWork';
import RawHttpsIcon from '@mui/icons-material/Https';
import RawTodayIcon from '@mui/icons-material/Today';
import RawLocalOfferIcon from '@mui/icons-material/LocalOffer';
import RawAttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RawEmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';
import RawAccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RawVisibilityIcon from '@mui/icons-material/Visibility';
import RawIconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '../atoms/Menu';
import { Theme, styled } from '@mui/material/styles';
import {
  ISociety,
  BrowserBloomer,
  IContact,
  IMissionCardDetails,
  ExtensionAssessmentGQL,
} from '@bloomays-lib/types.shared';
import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';
import { useTranslation } from 'react-i18next';
import { getAssessmentKey } from '../../helpers/locales';
import Modal from '../atoms/Modal';
import { useEffect, useState } from 'react';
import TagMissionType from './TagMissionType';
import { Carousel, FileViewer } from '@bloomays-lib/ui.shared';
import { BloomerCard } from './BloomerCard';
import { useAuth } from '../../auth/AuthProvider';

export type SocietyCard = {
  type: 'society';
  data: {
    society?: ISociety;
    extraInfoTitle?: string;
    path?: string;
  };
};

export type BloomerCard = {
  type: 'bloomer';
  data: {
    bloomer?: BrowserBloomer;
  };
};

export type ContactCard = {
  type: 'contact';
  data: {
    contact?: IContact | IContact[];
    extraInfoTitle?: string;
  };
};

type Action = {
  name: string;
  action: () => void;
};

export type MissionCard = {
  type: 'mission';
  data: {
    mission: IMissionCardDetails;
    actions: Action[];
    currentExtensionAssessment: ExtensionAssessmentGQL | null;
  };
};

export type MissionDetailCardProps = SocietyCard | BloomerCard | ContactCard | MissionCard;

const CurrentExtensionAssessmentLine = (props: { extensionAssessment: ExtensionAssessmentGQL | null }) => {
  const { t } = useTranslation(['extendShortenMission']);
  const { extensionAssessment } = props;

  let assessmentLabel = t('nonExistingAssessment');
  if (extensionAssessment) {
    const { assessment, newEndDate } = extensionAssessment;
    const assessmentText = t(getAssessmentKey(assessment));
    assessmentLabel =
      assessment > 0.5 ? t('assessmentLabel', { assessmentLabel: assessmentText, newEndDate }) : t(assessmentText);
  }

  return (
    <ContainerLine>
      <CenteredLabel id="taxesMissionCard" variant="body1Light" component="p">
        <EmojiSymbolsIcon />
        {t('assessmentLabelInDetailCard')}
      </CenteredLabel>
      <ValueTypography id="taxesMissionCardValue" variant="body1Light">
        {assessmentLabel}
      </ValueTypography>
    </ContainerLine>
  );
};

const getContactComponent = (contact?: IContact) => {
  return (
    <ContainerData>
      <ContainerLine>
        <CenteredLabel variant="body1Light" component="p">
          <PermIdentityIcon />
          Nom complet:
        </CenteredLabel>
        <DarkTypo variant="body1Light">
          {contact?.fullname || (contact?.firstname && `${contact?.firstname} ${contact?.lastname}`)}
        </DarkTypo>
      </ContainerLine>
      <ContainerLine>
        <CenteredLabel variant="body1Light" component="p">
          <MailOutlineIcon />
          Email:
        </CenteredLabel>
        <DarkTypo variant="body1Light">
          <HrefLine href={`mailto:${contact?.email}`}>{contact?.email}</HrefLine>
        </DarkTypo>
      </ContainerLine>
      <ContainerLine>
        <CenteredLabel variant="body1Light" component="p">
          <PhoneIcon />
          Téléphone:
        </CenteredLabel>
        <DarkTypo variant="body1Light">
          <HrefLine href={`tel:${contact?.phoneNumber}`}>{contact?.phoneNumber}</HrefLine>
        </DarkTypo>
      </ContainerLine>
      <ContainerLine>
        <CenteredLabel variant="body1Light" component="p">
          <WorkOutlineIcon />
          Job:
        </CenteredLabel>
        <DarkTypo variant="body1Light">{contact?.nameJob}</DarkTypo>
      </ContainerLine>
    </ContainerData>
  );
};

const MissionDetailCard = ({ type, data }: MissionDetailCardProps): JSX.Element => {
  const [token, setToken] = useState<string | undefined>();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user?.token) {
      setToken(auth.user?.token);
    }
  }, [auth]);
  const [filePath, setFilePath] = useState<string | undefined>(undefined);

  switch (type) {
    case 'society': {
      const { extraInfoTitle, society } = data;
      return (
        <Card>
          <Modal
            open={!!filePath}
            onClose={() => setFilePath(undefined)}
            additionnalCSS={{
              width: '822px',
              padding: '32px',
              border: 'none',
              borderRadius: '20px',
              height: '98%',
            }}
          >
            <FileViewer width="100%" height="100%" url={filePath as string} accessToken={token} />
          </Modal>
          <CardContent>
            <CardHeader title={`La societé ${extraInfoTitle ? extraInfoTitle : ''}`} />
            <ContainerData>
              <ContainerLine>
                <CenteredLabel variant="body1Light" component="p">
                  <PermIdentityIcon />
                  Nom:
                </CenteredLabel>
                <ValueTypography variant="body1Light">{society?.name}</ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel variant="body1Light" component="p">
                  <HomeWorkIcon />
                  Statut:
                </CenteredLabel>
                <ValueTypography variant="body1Light">{society?.status}</ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel variant="body1Light" component="p">
                  <HttpsIcon />
                  Siret:
                </CenteredLabel>
                <ValueTypography variant="body1Light">{society?.siret}</ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel variant="body1Light" component="p">
                  <AccountBalanceIcon /># TVA:
                </CenteredLabel>
                <ValueTypography variant="body1Light">{society?.tva}</ValueTypography>
              </ContainerLine>
              {!society?.isClient && (
                <>
                  <ContainerLine>
                    <CenteredLabel variant="body1Light" component="p">
                      <HomeWorkIcon />
                      Portage :
                    </CenteredLabel>
                    <ValueTypography variant="body1Light">{society?.portage ? 'Oui' : 'Non'}</ValueTypography>
                  </ContainerLine>
                  <ContainerLine>
                    <CenteredLabel variant="body1Light" component="p">
                      <HomeWorkIcon />
                      IBAN :
                    </CenteredLabel>
                    <ValueTypography variant="body1Light">{society?.IBAN}</ValueTypography>
                  </ContainerLine>
                  <ContainerLine>
                    <CenteredLabel variant="body1Light" component="p">
                      <HomeWorkIcon />
                      BIC :
                    </CenteredLabel>
                    <ValueTypography variant="body1Light">{society?.BIC}</ValueTypography>
                  </ContainerLine>
                  {society?.KBIS && (
                    <ContainerLine>
                      <CenteredLabel variant="body1Light" component="p">
                        <FileOpenIcon />
                        KBIS :
                      </CenteredLabel>
                      <IconButton
                        onClick={() => setFilePath(society?.KBIS?.toString())}
                        title={society?.KBIS?.toString()}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </ContainerLine>
                  )}
                  {society?.URSSAFVigilanceCertificate && (
                    <ContainerLine>
                      <CenteredLabel variant="body1Light" component="p">
                        <FileOpenIcon />
                        URSSAF ({society.URSSAFVCExpirationDate || 'Expiration date not found'}) :
                      </CenteredLabel>
                      <IconButton
                        onClick={() => setFilePath(society?.URSSAFVigilanceCertificate?.toString())}
                        title={society?.URSSAFVigilanceCertificate?.toString()}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </ContainerLine>
                  )}
                  {society?.RCPVigilanceCertificate && (
                    <ContainerLine>
                      <CenteredLabel variant="body1Light" component="p">
                        <FileOpenIcon />
                        RCP :
                      </CenteredLabel>
                      <IconButton
                        onClick={() => setFilePath(society?.RCPVigilanceCertificate?.toString())}
                        title={society?.RCPVigilanceCertificate?.toString()}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </ContainerLine>
                  )}
                </>
              )}
            </ContainerData>
          </CardContent>
        </Card>
      );
    }
    case 'bloomer': {
      const bloomer = data.bloomer as BrowserBloomer;

      return <BloomerCard bloomer={bloomer} filePath={filePath} setFilePath={setFilePath} />;
    }
    case 'contact': {
      const { extraInfoTitle, contact } = data;
      return (
        <Card>
          <CardContent>
            <CardHeader title={`Le contact ${extraInfoTitle ? extraInfoTitle : ''}`} />
            {contact && Array.isArray(contact) && contact.length > 1 ? (
              <Carousel childs={contact.map((c) => getContactComponent(c))}></Carousel>
            ) : (
              getContactComponent(Array.isArray(contact) ? contact[0] : contact)
            )}
          </CardContent>
        </Card>
      );
    }
    case 'mission': {
      const { mission, actions, currentExtensionAssessment } = data;

      return (
        <Card>
          <CardContent>
            <CardHeader title={'La mission'} />
            <ContainerData>
              <ContainerLine>
                <CenteredLabel id="typeMissionCard" variant="body1Light" component="p">
                  <LocalOfferIcon />
                  Type:
                </CenteredLabel>
                &nbsp;
                <TagMissionType type={mission?.type} />
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="labelMissionCard" variant="body1Light" component="p">
                  <LocalOfferIcon />
                  Label:
                </CenteredLabel>
                <ValueTypography id="labelMissionCardValue" variant="body1Light">
                  {mission?.label}
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="beginMissionMissionCard" variant="body1Light" component="p">
                  <TodayIcon />
                  Début de mission:
                </CenteredLabel>
                <ValueTypography id="beginMissionlMissionCardValue" variant="body1Light">
                  {convertDateToStringFormat(mission?.beginMission, 'dd/MM/yyyy')}
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="endMissionMissionCard" variant="body1Light" component="p">
                  <TodayIcon />
                  Fin de la mission:
                </CenteredLabel>
                <ValueTypography id="endMissionMissionCardValue" variant="body1Light">
                  {convertDateToStringFormat(mission?.endMission, 'dd/MM/yyyy')}
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="purchaseMissionCard" variant="body1Light" component="p">
                  <AttachMoneyIcon />
                  Prix d&apos;achat:
                </CenteredLabel>
                <ValueTypography id="purchaseMissionCardValue" variant="body1Light">
                  {mission?.purchasePricePerDayWithoutTaxes} €
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="saleMissionCard" variant="body1Light" component="p">
                  <AttachMoneyIcon />
                  Prix de vente:
                </CenteredLabel>
                <ValueTypography id="saleMissionCardValue" variant="body1Light">
                  {mission?.salePricePerDayWithoutTaxes} €
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="taxesMissionCard" variant="body1Light" component="p">
                  <EmojiSymbolsIcon />
                  Taxes:
                </CenteredLabel>
                <ValueTypography id="taxesMissionCardValue" variant="body1Light">
                  {mission?.taxes} %
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="taxesMissionCard" variant="body1Light" component="p">
                  <EmojiSymbolsIcon />
                  Type de contrat:
                </CenteredLabel>
                <ValueTypography id="taxesMissionCardValue" variant="body1Light">
                  {mission?.contractType ? mission?.contractType : '⚠️⚠️⚠️ INFORMATION MANQUANTE ⚠️⚠️⚠️'}
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="bloomerPaydateDaysMissionCard" variant="body1Light" component="p">
                  <EmojiSymbolsIcon />
                  Delais de paiement Bloomer:
                </CenteredLabel>
                <ValueTypography id="bloomerPaydateDaysMissionCardValue" variant="body1Light">
                  {mission?.bloomerPaydateDays}
                </ValueTypography>
              </ContainerLine>
              <ContainerLine>
                <CenteredLabel id="clientPaydateDaysMissionCard" variant="body1Light" component="p">
                  <EmojiSymbolsIcon />
                  Delais de paiement Client:
                </CenteredLabel>
                <ValueTypography id="clientPaydateDaysMissionCardValue" variant="body1Light">
                  {mission?.clientPaydateDays}
                </ValueTypography>
              </ContainerLine>
              <CurrentExtensionAssessmentLine extensionAssessment={currentExtensionAssessment} />
              <ContainerActionButton>
                <Menu id="actionsMissionCardButton" title="Actions" menuList={actions} />
              </ContainerActionButton>
            </ContainerData>
          </CardContent>
        </Card>
      );
    }
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};

export default MissionDetailCard;

const Card = styled(RawCard)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  maxWidth: 400,
  margin: 20,
  [theme.breakpoints.up('lg')]: {
    maxWidth: 600,
  },
}));

const CardHeader = styled(RawCardHeader)(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'left',
}));

const ContainerLine = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const ValueTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: 5,
  color: theme.palette.secondary.dark,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'normal',
  },
}));

const ContainerData = styled('div')(() => ({
  paddingLeft: 5,
}));

const ContainerActionButton = styled('div')(() => ({
  float: 'right',
  margin: '0 0.5em 0.5em 0',
}));

const CenteredLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  minWidth: 'auto',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'normal',
  },
})) as typeof Typography;
// Typescript limitation https://github.com/mui/material-ui/issues/15695

const DarkTypo = styled(Typography)(({ theme }) => ({
  paddingLeft: 5,
  color: theme.palette.secondary.dark,
}));

const HrefLine = styled('a')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const iconCSS = (args: { theme: Theme }) => ({
  paddingRight: 10,
  color: args.theme.palette.grey[500],
  width: 20,
});

const iconVCSS = (args: { theme: Theme }) => ({
  paddingTop: 0,
  color: args.theme.palette.grey[500],
  width: 20,
});

const PermIdentityIcon = styled(RawPermIdentityIcon)(iconCSS);
const MailOutlineIcon = styled(RawMailOutlineIcon)(iconCSS);
const PhoneIcon = styled(RawPhoneIcon)(iconCSS);
const WorkOutlineIcon = styled(RawWorkOutlineIcon)(iconCSS);
const HomeWorkIcon = styled(RawHomeWorkIcon)(iconCSS);
const AccountBalanceIcon = styled(RawAccountBalanceIcon)(iconCSS);
const HttpsIcon = styled(RawHttpsIcon)(iconCSS);
const AttachMoneyIcon = styled(RawAttachMoneyIcon)(iconCSS);
const TodayIcon = styled(RawTodayIcon)(iconCSS);
const LocalOfferIcon = styled(RawLocalOfferIcon)(iconCSS);
const EmojiSymbolsIcon = styled(RawEmojiSymbolsIcon)(iconCSS);
const FileOpenIcon = styled(RawFileOpenIcon)(iconCSS);
const VisibilityIcon = styled(RawVisibilityIcon)(iconVCSS);
const IconButton = styled(RawIconButton)(({ theme }) => ({
  paddingTop: '0px',
  paddingBottom: '0px',
}));
