import RawMainCard from '../atoms/MainCard';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { JobProcess } from '@bloomays-lib/types.shared';
import { ReadMore } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';

export type JobQualificationProps = {
  job: JobProcess;
};

const JobQualification = ({ job }: JobQualificationProps): JSX.Element => {
  return (
    <Container>
      <MainCard contentSX={{ padding: '20px !important' }} title="Votre recherche:">
        <ContainerChildren>
          <SingleLineText text={`👉 Poste:`} span={`${job?.title}`} />
          <SingleLineText text={`👉 Durée de la mission/PE:`} span={`${job?.duration} mois`} />
          <SingleLineText text={`👉 Localisation:`} span={`${job?.localization}`} />
          <SingleLineText text={`👉 Date de début de la mission:`} span={`${displayMonth(new Date(job?.startDate))}`} />
          <SingleLineText text={`👉 TJM/Package:`} span={`${job?.salesPriceWithoutTaxes} €`} />
        </ContainerChildren>
      </MainCard>
      <MainCard contentSX={{ padding: '20px !important' }} title="Votre Scorecard :">
        <ContainerChildren>
          {job?.scorecards?.map((scorecard: string, i: number) => (
            <SingleLineText key={`scorecard${i}`} text={`👉 ${scorecard}`} />
          ))}
        </ContainerChildren>
      </MainCard>
      <MainCard contentSX={{ padding: '20px !important' }} title="Votre Description :">
        <ReadMore>
          <ContainerChildren>
            <SingleLineText text={`${job?.description}`} />
          </ContainerChildren>
        </ReadMore>
      </MainCard>
    </Container>
  );
};

export default JobQualification;
export { JobQualification };

const Container = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  margin: '20px',
  gap: '20px',
  justifyContent: 'space-around',
}));

const MainCard = styled(RawMainCard)(({ theme }) => ({
  color: 'black',
  overflow: 'hidden',
  position: 'relative',
  width: '400px',
  textAlign: 'left',
  margin: '10px 10px 10px 10px',
  '&:after': {
    content: '""',
    zIndex: '34',
    position: 'absolute',
    width: '210px',
    height: '210px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: '-85px',
    right: '-95px',
    [theme.breakpoints.down('sm')]: {
      top: '-105px',
      right: '-140px',
    },
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '210px',
    height: '210px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: '-125px',
    right: '-15px',
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: '-155px',
      right: '-70px',
    },
  },
}));

const ContainerChildren = styled('div')(() => ({
  paddingTop: '1.5em',
  paddingRight: '0.5em',
  minWidth: '250px',
}));

const displayMonth = (date: Date) => {
  return `${date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: 'long',
  })} ${new Date(date)?.getFullYear()}`;
};
