import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SwitchItem from '../atoms/SwitchItem';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import FilterTab from '../atoms/FilterTab';
import { Status } from '@bloomays-lib/types.shared';
import { Action, ActionType, State } from '../../types/talents';

export type FilterStatusProps = {
  onSwitch: React.Dispatch<Action>;
  state: State;
  cdi: boolean;
  freelance: boolean;
  setFreelance: React.Dispatch<React.SetStateAction<boolean>>;
  setCdi: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const FilterStatus = ({ onSwitch, state, cdi, freelance, setFreelance, setCdi, setPage }: FilterStatusProps) => {
  const status = state.search.status;
  const [open, setOpen] = useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, status]);

  const getMergedStatus = (f: boolean, c: boolean) => {
    let statusSwitch: Status | undefined;

    if (f && c) {
      statusSwitch = Status.FreeAndCDI;
    }
    if (f && !c) {
      statusSwitch = Status.Freelance;
    }
    if (!f && c) {
      statusSwitch = Status.CDI;
    }
    return statusSwitch;
  };

  const handleSwitchFreelance = (checked: boolean) => {
    setFreelance(checked);

    onSwitch({
      type: ActionType.STATUS_SEARCH,
      status: getMergedStatus(checked, cdi),
    });
    setPage(1);
  };

  const handleSwitchCDI = (checked: boolean) => {
    setCdi(checked);
    onSwitch({
      type: ActionType.STATUS_SEARCH,
      status: getMergedStatus(freelance, checked),
    });
    setPage(1);
  };

  const getNumberFilter = () => {
    switch (status) {
      case Status.Freelance:
      case Status.CDI:
        return 1;
      case Status.FreeAndCDI:
        return 2;
      default:
        return 0;
    }
  };

  return (
    <div>
      <FilterTab label={'Statut'} numberOfFilter={getNumberFilter()} onClick={() => setOpen(!open)} filled={false} />
      {open && (
        <StyledContainer ref={ref}>
          <StyledText text="Statut" variant={'subtitle2Medium'} />
          <StyledList>
            <SwitchItem checked={freelance} label="Freelance" onSwitch={() => handleSwitchFreelance(!freelance)} />
            <SwitchItem checked={cdi} label="CDI" onSwitch={() => handleSwitchCDI(!cdi)} />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterStatus;
export { FilterStatus };

const StyledContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 20px;
  padding: 24px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 291px;
  position: absolute;
  z-index: 1;
`,
);

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
