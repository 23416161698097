import { CardItem } from '../atoms/CardItem';
import { styled } from '@mui/material/styles';
import { SingleLineText } from '../atoms/SingleLineText';
import { Trans, useTranslation, TFunction } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { HrefLink } from '../atoms/HrefLink';
import Link from '@mui/material/Link';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ItemTalentLevel from '../atoms/ItemTalentLevel';
import { ActivityLog, EvenementType, ITalent } from '@bloomays-lib/types.shared';
import { constructDateDetails, convertDateToStringFormat } from '@bloomays-lib/utils.shared';

export type LogProps = {
  activityLog: ActivityLog;
};

export const getCandiUrl = (log: ActivityLog) => {
  const candiUrl = JSON.parse(log?.data)?.candiUrl;
  return candiUrl;
};

const _logAction = (t: TFunction) => (log: ActivityLog) => {
  const candiUrl = getCandiUrl(log);
  switch (log.type) {
    case EvenementType.createTalent:
      return ` a créé le talent.`;
    case EvenementType.createJobTalent:
      return ` a ajouté une expérience : `;
    case EvenementType.deleteAttachmentTalent:
      return ` a supprimé le CV : `;
    case EvenementType.deleteDirectSkill:
      return ` a supprimé la compétence : `;
    case EvenementType.deleteEducationTalent:
      return ` a supprimé la formation : `;
    case EvenementType.deleteJobTalent:
      return ` a supprimé l'expérience : `;
    case EvenementType.updateAttachmentTalent:
      return ` a mis à jour le CV : `;
    case EvenementType.updateDirectSkill:
      return ` a modifié le level des compétences : `;
    case EvenementType.updateEducationTalent:
      return ` a modifié la formation : `;
    case EvenementType.updateJobTalent:
      return ` a modifié l'expérience :`;
    case EvenementType.updateTalent:
      return ` a modifié la fiche talent : `;
    case EvenementType.createAttachmentTalent:
      return ` a ajouté un CV à la fiche talent : `;
    case EvenementType.createDirectSkill:
      return ` a ajouté une compétence : `;
    case EvenementType.createLang:
      return ` a ajouté une langue : `;
    case EvenementType.updateLang:
      return ` a modifié le level des langues : `;
    case EvenementType.deleteLang:
      return ` a supprimé la langue : `;
    case EvenementType.createEducationTalent:
      return ` a ajouté une formation : `;
    case EvenementType.addComment:
      return ` a commenté ce profil`;
    case EvenementType.createJobFunnelTalent: {
      return (
        <Trans
          i18nKey="createJobFunnelTalent"
          t={t}
          values={{ candiUrl }}
          components={{
            bold: <strong />,
            link1:
              candiUrl === undefined ? (
                <SingleLineText sx={{ display: 'inline-block' }} text="talent" />
              ) : (
                <Link href={candiUrl} target="_blank" title={'Candidat url'} rel="noreferrer">
                  talent
                </Link>
              ),
          }}
        />
      );
    }
    case EvenementType.moveJobFunnelTalent: {
      const previous = log?.previousData && JSON.parse(log?.data)?.from;
      const current = log?.previousData && JSON.parse(log?.data)?.to;
      return (
        <Trans
          i18nKey="moveJobFunnelTalent"
          t={t}
          values={{ previous: previous, current: current }}
          components={{
            bold: <strong />,
            link1: (
              <Link href={candiUrl} target="_blank" title={'Candidat url'} rel="noreferrer">
                {}
              </Link>
            ),
          }}
        />
      );
    }
    default:
      return ` unkonwn action : `;
  }
};

const getVariantColor = (log: ActivityLog) => {
  switch (log.type) {
    case EvenementType.moveJobFunnelTalent:
    case EvenementType.createJobFunnelTalent:
      return 'purple';
    case EvenementType.addComment:
      return 'grey';
    default:
      return 'pink';
  }
};

const getVariantEmoji = (log: ActivityLog) => {
  switch (log.type) {
    case EvenementType.deleteAttachmentTalent:
    case EvenementType.deleteDirectSkill:
    case EvenementType.deleteEducationTalent:
    case EvenementType.deleteJobTalent:
      return <DeleteOutlineIcon />;
    case EvenementType.updateAttachmentTalent:
    case EvenementType.updateDirectSkill:
    case EvenementType.updateLang:
    case EvenementType.deleteLang:
    case EvenementType.updateEducationTalent:
    case EvenementType.updateJobTalent:
    case EvenementType.updateTalent:
      return <EditIcon />;
    case EvenementType.createDirectSkill:
    case EvenementType.createLang:
    case EvenementType.createAttachmentTalent:
    case EvenementType.createEducationTalent:
    case EvenementType.createJobTalent:
      return <AddCircleOutlineIcon />;
    case EvenementType.createJobFunnelTalent:
      return <PersonAddAlt1Icon />;
    case EvenementType.addComment:
      return <ChatBubbleOutlineIcon />;
    case EvenementType.moveJobFunnelTalent:
      return <SyncAltIcon />;
    case EvenementType.createTalent:
    default:
      return <PersonAddAlt1Icon />;
  }
};

const Log = ({ activityLog }: LogProps) => {
  const { t } = useTranslation(['eventTypeActivityLog', 'talentSheet']);

  const data: any = JSON.parse(activityLog?.data);
  const previousData: any = activityLog?.previousData ? JSON.parse(activityLog?.previousData) : null;

  const getUpdatedValues = (current: any, previous: any) => {
    const updatedValues = [];

    if (typeof data === 'string') {
      return [data];
    }

    if (current?.skill || (current?.label && (current?.level || current?.level === 0))) {
      for (const key in current) {
        if (
          current?.[key as keyof ITalent] !== previous?.[key as keyof ITalent] ||
          activityLog.type === EvenementType.createDirectSkill
        ) {
          return [
            <ItemTalentLevel
              key={current?.id}
              skill={current?.skill || { label: current?.label }}
              level={Number(current?.level) || undefined}
            />,
          ];
        }
      }
    }
    if (current?.name) {
      const element: React.ReactElement[] = [];
      Object.keys(current).forEach(function (key, index) {
        if (typeof current[key] === 'string' && key !== 'id' && key !== 'description') {
          element.push(<SingleLineText text={`${current[key]}, `} key={current.id + '-' + key + '-' + index} />);
        } else if (current[key]?.[0]?.label && (!current[key]?.[0]?.alternateLabels || current[key]?.[0]?.category)) {
          Object.keys(current[key]).forEach((el, elIndex) => {
            if (el !== 'id') {
              element.push(
                <SingleLineText
                  text={`${current[key][el].label}, `}
                  key={current.id + '-' + key + '-' + index + elIndex}
                />,
              );
            }
          });
        } else if (current[key]?.startDate) {
          const dateDetail = constructDateDetails(current[key]);
          element.push(<SingleLineText text={dateDetail} key={current.id + '-' + key + '-' + index} />);
        }
      });
      return element.reverse();
    }

    if (!previous) return [];

    for (const key in current) {
      if (current[key] !== previous[key]) {
        if (!Array.isArray(current[key]) || !Array.isArray(previous[key])) {
          if (
            key !== 'updatedAt' &&
            key !== 'createdAt' &&
            key !== 'id' &&
            key !== 'jobs' &&
            key !== 'skillLevels' &&
            key !== 'jobLevels' &&
            key !== 'educations' &&
            key !== 'location' &&
            key !== 'languages' &&
            key !== 'positionTitle' &&
            key !== 'cv'
          ) {
            if (Array.isArray(current[key]) && (current[key] as []).length > 0) {
              (current[key] as []).forEach((element: any) => {
                updatedValues.push(element.url);
              });
            } else {
              updatedValues.push(current[key]);
            }
          }
          if (current[key]?.address !== previous[key]?.address || current[key]?.range !== previous[key]?.range) {
            Object.keys(current[key]).forEach((element: any) => {
              if (element === 'address') {
                updatedValues.push(current[key][element]);
              } else if (element === 'range') {
                updatedValues.push(`rayon : ${current[key][element]}km`);
              }
            });
          }
          if (current[key]?.jobTitle?.label !== previous[key]?.jobTitle?.label) {
            Object.keys(current[key]).forEach((element: any) => {
              if (element === 'jobTitle') {
                updatedValues.push(current[key][element]?.label);
              }
            });
          }
        }
      }
    }
    return updatedValues
      .filter((value) => value !== undefined)
      .join(', ')
      .split('');
  };

  const updatedDataRaw = getUpdatedValues(data, previousData);

  const jobUrl = JSON.parse(activityLog?.data)?.jobUrl;

  const createJob = !JSON.parse(activityLog?.data)?.from && JSON.parse(activityLog?.data)?.job;

  const mooveJob = JSON.parse(activityLog?.data)?.from && JSON.parse(activityLog?.data)?.job;

  const comment = !JSON.parse(activityLog?.data)?.firstname && JSON.parse(activityLog?.data)?.comment;
  const listOfUpdatedDataClean = updatedDataRaw;
  const logAction = _logAction(t);

  return (
    <StyledContainer>
      <CardItem
        variant={getVariantColor(activityLog)}
        size={'small'}
        rounded={true}
        emoji={getVariantEmoji(activityLog)}
      />
      <StyledLogAndDateContainer>
        <StyledConstructedLogContainer>
          <SingleLineText
            sx={{ marginRight: '4px' }}
            text={activityLog.tam}
            variant={'body2SemiBold'}
            key={'tam-' + activityLog.id}
          />
          <SingleLineText text={logAction(activityLog)} key={'log-' + activityLog.id} />
          {listOfUpdatedDataClean &&
            listOfUpdatedDataClean.length > 0 &&
            listOfUpdatedDataClean.map((updated) => {
              if (
                activityLog.type === EvenementType.moveJobFunnelTalent ||
                activityLog.type === EvenementType.addComment ||
                activityLog.type === EvenementType.createJobFunnelTalent
              ) {
                return '';
              } else {
                return updated;
              }
            })}
          {jobUrl ? (
            <HrefLink title={'Job url'} href={jobUrl} text={createJob} />
          ) : (
            <SingleLineText text={createJob} key={'createJob-' + activityLog.id} />
          )}
        </StyledConstructedLogContainer>
        {mooveJob && jobUrl ? (
          <HrefLink href={jobUrl} text={mooveJob} />
        ) : (
          mooveJob && <SingleLineText text={mooveJob} key={'mooveJob-' + activityLog.id} />
        )}
        {comment && (
          <StyledComment>
            <SingleLineText text={comment} key={'comment-' + activityLog.id} />
          </StyledComment>
        )}
        <StyledDateText
          variant={'body1Light'}
          text={convertDateToStringFormat(activityLog.date, 'dd MMM yyyy HH:mm:SS')}
          key={'date-' + activityLog.id}
        />
      </StyledLogAndDateContainer>
    </StyledContainer>
  );
};

export default Log;
export { Log };

const StyledConstructedLogContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`,
);

const StyledDateText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
`,
);

const StyledContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
`,
);

const StyledLogAndDateContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: column wrap;
  gap: 5px;
  width: 100%;
  align-items: flex-start;
`,
);

const StyledComment = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  background-color: ${theme.palette.grey[200]};
  border-radius: 10px;
  width: 98%;
  `,
);
