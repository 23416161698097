import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import RawTypography from '@mui/material/Typography';
import { ReactI18NextChild } from 'react-i18next';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';

const Typography = styled(RawTypography)(() => ({
  color: 'rgb(0, 0, 0, 1)',
}));

export type TitlePartProps = {
  textTitle?: string | ReactElement<any, any>;
  emoji?: string | Record<string, unknown> | ReactElement<any, any>;
  span?: ReactI18NextChild | Iterable<ReactI18NextChild>;
  id?: string;
  variant?: any;
  sx?: SxProps<Theme>;
};

const TitlePart = ({ textTitle = '', emoji, span = '', id, variant = 'heading2', sx }: TitlePartProps): JSX.Element => {
  return (
    <Typography variant={variant} id={id} sx={sx}>
      <>
        {textTitle}
        {emoji} <span>{span}</span>
      </>
    </Typography>
  );
};

export default TitlePart;
export { TitlePart };
