export const getAssessmentKey = (assessment: number): string => {
  if (assessment === 0.25) {
    return 'assessmentLabel1';
  }
  if (assessment === 0.5) {
    return 'assessmentLabel2';
  }
  if (assessment === 0.75) {
    return 'assessmentLabel3';
  }
  if (assessment === 1) {
    return 'assessmentLabel4';
  }
  return 'assessmentLabel0';
};
