import { ApolloError, ApolloLink, ServerError, ServerParseError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { monitorException, withScope } from '../monitoring';
import { Scope } from '@sentry/react';
import { GraphQLError } from 'graphql';

type MonitoringOptions = {
  filterExceptions: (error: GraphQLError | Error | ServerParseError | ServerError) => boolean;
};

const filterNothing = (g: any) => {
  return g;
};

export const errorLinkMonitoring = (options?: MonitoringOptions): ApolloLink => {
  return onError(({ graphQLErrors, networkError, operation }) => {
    withScope((scope: Scope) => {
      scope.setContext('Apollo', {
        operationName: operation.operationName,
        variables: operation.variables,
        query: JSON.stringify(operation.query, null, 4),
      });
      const filterExceptions = options?.filterExceptions || filterNothing;
      if (graphQLErrors) {
        if (!filterExceptions(graphQLErrors[0])) {
          const err = new ApolloError({ graphQLErrors });
          scope.setFingerprint([err.message]);
          monitorException(err, {
            extra: {
              graphQLErrors: graphQLErrors,
            },
          });
        }
      }
      if (networkError) {
        if (!filterExceptions(networkError)) {
          const err = new ApolloError({ networkError });
          scope.setFingerprint([err.message]);
          monitorException(err, {
            extra: {
              networkError: networkError,
            },
          });
        }
      }
    });
  });
};
