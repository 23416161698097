import { BrowserConfig } from '@bloomays-lib/types.shared';

const config = (): BrowserConfig => {
  const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN as string;
  const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE as string;
  const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID as string;
  const BLOOMERS_API = import.meta.env.VITE_BLOOMERS_API as string;
  const TALENTS_API = import.meta.env.VITE_TALENTS_API as string;
  const DEBUG_MODE = (import.meta.env.VITE_DEBUG_MODE as boolean) || false;

  // eslint-disable-next-line no-console
  if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID || !BLOOMERS_API || !AUTH0_AUDIENCE || !TALENTS_API) {
    // eslint-disable-next-line no-console
    console.error('Missing environment variables for Auth0 client');
  }
  return {
    auth0: {
      clientId: AUTH0_CLIENT_ID,
      domain: AUTH0_DOMAIN,
      audience: AUTH0_AUDIENCE,
    },
    url: {
      bloomersAPI: BLOOMERS_API,
      talentAPI: `${TALENTS_API}graphql`,
    },
    service: 'forms.bloomers',
    debug: DEBUG_MODE,
    env:
      import.meta.env.VITE_ENV === 'production' ||
      import.meta.env.VITE_ENV === 'staging' ||
      import.meta.env.VITE_ENV === 'test'
        ? import.meta.env.VITE_ENV
        : 'development',
    release: import.meta.env.VITE_RELEASE || 'localhost',
    logger: {
      datadog: {
        apiKey: import.meta.env.VITE_FORM_DATADOG_API_KEY,
        rumAppId: import.meta.env.VITE_FORM_RUM_APP_ID,
      },
      level: import.meta.env.VITE_LOGGER_LEVEL || 'info',
    },
    monitoring: {
      datadog: {
        apiKey: import.meta.env.VITE_FORM_DATADOG_API_KEY,
        rumAppId: import.meta.env.VITE_FORM_RUM_APP_ID,
      },
      sentry: {
        dsn: import.meta.env.VITE_SENTRY_DSN,
      },
    },
  };
};

export default config;
