import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker as MuiDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { fr } from 'date-fns/locale';

export type DatePickerValue = Date | null;

export type DatePickerProps = {
  onAccept?: (value: DatePickerValue) => void;
  handleChange: (date: DatePickerValue) => void;
  shouldDisableDate?: (date: Date) => boolean;
  value: Date | null;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  views?: ('year' | 'day' | 'month')[];
  openTo?: 'year' | 'day' | 'month';
  disabled?: boolean;
  onClose?: any;
};

const DatePicker = ({
  handleChange,
  onAccept,
  value,
  label = '',
  views,
  openTo,
  disabled,
  shouldDisableDate,
  onClose,
  minDate,
  maxDate,
  ...props
}: DatePickerProps): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <MuiDatePicker
        onAccept={onAccept}
        onClose={onClose}
        disabled={disabled}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        openTo={openTo ? openTo : 'year'}
        views={views ? views : ['year', 'month']}
        value={value ? value : null}
        onChange={(date) => {
          handleChange(date);
        }}
        shouldDisableDate={shouldDisableDate}
        {...props}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
export { DatePicker };
