import { SingleLineText } from '@bloomays-lib/ui.shared';
import Title from '../atoms/Title';
import { Logo } from '@bloomays-lib/ui.shared';
import logo from '../../graphic-charter/bloomer.png';
import { HrefLink } from '@bloomays-lib/ui.shared';
import DetailsSectionCard from '../molecules/DetailsSectionCard';
import { detectUSDate } from '@bloomays-lib/utils.shared';
import { TalentProcess } from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

export type DetailsTalentCardProps = {
  details: TalentProcess;
};

const DetailsTalentCard = ({ details }: DetailsTalentCardProps) => {
  const { t } = useTranslation(['talents', 'random']);

  return (
    <ContainerDetailsCard>
      <ContainerLeftSection>
        <Logo image={logo} />
        <ContainerTextLeft>
          <ContainerText>
            <TextLeftSingleLineText id="candiLK" text={`${t('LKLink')} :`} />
            {details?.linkedInUrl ? <HrefLinkLK text={t('seeProfil')} href={details?.linkedInUrl} /> : '-'}
          </ContainerText>
          <ContainerText>
            <TextLeftSingleLineText
              id="candiAvailable"
              text={`${t('available')} : ${details?.availableDate ? detectUSDate(details?.availableDate) : '-'}`}
            />
          </ContainerText>
          <ContainerText>
            <TextLeftSingleLineText
              id="candiTJ"
              text={`${t('TJ')} : ${details?.salesPriceWithoutTaxes ? details?.salesPriceWithoutTaxes : '-'} €`}
            />
          </ContainerText>
          <ContainerText>
            <TextLeftSingleLineText
              id="candiRef"
              text={`${t('reference')} : ${details?.referenceAccepted ? details?.referenceAccepted : '-'}`}
            />
          </ContainerText>
          <ContainerText>
            <TextLeftSingleLineText id="candiCV" text={`${t('CV')} :`} />
            {details?.CV ? <HrefLinkLK text={t('downloadCV')} href={details?.CV} /> : '-'}
          </ContainerText>
        </ContainerTextLeft>
      </ContainerLeftSection>
      <ContainerRightSection>
        <ContainerHeaderDetailCard>
          <Title
            id="candiFullname"
            textTitle={details?.firstname ? `${details?.firstname} ${details?.lastname}` : 'TBC'}
          />
          <SingleLineText text={details?.jobTitle ? `${details?.jobTitle}` : ''} />
        </ContainerHeaderDetailCard>
        <ContainerScrollable>
          {details?.noGoReason && (
            <DetailsSectionCard text={`${details?.noGoReason}: ${details?.noGoExplanation}`} title={t('noGoTitle')} />
          )}
          {!details?.experiences && !details?.pluses && !details?.noGoReason && (
            <SingleLineText text={t('notEnoughData')} />
          )}
          {details?.experiences && <DetailsSectionCard text={details?.experiences} title={t('experiencesQuestion')} />}
          {details?.pluses && <DetailsSectionCard text={details?.pluses} title={t('plusesQuestion')} />}
          {details?.phase !== 'NotValidated' && details?.emailMessagingAddress && (
            <ContainerButton>
              <HrefLinkButton
                id="candiDesagreed"
                href={`mailto:${details?.emailMessagingAddress}?subject=Ce%20profil%20ne%20m'interresse%20pas&body=Bonjour%2C%20Je%20ne%20souhaite%20pas%20rencontrer%20ce%20profil.%20Merci%20!`}
                text={t('dislike', { ns: 'random' })}
              />
              <HrefLinkButton
                id="candiAgreed"
                href={`mailto:${details?.emailMessagingAddress}?subject=Ce%20profil%20m'interresse&body=Bonjour%2C%20Je%20souhaite%20rencontrer%20ce%20profil.%20Merci%20!`}
                text={t('like', { ns: 'random' })}
              />
            </ContainerButton>
          )}
        </ContainerScrollable>
      </ContainerRightSection>
    </ContainerDetailsCard>
  );
};

export default DetailsTalentCard;
export { DetailsTalentCard };

const ContainerButton = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  margin: '20px',
  gap: '30px',
  justifyContent: 'space-around',
}));

const ContainerDetailsCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  textAlign: 'left',
  justifyContent: 'left',
  [theme.breakpoints.down(915)]: {
    marginBottom: 0,
  },
  [theme.breakpoints.down(600)]: {
    marginBottom: 0,
  },
}));

const ContainerLeftSection = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[50]}`,
  minWidth: '300px',
  [theme.breakpoints.down(915)]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    minWidth: '100px',
  },
  [theme.breakpoints.down(600)]: {
    display: 'flex',
    flexFlow: 'row wrap',
    minWidth: '100px',
  },
}));

const ContainerText = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
}));

const ContainerRightSection = styled('div')(({ theme }) => ({
  marginLeft: '1em',
  [theme.breakpoints.down(600)]: {
    marginLeft: '0em',
  },
}));

const ContainerTextLeft = styled('div')(({ theme }) => ({
  marginTop: '4em',
  [theme.breakpoints.down(915)]: {
    margin: '2em 1em 1em 1em',
  },
  [theme.breakpoints.down(600)]: {
    margin: '0em',
  },
}));

const TextLeftSingleLineText = styled(SingleLineText)(({ theme }) => ({
  margin: '1em 0 1em 0',
  [theme.breakpoints.down(915)]: {
    margin: '0.4em 0',
  },
  [theme.breakpoints.down(600)]: {
    margin: '0.4em 0',
  },
}));

const ContainerHeaderDetailCard = styled('div')(({ theme }) => ({
  marginBottom: '3em',
  [theme.breakpoints.down(915)]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down(600)]: {
    marginBottom: 0,
  },
}));

const ContainerScrollable = styled('div')(() => ({
  overflowY: 'auto',
  height: '60vh',
}));

const HrefLinkButton = styled(HrefLink)(({ theme }) => ({
  border: 'none',
  padding: '0.7em 1.4em',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.paper.white,
  boxShadow: '2px 2px 5px rgb(201, 201, 201)',
  margin: '0.8em auto',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.white,
    color: theme.palette.primary.main,
    transition: 'ease 0.3s',
  },
}));

const HrefLinkLK = styled(HrefLink)(() => ({
  display: 'flex',
  justifyContent: 'left',
}));
