/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium';

const CustomToolbar: React.FunctionComponent<{
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  children?: React.ReactNode;
}> = ({ setFilterButtonEl, children }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport></GridToolbarExport>
    {children}
  </GridToolbarContainer>
);

export default CustomToolbar;
export { CustomToolbar };
