import { AirtableMissionPayload, AirtableSociety, CRAStatus, ContractLegalStatus, MissionType } from './airtable';
import { FileUpload, Upload } from 'graphql-upload';
import { ContractType, IMission } from './types.shared';
import { Candidat } from './domain';

export type { FileUpload };

// In graphql data that can be undefined, is always returned as null
type Maybe<T> = T | null;

// related to type Mision in schema.bloomers.graphql and ALL_MISSIONS gql query
export type AllMissionGQLItemResult = {
  recordId: string;
  label: Maybe<string>;
  beginMission: string;
  endMission: string;
  createdAt: string;
  bloomerFullName: Maybe<string[]>;
  clientSocietyName: string[];
  bloomerSocietyName: Maybe<string[]>;
  completed: number;
  isActive: Maybe<number>;
  replacement: Maybe<boolean>;
  type: Maybe<MissionType>;
};

// see ALL_MISSIONS_WITH_EXTENSION gql query
export interface AllMissionWithExtensionGQLItemResult extends AllMissionGQLItemResult {
  extension: Maybe<Pick<ExtensionAssessmentGQL, 'assessment' | 'newEndDate'>>;
}

export type AllMissionGraphQLQueryParams =
  | {
      isActive: boolean;
      missionIds?: string[];
    }
  | undefined;

export type AllMissionGQLResult<T> = {
  count: number;
  results: T[];
};

export type UpdateMissionGQLInput = {
  recordId: string;
} & Partial<AirtableMissionPayload>;

export type UpsertExtensionAssessmentGQLInput = {
  missionId: string;
  assessment: number;
  newEndDate: Date;
};

export type ExtensionAssessmentGQL = {
  missionId: string;
  assessment: number;
  updatedAt: Date;
  newEndDate: Date;
  updatedBy: string;
};

export type ExtensionMissionGQLInput = {
  endMission: string;
  recordId: string;
  useTemplateForSST: boolean;
  useTemplateForPDS: boolean;
};

export type ShortenMissionGQLInput = Omit<ExtensionMissionGQLInput, 'useTemplateForSST' | 'useTemplateForPDS'>;

export type GetExtensionAssessmentGQLResult = {
  getExtensionAssessment: Maybe<ExtensionAssessmentGQL>;
};

export type InvoiceStatus = 'Non créée' | 'En erreur' | 'Créée à valider' | 'Créée et finalisée' | 'En cours';

export type CRADashboardItemGQL = {
  missionId: string;
  bloomerFullName: string;
  clientSocietyName: string;
  contactOperationsName: string;
  activityId: Maybe<string>;
  activityMonth: Maybe<Date>;
  status: CRAStatus;
  craURL: Maybe<string>;
  invoiceURL: Maybe<string>;
  signingDocumentId: Maybe<string>;
  bloomerPayDateDays: Maybe<number>;
  dateUploadInvoice: Maybe<Date>;
  bloomerInvoiceBilled: boolean;
  bloomerInvoiceStatus: InvoiceStatus;
  bloomerInvoiceLastError: Maybe<string>;
  bloomerSocietyName: Maybe<string>;
  clientInvoiceStatus: InvoiceStatus;
  clientInvoiceLastError: Maybe<string>;
  bloomerScore: Maybe<number>;
  bloomerComment: Maybe<string>;
  bloomerScoreDate: Maybe<Date>;
  clientScore: Maybe<number>;
  clientComment: Maybe<string>;
  clientScoreDate: Maybe<Date>;
};

export type FileUploadedGQL = {
  path: string;
  url: string;
};

export type FileUploadGQL = Upload;

export type UploadGQLInput = FileUploadGQL | FileUploadedGQL;

export interface SocietyGQLInput
  extends Omit<AirtableSociety, 'KBIS' | 'URSSAFVigilanceCertificate' | 'RCPVigilanceCertificate'> {
  KBIS?: UploadGQLInput;
  URSSAFVigilanceCertificate?: UploadGQLInput;
  RCPVigilanceCertificate?: UploadGQLInput;
}

export type JobHistoricalGQL = {
  // related to GET_JOBS_HISTORICAL resolver
  processedOn: number;
  id: string;
  progress: number;
  error: string | null;
  data: {
    contact: string;
  };
};

export type LegalContractGQL = {
  id: number;
  recordId: string;
  nbSigner: number;
  signerDocId1: Maybe<string>;
  createdAt: Date;
  createdDate: string;
  signedCount: number;
  signedDate: Maybe<string>;
  smartFields?: Maybe<string>;
  status: ContractLegalStatus;
  provider: Maybe<string>;
  worflowExternalId?: string;
  contractTemplate: Maybe<string>;
  type: ContractType;
  step: string;
  emptyFileUrl: Maybe<string>;
  signedFileUrl: Maybe<string>;
  externalDocumentId: Maybe<string>;
};

export enum MissionStatusFilter {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INCOMING = 'INCOMING',
}

export type MissionDataForGetLegalContractsResult = {
  id: string;
  label: string;
  bloomerId: string;
  bloomerFullName: string;
  clientSocietyId: string;
  clientSocietyName: string;
  bloomerSocietyName: Maybe<string>;
};

export type ContractsByMissionGQL = {
  mission: MissionDataForGetLegalContractsResult;
  contracts: LegalContractGQL[];
};

export type GetLegalContractsByMissionGQLResult = {
  getLegalContractsByMission: ContractsByMissionGQL;
};

export type GetLegalContractsGQLResult = {
  getLegalContracts: ContractsByMissionGQL[];
};

export type GetLegalContractsGQLParams = {
  status: MissionStatusFilter;
};

export type UploadAmendmentGQLInput = {
  file: Upload;
  legalContractRecordId: string;
  missionRecordId: string;
  signed: boolean;
  createInvitation: boolean;
  signedDate?: string;
};

export type UploadAmendmentGQLResult = {
  uploadAmendment: {
    recordId: string;
    type: ContractType;
    step: string;
    status: ContractLegalStatus;
    nbSigner: number;
    signedCount: number;
    emptyFileUrl: Maybe<string>;
    signedFileUrl: Maybe<string>;
    signedDate: Maybe<Date>;
    externalDocumentId: string;
  };
};

export type GetOneFullMissionParams = {
  recordId: string;
};

export type GetOneFullMissionResponse = {
  missions: IMission[];
};

export type SyncCandidatJobInput = {
  jobId: string;
} & Candidat;
