import { FormEvent, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { notify, cancel } from '../../helpers/toastify';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import EditBloomerSociety from '../organisms/EditBloomerSociety';
import { UPSERT_BLOOMER_SOCIETY, ME_FULL } from '@bloomays-lib/adapter.api-bloomer';
import { useNavigate, useParams } from 'react-router-dom';
import { IMission, ISociety } from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { errorLogger } from '../../helpers/error';

const BloomerSociety = () => {
  const { t } = useTranslation(['notify']);
  const formSociety = useRef<any>();
  const navigate = useNavigate();
  const [upsertSociety] = useMutation<{ upsertBloomerSociety: ISociety }>(UPSERT_BLOOMER_SOCIETY, {
    refetchQueries: [
      {
        query: ME_FULL,
      },
    ],
  });

  const { recordId, missionRecordId } = useParams<{ recordId: string; missionRecordId: string }>();

  const { loading, data, error } = useQuery(ME_FULL);
  const society = data?.me?.bloomer?.mission?.find((mission: IMission) => {
    return mission.bloomerBillingSociety?.find((bloomerBillingSociety) => {
      return bloomerBillingSociety.recordId === recordId;
    });
  })?.bloomerBillingSociety?.[0];

  const submitForm = (e: FormEvent<HTMLFormElement>): void => {
    formSociety?.current?.validate(e);
  };

  const upsertData = async (society: ISociety) => {
    try {
      notify('info', `${t('updateInProgress')}`, null, {
        hideProgressBar: false,
        autoClose: 10000,
      });
      const upload = await upsertSociety({
        variables: {
          input: {
            name: society.name,
            recordId: society.recordId,
            status: society.status,
            siret: society.siret,
            KBIS: society.KBIS,
            URSSAFVigilanceCertificate: society.URSSAFVigilanceCertificate,
            RCPVigilanceCertificate: society.RCPVigilanceCertificate,
            IBAN: society.IBAN,
            BIC: society.BIC,
            portage: society.portage,
            bloomerBillingMission: missionRecordId ? [missionRecordId] : undefined,
            tva: society.tva,
            addressCountryCode: society.addressCountryCode,
          },
        },
      });
      cancel();
      notify('success', `${t('updateSuccess')}`, null, {
        hideProgressBar: true,
        autoClose: 2000,
      });
      navigate('/dashboard');
      return upload?.data?.upsertBloomerSociety;
    } catch (error: any) {
      cancel();
      notify('error', `${t('randomUpdateError')}`, error);
      errorLogger(error, {
        extraInfos: 'extraErrorInfos',
      });
    }
  };

  if (loading) return <LoaderSkeleton height={600} width={800} />;

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve data !',
    });
    notify('error', 'Impossible de récupérer les données. Erreur du server, essaie plus tard ! 😓', error);
  }

  return (
    <div>
      <form onSubmit={submitForm}>
        <EditBloomerSociety ref={formSociety} onValidated={upsertData} society={society} />
      </form>
    </div>
  );
};

export default WithErrorBoundary(BloomerSociety);
