import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import {
  GetLegalContractsGQLParams,
  GetLegalContractsGQLResult,
  MissionStatusFilter,
} from '@bloomays-lib/types.shared';
import { GET_LEGAL_CONTRACTS } from '@bloomays-lib/adapter.api-bloomer';
import { LoaderPending } from '@bloomays-lib/ui.shared';
import TableLegalContracts from '../organisms/TableLegalContracts';
import LabelAndSelectField from '../molecules/LabelAndSelectField';

const Contracts = () => {
  const { t } = useTranslation(['legalContracts', 'mission']);
  const [status, setStatus] = useState<MissionStatusFilter>(MissionStatusFilter.ACTIVE);

  const {
    loading,
    error,
    data: resultData,
  } = useQuery<GetLegalContractsGQLResult, GetLegalContractsGQLParams>(GET_LEGAL_CONTRACTS, {
    variables: { status },
  });

  return (
    <Container>
      <TitlePart id="titleAdminDashboardAllContracts" span={t('pageTitle')} />
      <FilterContainer>
        <LabelAndSelectField
          textLabel={t('statusLabel', { ns: 'mission' })}
          name="status"
          options={[
            { value: MissionStatusFilter.FINISHED, label: t('finished', { ns: 'mission' }) },
            { value: MissionStatusFilter.ACTIVE, label: t('active', { ns: 'mission' }) },
            { value: MissionStatusFilter.INCOMING, label: t('incoming', { ns: 'mission' }) },
          ]}
          value={status}
          defaultOption={false}
          required={true}
          handlechange={(e) => {
            setStatus(e.target.value as MissionStatusFilter);
          }}
        />
      </FilterContainer>
      <TableContainer>
        {loading ? (
          <LoaderPending text={t('fecthingAllContracts')} />
        ) : error ? (
          <NoResult>{t('errorFetchingContracts')}</NoResult>
        ) : !resultData || resultData.getLegalContracts.length === 0 ? (
          <NoResult>{t('noMission', { ns: 'mission' })}</NoResult>
        ) : (
          <TableLegalContracts missionsContracts={resultData?.getLegalContracts} />
        )}
      </TableContainer>
    </Container>
  );
};

export default Contracts;

const Container = styled('div')(() => ({
  margin: '0 80px',
}));

const TableContainer = styled('div')(() => ({
  marginTop: '50px',
  display: 'flex',
  justifyContent: 'center',
}));

const NoResult = styled('div')(({ theme }) => ({
  width: '80%',
  backgroundColor: theme.palette.primary.main,
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FilterContainer = styled('div')(() => ({
  marginTop: '10px',
  width: '30%',
}));
