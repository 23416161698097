import React, { ReactNode } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import colors from './themes-vars.module.scss';
import './shared-fonts.css';

const color = colors;

declare module '@mui/material/styles' {
  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    800: string;
    700: string;
    600: string;
    500: string;
    400: string;
  }

  interface Palette {
    tertiary: {
      yellow: string;
      yellowMedium: string;
      pinkLight: string;
      blue: string;
      blueGrey: string;
      lavander: string;
      purple: string;
      greenLight: string;
      greenMedium: string;
      redLight: string;
      brown: string;
      darkPink: string;
      red: string;
      yellowStar: string;
      positive: string;
    };
    paper: {
      white: string;
    };
  }

  interface TypographyVariants {
    body1Light: TypoCSS;
    body1Regular: TypoCSS;
    body2Medium: TypoCSS;
    body2SemiBold: TypoCSS;
    button: TypoCSS;
    button2: TypoCSS;
    caption: TypoCSS;
    heading1: TypoCSS;
    heading2: TypoCSS;
    heading3: TypoCSS;
    heading4: TypoCSS;
    heading5: TypoCSS;
    subtitle1: TypoCSS;
    subtitle2Medium: TypoCSS;
    heading1Mobile: TypoCSS;
    heading2Mobile: TypoCSS;
    heading3Mobile: TypoCSS;
    heading4Mobile: TypoCSS;
    heading5Mobile: TypoCSS;
    subtitle1Mobile: TypoCSS;
    subtitle2MediumMobile: TypoCSS;
  }

  interface PaletteOptions {
    tertiary: {
      yellow: string;
      yellowMedium: string;
      pinkLight: string;
      blue: string;
      blueGrey: string;
      lavander: string;
      purple: string;
      greenLight: string;
      greenMedium: string;
      redLight: string;
      brown: string;
      darkPink: string;
      red: string;
      yellowStar: string;
      positive: string;
    };
    paper: {
      white: string;
    };
  }

  interface TypoCSS {
    fontWeight: number;
    fontFamily: string;
    fontSize: number;
    lineHeight: string;
    display?: string;
  }

  interface TypographyPropsVariantOverrides {
    body1Light: TypoCSS;
    body1Regular: TypoCSS;
    body2Medium: TypoCSS;
    body2SemiBold: TypoCSS;
    button: TypoCSS;
    button2: TypoCSS;
    caption: TypoCSS;
    heading1: TypoCSS;
    heading2: TypoCSS;
    heading3: TypoCSS;
    heading4: TypoCSS;
    heading5: TypoCSS;
    subtitle1: TypoCSS;
    subtitle2Medium: TypoCSS;
    heading1Mobile: TypoCSS;
    heading2Mobile: TypoCSS;
    heading3Mobile: TypoCSS;
    heading4Mobile: TypoCSS;
    heading5Mobile: TypoCSS;
    subtitle1Mobile: TypoCSS;
    subtitle2MediumMobile: TypoCSS;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1Light: TypoCSS;
    body1Regular: TypoCSS;
    body2Medium: TypoCSS;
    body2SemiBold: TypoCSS;
    button: TypoCSS;
    button2: TypoCSS;
    caption: TypoCSS;
    heading1: TypoCSS;
    heading2: TypoCSS;
    heading3: TypoCSS;
    heading4: TypoCSS;
    heading5: TypoCSS;
    subtitle1: TypoCSS;
    subtitle2Medium: TypoCSS;
    heading1Mobile: TypoCSS;
    heading2Mobile: TypoCSS;
    heading3Mobile: TypoCSS;
    heading4Mobile: TypoCSS;
    heading5Mobile: TypoCSS;
    subtitle1Mobile: TypoCSS;
    subtitle2MediumMobile: TypoCSS;
  }
}

declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {
    body1Light: true;
    body1Regular: true;
    body2Medium: true;
    body2SemiBold: true;
    button: true;
    button2: true;
    caption: true;
    heading1: true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading5: true;
    subtitle1: true;
    subtitle2Medium: true;
    heading1Mobile: true;
    heading2Mobile: true;
    heading3Mobile: true;
    heading4Mobile: true;
    heading5Mobile: true;
    subtitle1Mobile: true;
    subtitle2MediumMobile: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    button: true;
    button2: true;
    caption: true;
  }
}

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
  },
);

export const theme = createTheme({
  palette: {
    primary: {
      main: '#fe83b6',
      dark: color.electricBlue,
    },
    secondary: {
      light: color.purpleBlue,
      main: '#d4dafd',
      dark: color.lightBlue,
      800: color.lightPink,
      700: color.yellow,
      600: color.purpleExtraLight,
      500: color.blueExtraLight,
      400: color.pinkExtraLight,
    },
    tertiary: {
      yellow: color.yellow,
      yellowMedium: color.yellowMedium,
      pinkLight: color.pinkLight,
      blue: color.blue,
      blueGrey: color.blueGrey,
      lavander: color.lavander,
      purple: color.purple,
      greenLight: color.greenLight,
      greenMedium: color.greenMedium,
      redLight: color.redLight,
      brown: color.brown,
      darkPink: color.darkPink,
      red: color.red,
      yellowStar: color.yellowStar,
      positive: color.positive,
    },
    grey: {
      50: color.plainBlack,
      100: color.softBlack,
      200: color.greyExtraLight,
      300: color.greyLight,
      400: color.greyMedium,
      500: color.grey,
      600: color.blue,
      700: color.bgModal,
      800: color.soft,
      900: color.medium,
    },
    paper: {
      white: color.paper,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          width: '210px',
        },
        list: {
          padding: '0px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'button' },
          style: {
            fontWeight: 600,
            fontFamily: '"Poppins SemiBold", sans-serif',
            fontSize: 14,
            lineHeight: '19.6px',
          },
        },
        {
          props: { variant: 'button2' },
          style: {
            fontWeight: 700,
            fontFamily: '"Poppins Bold", sans-serif',
            fontSize: 14,
            lineHeight: '19.6px',
          },
        },
        {
          props: { variant: 'caption' },
          style: {
            fontWeight: 400,
            fontFamily: '"Poppins Regular", sans-serif',
            fontSize: 14,
            lineHeight: '19.6px',
            display: 'block',
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          backgroundColor: 'white',
        },
        outlined: {
          border: `1px solid black`,
        },
      },
    },
  },
  typography: {
    fontFamily: ['Poppins Regular', 'Open Sans'].join(','),
    body1Light: {
      fontWeight: 300,
      fontFamily: '"Poppins Light", sans-serif',
      fontSize: 14,
      display: 'block',
      lineHeight: '19.6px',
    },
    body1Regular: {
      fontWeight: 400,
      fontFamily: '"Poppins Regular", sans-serif',
      fontSize: 14,
      lineHeight: '19.6px',
      display: 'block',
    },
    body2Medium: {
      fontWeight: 500,
      display: 'block',
      fontFamily: '"Poppins Medium", sans-serif',
      fontSize: 14,
      lineHeight: '19.6px',
    },
    body2SemiBold: {
      fontWeight: 600,
      display: 'block',
      fontFamily: '"Poppins SemiBold", sans-serif',
      fontSize: 14,
      lineHeight: '19.6px',
    },
    button: {
      fontWeight: 600,
      fontFamily: '"Poppins SemiBold", sans-serif',
      fontSize: 14,
      lineHeight: '19.6px',
    },
    button2: {
      fontWeight: 700,
      fontFamily: '"Poppins Bold", sans-serif',
      fontSize: 14,
      lineHeight: '19.6px',
    },
    caption: {
      fontWeight: 400,
      fontFamily: '"Poppins Regular", sans-serif',
      fontSize: 12,
      lineHeight: '16.8px',
    },
    heading1: {
      fontWeight: 400,
      fontFamily: '"MonumentExtended Regular", sans-serif',
      fontSize: 32,
      lineHeight: '44.8px',
      display: 'block',
    },
    heading2: {
      fontWeight: 700,
      fontFamily: '"Poppins Bold", sans-serif',
      fontSize: 28,
      lineHeight: '39.2px',
      display: 'block',
    },
    heading3: {
      fontWeight: 400,
      fontFamily: '"MonumentExtended Regular", sans-serif',
      fontSize: 24,
      display: 'block',
      lineHeight: '33.6px',
    },
    heading4: {
      fontWeight: 400,
      fontFamily: '"MonumentExtended Regular", sans-serif',
      fontSize: 20,
      display: 'block',
      lineHeight: '28px',
    },
    heading5: {
      fontWeight: 700,
      fontFamily: '"Poppins Bold", sans-serif',
      fontSize: 20,
      display: 'block',
      lineHeight: '28px',
    },
    subtitle1: {
      fontWeight: 400,
      fontFamily: '"Poppins Regular", sans-serif',
      fontSize: 16,
      lineHeight: '22.4px',
    },
    subtitle2Medium: {
      fontWeight: 500,
      fontFamily: '"Poppins Medium", sans-serif',
      fontSize: 16,
      lineHeight: '22.4px',
    },
    heading1Mobile: {
      fontWeight: 400,
      fontFamily: '"MonumentExtended Regular", sans-serif',
      fontSize: 24,
      lineHeight: '33.6px',
    },
    heading2Mobile: {
      fontWeight: 700,
      fontFamily: '"Poppins Bold", sans-serif',
      fontSize: 24,
      lineHeight: '33.6px',
    },
    heading3Mobile: {
      fontWeight: 400,
      fontFamily: '"MonumentExtended Regular", sans-serif',
      fontSize: 22,
      lineHeight: '30.8px',
    },
    heading4Mobile: {
      fontWeight: 400,
      fontFamily: '"MonumentExtended Regular", sans-serif',
      fontSize: 16,
      lineHeight: '25.2px',
    },
    heading5Mobile: {
      fontWeight: 700,
      fontFamily: '"Poppins Bold", sans-serif',
      fontSize: 18,
      lineHeight: '25.2px',
    },
    subtitle1Mobile: {
      fontWeight: 400,
      fontFamily: '"Poppins Regular", sans-serif',
      fontSize: 16,
      lineHeight: '22.4px',
    },
    subtitle2MediumMobile: {
      fontWeight: 500,
      fontFamily: '"Poppins SemiBold", sans-serif',
      fontSize: 16,
      lineHeight: '22.4px',
    },
  },
});

type ThemingProps = {
  children: ReactNode;
};

const Theming = ({ children }: ThemingProps): JSX.Element => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Theming;
export { Theming };
