import Typography from '@mui/material/Typography';
import { ReactI18NextChild } from 'react-i18next';
import { styled } from '@mui/material/styles';

export type TitleProps = {
  textTitle?: string;
  span?: ReactI18NextChild | Iterable<ReactI18NextChild>;
  id?: string;
};

const StyledTitle = styled(Typography)(
  () => `
  color: 'rgb(54, 54, 54)',
  padding: '0.4em 0',
  width: '100%',
`,
);

const StyledSpan = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.secondary.dark}
`,
);

const Title = ({ textTitle, span, id }: TitleProps): JSX.Element => {
  return (
    <div>
      <StyledTitle variant="heading1" id={id}>
        {textTitle}
      </StyledTitle>
      <StyledSpan variant="heading1">{span}</StyledSpan>
    </div>
  );
};

export default Title;
export { Title };
