import Skeleton from '@mui/material/Skeleton';
import { SingleLineText } from '../atoms/SingleLineText';
import { styled } from '@mui/material/styles';

export type LoaderSkeletonProps = {
  width?: number | string;
  height?: number | string;
  label?: string;
  variant?: 'circular' | 'rectangular' | 'rounded';
  play?: boolean;
};

export const LoaderSkeleton = ({
  width = 400,
  height = 600,
  variant = 'rectangular',
  label = 'Chargement des données ...',
  play = true,
}: LoaderSkeletonProps) => {
  return (
    <StyledLoader
      sx={() => {
        return {
          width: width,
          height: height,
          margin: '0 auto',
        };
      }}
    >
      <SingleLineText text={label} />
      <Skeleton animation={play ? 'wave' : false} variant={variant} width={width} height={height} />
    </StyledLoader>
  );
};

export default LoaderSkeleton;

const StyledLoader = styled('div')(
  (props) => `
  display:flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 3em;
`,
);
