import React, { RefObject } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';

const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

type MainCardProps = {
  boxShadow?: boolean;
  children: React.ReactNode;
  content?: boolean;
  contentSX?: SxProps<Theme>;
  darkTitle?: boolean;
  secondary?: React.ReactNode | string;
  shadow?: string;
  sx?: Record<string, unknown>;
  title?: React.ReactNode | string;
  className?: string;
};

const MainCard = React.forwardRef(
  (
    { children, content = true, contentSX, darkTitle, secondary, title, ...others }: MainCardProps,
    ref: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined,
  ) => {
    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          borderColor: 'lightgrey',
          boxShadow: '2px 2px 20px lightgrey',
          borderRadius: '10px !important',
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
        {darkTitle && title && (
          <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}
      </Card>
    );
  },
);

export default MainCard;
MainCard.displayName = 'MainCard';
