import { styled } from '@mui/material/styles';
import { ActionButton, LoaderSkeleton } from '@bloomays-lib/ui.shared';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { compact, get, includes, isEmpty, map, pipe } from 'lodash/fp';
import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';
import DataGrid from './DataGrid';
import { AllMissionWithExtensionGQLItemResult, ExtensionAssessmentGQL } from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import parse from 'date-fns/parse';
import isBefore from 'date-fns/isBefore';
import endOfMonth from 'date-fns/endOfMonth';

// TODO : rethink this behavior to allow scroll on the table when several columns exists
const computeColumnWidth = (windowWidth: number, columnNames: string[]): number => {
  if (isEmpty(columnNames)) return windowWidth;

  // The current maxWith of the table us 1680, 80px already used for the edit column
  const width = windowWidth - 70;
  return width / columnNames.length;
};

const assessmentValues = ['Prolongée', 'Mission terminée', 'Non renouvelée', '⚠️ À estimer', '25', '50', '75', '100'];

const getAssessmentTextValue = (month: string, row: AllMissionWithExtensionGQLItemResult): string | undefined => {
  const today = new Date();
  const lastDayOfSelectedMonth = endOfMonth(parse(month, 'MM/yyyy', today));
  if (isBefore(lastDayOfSelectedMonth, new Date(row.endMission))) {
    return 'Prolongée';
  }

  if (!row.isActive) {
    return 'Mission terminée'; // show nothing because the mission is finished
  }

  // if no extension
  if (!row.extension) {
    return '⚠️ À estimer'; // Warn if mission is still ongoing
  }

  // if month is not the real current endMission, column is empty
  if (
    month !== convertDateToStringFormat(row.endMission, 'MM/yyyy') &&
    month !== convertDateToStringFormat(row.extension.newEndDate, 'MM/yyyy')
  ) {
    return undefined;
  }

  if (row.extension.assessment === 0) {
    return 'Non renouvelée';
  }

  const maxWidth = row.extension.assessment * 100;
  return `${maxWidth}`;
};

const getColumnDef = (
  t: (key: string, options?: { ns: string }) => string,
  width: number,
  columnName: string,
  month: string,
): GridColDef<AllMissionWithExtensionGQLItemResult> => {
  switch (columnName) {
    case 'bloomerFullName':
    case 'clientSocietyName': {
      return {
        width,
        headerClassName: 'header',
        cellClassName: 'cellStyle',
        field: columnName,
        headerName: t(columnName, { ns: 'mission' }),
        type: 'string',
        valueGetter: (params: GridValueGetterParams<AllMissionWithExtensionGQLItemResult>) =>
          get([columnName, '0'], params.row),
      };
    }
    case 'purchase':
    case 'sale': {
      return {
        width,
        headerClassName: 'header',
        cellClassName: 'cellStyle',
        field: columnName,
        headerName: t(columnName, { ns: 'mission' }),
        type: 'number',
        valueGetter: (params: GridValueGetterParams<AllMissionWithExtensionGQLItemResult>) => {
          return get([columnName, '0'], params.row);
        },
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          params.value !== undefined ? `${params.value} €` : undefined,
      };
    }
    case 'createdAt':
    case 'endMission':
    case 'beginMission': {
      return {
        width,
        headerClassName: 'header',
        cellClassName: 'cellStyle',
        field: columnName,
        headerName: t(columnName, { ns: 'mission' }),
        type: 'date',
        valueGetter: (params: GridValueGetterParams<AllMissionWithExtensionGQLItemResult>) => {
          return new Date(get([columnName], params.row));
        },
        valueFormatter: (params: GridValueFormatterParams<Date>): string =>
          convertDateToStringFormat(params.value, 'dd/MM/yyyy'),
      };
    }
    case 'replacement': {
      return {
        width,
        headerClassName: 'header',
        cellClassName: 'cellStyle',
        field: columnName,
        headerName: t(columnName, { ns: 'mission' }),
        type: 'boolean',
        align: 'center',
        renderCell: (params: GridRenderCellParams<AllMissionWithExtensionGQLItemResult>) => {
          return !params.row.replacement ? <CloseIcon /> : <CheckIcon />;
        },
      };
    }
    case 'assessment': {
      // this column is only rendered if end of selected month is after today. See getColumnsByKPI on DetailsKPIsMissions
      return {
        width,
        headerClassName: 'header',
        cellClassName: 'cellStyle',
        field: columnName,
        headerName: t(columnName, { ns: 'mission' }),
        type: 'singleSelect',
        valueOptions: assessmentValues,
        valueGetter: (params: GridValueGetterParams<AllMissionWithExtensionGQLItemResult>) => {
          return getAssessmentTextValue(month, params.row);
        },
        valueFormatter: (params: GridValueFormatterParams<number | void>) => {
          return params.value ? params.value : null;
        },
        renderCell: (params: GridRenderCellParams<AllMissionWithExtensionGQLItemResult>) => {
          const row = params.row;
          if (!params.row) return undefined;

          const label = getAssessmentTextValue(month, params.row);
          if (!includes(label, ['25', '50', '75', '100'])) {
            return label;
          }

          const extension = row.extension as Pick<ExtensionAssessmentGQL, 'assessment' | 'newEndDate'>;
          // show extension only if month === real current endMission
          const maxWidth = extension.assessment * 100;
          const newEndDate =
            extension.assessment > 0.5
              ? ` pour le ${convertDateToStringFormat(extension.newEndDate, 'dd/MM/yyyy')}`
              : '';
          const barBackground = maxWidth > 50 ? '#6ba152' : '#ffb9b9';
          return (
            <div style={{ width: '100%', position: 'relative' }}>
              <div
                style={{
                  width: '100%',
                  position: 'absolute',
                  backgroundColor: 'transparent',
                  border: '1px solid',
                  textAlign: 'left',
                }}
              >
                {maxWidth} %{newEndDate}
              </div>
              <div style={{ maxWidth: `${maxWidth}%`, backgroundColor: `${barBackground}`, height: '21px' }}></div>
            </div>
          );
        },
      };
    }
    default: {
      return {
        width,
        field: columnName,
        headerClassName: 'header',
        cellClassName: 'cellStyle',
        headerName: t(columnName, { ns: 'mission' }),
        type: 'string',
      };
    }
  }
};

type TableProps = {
  month: string;
  rows: AllMissionWithExtensionGQLItemResult[];
  fetchLoading: boolean;
  pageSize?: number;
  hideFooterPagination?: boolean;
  columnNames: string[];
  estimatedMissionIds?: string[];
};

const TableMissionsForKPIs = ({
  month,
  rows,
  fetchLoading,
  pageSize,
  hideFooterPagination = false,
  columnNames,
  estimatedMissionIds = [],
}: TableProps) => {
  const { t } = useTranslation(['mission']);
  const navigate = useNavigate();
  const width = window.innerWidth * 0.89;

  if (rows?.length === 0 || typeof rows === 'undefined') {
    return <LoaderSkeleton height={400} width={1200} />;
  }

  const columns: GridColDef<AllMissionWithExtensionGQLItemResult>[] = pipe(
    map<string, GridColDef | void>((columnName) => {
      return getColumnDef(t, computeColumnWidth(width, columnNames), columnName, month);
    }),
    compact<GridColDef>,
  )(columnNames);

  columns.push({
    field: 'edit',
    headerClassName: 'header',
    cellClassName: 'cellStyle',
    headerName: t('edit', { ns: 'mission' }),
    width: 70,
    align: 'center',
    renderCell: (params: GridRenderCellParams<AllMissionWithExtensionGQLItemResult>) => {
      if (params.row.recordId) {
        return (
          <ActionButton
            actionType="Edit"
            color="secondary"
            id={`editBtn${params.row?.recordId}`}
            aria-label="edit"
            title="Command + Click pour ouvrir dans un nouvel onglet"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate(`/missions/${params.row?.recordId}`);
            }}
            target="_self"
            href={`/missions/${params.row?.recordId}`}
          />
        );
      }
    },
  });

  const getRowId = (row: AllMissionWithExtensionGQLItemResult) => row.recordId as string;

  return (
    <Container>
      {!isEmpty(estimatedMissionIds) ? (
        <LegendContainer>
          <EstimatedLegend></EstimatedLegend>
          <div>{t('estimatedLegend')}</div>
        </LegendContainer>
      ) : null}
      <DataGrid
        uniqueDatagridId="table-missions"
        initialState={{
          sorting: {
            sortModel: [{ field: 'clientSocietyName', sort: 'asc' }],
          },
        }}
        fetchLoading={fetchLoading}
        getRowClassName={(params) => {
          const mission = params.row as AllMissionWithExtensionGQLItemResult;
          if (estimatedMissionIds.includes(mission.recordId)) return 'estimatedRow';
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        }}
        hideFooterPagination={hideFooterPagination}
        rows={rows}
        getRowId={getRowId}
        columns={columns}
        pageSize={pageSize}
        pagination={true}
      />
    </Container>
  );
};

export default TableMissionsForKPIs;

const Container = styled('div')(() => ({
  width: '100%',
}));

const LegendContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: '5px 0 5px 0',
}));

const EstimatedLegend = styled('div')(() => ({
  width: '20px',
  height: '20px',
  backgroundColor: '#dcf2d2',
  marginRight: '10px',
}));
