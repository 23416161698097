import React, { AriaRole, forwardRef, ForwardedRef, useCallback, useMemo } from 'react';
import RawMuiIconButton from '@mui/material/IconButton';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import { HrefLink } from './HrefLink';

const StyledHrefLink = (color: any) =>
  forwardRef((props: IconButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return (
      <HrefLink
        ref={ref}
        color={color}
        href={props.href as string}
        target={props.target}
        title={props.title}
        id={props.id}
      >
        {props.children}
      </HrefLink>
    );
  });

export type IconButtonProps = {
  onClick?: (event: any) => void;
  children: React.ReactNode;
  disable?: boolean;
  title?: string;
  id?: string;
  role?: AriaRole | undefined;
  color?: 'primary' | 'secondary';
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  variant?: 'button' | 'contained';
  loading?: boolean;
};

const IconButton = ({
  id,
  disable,
  title,
  onClick,
  href,
  role,
  children,
  target,
  color = 'primary',
  variant = 'button',
  loading = false,
  ...props
}: IconButtonProps): JSX.Element => {
  const MemoStyledHrefLink = useMemo(() => {
    return StyledHrefLink(color);
  }, [color]);

  return (
    <MuiIconButton
      {...(href ? { href: href, target: target } : {})}
      role={role}
      id={id}
      onClick={onClick}
      disabled={disable}
      title={title}
      color={color}
      variant={variant}
      LinkComponent={MemoStyledHrefLink}
      {...props}
    >
      {loading ? <MuiCircularProgress color={color} size={22} /> : children}
    </MuiIconButton>
  );
};

const MuiIconButton = styled(RawMuiIconButton)<{
  variant?: Exclude<MuiButtonProps['variant'], 'text'>;
}>(({ theme, color, variant }) => {
  const ovverrideStyle: SxProps<Theme> = {
    color: color === 'primary' ? theme.palette.paper.white : theme.palette.grey[500],
    backgroundColor: color === 'primary' ? theme.palette['primary'].main : theme.palette['secondary'].main,
    ':hover': {
      backgroundColor: color === 'primary' ? theme.palette['secondary'][800] : theme.palette['tertiary'].purple,
    },
    borderRadius: '5px',
  };
  if (variant === 'contained') {
    ovverrideStyle.backgroundColor = 'transparent';
    // ovverrideStyle.border = `1px solid ${theme.palette["primary"].main}`;
    ovverrideStyle.color = color === 'primary' ? theme.palette['primary'].main : theme.palette.grey[500];
    ovverrideStyle[':hover'] = {
      backgroundColor: color === 'primary' ? 'transparent' : theme.palette.secondary[600],
      color: color === 'primary' ? theme.palette.secondary[800] : theme.palette.grey[500],
    };
  }
  return ovverrideStyle;
});

const MuiCircularProgress = styled(CircularProgress)<CircularProgressProps>(({ theme, color }) => {
  return {
    color: color === 'secondary' ? theme.palette.primary.main : theme.palette.grey[500],
  };
}) as unknown as typeof CircularProgress;

export default IconButton;
export { IconButton };
