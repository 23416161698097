export type MonthMissionKPI = {
  month: string;
  count: number;
  missionIds: string[];
  estimatedMissionIds?: string[];
};

export type MissionYearData = {
  currentYear: MonthMissionKPI[];
  lastYear: MonthMissionKPI[];
  diff: MonthMissionKPI[];
  average: {
    currentYear: number;
    lastYear: number;
  };
};

export type DashboardMissionData = {
  D: MissionYearData;
  NS: MissionYearData;
  M: MissionYearData;
  F: MissionYearData;
  EB: MissionYearData;
  BE: MissionYearData;
  BR: MissionYearData;
};
export enum KPIType {
  D = 'D',
  NS = 'NS',
  M = 'M',
  F = 'F',
  EB = 'EB',
  BE = 'BE',
  BR = 'BR',
}
