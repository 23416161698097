import RawMainCard from '../atoms/MainCard';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { JobProcess } from '@bloomays-lib/types.shared';
import CallIcon from '@mui/icons-material/Call';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import EmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';
import { useTranslation } from 'react-i18next';
import { Theme, styled } from '@mui/material/styles';

export type StatisticsProps = {
  job: JobProcess;
};

const Statistics = ({ job }: StatisticsProps): JSX.Element => {
  const { t } = useTranslation(['talents']);

  return (
    <MainCard contentSX={{ padding: '20px !important' }}>
      <ContainerChildren>
        {!!job?.nbProfilLKContacted && (
          <ContainerIcon>
            <LinkedInIcon sx={iconCSS} />
            <SingleLineText text={` ${t('nbProfilLKContacted')}:`} span={job?.nbProfilLKContacted} />
          </ContainerIcon>
        )}
        {!!job?.nbProfilPhoneContacted && (
          <ContainerIcon>
            <CallIcon sx={iconCSS} />
            <SingleLineText text={` ${t('nbProfilPhoneContacted')}:`} span={job?.nbProfilPhoneContacted} />
          </ContainerIcon>
        )}
        {!!job?.nbProfilEmailContacted && (
          <ContainerIcon>
            <EmailIcon sx={iconCSS} />
            <SingleLineText text={` ${t('nbProfilEmailContacted')}:`} span={job?.nbProfilEmailContacted} />
          </ContainerIcon>
        )}
        {!!job?.responsePerPercentage && (
          <ContainerIcon>
            <EmojiSymbolsIcon sx={iconCSS} />
            <SingleLineText text={` ${t('responsePerPercentage')}:`} span={`${job?.responsePerPercentage} %`} />
          </ContainerIcon>
        )}
      </ContainerChildren>
    </MainCard>
  );
};

export default Statistics;
export { Statistics };

const MainCard = styled(RawMainCard)(({ theme }) => ({
  color: theme.palette.grey[50],
  overflow: 'hidden',
  position: 'relative',
  width: '94%',
  textAlign: 'left',
  margin: '10px auto',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '210px',
    height: '210px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: '-125px',
    right: '-15px',
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: '-155px',
      right: '-70px',
    },
  },
}));

const ContainerChildren = styled('div')(() => ({
  padding: '0.5em',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
}));

const ContainerIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const iconCSS = (theme: Theme) => ({
  fontSize: 18,
  color: theme.palette.primary.main,
});
