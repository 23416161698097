import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button as RawButton } from './Button';

const Button = styled(RawButton)(({ theme }) => ({
  margin: '.1em auto',
  backgroundColor: theme.palette.paper.white,
  border: 'none',
  display: 'flex',
  alignContent: 'center !important',
  alignItems: 'center',
  color: theme.palette.primary.main,
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.secondary.light,
    transition: 'ease 0.3s',
    borderBottom: `solid 1px ${theme.palette.secondary.light}`,
  },
}));

export type ReadMoreProps = {
  children: React.ReactNode;
  className?: string;
};

const ReadMore = ({ children, className }: ReadMoreProps) => {
  const [clamped, setClamped] = useState<boolean>(false);

  return (
    <div className={`${className}`}>
      <Box
        sx={() => {
          if (clamped) return {};
          return {
            overflow: 'hidden',
            maxHeight: '50px',
          };
        }}
      >
        {children}
      </Box>
      <Button
        id="readMore"
        onClick={() => setClamped(!clamped)}
        textButton={`Lire ${clamped ? 'moins' : 'plus'} `}
        emoji={clamped ? <KeyboardControlKeyIcon /> : <KeyboardArrowDownIcon />}
      />
    </div>
  );
};

export default ReadMore;
export { ReadMore };
