import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { Span } from '@sentry/types';
import { Transaction, Integration } from '@sentry/types';
import { CaptureContext } from '@sentry/types';
import { Env, ILogger, Service } from '@bloomays-lib/types.shared';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import React from 'react';
let sentryInit = false;

export interface MonitoringOptions {
  sentryDSN?: string;
  useReplay: boolean;
  useReact: boolean;
  isWorkerService?: boolean;
  release: string;
  datadog: {
    datadogAPIKey?: string;
    datadogRUMAppId?: string;
    allowedTracingOrigins: string[];
  };
  env: Env;
  logger?: ILogger;
  service: Service;
}

const initMonitoring = (options: MonitoringOptions): void => {
  const sentryDSN = options.sentryDSN;
  const isServiceWorker = options.isWorkerService === true;
  let _document;
  //polyfill in monitoring cause issue
  // packages/common.logger/src/browser/datadogServiceWorkerPolyfill.ts
  if (isServiceWorker) {
    if (globalThis.document) {
      _document = globalThis.document;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      globalThis.document = undefined;
    }
  }
  const datadogAPIKey = options.datadog.datadogAPIKey;
  if (datadogAPIKey && options.datadog.datadogRUMAppId) {
    datadogRum.init({
      applicationId: options.datadog.datadogRUMAppId,
      clientToken: datadogAPIKey,
      site: 'datadoghq.eu',
      service: options.service,
      env: options.env,
      version: options.release,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: options.datadog?.allowedTracingOrigins,
    });
    datadogRum.startSessionReplayRecording();
    options.logger?.info('Datadog RUM Init Done ✅');
  }

  const integrations: Integration[] = [];
  if (!isServiceWorker) {
    integrations.push(
      new Sentry.BrowserTracing(
        options.useReact
          ? {
              routingInstrumentation: SentryReact.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
              ),
            }
          : {},
      ),
    );
  }
  if (options.useReplay) {
    integrations.push(new Sentry.Replay());
  }
  if (sentryDSN && !sentryInit) {
    Sentry.init({
      dsn: sentryDSN,
      integrations,
      tracePropagationTargets: ['localhost', /^\//, /^.*\.bloomays\.com/],
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      environment: options.env,
      release: options.release,
      beforeSend(event) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
          // Sentry.showReportDialog({ eventId: event.event_id })
        }
        return event;
      },
    });
    sentryInit = true;
    options.logger?.info(`Sentry Init (${integrations.map((i) => i.name).join(', ')}) Done ✅`);
  }

  if (isServiceWorker) {
    if (!globalThis.document && _document) {
      globalThis.document = _document;
    }
  }
};

const setTransactionName = (transactionName: string | undefined): void => {
  Sentry.configureScope((scope) => scope.setTransactionName(transactionName));
};
const startTransaction = (name: string): Transaction => {
  const trans = Sentry.startTransaction({ name });
  //const span = transaction.startChild({ op: "functionX" }); // This function returns a Span
  // functionCallX
  //span.finish(); // Remember that only finished spans will be sent with the transaction
  // transaction.finish();
  return trans;
};

const setSpan = (span: Span | undefined) => {
  Sentry.configureScope((scope) => scope.setSpan(span));
};

const startStep = (transaction: Transaction, stepName: string): Span => {
  const span = transaction.startChild({ op: stepName });
  setSpan(span);
  return span;
};

const stopStep = (span: { finish: () => void }): void => {
  span.finish();
};

const stopTransaction = (transaction: Transaction): void => {
  Sentry.configureScope((scope) => scope.clear());
  transaction.finish();
};

const setUser = (user: { displayName: string; email: string }): void => {
  Sentry.setUser({
    id: user?.email,
    email: user?.email,
    username: user?.displayName,
  });
  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: user?.email,
      username: user?.displayName,
      email: user?.email,
    });
  });
  datadogRum.setUser({
    id: user?.email,
    name: user?.displayName,
    email: user?.email,
  });
};

const monitorException = (error: any, ctx?: CaptureContext, flush?: boolean): void => {
  Sentry.captureException(error, ctx);
  if (flush === true) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Sentry.flush();
  }
};

const withScope = Sentry.withScope;

export {
  initMonitoring,
  setTransactionName,
  startTransaction,
  startStep,
  stopTransaction,
  stopStep,
  setUser,
  monitorException,
  withScope,
};
