import { styled } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { Footer, Header } from '@bloomays-lib/ui.shared';
import { useAuth } from '../../auth/AuthProvider';
import useNotifications from '../../contexts/NotificationsProvider';

type BucketProps = {
  showFooter: boolean;
};

const Bucket = ({ showFooter = false }: BucketProps): JSX.Element => {
  const auth = useAuth();
  const navigate = useNavigate();
  let user;
  if (auth) {
    user = auth.user;
  }

  const notificationCenterContext = useNotifications();

  return (
    <ContainerBucket>
      <Header
        returnToDashboard={() => navigate('/dashboard')}
        logout={auth?.logout}
        user={user}
        invitationFinished={true}
        notificationCenterContext={notificationCenterContext}
      ></Header>
      <Outlet />
      {showFooter ? <Footer release={import.meta.env.VITE_RELEASE} env={import.meta.env.VITE_ENV} /> : ''}
    </ContainerBucket>
  );
};

export default Bucket;

const ContainerBucket = styled('div')(() => ({
  margin: 0,
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  display: 'grid',
  gridAutoColumns: 'minmax(auto, 100%)',
  gridTemplateRows: '12.6vh auto 10vh',
}));
