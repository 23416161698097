import { AllMissionWithExtensionGQLItemResult, KPIType } from '@bloomays-lib/types.shared';
import pull from 'lodash/pull';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import TableMissionsForKPIs from '../molecules/TableMissionsForKPIs';
import { LoaderPending } from '@bloomays-lib/ui.shared';

const getColumnsByKPI = (kpi: KPIType): string[] => {
  const columns = [
    'clientSocietyName',
    'bloomerFullName',
    'label',
    'beginMission',
    'endMission',
    'createdAt',
    'replacement',
    'assessment',
  ];
  switch (kpi) {
    case KPIType.D: {
      return pull(columns, 'assessment');
    }
    case KPIType.BE:
    case KPIType.EB:
    case KPIType.F: {
      return pull(columns, 'createdAt');
    }
    default:
      return pull(columns, 'createdAt', 'assessment');
  }
};

export type DetailsKPIsMissionsProps = {
  kpi: KPIType;
  month: string;
  loadingMissions?: boolean;
  missions: AllMissionWithExtensionGQLItemResult[];
  estimatedMissionIds: string[];
  play?: boolean;
};

const DetailsKPIsMissions = ({
  kpi,
  month,
  loadingMissions = false,
  missions,
  estimatedMissionIds,
  play = true,
}: DetailsKPIsMissionsProps) => {
  const { t } = useTranslation(['missionPlanning', 'random', 'KPI', 'mission']);
  return (
    <Container>
      <PopinTitleDiv>
        {t('missionDetails', {
          ns: 'missionPlanning',
          KPIName: t(kpi, { ns: 'KPI' }),
          month,
        })}
      </PopinTitleDiv>
      {loadingMissions ? (
        <LoaderPending text="Chargement du détail des missions" rotate={play} />
      ) : (
        <TableMissionsForKPIs
          month={month}
          fetchLoading={false}
          rows={missions}
          estimatedMissionIds={estimatedMissionIds}
          columnNames={getColumnsByKPI(kpi)}
          pageSize={15}
        />
      )}
    </Container>
  );
};

export default DetailsKPIsMissions;

const PopinTitleDiv = styled('div')(() => ({
  width: '90%',
  display: 'flex',
  fontSize: 'large',
  fontWeight: 'bold',
  margin: '20px 0px 20px 0px',
}));

const Container = styled('div')(() => ({
  minHeight: '400px',
}));
