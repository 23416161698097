import { LogLevel } from './factory';
import type { LogLevel as ConsolaLevel } from 'consola';

export const logLevelToConsolaLevel = (level: LogLevel): ConsolaLevel => {
  switch (level) {
    case 'verbose':
      return Number.POSITIVE_INFINITY;
    case 'debug':
      return 4;
    case 'info':
      return 3;
    case 'warn':
      return 1;
    case 'error':
      return 0;
    default:
      return 3;
  }
};
