import { AirtableActivity, CRADayDetails, EachDay, IActivity, IMission } from '@bloomays-lib/types.shared';
import { isSaturday, isSunday, isWeekDay, isHoliday } from './isHoliday';
import { dateStrToUTCDate, lastDateOfMonth } from '@bloomays-lib/utils.shared';
import format from 'date-fns/format';
import { notify } from './toastify';

export const weekDay = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

export const months: { [key: number]: string } = {
  0: 'Janvier',
  1: 'Février',
  2: 'Mars',
  3: 'Avril',
  4: 'Mai',
  5: 'Juin',
  6: 'Juillet',
  7: 'Aout',
  8: 'Septembre',
  9: 'Octobre',
  10: 'Novembre',
  11: 'Décembre',
};

export const checkIsFinished = (mission: IMission, activitiesDate: Date) => {
  const activitiesFormatted = dateStrToUTCDate(format(activitiesDate, 'dd/MM/yyyy'), 'dd/MM/yyyy').setDate(1);
  const missionFormatted = dateStrToUTCDate(format(new Date(mission.endMission), 'dd/MM/yyyy'), 'dd/MM/yyyy').setDate(
    1,
  );
  const isFinished = activitiesFormatted > missionFormatted;
  if (isFinished) {
    notify('info', 'Tu ne peux pas déclarer de CRA après la fin de ta mission !');
  }
  return isFinished;
};

export const createDaysDetail = (days: EachDay[]): CRADayDetails => {
  if (!days || days.length === 0) {
    throw new Error('Pas de détail du CRA');
  }
  const details: CRADayDetails = {};

  days.forEach((day: EachDay) => {
    details[day.id] = {
      duration: day.dayWorked || 0,
      comment: day.comment || '',
      isAstreinte: day.astreinte || 0,
    };
  });
  return details;
};

export const calculPerDay = (
  arr: EachDay[],
  property: 'nbDaysWorked' | 'nbDaysMarkupWorked' | 'nbDaysMarkup2Worked' | 'astreinte',
): number => {
  let initialValue = 0;
  arr?.forEach((el) => {
    const date = dateStrToUTCDate(el.date, 'd/M/yyyy');
    const shoudNotWork = isHoliday(date) || el.isHoliday;
    if (isWeekDay(date) && property === 'nbDaysWorked' && !shoudNotWork) {
      initialValue += el.dayWorked || 0;
    } else if (isSaturday(date) && property === 'nbDaysMarkupWorked' && !shoudNotWork) {
      initialValue += el.dayWorked || 0;
    } else if ((isSunday(date) || shoudNotWork) && property === 'nbDaysMarkup2Worked') {
      initialValue += el.dayWorked || 0;
    } else if (property === 'astreinte' && el.astreinte) {
      initialValue += el.astreinte || 0;
    } else {
      return (initialValue += 0);
    }
  });
  return initialValue;
};

export const retrieveValue = (
  activity: any,
  day: number,
  property: 'isAstreinte' | 'duration' | 'comment',
): number | undefined | string => {
  if (!activity) {
    return;
  }
  if (typeof activity === 'string') {
    try {
      activity = JSON.parse(activity);
    } catch (err: any) {
      throw new Error(`Activity is not a valid JSON - Activity: ${activity}`, {
        cause: err,
      });
    }
  }
  const objectLength = Object.keys(activity).length;
  if (objectLength === 0) return;

  switch (typeof activity[day]) {
    case 'number':
    case 'undefined':
    case null:
      switch (property) {
        case 'duration':
          return activity[day] || 0;
        case 'comment':
          return '';
        case 'isAstreinte':
          return 0;
      }
      break;
  }
  return activity[day]?.[property] ? activity[day][property] : property === 'comment' ? '' : 0;
};

export const convertActivityToEachDaysArray = (activity: AirtableActivity): EachDay[] => {
  const activityDaysDetails = activity.nbDaysDetails && JSON.parse(JSON.stringify(activity?.nbDaysDetails));

  const lastDateCurrentMonth = lastDateOfMonth(new Date(activity.month.toString()));

  const arrayDateofEachDay: EachDay[] = [];

  for (let i = lastDateCurrentMonth.getDate(); i >= 1; i--) {
    const journey = `${lastDateCurrentMonth.getMonth() + 1}/${i}/${lastDateCurrentMonth.getFullYear()}`;

    const journeyStringToDate = new Date(journey);
    const date = `${i}/${lastDateCurrentMonth.getMonth() + 1}/${lastDateCurrentMonth.getFullYear()}`;

    arrayDateofEachDay.push({
      id: i,
      day: weekDay[journeyStringToDate.getDay()],
      date: date,
      isHoliday: isHoliday(journeyStringToDate),
      dayWorked: activity?.nbDaysDetails ? (retrieveValue(activityDaysDetails, i, 'duration') as number) : 0,
      checked: false,
      comment: activity?.nbDaysDetails ? (retrieveValue(activityDaysDetails, i, 'comment') as string) : '',
      astreinte: activity?.nbDaysDetails ? (retrieveValue(activityDaysDetails, i, 'isAstreinte') as number) : 0,
    });
  }

  return arrayDateofEachDay.reverse();
};

export const dateStrToMonth = (arrData: any[]): any[] => {
  const arrMonthsFilled = arrData.map((el: { activity: IActivity[]; contactOperations: { email: string } }) => {
    if (!el.activity) {
      return [];
    }
    const activities = el.activity;
    return activities?.map((element: IActivity) => {
      const dateToChange = element.month;
      const dateObject = new Date(dateToChange);
      return {
        recordId: element.recordId,
        date: dateObject,
        month: months[dateObject.getMonth()],
        monthNumber: dateObject.getMonth(),
        year: dateObject.getFullYear(),
        nbDaysWorked:
          (element?.nbDaysWorked || 0) + (element.nbDaysMarkupWorked || 0) + (element.nbDaysMarkup2Worked || 0),
        validated: element.validated,
        nbDaysDetails: element.nbDaysDetails,
        signingDocumentId: element.signingDocumentId,
        sign: element.signing,
        contactOperations: el?.contactOperations?.email,
        signedCount: element.signedCount,
        urlCraS3: element.urlCraS3,
        urlInvoicesS3: element.urlInvoicesS3,
      };
    });
  });
  return arrMonthsFilled;
};
