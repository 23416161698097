import { Title } from '../atoms/Title';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { styled } from '@mui/material/styles';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import ServiceSkills from '../../services/serviceSkills';
import LabelManagment from '../organisms/LabelManagment';

const Container = styled('div')(({ theme }) => ({
  width: '90%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
}));

const AdminSkillManagment = (): JSX.Element => {
  const AdminSkills = ServiceSkills(LabelManagment);

  return (
    <div>
      <Title id="adminSkills" textTitle={'Administration des compétences'} />
      <SingleLineText id="descAdminSkills" text={'Administration des compétences et gestion des doublons'} />
      <Container>
        <AdminSkills />
      </Container>
    </div>
  );
};

export default WithErrorBoundary(AdminSkillManagment);
