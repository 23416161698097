import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import FilterTab from '../atoms/FilterTab';
import { useTranslation } from 'react-i18next';
import MultipleSelectSearch from './MultipleSelectSearch';
import { SingleLineText, Switch } from '@bloomays-lib/ui.shared';
import { Item, ItemSkills } from '@bloomays-lib/types.shared';
import { Action, ActionType, State } from '../../types/talents';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import has from 'lodash/fp/has';
import keyBy from 'lodash/fp/keyBy';

export type FilterSkillProps = {
  onSearchSkill: (searchValue: string) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  skills: ItemSkills[];
  state: State;
  dispatch: React.Dispatch<Action>;
  allSkills?: ItemSkills[];
  setAllSkills: React.Dispatch<React.SetStateAction<ItemSkills[] | undefined>>;
  loadingSkills: boolean;
  searchSkillValue: string;
  setSearchSkillValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSkillsList: React.Dispatch<React.SetStateAction<ItemSkills[]>>;
  selectedSkillsList: ItemSkills[];
};

const FilterSkill = ({
  dispatch,
  skills,
  onSearchSkill,
  state,
  loadingSkills,
  allSkills,
  setAllSkills,
  setPage,
  searchSkillValue,
  setSearchSkillValue,
  selectedSkillsList,
  setSelectedSkillsList,
}: FilterSkillProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('talentTool');
  const ref = React.useRef<HTMLDivElement>(null);
  const globalStateSkills = state.search.skills?.skills;
  const mapGlobalStateSkills = keyBy('id', globalStateSkills);
  const [filterOperator, setFilterOperator] = useState<'AND' | 'OR'>(
    get(['search', 'skills', 'or'], state) ? 'OR' : 'AND',
  );

  const onClickLevelSkill = (item: ItemSkills, level: number) => {
    const newskill: Item = { id: item.id, minLevel: level, label: item.label };
    setAllSkills(
      allSkills?.map((skill) => {
        if (skill.id === item.id) {
          dispatch({
            type: ActionType.ADD_SKILLS_SEARCH,
            skills: [newskill],
          });
          return { ...skill, selected: true, level };
        } else {
          return skill;
        }
      }),
    );
  };

  React.useEffect(() => {
    // use effect pour marquer comme selectionnées les skills du globalStateSkills dans allSkills
    if (skills.length > 0) {
      setAllSkills(
        skills?.map((skill) => {
          return {
            ...skill,
            selected: has(skill.id, mapGlobalStateSkills),
          };
        }),
      );
    }
  }, [setAllSkills, skills]);

  React.useEffect(() => {
    // ça m'a l'air inutile, je sais pas à quelle moment globalStateSkills est changé à l'intérieur ici
    if (globalStateSkills) {
      setSelectedSkillsList(globalStateSkills);
    }
  }, [globalStateSkills, setSelectedSkillsList]);

  const onSwitchItem = (e: ItemSkills, checked: boolean) => {
    const newskill: Item = {
      id: e.id,
      minLevel: e.level || 0,
      label: e.label,
    };
    if (checked) {
      dispatch({
        type: ActionType.ADD_SKILLS_SEARCH,
        skills: [newskill],
      });
    } else {
      dispatch({
        type: ActionType.RM_SKILLS_SEARCH,
        skillsId: [e.id],
      });
    }

    // Cette logique est à revoir, la liste de resultats à afficher devrai être setté ailleurs (state ? useEffect ?)
    setAllSkills(
      allSkills?.map((skill) => {
        if (skill.id === e.id) {
          if (!checked) {
            return { ...skill, selected: checked, level: undefined };
          } else {
            return { ...skill, selected: checked, level: e.level };
          }
        } else {
          return skill;
        }
      }),
    );
    setPage(1);
  };

  const onSwitchAllItems = (items: ItemSkills[], unSelect?: boolean) => {
    const newSkills: Item[] = items.map((item) => {
      return { id: item.id, minLevel: item.level || 0, label: item.label };
    });
    const allSkillsSelected = items.map((item) => {
      return { ...item, selected: true };
    });
    if (unSelect) {
      setAllSkills(allSkillsSelected);
      dispatch({
        type: ActionType.ADD_SKILLS_SEARCH,
        skills: newSkills,
      });
    } else {
      const idSkills = items.map((skill) => {
        return skill.id;
      });
      setAllSkills([]);
      dispatch({
        type: ActionType.RM_SKILLS_SEARCH,
        skillsId: idSkills,
      });
    }
    setPage(1);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div>
      <FilterTab
        label={t('skill')}
        numberOfFilter={globalStateSkills?.length}
        onClick={() => {
          setOpen(!open);
        }}
        filled={false}
      />
      {open && (
        <StyledContainer ref={ref}>
          <div>
            <StyledText text={t('skillRequired')} variant={'subtitle2Medium'} />
          </div>
          <OperatorFilterContainer>
            {t('skillOrOperator')}
            <Switch
              sx={{
                marginRight: 0,
              }}
              disabled={isEmpty(selectedSkillsList) || selectedSkillsList.length < 2}
              checked={filterOperator === 'AND'}
              onSwitch={(evt, value) => {
                const filterOperator = value ? 'AND' : 'OR';
                setFilterOperator(filterOperator);
                dispatch({
                  type: ActionType.OPERATOR_SKILLS_SEARCH,
                  skills: globalStateSkills,
                  or: filterOperator === 'OR',
                });
              }}
            />
            {t('skillAndOperator')}
          </OperatorFilterContainer>
          <StyledList>
            <MultipleSelectSearch
              setSearch={setSearchSkillValue}
              search={searchSkillValue}
              loading={loadingSkills}
              onClickLevel={onClickLevelSkill}
              onSwitch={onSwitchItem}
              items={allSkills}
              onSwitchMultiple={onSwitchAllItems}
              onSearch={onSearchSkill}
              selectedItemsList={selectedSkillsList}
              setSelectedItemsList={setSelectedSkillsList}
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterSkill;
export { FilterSkill };

const OperatorFilterContainer = styled('div')(
  () => `
  width: 100%;
  font-size: x-small;
`,
);

const StyledContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 20px;
  padding: 24px 24px 32px 24px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 528px;
  max-height: 426px;
  position: absolute;
  z-index: 1;
  overflow-X: auto;
`,
);

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
