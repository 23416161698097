import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ONE_JOB_CLIENT } from '@bloomays-lib/adapter.api-bloomer';
import TalentGrid from '../organisms/TalentGrid';
import { LoaderSkeleton, SingleLineText } from '@bloomays-lib/ui.shared';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { notify } from '../../helpers/toastify';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import JobQualification from '../organisms/JobQualification';
import { JobProcess } from '@bloomays-lib/types.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import Title from '../atoms/Title';
import Stepper from '../organisms/Stepper';
import Statistics from '../organisms/Statistics';
import { styled } from '@mui/material/styles';
import { errorLogger, getApolloErrorCode } from '../../helpers/error';

const TalentBoard = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const {
    loading: loadingCandidates,
    error: errorCandidates,
    data: dataJobProcess,
  } = useQuery(ONE_JOB_CLIENT, {
    variables: {
      id,
    },
  });
  const [jobProcessState, setJobProcessState] = useState<JobProcess>(dataJobProcess?.jobProcess);

  const { t } = useTranslation(['notify', 'talents', 'random']);

  React.useEffect(() => {
    setJobProcessState(dataJobProcess?.jobProcess);
  }, [dataJobProcess]);

  const [animationData, setAnimationData] = useState<any>();
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    import('../../graphic-charter/animations/search.json').then(setAnimationData);
  }, []);

  if (loadingCandidates) {
    return <LoaderSkeleton label={t('loadingText', { ns: 'random' })} height={600} width={800} />;
  }

  if (errorCandidates) {
    const errorCode = getApolloErrorCode(errorCandidates);

    switch (errorCode) {
      case 'OBJECT_NOT_FOUND':
        notify('error', t('jobProcessNotFound'));
        break;
      default:
        errorLogger(errorCandidates, {
          extraInfos: `Internal server error, impossible to retrieve data about job Process: ${id}`,
        });
        notify('error', t('randomLKError'), errorCandidates);
    }
  }

  const job: JobProcess = jobProcessState;
  const candidates = job?.talentsProcess;

  const jobType: any = {
    IT: 'Freelance',
    CDI: 'CDI',
    RPO: 'RPO',
  };

  const workflowsSteps = [
    t(`phase.Prequalification`, { ns: 'talents' }),
    t(`phase.Qualification`, { ns: 'talents' }),
    t(`phase.Selected`, { ns: 'talents' }),
    t(`phase.ReferenceCheck`, { ns: 'talents' }),
    t(`phase.InterviewPreparation`, { ns: 'talents' }),
    t(`phase.InterviewDebriefing`, { ns: 'talents' }),
    t(`phase.ToConfirm`, { ns: 'talents' }),
    t(`phase.Validated`, { ns: 'talents' }),
    t(`phase.Archive`, { ns: 'talents' }),
  ];

  const hidden =
    !job?.nbProfilPhoneContacted &&
    !job?.nbProfilLKContacted &&
    !job?.nbProfilEmailContacted &&
    !job?.responsePerPercentage;

  return (
    <div>
      <Title textTitle={`${job?.title} en ${jobType[job?.type]}`} />
      <PaddingTitlePart textTitle={`📌  ${t('recap', { ns: 'talents' })}`} />
      <JobQualification job={job} />
      {!hidden && (
        <>
          <TitlePart textTitle={`🧮 ${t('statistics', { ns: 'talents' })}`} />
          <Statistics job={job} />
        </>
      )}
      <MobileStepper steps={workflowsSteps} title={`💼 ${t('workflowsSteps', { ns: 'talents' })}`} />
      {candidates?.length !== 0 ? (
        <TalentGrid candidates={candidates} />
      ) : (
        <ContainerLottie>
          <TitleSingleLineText text={t('descriptionProcess', { ns: 'talents' })} />
          <Lottie id="lottieCandidates" animationData={animationData} style={{ height: 500 }} play={true} />
        </ContainerLottie>
      )}
    </div>
  );
};

export default WithErrorBoundary(TalentBoard);

const PaddingTitlePart = styled(TitlePart)(() => ({
  padding: 0,
}));

const MobileStepper = styled(Stepper)(({ theme }) => ({
  [theme.breakpoints.down(700)]: {
    display: 'none',
  },
}));

const TitleSingleLineText = styled(SingleLineText)(() => ({
  marginLeft: '20px',
  marginRight: '20px',
  fontWeight: 'bold',
}));

const ContainerLottie = styled('div')(() => ({
  marginTop: '6em',
}));
