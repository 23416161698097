import holidays from './holidaysDate.json';
import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';

export const isHoliday = (dateParams: Date) => {
  if (!dateParams.getTime()) {
    return false;
  }
  const holiday = holidays.filter((date: string) => {
    return date === convertDateToStringFormat(dateParams);
  });
  if (holiday.length > 0) return true;
  return false;
};

export const isWeekDay = (dateParams: Date) => {
  const dayOfWeek = dateParams.getDay();
  if (dayOfWeek === 6 || dayOfWeek === 0) {
    return false;
  }
  return true;
};

export const isSunday = (dateParams: Date) => {
  const dayOfWeek = dateParams.getDay();
  if (dayOfWeek === 0) {
    return true;
  }
  return false;
};

export const isSaturday = (dateParams: Date) => {
  const dayOfWeek = dateParams.getDay();
  if (dayOfWeek === 6) {
    return true;
  }
  return false;
};
