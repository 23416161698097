import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import MainCard from '../atoms/MainCard';
import { LoaderSkeleton, TitlePart } from '@bloomays-lib/ui.shared';
import DatePicker from '../atoms/DatePicker';
import { ChartData } from 'chart.js';
import { useTheme } from '@mui/material/styles';

export type CamembertProps = {
  data: ChartData<'doughnut', number[], unknown>;
  title: string;
  camembertData?: { color: string; value: string; title: string }[] | [];
  value: Date;
  handleChange: (e: Date | null) => void;
  isLoading: boolean;
  subTitle: string;
};

ChartJS.register(ArcElement, Tooltip, Legend);

const Camembert = (
  { data, title, camembertData, handleChange, isLoading, value, subTitle }: CamembertProps,
  props: any,
) => {
  const theme = useTheme();

  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <div>
      {isLoading ? (
        <LoaderSkeleton label="" height={540} width={550} />
      ) : (
        <MainCard {...props} contentSX={{ maxHeight: 500 }}>
          <ContainerLabelAndDate>
            <TitlePart textTitle={title} />
            <DatePicker value={value} handleChange={handleChange} label="Mois" />
          </ContainerLabelAndDate>
          <CardContent>
            <Box
              sx={{
                height: 280,
              }}
            >
              <Typography>{subTitle}</Typography>
              <Doughnut data={data} options={options} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2,
              }}
            >
              {camembertData?.map((data, i) => (
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '0.5em 0.7em',
                  }}
                >
                  <Typography color="textPrimary" variant="body1">
                    {data?.title}
                  </Typography>
                  {data?.value && (
                    <Typography style={{ color: data?.color }} variant="h6">
                      {data?.value}%
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </CardContent>
        </MainCard>
      )}
    </div>
  );
};

export default Camembert;
export { Camembert };

const ContainerLabelAndDate = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'start',
}));
