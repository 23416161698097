import { ReactNode, Reducer, createContext, useContext, useEffect, useReducer } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  DELETE_ALL_NOTIFICATION,
  ME_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '@bloomays-lib/adapter.api-bloomer';
import { convertINotificationToNotifcationCenterItem } from '../services/notificationService';
import { INotification } from '@bloomays-lib/types.shared';
import { Action, reducerNotificationCenter } from '../reducers/reducerNotificationCenter';
import { NotificationCenterContext, NotificationCenterItem } from '@bloomays-lib/ui.shared';

export const NotificationContext = createContext<NotificationCenterContext>({
  loading: true,
  notifications: [],
  error: undefined,
  read: () => Promise.resolve(false),
  readAll: () => Promise.resolve(0),
  deleteAll: () => Promise.resolve(0),
});

export const NotificationProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [notificationCenterItems, dispatch] = useReducer<Reducer<NotificationCenterItem[], Action>>(
    reducerNotificationCenter,
    [],
  );
  const { loading, error, data } = useQuery<{
    me: { bloomer?: { notification?: INotification[] } };
  }>(ME_NOTIFICATION);
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const [deleteAllNotification] = useMutation<{ deleteAllNotification: number }>(DELETE_ALL_NOTIFICATION);
  const [readAllNotification] = useMutation<{ readAllNotification: number }>(READ_ALL_NOTIFICATION);

  const read = async (notification: NotificationCenterItem): Promise<boolean> => {
    dispatch({ type: 'READ', notification });
    if (notification.data?.recordId) {
      try {
        await updateNotification({
          refetchQueries: [ME_NOTIFICATION],
          variables: {
            recordId: notification.data?.recordId,
            read: true,
          },
        });
        return true;
      } catch (err: any) {
        return false;
      }
    }
    return true;
  };

  const readAll = async (): Promise<number> => {
    dispatch({ type: 'READ_ALL' });
    try {
      const res = await readAllNotification({
        refetchQueries: [ME_NOTIFICATION],
      });
      return res.data?.readAllNotification || -1;
    } catch (err: any) {
      return -1;
    }
  };

  const deleteAll = async (): Promise<number> => {
    dispatch({ type: 'DELETE_ALL' });
    try {
      const res = await deleteAllNotification({
        refetchQueries: [ME_NOTIFICATION],
      });
      return res.data?.deleteAllNotification || -1;
    } catch (err: any) {
      return -10;
    }
    return 0;
  };

  useEffect(() => {
    if (data?.me?.bloomer?.notification) {
      dispatch({
        type: 'FETCH',
        notifications: data.me.bloomer.notification.map(convertINotificationToNotifcationCenterItem),
      });
    }
  }, [data]);

  const result: NotificationCenterContext = {
    loading,
    notifications: notificationCenterItems,
    error,
    read,
    readAll,
    deleteAll,
  };

  return <NotificationContext.Provider value={result}>{children}</NotificationContext.Provider>;
};

export default function useNotifications() {
  return useContext(NotificationContext);
}
