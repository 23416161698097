import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import RawCard from '@mui/material/Card';
import RawCardHeader from '@mui/material/CardHeader';
import RawCardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoBloomays from '../../graphic-charter/bloomer.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTranslation } from 'react-i18next';
import { TalentProcess } from '@bloomays-lib/types.shared';
import Tag from '../atoms/Tag';
import DetailsCard from '../organisms/DetailsTalentCard';
import Modal from '../atoms/Modal';
import { detectUSDate } from '@bloomays-lib/utils.shared';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import { IconButton } from '@bloomays-lib/ui.shared';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

export type CardTalentProps = {
  candidate: TalentProcess;
  actionCard?: () => void;
};

const CardTalent = ({ candidate }: CardTalentProps): JSX.Element => {
  const { t } = useTranslation(['talents']);
  const [expanded, setExpanded] = React.useState(false);
  const { firstname, lastname, jobTitle, location, phase, availableDate, emailMessagingAddress, linkedInUrl } =
    candidate;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actionCard = () => {
    handleOpen();
  };

  let cleanedAvailableDate = 'TBC';
  if (availableDate) {
    cleanedAvailableDate = detectUSDate(availableDate);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let notValidatedClassesRoot: SxProps<Theme>;
  let notValidatedClassesMedia: SxProps<Theme>;

  switch (phase) {
    case 'NotValidated':
    case 'Archive':
      notValidatedClassesMedia = (theme: Theme) => ({
        backgroundColor: theme.palette.grey[300],
      });
      notValidatedClassesRoot = (theme: Theme) => ({
        background: theme.palette.grey[300],
      });
      break;
    default:
      notValidatedClassesMedia = null;
      notValidatedClassesRoot = null;
  }

  const picture = LogoBloomays;

  const jobNameReduced = jobTitle?.length > 50 ? `${jobTitle?.substring(0, 50)}...` : jobTitle;

  const modalWidth = window.innerWidth < 1100 ? '90%' : '65%';

  const titleCard = firstname && lastname ? `${firstname} ${lastname} - ${jobNameReduced} - ` : 'TBC - ';

  return (
    <Card sx={notValidatedClassesRoot}>
      <Modal open={open} onClose={handleClose} additionnalCSS={{ width: modalWidth, overflowY: 'auto' }}>
        <DetailsCard details={candidate} />
      </Modal>
      <CardHeader
        disableTypography={true}
        action={
          <IconButton
            variant="contained"
            aria-label="see more"
            onClick={() => {
              if (actionCard) actionCard();
            }}
          >
            ➕
          </IconButton>
        }
        title={<Typography variant="caption">{titleCard}</Typography>}
        subheader={<Typography variant="caption">{`${location || 'TBC'} - ${cleanedAvailableDate}`}</Typography>}
      />
      <ContainerTag>
        <Tag text={phase ? t(`phase.${phase}`) : 'TBC'} />
      </ContainerTag>
      <CardMedia sx={notValidatedClassesMedia} image={picture} title={`${firstname} ${lastname}`} />
      <CardActions disableSpacing>
        {phase !== 'NotValidated' && emailMessagingAddress && (
          <>
            <IconButton
              variant="contained"
              color="secondary"
              aria-label="i don't like it"
              href={`mailto:${emailMessagingAddress}?subject=Ce%20profil%20ne%20m'interresse%20pas&body=Bonjour%2C%20Je%20ne%20souhaite%20pas%20rencontrer%20ce%20profil.%20Merci%20!`}
              target="_blank"
            >
              <ThumbDownIcon />
            </IconButton>
            <IconButton
              variant="contained"
              color="secondary"
              aria-label="it's my favorite"
              href={`mailto:${emailMessagingAddress}?subject=Ce%20profil%20m'interresse&body=Bonjour%2C%20Je%20souhaite%20rencontrer%20ce%20profil.%20Merci%20!`}
              target="_blank"
            >
              <ThumbUpIcon />
            </IconButton>
          </>
        )}
        {linkedInUrl ? (
          <IconButton aria-label="share" href={linkedInUrl} target="_blank">
            <LinkedInIcon />
          </IconButton>
        ) : null}
        <ExpandIconButton
          sx={[
            {
              transform: 'rotate(0deg)',
              transition: (theme: Theme) =>
                theme.transitions.create('all', {
                  duration: theme.transitions.duration.shortest,
                }),
            },
            expanded && {
              transform: 'rotate(180deg)',
            },
          ]}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandIconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph></Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CardTalent;
export { CardTalent };

const Card = styled(RawCard)(() => ({
  maxWidth: 300,
  minWidth: 300,
  maxHeight: 390,
  minHeight: 390,
  margin: '2em 18px',
}));

const CardMedia = styled(RawCardMedia)(() => ({
  minHeight: 200,
}));

const ExpandIconButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardHeader = styled(RawCardHeader)(() => ({
  minHeight: 50,
  maxHeight: 50,
  paddingRight: 5,
  marginBottom: 0,
  paddingBottom: 0,
}));

const ContainerTag = styled('div')(() => ({
  margin: '0.5em',
  display: 'flex',
}));
