import { EU_VAT_REGEX_STRING } from '@bloomays-lib/types.shared';
import { TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';

export type VATTextFieldProps = {
  name?: string;
  required?: boolean;
  value: string;
  onChange?: (e: { target: { value: string; [key: string]: any } }) => void;
  id?: string;
  hidden?: boolean;
  formElement?: boolean;
};

const VATTextField = (props: VATTextFieldProps): JSX.Element => {
  return (
    <ValidationTextField
      label="Numéro TVA"
      minLength={'2'}
      hidden={props.hidden}
      type={TypeInput.text}
      placeholder="Obligatoire pour les sociétés assujetties à la TVA"
      pattern={EU_VAT_REGEX_STRING}
      formElement={props.formElement}
      {...props}
    />
  );
};

export default VATTextField;
export { VATTextField };
