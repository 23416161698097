export const findDuplicate = (array: any[]) => {
  const compteur = new Map();
  const duplicate = [];

  for (const element of array) {
    if (compteur.has(element.label)) {
      compteur.set(element.label, compteur.get(element.label) + 1);
    } else {
      compteur.set(element.label, 1);
    }
  }

  for (const [element, count] of compteur.entries()) {
    if (count > 1) {
      duplicate.push({ element, count });
    }
  }

  return duplicate;
};

export const isObject = (val: any) => {
  return val !== null && typeof val === 'object';
};

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export const safeCircularStringify = (val: any) => {
  return JSON.stringify(val, getCircularReplacer());
};
