import React, { useCallback, useDeferredValue, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import {
  GridActionsCellItem,
  GridEventListener,
  GridRenderCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridColDef,
  GridRowParams,
  GridCellParams,
  gridClasses,
  GridColTypeDef,
  GridRenderEditCellParams,
  useGridApiContext,
  GridValueGetterParams,
  useGridApiRef,
  GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import redirect from '../../graphic-charter/icons/redirect.svg';
import RawDataGrid from '../molecules/DataGrid';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { IJobTitle } from '@bloomays-lib/types.shared';
import omit from 'lodash/omit';
import { ITalent } from '@bloomays-lib/types.shared';
import FreeSoloAutoComplete from '../atoms/FreeSoloAutoComplete';
import useFuse from '../../customHooks/useFuse';
import Slider from '../atoms/Slider';
import { Logger } from '../../services/serviceLogger';
import { Button, Logo } from '@bloomays-lib/ui.shared';
import { IFuseOptions } from 'fuse.js';
const logger = Logger('DirectJobTitleManagment');

export const cleanEditableLabelBeforeUpdate = (row: ITalentEditable): ITalent => {
  return omit(row, ['merge']) as unknown as ITalent;
};

type ITalentEditable = ITalent & {
  merge?: string;
};

export type DirectJobTitleManagmentProps = {
  labels: IJobTitle[];
  talents: ITalent[];
  onError: (error: any) => void;
  onUpdate: (newtalentRow: ITalent, newJobTitle: string) => Promise<ITalent | undefined>;
};

const DataGrid = styled(RawDataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const DirectJobTitleManagment = ({ labels, talents, onError, onUpdate }: DirectJobTitleManagmentProps): JSX.Element => {
  const [rows, setRows] = React.useState<ITalentEditable[]>(talents);
  const [rowsSelected, setRowsSelected] = React.useState<ITalentEditable[]>([]);
  const [rowsSelectedJobTitle, setRowsSelectedJobTitle] = React.useState<string>();
  const [sliderValue, setSliderValue] = React.useState<number>(0.2);
  const [fuseThreshold, setFuseThreshold] = React.useState<number>(0.2);
  const [fuseOptions, setFuseOptions] = React.useState<IFuseOptions<IJobTitle>>({});
  const deferredFuseThreshold = useDeferredValue(fuseThreshold);
  const isoRows = React.useMemo<IJobTitle[]>(() => {
    return labels.filter((s) => s.iso);
  }, [labels]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const apiRef = useGridApiRef();

  const resolveRows = useCallback(() => Promise.resolve(isoRows), [isoRows]);

  React.useEffect(() => {
    const handleEvent: GridEventListener<'rowSelectionChange'> = (
      params, // GridRowSelectionCheckboxParams
      event, // MuiEvent<React.ChangeEvent<HTMLElement>>
      details, // GridCallbackDetails
    ) => {
      setRowsSelected(rows.filter((t) => params.includes(t.id || 0)));
    };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent('rowSelectionChange', handleEvent);
  }, [apiRef, rows]);

  useEffect(() => {
    const initRowModel: GridRowModesModel = {};
    talents.forEach((t) => {
      if (t.id) {
        initRowModel[t.id] = { mode: GridRowModes.Edit };
      }
    });
    // setRowModesModel(initRowModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFuseOptions({
      keys: ['label', 'alternateLabels'],
      threshold: deferredFuseThreshold,
      shouldSort: true,
    });
  }, [deferredFuseThreshold]);

  const searchFuse = useFuse<IJobTitle>(isoRows, fuseOptions);

  const handleSliderChange = useCallback(
    (event: Event, value: number | number[]) => {
      setSliderValue(Array.isArray(value) ? value?.[0] || 0 : value);
    },
    [setSliderValue],
  );

  const handleSliderCommited = useCallback(
    (event: React.SyntheticEvent | Event, value: number | number[]) => {
      setFuseThreshold(Array.isArray(value) ? value?.[0] || 0 : value);
    },
    [setFuseThreshold],
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params: any, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel],
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel],
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel],
  );

  const processRowUpdate = useCallback(
    async (newRow: ITalentEditable, originalRow: ITalentEditable) => {
      setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
      if (newRow.merge) {
        const serverRow = await onUpdate(cleanEditableLabelBeforeUpdate(newRow), newRow.merge);
        logger.debug('row server side updated', serverRow);
        // remove saved row
        setRows(rows.filter((row) => row.id !== newRow.id));
      }
      return newRow;
    },
    [rows, onUpdate],
  );

  const selectedRowUpdate = useCallback(() => {
    setRows(
      rows.map((row) => {
        if (rowsSelected.some((rS) => rS.id === row.id)) {
          return { ...row, merge: rowsSelectedJobTitle };
        }
        return row;
      }),
    );
    // turn row on edit mode
    const editableRows: GridRowModesModel = {};
    rowsSelected.forEach((rS) => {
      if (rS.id && rows.find((r) => r.id === rS.id)) {
        editableRows[rS.id as string] = { mode: GridRowModes.Edit };
      }
    });
    setRowModesModel({ ...rowModesModel, ...editableRows });
  }, [rows, rowsSelected, rowModesModel, rowsSelectedJobTitle]);

  const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCellEditable = useCallback((params: GridCellParams<ITalentEditable>) => {
    return true;
  }, []);

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'),
    [],
  );

  function CustomEditAutoComplete(props: GridRenderEditCellParams<ITalentEditable>) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef();

    const setTag = useCallback(
      (tags: string[]) => {
        const jobTitle = tags?.[0] ?? undefined;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        apiRef.current.setEditCellValue({
          id,
          field,
          value: jobTitle,
          debounceMs: 200,
        });
      },
      [apiRef, field, id],
    );

    React.useLayoutEffect(() => {
      if (hasFocus) {
        (ref.current as any)?.focus();
      }
    }, [hasFocus]);
    return (
      <FreeSoloAutoComplete
        tags={value ? [value] : []}
        loading={false}
        multiple={false}
        freeSolo={false}
        setTag={setTag}
        collectTags={resolveRows}
        label={''}
        placeholder={''}
        initTags={isoRows}
      />
    );
  }

  const isoJobTitle: GridColTypeDef<ITalentEditable> = {
    type: 'string',
    width: 130,
    renderEditCell: (params: GridRenderCellParams<ITalentEditable>) => {
      return <CustomEditAutoComplete {...params} />;
    },
    valueGetter: (params: GridValueGetterParams<ITalentEditable>) => {
      const talentlabel = params.row.label;
      if (!talentlabel) {
        return undefined;
      }

      if (params.value) {
        return params.value;
      }
      const search = searchFuse(talentlabel);
      return search?.[0]?.item?.label || undefined;
    },
  };

  const setTagFromFreeSolo = useCallback(
    (tags: string[]) => {
      const jobTitle = tags?.[0] ?? undefined;
      setRowsSelectedJobTitle(jobTitle);
    },
    [setRowsSelectedJobTitle],
  );

  const SliderFuse = useCallback(() => {
    const sliderMarks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
    ];
    return (
      <>
        {rowsSelected?.length > 0 && (
          <>
            <FreeSoloAutoComplete
              tags={rowsSelectedJobTitle ? [rowsSelectedJobTitle] : []}
              loading={false}
              multiple={false}
              freeSolo={false}
              setTag={setTagFromFreeSolo}
              collectTags={resolveRows}
              label={''}
              placeholder={''}
              initTags={isoRows}
            />
            <Button disable={rowsSelectedJobTitle === undefined} textButton={'Appliquer'} onClick={selectedRowUpdate} />
          </>
        )}
        <Slider
          value={sliderValue}
          defaultValue={sliderValue}
          label={'Sensibilité matching'}
          min={0.0}
          max={1}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderCommited}
          step={0.01}
          marks={sliderMarks}
          sx={{ width: 150, padding: '4px 20px 0px 10px' }}
        />
      </>
    );
  }, [
    rowsSelected?.length,
    rowsSelectedJobTitle,
    setTagFromFreeSolo,
    resolveRows,
    isoRows,
    selectedRowUpdate,
    sliderValue,
    handleSliderChange,
    handleSliderCommited,
  ]);

  const columns: GridColDef[] = [
    {
      field: 'fullname',
      headerName: 'Talent',
      sortable: true,
      editable: false,
      minWidth: 100,
    },
    {
      field: 'label',
      headerName: 'Label',
      sortable: true,
      editable: false,
      minWidth: 300,
    },
    {
      field: 'merge',
      headerName: 'Label correspondant avec...',
      editable: true,
      sortable: true,
      filterable: false,
      minWidth: 400,
      ...isoJobTitle,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      filterable: false,
      width: 350,
      headerName: 'Actions',
      getActions: (params: GridRowParams<ITalentEditable>) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<StyledHoverLogo image={redirect} />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => {
                const urlFullTalentSheet = `${import.meta.env.VITE_PUBLIC_APP_ENDPOINT}talentsV2/${params.row.id}`;
                window.open(urlFullTalentSheet, '_blank');
              }}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<StyledHoverLogo image={redirect} />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={() => {
              const urlFullTalentSheet = `${import.meta.env.VITE_PUBLIC_APP_ENDPOINT}talentsV2/${params.row.id}`;
              window.open(urlFullTalentSheet, '_blank');
            }}
          />,
        ];
      },
    },
  ];
  return (
    <div>
      {labels && labels.length > 0 && (
        <DataGrid
          uniqueDatagridId="direct-job-title-managment"
          apiRef={apiRef}
          isCellEditable={isCellEditable}
          pagination={true}
          getRowClassName={getRowClassName}
          childrenToolBar={SliderFuse()}
          hideFooterPagination={false}
          rows={rows}
          getRowId={(row: ITalentEditable) => {
            return row.id as string;
          }}
          columns={columns}
          pageSize={25}
          sx={{
            width: '100%',
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={onError}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          checkboxSelection
        />
      )}
    </div>
  );
};
export { DirectJobTitleManagment };
export default DirectJobTitleManagment;

const StyledHoverLogo = styled(Logo)(
  () => `
  &:hover {
    cursor: pointer;
  }
`,
);
