import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { forwardRef, useEffect } from 'react';

export type NotificationBadgeProps = {
  notificationsUnread: number;
  toggleNotificationCenter: (event: React.MouseEvent<HTMLElement>) => void;
};

const NotificationBadge = forwardRef(
  ({ notificationsUnread, toggleNotificationCenter }: NotificationBadgeProps, ref: any) => {
    useEffect(() => {
      ref?.current?.focus();
    }, [ref]);

    return (
      <IconButton size="large" onClick={toggleNotificationCenter} ref={ref}>
        <Badge badgeContent={notificationsUnread} color="primary">
          <MailIcon color="action" />
        </Badge>
      </IconButton>
    );
  },
);

export default NotificationBadge;
export { NotificationBadge };
