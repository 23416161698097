import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notify, cancel } from '../../helpers/toastify';
import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_PREFERENCES, ME_LIGHT } from '@bloomays-lib/adapter.api-bloomer';
import { useTranslation } from 'react-i18next';
import { Button, LoaderSkeleton, TypeInput, ValidationTextField, ActionButton } from '@bloomays-lib/ui.shared';
import Title from '../atoms/Title';
import { TitlePart as RawTitlePart } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { BrowserBloomer } from '@bloomays-lib/types.shared';
import { errorLogger } from '../../helpers/error';

const BloomerSettings = () => {
  const { t } = useTranslation(['notify']);
  const [uploading, setUploading] = useState(false);
  const { loading, data, error } = useQuery(ME_LIGHT);
  const navigate = useNavigate();
  const bloomer = data?.me?.bloomer;

  const [settings, setSettings] = useState(bloomer);
  const [updatePreferences] = useMutation(UPDATE_PREFERENCES, {
    refetchQueries: [
      {
        query: ME_LIGHT,
      },
    ],
  });

  const communicationTypeOpts = [
    { label: 'Pas de notification', value: 'doNotDisturbed' },
    { label: 'SMS', value: 'sms' },
    { label: 'Email', value: 'email' },
    { label: "Whats'app", value: 'whatsapp' },
  ];

  const upsertData = async (bloomer: BrowserBloomer) => {
    try {
      notify('info', t('preferencesLoading'), null, {
        hideProgressBar: false,
        autoClose: 10000,
      });
      const upload = await updatePreferences({
        variables: {
          preferences: {
            communicationType: bloomer?.communicationType,
          },
        },
      });
      cancel();
      notify('success', t('preferencesSuccesChange'), null, {
        hideProgressBar: true,
        autoClose: 2000,
      });
      return upload.data.updatePreferences;
    } catch (error: any) {
      cancel();
      notify('error', t('preferencesErrorChanged'), error);
      errorLogger(error, {
        extraInfos: 'extraErrorInfos',
      });
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!settings || settings?.communicationType === bloomer?.communicationType) {
      return notify('info', t('preferencesHasNoChange'));
    }
    setUploading(true);
    const preferences = await upsertData(settings);
    if (preferences) {
      setUploading(false);
      return true;
    }
  };

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve data about current bloomer !',
    });
    notify('error', 'Impossible de récupérer tes infos. Erreur du server, essaie plus tard ! 😓', error);
  }

  return (
    <BloomerDashboardContainer>
      <Title textTitle="Réglages ⚙️" />
      {loading ? (
        <LoaderSkeleton height={200} width={500} />
      ) : (
        <div>
          <FormContainer>
            <ActionButton
              actionType="Edit"
              title={'Éditer mes infos persos'}
              onClick={() => navigate('/me/informations')}
            />
          </FormContainer>
          <TitlePart textTitle={'Mes préférences'} />
          <StyledForm>
            <ValidationTextField
              name="favoriteCommunication"
              label="Mode de communication favori"
              type={TypeInput.select}
              defaultOption={false}
              value={settings?.communicationType || bloomer?.communicationType}
              required={true}
              options={communicationTypeOpts}
              onChange={(e) => {
                e.preventDefault();
                setSettings({
                  ...bloomer,
                  communicationType: e.target.value,
                });
              }}
            />
            <Button
              onClick={(e) => onSubmit(e)}
              none={'none'}
              textButton={uploading ? "En cours d'envoi" : 'Sauvegarder'}
              disable={uploading}
            />
          </StyledForm>
        </div>
      )}
    </BloomerDashboardContainer>
  );
};

export { BloomerSettings };
export default BloomerSettings;

const BloomerDashboardContainer = styled('div')(({ theme }) => ({
  width: '50%',
  margin: '2em auto',
  [theme.breakpoints.down('md')]: {
    width: '95%',
    margin: '2em auto',
  },
}));

const FormContainer = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start',
  margin: '0.5em auto',
}));

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  borderRadius: '10px',
  margin: '1.5em auto',
  padding: '1em',
  boxShadow: '2px 2px 20px lightgrey',
}));

const TitlePart = styled(RawTitlePart)(({ theme }) => ({
  textAlign: 'left',
  marginTop: '1em',
}));
