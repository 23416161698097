import { SkillTalentLevel } from '@bloomays-lib/types.shared';
import { ItemTalentLevelProps } from '@bloomays-lib/ui.shared';

export const getSkillsSorted = (skills: ItemTalentLevelProps[]): ItemTalentLevelProps[] => {
  return [...skills].sort((a, b) => {
    if (!a?.level) return 1;
    if (!b?.level) return -1;

    return b?.level - a?.level;
  });
};

export const _getSkillsSorted = (skills: SkillTalentLevel[]): ItemTalentLevelProps[] => {
  const sortedSkills = [...skills].sort((a, b) => {
    if (!a?.level) return 1;
    if (!b?.level) return -1;

    return b?.level - a?.level;
  });

  const t: ItemTalentLevelProps[] = sortedSkills.map((sk) => {
    const item: ItemTalentLevelProps = {
      level: sk.level,
      skill: {
        id: sk.skill.id,
        label: sk.skill.label,
      },
    };
    return item;
  });
  return t;
};
