import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { Button } from '@bloomays-lib/ui.shared';
import { SingleLineText, Switch } from '@bloomays-lib/ui.shared';
import { useEffect, useState } from 'react';
import { JobTalent } from '@bloomays-lib/types.shared';
import DatePicker from '../atoms/DatePicker';
import { capitalize } from '@bloomays-lib/utils.shared';

export type EducationEditionProps = {
  item?: JobTalent;
  upsert: (e: any) => any;
  deleteEduc: (id?: string) => Promise<void>;
  loading: boolean;
};

const EducationEdition = ({ item, loading, upsert, deleteEduc }: EducationEditionProps) => {
  const { t } = useTranslation(['talentSheet', 'languages', 'random']);
  const [TMPItem, setTMPItem] = useState(item);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setTMPItem(item);
  }, [item]);

  return (
    <StyledEducationEdition>
      <StyledContainerTitle>
        <TitlePart variant={'heading5'} textTitle={t('addEducation')} />
      </StyledContainerTitle>
      <ValidationTextField
        label={t('nameSchool')}
        onChange={(e: any) => {
          setTMPItem({ ...TMPItem, name: e.target.value });
          setHasChanged(true);
        }}
        value={TMPItem?.name}
        required
        type={TypeInput.text}
        placeholder=""
      />
      <ValidationTextField
        label={t('nameEducation')}
        onChange={(e: any) => {
          setTMPItem({ ...TMPItem, label: e.target.value });
          setHasChanged(true);
        }}
        value={TMPItem?.label}
        type={TypeInput.text}
        placeholder=""
      />
      <StyledCurrentJob>
        <Switch
          checked={TMPItem?.current}
          onSwitch={(e) => {
            setTMPItem({ ...TMPItem, current: e.target.checked });
            setHasChanged(true);
          }}
        />
        <SingleLineText text={t('isCurrentEducation')} />
      </StyledCurrentJob>
      <StyledContainerDate>
        <StyledContainerOneDate>
          <SingleLineText variant={'body1Regular'} text={t('beginDate', { ns: 'random' })} />
          <DatePicker
            handleChange={(e: Date | null) => {
              setHasChanged(true);
              setTMPItem({
                ...TMPItem,
                period: {
                  ...TMPItem?.period,
                  startDate: e,
                },
              });
            }}
            label={`${capitalize(t('month', { ns: 'random' }))} & ${capitalize(t('year', { ns: 'random' }))}`}
            views={['month', 'year']}
            openTo={'month'}
            value={TMPItem?.period?.startDate ? new Date(TMPItem?.period?.startDate) : null}
          />
        </StyledContainerOneDate>
        <StyledContainerOneDate>
          <SingleLineText variant={'body1Regular'} text={t('endDate', { ns: 'random' })} />
          <DatePicker
            handleChange={(e: Date | null) => {
              setHasChanged(true);
              setTMPItem({
                ...TMPItem,
                period: {
                  ...TMPItem?.period,
                  endDate: e,
                },
              });
            }}
            views={['month', 'year']}
            disabled={TMPItem?.current}
            openTo={'month'}
            label={`${capitalize(t('month', { ns: 'random' }))} & ${capitalize(t('year', { ns: 'random' }))}`}
            value={TMPItem?.period?.endDate ? new Date(TMPItem?.period?.endDate) : null}
          />
        </StyledContainerOneDate>
      </StyledContainerDate>
      <ValidationTextField
        label={t('description')}
        onChange={(e: any) => {
          setTMPItem({ ...TMPItem, description: e.target.value });
          setHasChanged(true);
        }}
        value={TMPItem?.description}
        multiline
        minRows={4}
        type={TypeInput.text}
        placeholder=""
      />
      <StyledSaveDiv>
        {TMPItem?.id && (
          <StyledDeleteButton
            textButton={t('delete', { ns: 'random' })}
            onClick={async () => {
              await deleteEduc(TMPItem?.id);
            }}
            disable={loading}
          />
        )}
        <Button
          textButton={t('save', { ns: 'random' })}
          onClick={async () => {
            setHasChanged(false);
            await upsert(TMPItem);
          }}
          disable={loading || !hasChanged}
        />
      </StyledSaveDiv>
    </StyledEducationEdition>
  );
};

export default EducationEdition;
export { EducationEdition };

const StyledEducationEdition = styled('div')(
  ({ theme }) => `
    background-color: ${theme.palette.grey[200]};
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 4px 14px;
    min-width: 720px;
  `,
);

const StyledContainerTitle = styled('div')(
  () => `
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 30px;
    `,
);

const StyledSaveDiv = styled('div')(
  () => `
    display: flex;
    justify-content: space-between;
    `,
);

const StyledCurrentJob = styled('div')(
  () => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    `,
);

const StyledContainerDate = styled('div')(
  () => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 8px;
    padding: 20px 0;
    align-items: center;
    `,
);

const StyledContainerOneDate = styled('div')(
  () => `
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 10px;
    `,
);

const StyledDeleteButton = styled(Button)(
  ({ theme }) => `
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  background-color: ${theme.palette.grey[500]};
  color: ${theme.palette.paper.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  &:hover {
    cursor: 'pointer';
    color: ${theme.palette.grey[500]};
    background-color: ${theme.palette.paper.white};
    transition: 'ease 0.3s';
  }
  `,
);
