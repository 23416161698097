import React from 'react';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { Logo } from '@bloomays-lib/ui.shared';

export type DetailInfoProps = {
  emoji?: string;
  text: string;
  span?: string;
};

const StyledEmoji = styled(Logo)(
  () => `
  display: flex;
  padding: 0 2px 0 10px;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
  padding: 2px 2px 0 2px;
`,
);
const StyledSpan = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
  padding-top: 2px;
`,
);

const StyledContainer = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
  display: flex;
  align-items: center;
`,
);

const DetailInfo = ({ emoji, text, span }: DetailInfoProps) => {
  return (
    <StyledContainer>
      <StyledEmoji image={emoji} />
      <StyledText variant={'body2Medium'} text={text} />
      {span && <StyledSpan variant={'body2Medium'} text={span} />}
    </StyledContainer>
  );
};

export default DetailInfo;
export { DetailInfo };
