import React, { useEffect, useMemo, useState } from 'react';

import { LoaderSkeleton, TitlePart, TypeInput } from '@bloomays-lib/ui.shared';
import { Button, SingleLineText, ValidationTextField } from '@bloomays-lib/ui.shared';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { LabelAndInput } from '../molecules/LabelAndInput';
import BasicTable from '../molecules/BasicTable';
import { styled } from '@mui/material/styles';
import { EachDay } from '@bloomays-lib/types.shared';
import orderBy from 'lodash/orderBy';
import Checkbox from '../atoms/Checkbox';
import SummaryDaysWorked from './SummaryDaysWorked';
import Modal from '../atoms/Modal';
import debounce from 'lodash/debounce';
import { calculPerDay } from '../../helpers/CRA';
import { SelectField } from '../atoms/SelectField';
import Box from '@mui/material/Box';
export type AddCRAProps = {
  eachDay: EachDay[];
  valueOfAll: number;
  setValueOfAll: React.Dispatch<React.SetStateAction<number>>;
  isSelectAll: boolean;
  setIsSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  setShowApplyValue: React.Dispatch<React.SetStateAction<boolean>>;
  showApplyValue: boolean;
  submitCRA: (e: EachDay[]) => Promise<void>;
  month: string;
};

const AddCRA = ({
  eachDay,
  valueOfAll,
  setValueOfAll,
  isSelectAll,
  setIsSelectAll,
  showApplyValue,
  setShowApplyValue,
  month,
  submitCRA,
}: AddCRAProps) => {
  const hourlegacy = [
    { value: '0', label: '0' },
    { value: '0.1', label: '0.1' },
    { value: '0.2', label: '0.2' },
    { value: '0.3', label: '0.3' },
    { value: '0.4', label: '0.4' },
    { value: '0.5', label: '0.5' },
    { value: '0.6', label: '0.6' },
    { value: '0.7', label: '0.7' },
    { value: '0.8', label: '0.8' },
    { value: '0.9', label: '0.9' },
    { value: '1', label: '1' },
  ];

  const hour = [
    { value: '0', label: '0 heure' },
    { value: '0.125', label: '1 heure' },
    { value: '0.25', label: '2 heures' },
    { value: '0.375', label: '3 heures' },
    { value: '0.5', label: '4 heures' },
    { value: '0.625', label: '5 heures' },
    { value: '0.75', label: '6 heures' },
    { value: '0.875', label: '7 heures' },
    { value: '1', label: '1 journée' },
  ];

  const getShowVlaueFromEachDays = (days: EachDay[]) => {
    const initValueShowAstreinte: { [key: number]: boolean } = {};
    days.forEach((td) => {
      if (td.astreinte && td.astreinte > 0) {
        initValueShowAstreinte[td.id] = true;
      }
    });
    return initValueShowAstreinte;
  };

  const isHourLegacyMode = useMemo(() => {
    return eachDay.some((ed) => {
      return ed.dayWorked && ['0.1', '0.2', '0.3', '0.4', '0.6', '0.7', '0.8', '0.9'].includes(ed.dayWorked.toString());
    });
  }, [eachDay]);

  const hourHNO = useMemo(() => {
    const tmpHour = [
      { value: '0', label: '0 heure' },
      { value: '0.0625', label: '30 minutes' },
    ];

    for (let i = 1; i <= 10; i = i + 0.5) {
      tmpHour.push({ value: (i * 0.125).toString(), label: `${i} heures ` });
    }
    return tmpHour;
  }, []);

  const [tableData, setTableData] = React.useState(eachDay);
  const [open, setOpen] = useState(false);
  const [showAstreinteSelect, setShowAstreinteSelect] = useState<{ [key: number]: boolean }>({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleComment = (id: number, comment: string) => {
    const updatedTableData = tableData.map((item) => {
      if (item.id === id) {
        return { ...item, comment: comment };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const handleShowAstreinte = (id: number, show: boolean, astreinte?: number) => {
    handleAstreinte(id, show ? astreinte || 0 : 0);
    setShowAstreinteSelect((prev) => {
      return { ...prev, [id]: show };
    });
  };

  const handleAstreinte = (id: number, astreinte: number) => {
    const updatedTableData = tableData.map((item) => {
      if (item.id === id) {
        return { ...item, astreinte: astreinte };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const handleWorkedDay = (id: number, checked: boolean) => {
    const updatedTableData = tableData.map((item) => {
      if (item.id === id) {
        return { ...item, checked: checked };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const selectAll = (isSelect: boolean) => {
    const updatedTableData = tableData.map((item) => {
      return { ...item, checked: isSelect };
    });
    setTableData(updatedTableData);
  };

  const applyValue = (value: number) => {
    const updatedTableData = tableData.map((item) => {
      if (item.checked) {
        return { ...item, dayWorked: value };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const handleHalfDay = (id: number, nb: number) => {
    const updatedTableData = tableData.map((item) => {
      if (item.id === id) {
        return { ...item, dayWorked: nb };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const SelectAll = () => {
    return (
      <CheckDayLabelAndInput
        name={'selectAll'}
        textLabel={'Tout sélectionner'}
        type={'checkbox'}
        checked={isSelectAll}
        handleChange={(e) => {
          setShowApplyValue(true);
          setIsSelectAll(e.target.checked);
          selectAll(e.target.checked);
        }}
        id={'selectAll'}
        readOnly={false}
      />
    );
  };

  useEffect(() => {
    const result = getShowVlaueFromEachDays(eachDay);
    setShowAstreinteSelect(result);
  }, [eachDay]);

  useEffect(() => {
    if (eachDay) {
      setTableData(eachDay);
    }
  }, [eachDay]);

  const debouncedUpdateCra = debounce(handleComment, 500);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        additionnalCSS={{
          overflowY: 'auto',
          width: '65%',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <SummaryDaysWorked
          month={month}
          daysWorked={calculPerDay(tableData, 'nbDaysWorked')}
          daysMarkup={calculPerDay(tableData, 'nbDaysMarkupWorked')}
          daysMarkup2={calculPerDay(tableData, 'nbDaysMarkup2Worked')}
          daysAstreinte={calculPerDay(tableData, 'astreinte')}
          submitCRA={() => {
            return submitCRA(tableData);
          }}
          handleClose={handleClose}
        />
      </Modal>
      <TitlePart textTitle="Ajouter mes CRA" />
      <div style={{ margin: '1.5em 0' }}>
        <SingleLineText text="Une journée correspond à 8h de travail" />
        <SingleLineText text="Pour aller plus vite, tu peux tout sélectionner (grâce à la box 'Tout sélectionner') ou sélectionner les jours de ton choix et leur attribuer une valeur ! :)" />
        <SingleLineText text="Samedi : Majoration à 150% *, Dimanche, férié et heures sup (HNO) : Majoration à 200% *" />
        <SingleLineText text="* sauf si contractuellement différent" />
      </div>
      {showApplyValue && (
        <ContainerApplyValue>
          <SelectField
            name={'selectAll'}
            value={valueOfAll}
            defaultOption={true}
            options={isHourLegacyMode ? hourlegacy : hour}
            handlechange={(e: any) => {
              setValueOfAll(Number(e.target.value));
            }}
            required={false}
            label={'Valeur à appliquer à la séléction'}
          />
          <Button
            sx={{ marginTop: '6px', marginLeft: '16px' }}
            id="valueOfAllButton"
            textButton="Valider"
            onClick={() => applyValue(valueOfAll === undefined ? 0 : valueOfAll)}
          />
        </ContainerApplyValue>
      )}
      {tableData?.length === 0 ? (
        <LoaderSkeleton height={1000} width={1500} />
      ) : (
        <>
          <StyledTable>
            <BasicTable
              columns={[<SelectAll key="selectAll" />, 'Jour', 'Date', 'Journée', 'Heures supp (HNO)', 'Commentaire']}
            >
              {orderBy(tableData, ['id'], ['asc'])?.map((row, i) => (
                <TableRow key={`eachDay-${i}`}>
                  <TableCellBody id={`dayWorkedCheckBox-${i}`}>
                    <Checkbox
                      id={`dayWorkedCheckBoxInput-${i}`}
                      name={`dayWorkedCheckBox${i}`}
                      checked={row.checked}
                      onChange={(e, check) => {
                        setShowApplyValue(true);
                        handleWorkedDay(row.id, check);
                      }}
                      disabled={false}
                      label={''}
                    />
                  </TableCellBody>
                  <TableCellBody id={`day-${i}`}>{row.day}</TableCellBody>
                  <TableCellBody id={`holiday-${i}`}>
                    {row.isHoliday ? (
                      <SingleLineText
                        sx={(theme) => ({
                          color: theme.palette.tertiary.red,
                          fontWeight: 'bold',
                        })}
                        text={`${row.date} Férié !!`}
                      />
                    ) : (
                      <SingleLineText text={row.date} />
                    )}
                  </TableCellBody>
                  <TableCell id={`dayWorked${i}`}>
                    <SelectField
                      name={`dayWorkedField${i}`}
                      id={`dayWorkedField${i}`}
                      value={row.dayWorked}
                      options={isHourLegacyMode ? hourlegacy : hour}
                      handlechange={(e: any) => {
                        handleHalfDay(row.id, Number(e.target.value));
                      }}
                      label={''}
                      required={false}
                    />
                  </TableCell>
                  <TableCellBody id={`dayAstreinteCheckBox-${i}`}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                        flexFlow: 'row nowrap',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        name={`dayAstreinteCheckBoxInput${i}`}
                        checked={showAstreinteSelect[row.id] === true}
                        onChange={(e, check) => {
                          handleShowAstreinte(row.id, check, row.astreinte);
                        }}
                        id={`dayAstreinteCheckBox-${i}`}
                        disabled={false}
                        label={''}
                      />
                      {showAstreinteSelect[row.id] === true && (
                        <SelectField
                          name={`dayAstreinteSelectInput${i}`}
                          id={`dayAstreinteSelectInput${i}`}
                          value={row.astreinte || 0}
                          options={hourHNO}
                          handlechange={(e: any) => {
                            handleAstreinte(row.id, Number(e.target.value));
                          }}
                          label={''}
                          required={false}
                        />
                      )}
                    </Box>
                  </TableCellBody>
                  <TableCell id={`comment-${i}`}>
                    <ValidationTextField
                      type={TypeInput.text}
                      id={`commentField-${i}`}
                      value={row?.comment}
                      maxLength={'50'}
                      onChange={(e) => {
                        debouncedUpdateCra(row.id, e.target.value);
                      }}
                      label={'Commentaire'}
                      placeholder={'Commentaire ...'}
                    />
                    <SingleLineText
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '-1em',
                        paddingRight: ' 0.5em',
                      }}
                      text={`${row?.comment?.length || 0}/50`}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </BasicTable>
          </StyledTable>
          <Button sx={{ marginTop: '1em' }} textButton={'Valider !'} onClick={() => handleOpen()} />
        </>
      )}
    </div>
  );
};

export default AddCRA;
export { AddCRA };

const TableCellBody = styled(TableCell)(() => ({
  textAlign: 'center' as CanvasTextAlign,
}));

const ContainerApplyValue = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  width: '700px',
  margin: '1em auto',
  alignItems: 'flex-start',
  alignContent: 'center',
}));

const CheckDayLabelAndInput = styled(LabelAndInput)(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
}));

const StyledTable = styled('div')(({ theme }) => ({
  margin: 'auto',
}));
