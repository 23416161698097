import { useLocation } from 'react-router-dom';

import { FileViewer } from '@bloomays-lib/ui.shared';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { useAuth } from '../../auth/AuthProvider';
import { useEffect, useState } from 'react';

const File = (): JSX.Element => {
  const location = useLocation();
  const [token, setToken] = useState<string | undefined>();
  const auth = useAuth();

  const [maxHeight, setMaxHeight] = useState<string | undefined>();
  useEffect(() => {
    setMaxHeight((window.outerHeight - 10).toString());
  }, []);

  useEffect(() => {
    if (auth?.user?.token) {
      setToken(auth.user?.token);
    }
  }, [auth]);

  return <FileViewer url={location.pathname} accessToken={token} width="100%" height={maxHeight} />;
};

export { File };
export default WithErrorBoundary(File);
