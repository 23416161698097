import { TitlePart as RawTitlePart } from '../atoms/TitlePart';
import { Logo as RawLogo } from '../atoms/Logo';

import { SingleLineText } from '../atoms/SingleLineText';
import { HrefLink as RawHrefLink } from '../atoms/HrefLink';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../../assets/logoWordmark.svg';
import { styled } from '@mui/material/styles';
import { Env } from '@bloomays-lib/types.shared';

interface FotterProps {
  release: string;
  env: Env;
}

const Footer = (props: FotterProps): JSX.Element => {
  return (
    <ContainerFooter>
      <ContainerLink>
        <ContainerSection>
          <Logo image={logo} />
          <HrehSLT text="Le growth au service de votre recrutement." />
          <HrefLink icon={<LinkedInIcon />} href="https://www.linkedin.com/company/bloomays/mycompany/" text={''} />
        </ContainerSection>
        <ContainerSection>
          <TitlePart variant="heading5" textTitle={'Dites-nous bonjour'} />
          <HrefLink text="hello@bloomays.com" href="mailto:hello@bloomays.com" />
          <HrehSLT text="4 rue du caire, 75002 Paris, France" />
        </ContainerSection>
        <ContainerSection>
          <TitlePart variant="heading5" textTitle={'À propos'} />
          <HrefLink text="BloomHelp" href="https://bit.ly/bloomays-help" />
          <HrefLink text="Mentions légales" href="https://www.bloomays.com/mentions-legales" />
        </ContainerSection>
      </ContainerLink>
      <ContainerVersion>
        <SingleLineText
          sx={{
            fontSize: '12px',
          }}
          text={`${new Date().getFullYear()} Bloomays v${props.release} ${props.env}`}
        />
      </ContainerVersion>
    </ContainerFooter>
  );
};

export default Footer;
export { Footer };

const HrefLink = styled(RawHrefLink)(({ theme }) => ({
  [theme.breakpoints.down(915)]: {
    justifyContent: 'center',
  },
}));

const HrehSLT = styled(SingleLineText)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: '.4em',
  textDecoration: 'none',
  color: theme.palette.primary.main,
}));

const ContainerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '6em 0 1em 0',
  flexDirection: 'column',
  [theme.breakpoints.down(915)]: {
    padding: '3em 0',
  },
  [theme.breakpoints.down(500)]: {
    padding: '0',
  },
}));

const ContainerLink = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
  margin: '1em',
  gap: 20,
  [theme.breakpoints.down(915)]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1em auto',
  },
  [theme.breakpoints.down(500)]: {
    display: 'none',
  },
}));

const ContainerSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'left',
  alignContent: 'center',
  [theme.breakpoints.down(915)]: {
    justifyContent: 'center',
  },
}));

const TitlePart = styled(RawTitlePart)(({ theme }) => ({
  textAlign: 'left',
  paddingTop: 0,
  [theme.breakpoints.down(915)]: {
    textAlign: 'center',
  },
}));

const Logo = styled(RawLogo)(({ theme }) => ({
  width: 180,
  paddingBottom: '0.7em',
  [theme.breakpoints.down(915)]: {
    margin: 'auto',
  },
}));

const ContainerVersion = styled('div')(({ theme }) => ({
  margin: '0.5em 0',
  textAlign: 'center',
}));
