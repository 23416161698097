import { useTranslation } from 'react-i18next';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import TableLegalContracts from '../organisms/TableLegalContracts';
import { useQuery } from '@apollo/client';
import { ContractsByMissionGQL, GetLegalContractsByMissionGQLResult } from '@bloomays-lib/types.shared';
import { GET_LEGAL_CONTRACTS_BY_MISSION } from '@bloomays-lib/adapter.api-bloomer';
import { LoaderPending } from '@bloomays-lib/ui.shared';

const MissionContracts = () => {
  const { t } = useTranslation(['legalContracts']);
  const { id } = useParams<{ id: string }>();
  const missionId = id as string;

  const {
    data: fetchContractsResult,
    loading: fetchingContracts,
    error: errorFetchingContracts,
  } = useQuery<GetLegalContractsByMissionGQLResult>(GET_LEGAL_CONTRACTS_BY_MISSION, {
    variables: { missionId },
  });

  if (fetchingContracts) return <LoaderPending text={t('fetchingMissionContracts')} />;
  if (errorFetchingContracts) return <>{t('errorFetchingContracts')}</>;
  if (!fetchContractsResult) return <>{t('missionNotFound')}</>;

  const contractsByMission: ContractsByMissionGQL = fetchContractsResult.getLegalContractsByMission;
  const mission = contractsByMission.mission;

  const missionText = t('pageSubTitle', {
    label: mission.label as string,
    societyName: mission.clientSocietyName,
  });

  return (
    <Container>
      <TitlePart id="titleAdminDashboard" span={t('pageTitle')} />
      <SingleLineText variant={'body1Medium'} text={missionText} />
      <TableContainer>
        <TableLegalContracts missionsContracts={[contractsByMission]} />
      </TableContainer>
    </Container>
  );
};

export default MissionContracts;

const Container = styled('div')(() => ({
  margin: '0 80px',
}));

const TableContainer = styled('div')(() => ({
  marginTop: '50px',
}));
