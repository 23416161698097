/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ActionCRA from './ActionCRA';
import { Button } from '@bloomays-lib/ui.shared';
import Modal from '../atoms/Modal';

import RawUploadFileIcon from '@mui/icons-material/UploadFile';
import RawInsertDriveFileIcon from '@mui/icons-material/AssignmentInd';
import RawVisibilityIcon from '@mui/icons-material/Visibility';
import Delete from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { IActivity, IMission } from '@bloomays-lib/types.shared';
import NPSMission from '../organisms/NPSMission';
import { FetchResult } from '@apollo/client';
import { UploadFileActivity } from '../../types/components';
import { Logger } from '../../services/serviceLogger';
const logger = Logger('ActionBundle');

export interface ActionBundleActivity extends IActivity {
  date: Date;
}

export type ActionBundleProps = {
  CRAEditing: boolean;
  CRACanSendInvite: boolean;
  CRAUploaded: boolean;
  CRASignSent: boolean;
  canUploadInvoices: boolean;
  canSeeInvoices: boolean;
  canDeleteInvoices: boolean;
  editFunc: () => void;
  sendFunc: () => void;
  cancelFunc: () => void;
  responseSign: {
    status: string;
    idRow: string | number | null;
  };
  i: number;
  modalUploadCRA: {
    show: boolean;
    id?: string | null | undefined;
  };
  responseUploadCRA: { status: string };
  uploadCRA: UploadFileActivity;
  deleteInvoice: (activityRecordID: string) => Promise<void>;
  setModalUploadBill: () => void;
  CRAValidated?: boolean | null;
  activity: ActionBundleActivity;
  CRAUrlEmbed: string | null;
  refetchActivities: () => void;
  mission: IMission;
  setResponseSign: React.Dispatch<
    React.SetStateAction<{
      status: string;
      idRow: string | number | null;
    }>
  >;
  upsertFollowUp: (args: {
    selected?: number;
    comment?: string;
    collectDate: Date;
    activityId: string;
  }) => Promise<FetchResult<any> | undefined>;
  stateNPS: { loading: boolean };
  getNPS: any;
};

export const ActionBundle = ({
  stateNPS,
  CRAEditing = true,
  CRACanSendInvite = false,
  CRAUploaded = false,
  CRASignSent = false,
  canUploadInvoices,
  canSeeInvoices,
  editFunc,
  sendFunc,
  cancelFunc,
  responseSign = { status: '', idRow: null },
  i,
  modalUploadCRA = { show: false, id: null },
  responseUploadCRA,
  uploadCRA,
  setModalUploadBill,
  CRAValidated,
  activity,
  CRAUrlEmbed,
  refetchActivities,
  setResponseSign,
  mission,
  upsertFollowUp,
  getNPS,
  deleteInvoice,
  canDeleteInvoices,
}: ActionBundleProps): JSX.Element => {
  const [selected, setSelected] = useState<number | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [openNPS, setOpenNPS] = useState<{
    id: null | number | string;
    show: boolean;
  }>({ id: null, show: false });
  const [loading, setLoading] = useState<{
    id: null | number | string;
    show: boolean;
  }>({ id: null, show: false });
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [openAction, setOpenAction] = useState<{
    id?: null | string;
    show: boolean;
  }>({ id: null, show: false });
  const { t } = useTranslation('bloomer');

  const handleClose = () => {
    setOpenNPS({ ...openNPS, show: false });
  };
  const handleCloseAction = () => {
    setOpenAction({ id: null, show: false });
  };

  React.useEffect(() => {
    if (modalUploadCRA.show === false) {
      handleClose();
    }
  }, [modalUploadCRA]);

  const labelCRAButton = CRAEditing ? `${t('validateCRA')}` : `${t('cancelOrEditCRA')}`;

  const getPlaceholder = () => {
    if (selected && selected <= 6) {
      return t('negativePHBloomer', { ns: 'NPSMission' });
    } else {
      return t('placeholder', { ns: 'NPSMission' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        flexFlow: 'row nowrap',
        alignItems: 'center',
      }}
    >
      {!CRAValidated && (
        <div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ActionButton
              id="validateOrCancelCRA"
              loading={loading.show}
              emoji={<SignCRAIcon />}
              disable={openNPS.show || openAction.show}
              onClick={async () => {
                setTimeout(() => {
                  setLoading({ id: i, show: true });
                });
                const NPS = await getNPS({
                  variables: {
                    month: activity.date,
                    type: 'bloomer',
                    activityId: activity.recordId,
                  },
                });
                setLoading({ id: i, show: false });
                if (NPS?.data?.getMissionFollowUp.score !== null) {
                  setOpenAction({ id: activity.recordId, show: true });
                } else {
                  setOpenAction({ ...openAction, id: activity.recordId });
                  setOpenNPS({ id: i, show: true });
                }
              }}
              textButton={labelCRAButton}
            />
            {CRAEditing && <LinkButton onClick={editFunc} textButton={t('editCRA')} />}
          </Box>
        </div>
      )}
      <Modal
        open={openNPS.show}
        onClose={handleClose}
        additionnalCSS={{
          width: '822px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <NPSMission
          stateNPS={stateNPS}
          onClick={async () => {
            await upsertFollowUp({
              comment,
              selected,
              collectDate: activity.date,
              activityId: activity.recordId!,
            });
            handleClose();
            setOpenAction({ id: activity.recordId, show: true });
          }}
          setSelected={(e: number) => setSelected(e)}
          setComment={(e: any) => setComment(e.target.value)}
          selected={selected}
          comment={comment}
          texts={{
            title: mission.enableCRAUpload
              ? t('uploadTitle', { ns: 'NPSMission' })
              : t('signTitle', { ns: 'NPSMission' }),
            placeholder: getPlaceholder(),
            button: mission.enableCRAUpload
              ? t('buttonUpload', { ns: 'NPSMission' })
              : t('buttonSign', { ns: 'NPSMission' }),
            details: t('details', { ns: 'NPSMission' }),
            nameLink: 'BloomHelp',
            url: 'https://bit.ly/bloomays-help',
            btnTitle: t('thanksToNote', { ns: 'NPSMission' }),
          }}
        />
      </Modal>
      <Modal
        open={openAction.show}
        onClose={handleCloseAction}
        additionnalCSS={{
          width: '700px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <ActionCRA
          handleCloseAction={handleCloseAction}
          setResponseSign={setResponseSign}
          refetchActivities={refetchActivities}
          CRAUrlEmbed={CRAUrlEmbed}
          responseSign={responseSign}
          CRASignSent={CRASignSent}
          cancelFunc={cancelFunc}
          CRACanSendInvite={CRACanSendInvite}
          sendFunc={sendFunc}
          CRAUploaded={CRAUploaded}
          modalUploadCRA={modalUploadCRA}
          responseUploadCRA={responseUploadCRA}
          uploadCRA={uploadCRA}
          activity={activity}
          mission={mission}
        />
      </Modal>
      {!CRAEditing && !CRASignSent && (
        <Box>
          <ContainerInvoices>
            {canUploadInvoices ? (
              <ActionButton
                emoji={<UploadFileIcon />}
                onClick={setModalUploadBill}
                textButton={t('uploadInvoice')}
              />
            ) : (
              <>
                {canSeeInvoices && activity?.urlInvoicesS3 && (
                  <>
                    <ActionButton
                      emoji={<RawVisibilityIcon />}
                      title={t('seeInvoice')}
                      textButton={t('seeInvoice')}
                      onClick={() => window.open(activity?.urlInvoicesS3, '_blank')}
                    />
                    {canDeleteInvoices && activity?.urlInvoicesS3 && !activity.payed && (
                      <LinkButton
                        emoji={<Delete />}
                        loading={loadingDelete}
                        title={t('deleteInvoice')}
                        textButton={t('deleteInvoice')}
                        onClick={async () => {
                          setTimeout(() => {
                            setLoadingDelete(true);
                          });
                          await deleteInvoice(activity.recordId).catch((err) => {
                            logger.error(err);
                          });
                          setLoadingDelete(false);
                        }}
                      />
                    )}
                  </>
                )}
                {canSeeInvoices && activity?.urlAdditionalInvoicesS3 && (
                  <ActionButton
                    emoji={<RawVisibilityIcon />}
                    title={t('seeAdditionalInvoice')}
                    textButton={t('seeAdditionalInvoice')}
                    onClick={() => window.open(activity?.urlAdditionalInvoicesS3, '_blank')}
                  />
                )}
              </>
            )}
            {activity?.urlCraS3 && (
              <LinkButton
                title={t('seeCRA')}
                onClick={() => window.open(activity?.urlCraS3, '_blank')}
                textButton={t('seeCRA')}
              />
            )}
          </ContainerInvoices>
        </Box>
      )}
    </Box>
  );
};

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: 0,
  border: 'none',
  backgroundColor: theme.palette.paper.white,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.white,
    color: theme.palette.tertiary.darkPink,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginBottom: '2px',
}));

const SignCRAIcon = styled(RawInsertDriveFileIcon)(({ theme }) => ({
  paddingRight: '5px',
  width: '19px !important',
}));

const UploadFileIcon = styled(RawUploadFileIcon)(({ theme }) => ({
  paddingRight: '5px',
  width: '19px !important',
}));

const ContainerInvoices = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));
