import { styled } from '@mui/material/styles';
import { ITalent } from '@bloomays-lib/types.shared';
import { TextArea, TitlePart } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button } from '@bloomays-lib/ui.shared';
import { notify } from '../../helpers/toastify';
import { MutationFunctionOptions, OperationVariables, DefaultContext, ApolloCache, FetchResult } from '@apollo/client';

export type TalentSheetInResumedProps = {
  talent?: ITalent;
  handleChange: (
    options?:
      | MutationFunctionOptions<
          {
            upsertTalentBasic: ITalent;
          },
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<{ upsertTalentBasic: ITalent }>>;
  loadingUpsert: boolean;
};

const TalentSheetInResumed = ({ talent, handleChange, loadingUpsert }: TalentSheetInResumedProps) => {
  const { t } = useTranslation(['talentSheet', 'random']);
  const [comment, setComment] = useState(talent?.comment || '');
  const [disabled, setDisabled] = useState(true);

  return (
    <div>
      <StyledXP>
        <StyledContainerSkillDetails>
          <TitlePart variant="subtitle2Medium" textTitle={t('comment', { ns: 'talentSheet' })} />
          <StyledCalculInformation>
            <TextArea
              handleChange={(e: { target: { value: string } }) =>
                setComment((prevState) => {
                  if (prevState !== e.target.value) {
                    setDisabled(false);
                    return e.target.value;
                  } else {
                    return prevState;
                  }
                })
              }
              name={'commentTalent'}
              value={comment}
              required={false}
            />
            <StyledDivButton>
              <Button
                onClick={async () => {
                  const commentOutput = await handleChange({
                    variables: {
                      input: {
                        id: talent?.id,
                        comment: comment,
                        basics: {
                          firstname: talent?.firstname,
                          lastname: talent?.lastname,
                          jobTitle: talent?.jobTitle,
                        },
                      },
                    },
                  });
                  if (commentOutput?.data?.upsertTalentBasic?.id) {
                    notify('success', t('modifSaved', { ns: 'random' }));
                  }
                  setDisabled(true);
                }}
                disable={disabled || loadingUpsert}
                textButton={loadingUpsert ? t('saveLoading', { ns: 'random' }) : t('validate', { ns: 'random' })}
              />
            </StyledDivButton>
          </StyledCalculInformation>
        </StyledContainerSkillDetails>
      </StyledXP>
    </div>
  );
};

export default TalentSheetInResumed;
export { TalentSheetInResumed };

const StyledXP = styled('div')(
  () => `
  box-shadow: 0px 4.30769px 23.6923px rgba(75, 75, 75, 0.09);
  border-radius: 10px;
  text-align: left;
  padding: 24px 24px 32px 24px;
  margin-bottom: 24px;
  `,
);

const StyledContainerSkillDetails = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap; 
  gap: 12px;
  `,
);

const StyledCalculInformation = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  background-color: ${theme.palette.grey[200]};
  border-radius: 10px;
  `,
);

const StyledDivButton = styled('div')(
  ({ theme }) => `
    margin: 20px 0px 0px auto;
  `,
);
