import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';

type Option = {
  id: string;
  label: string;
};

export type FreeSoloAutoCompleteProps = {
  collectTags: (input: string) => Promise<Option[]>;
  tags?: string[];
  loading: boolean;
  label: string;
  placeholder: string;
  setTag: (tags: string[]) => Promise<void> | void;
  disabled?: boolean;
  multiple?: boolean;
  freeSolo?: boolean;
  noOptionsText?: string;
  initTags?: Option[];
};

const FreeSoloAutoComplete = ({
  collectTags,
  tags = [],
  loading,
  label,
  placeholder,
  setTag,
  disabled = false,
  multiple = true,
  freeSolo = true,
  noOptionsText,
  initTags = [],
}: FreeSoloAutoCompleteProps) => {
  const { t } = useTranslation(['random']);
  const [options, setOptions] = React.useState<Option[]>(initTags);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = useState<string | string[] | null>(multiple ? tags : tags?.[0] || null);
  const fetchTags = React.useMemo(
    () =>
      throttle((args: { input: string }, callback: (results: Option[]) => void) => {
        collectTags(args.input).then(callback).catch(console.error);
      }, 500),
    [collectTags],
  );

  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(initTags);
      return undefined;
    }

    fetchTags({ input: inputValue }, (results: Option[]) => {
      if (results) {
        setOptions(results);
      }
    });
  }, [inputValue, fetchTags, initTags]);

  React.useEffect(() => {
    if (multiple) {
      setValue(tags);
    } else {
      setValue(tags?.[0] || null);
    }
  }, [tags, multiple]);

  return (
    <Autocomplete
      loading={loading}
      loadingText={t('loadingText')}
      noOptionsText={noOptionsText || t('noOptionsText', { defaultValue: "Pas d'options" })}
      multiple={multiple}
      autoHighlight
      id="tags-filled"
      getOptionLabel={(value: string | Option) => {
        if (typeof value === 'string') {
          return value;
        }
        return value.label;
      }}
      options={options?.map((option: Option) => {
        return option.label;
      })}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      autoSelect
      fullWidth={true}
      onChange={(event: any, newValue: any, reason: AutocompleteChangeReason) => {
        if (newValue) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          setTag(Array.isArray(newValue) ? newValue : [newValue]);
          setValue(newValue);
        }
      }}
      disabled={disabled}
      value={value}
      freeSolo={freeSolo}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <div key={index}>
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          </div>
        ))
      }
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
    />
  );
};

const memoizedComp = React.memo(FreeSoloAutoComplete);

export default memoizedComp;
export { memoizedComp as FreeSoloAutoComplete };
