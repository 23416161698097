import { styled } from '@mui/material/styles';

import Title from '../atoms/Title';

import RawHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Banniere from '../../graphic-charter/banniere.png';
import { SingleLineText, HrefLink, Logo as RawLogo } from '@bloomays-lib/ui.shared';

type FallbackErrorProps = {
  errorText?: string;
  errorCode?: string;
};

const FallbackError = ({ errorText, errorCode }: FallbackErrorProps): JSX.Element => {
  return (
    <ContainerDiv>
      {errorCode === 'UNKNOWN_BLOOMER' ? (
        <>
          <Title span="Salut l'inconnu.e !" />
          <HelpOutlineIcon />
        </>
      ) : errorCode && errorCode === '404' ? (
        <Title span="404 ! 🧐" />
      ) : (
        <Title span="Oups ! 😵" />
      )}
      <SingleLineText
        text={errorText ? errorText : 'Désolé mais une erreur est survenue, nos agents sont sur le coup !'}
      />
      <ContainerMailTo>
        <SingleLineText text="Pour en savoir plus envoie un mail à : " />
        <br />
        <br />
      </ContainerMailTo>
      <a href="mailto:csm@bloomays.com">csm@bloomays.com</a>
      <br />
      <RedirectionContainer>
        <SingleLineText text={'Retour au dasboard 👉'} />
        <HrefLink text="ici" href="/dashboard" />
      </RedirectionContainer>
      <Logo image={Banniere} alt="error fallback banniere" />
    </ContainerDiv>
  );
};

export default FallbackError;

const ContainerMailTo = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ContainerDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: theme.palette.paper.white,
  boxShadow: '2px 2px 20px lightgrey',
  borderRadius: '10px',
  width: '40%',
  margin: '1em auto',
  padding: '1em',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
}));

const RedirectionContainer = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Logo = styled(RawLogo)(() => ({
  paddingTop: '2em',
  width: '100%',
}));

const HelpOutlineIcon = styled(RawHelpOutlineIcon)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: '130px !important',
  margin: 'auto',
}));
