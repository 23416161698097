import { ITalent } from '@bloomays-lib/types.shared';
import { styled } from '@mui/material/styles';
import TalentItemCard from '../molecules/TalentItemCard';
import { useTranslation } from 'react-i18next';
import alone from '../../graphic-charter/alone.svg';
import { EmptyState } from '@bloomays-lib/ui.shared';

export type TalentsListProps = {
  talents: ITalent[];
};

const TalentsList = ({ talents }: TalentsListProps) => {
  const { t } = useTranslation(['talentSheet']);
  return (
    <StyledTalentsList>
      {talents && talents.length > 0 ? (
        talents?.map((talent: ITalent, i) => {
          return <TalentItemCard talent={talent} key={i} />;
        })
      ) : (
        <StyledEmptyState>
          <EmptyState image={alone} text={t('noTalentsSearch')} />
        </StyledEmptyState>
      )}
    </StyledTalentsList>
  );
};

export default TalentsList;
export { TalentsList };

const StyledTalentsList = styled('div')(
  ({ theme }) => `
    background-color: ${theme.palette.grey[200]};
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    gap: 24px;
    padding: 24px;
    min-width: 720px;
  `,
);

const StyledEmptyState = styled('div')(
  () => `
  display: flex;
  justify-content: center;
  `,
);
