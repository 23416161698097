import { object, string, number, boolean, array, date } from 'yup';
import { AddressCountryCodeEU } from '../types.shared';

export const FR_SIRET_REGEX_STRING = '^\\d{14}$';
export const EU_VAT_REGEX_STRING =
  'ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CHE[0-9]{8,10}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES(X(-|\\.)?0?\\d{7}(-|\\.)?[A-Z]|[A-Z](-|\\.)?\\d{7}(-|\\.)?[0-9A-Z]|\\d{8}(-|\\.)?[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2})';
export const EU_VAT_SIRET_REGEX_STRING = `${EU_VAT_REGEX_STRING}|${FR_SIRET_REGEX_STRING}`;
export const euVATSiretRegex = new RegExp(EU_VAT_SIRET_REGEX_STRING);
const euVATRegex = new RegExp(EU_VAT_REGEX_STRING);
const yupVATSiret = string().matches(
  euVATSiretRegex,
  'Le siret de la societé doit être un numéro de TVA ou un numéro de SIRET valide',
);
const yupVAT = string().matches(euVATRegex, 'Le siret de la societé doit être un numéro de TVA valide');
const schemas: any = {};

schemas.invoice = object({
  invoiceDate: date().required(),
  paymentDate: date().required(),
  title: string().required(),
  draft: boolean().required(),
  currencyISO4217: string().required().is(['EUR']),
  specialMention: string(),
  internalReference: string().required(),
  langage: string().required().oneOf(['fr_FR', 'en_GB']),
  type: string().required().oneOf(['FREE', 'CDI']),
  subtype: string().required('type of mission must be defined (IT, RPO, People').oneOf(['IT', 'RPO', 'People']),
  orderNumber: string().optional(),
  items: array()
    .required()
    .min(1)
    .of(
      object({
        reference: string().required(),
        label: string().required(),
        quantity: number().required(),
        unitAmountWithoutTaxe: number().required(),
        unit: string().required('Only day unit typ is supported').oneOf(['day']),
        vatRate: number().required('Only VAT RATE of 0 | 5.5 | 10 | 20 are supported').oneOf([0, 5.5, 10, 20]),
        description: string().required(),
        discountPercent: number().optional(),
      }),
    ),
  customerInfos: object({
    customerName: string().required(),
    siret: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVATSiret;
      })
      .required(),
    billingAddressName: string().required(),
    billingAddressPart1: string().required(),
    billingAddressPart2: string().optional(),
    billingPostalCode: string().required(),
    billingCity: string().required(),
    billingCountryCodeAlpha2: string()
      .length(2, "Le code pays addressCountryCode de l'adresse de facturation doit contenir deux caractères")
      .required(),
    deliveryAddressName: string().required(),
    deliveryAddressPart1: string().required(),
    deliveryAddressPart2: string().optional(),
    deliveryPostalCode: string().required(),
    deliveryCity: string().required(),
    deliveryCountryCodeAlpha2: string()
      .length(2, "Le code pays addressCountryCode de l'adresse de livraison doit contenir deux caractères")
      .required(),
    recipientName: string().required(),
    vatNumber: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVAT;
      })
      .optional(),
    paymentConditions: string().required().oneOf(['0', '15', '30', '45', '60', 'custom']),
    billingContacts: array()
      .required()
      .min(1)
      .of(
        object({
          firstname: string().required(),
          lastname: string().required(),
          email: string().email().required(),
          nameJob: string().optional(),
        }),
      ),
    operationalContacts: array()
      .required()
      .min(1)
      .of(
        object({
          firstname: string().required(),
          lastname: string().required(),
          email: string().email().required(),
          nameJob: string().optional(),
        }),
      ),
    phone: string().optional(),
    internalId: string().required(),
  }),
});

schemas.supplierInvoice = object({
  invoiceDate: date().required(),
  paymentDate: date().required(),
  currencyISO4217: string().required().is(['EUR']),
  internalReference: string().required(),
  langage: string().required().oneOf(['fr_FR', 'en_GB']),
  subtype: string().required('type of mission must be defined (IT, RPO, People').oneOf(['IT', 'RPO', 'People']),
  fileB64Encoded: string().required(),
  draft: boolean().required(),
  items: array()
    .required()
    .min(1)
    .of(
      object({
        label: string().required(),
        quantity: number().required(),
        unitAmountWithoutTaxe: number().required(),
        planItemNumber: string().optional(),
        vatRate: number().required('Only VAT RATE of 0 | 5.5 | 10 | 20 are supported').oneOf([0, 5.5, 10, 20]),
      }),
    ),
  supplierInfos: object({
    supplierName: string().required(),
    siret: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVATSiret;
      })
      .required(),
    billingAddressName: string(),
    billingAddressPart1: string(),
    billingAddressPart2: string(),
    billingPostalCode: string(),
    billingCity: string(),
    billingCountryCodeAlpha2: string()
      .length(2, "Le code pays addressCountryCode de l'adresse de facturation doit contenir deux caractères")
      .required(),
    vatNumber: string()
      .when('billingCountryCodeAlpha2', (billingCountryCodeAlpha2, schema) => {
        if (!AddressCountryCodeEU?.includes(billingCountryCodeAlpha2)) {
          return schema;
        }
        return yupVAT;
      })
      .optional(),
    paymentConditions: string().required().oneOf(['0', '15', '30', '45', '60', 'custom']),
    billingContacts: array()
      .required()
      .min(1)
      .of(
        object({
          firstname: string().required(),
          lastname: string().required(),
          email: string()
            .email('Email mal formé pour le contact de facturation de la societé fournisseur')
            .required('Email obligatoire pour le contact de facturation de la societé fournisseur'),
          nameJob: string().optional(),
        }),
      ),
    phone: string().optional(),
    internalId: string().required(),
  }),
});

schemas.KAM = object({
  firstname: string().required('KAM: Prénom manquant'),
  lastname: string().required('KAM: Nom manquant'),
  email: string().required('KAM: Email manquant').email(),
  phoneNumber: string().optional(),
  bloomers: array().optional(),
  billing: array().optional(),
});

schemas.bloomer = object({
  firstname: string().required('Bloomer: Prénom manquant'),
  lastname: string().required('Bloomer: Nom manquant'),
  email: string().required('Bloomer: Email manquant').email(),
  accountManagerName: string().required('Bloomer: nom du KAM manquant'),
  phoneNumber: string().optional(),
  accountManager: array().of(schemas.KAM.required('Bloomer: KAM manquant')),
});

schemas.contact = object({
  firstname: string().required('Contact: Prénom manquant'),
  lastname: string().required('Contact: Nom manquant'),
  nameJob: string().required('Contact: Intitulé du poste manquant'),
  email: string().required('Contact: Email manquant').email(),
  phoneNumber: string().optional(),
  isBilling: boolean().optional(),
  isOperational: boolean().optional(),
});

schemas.society = object({
  name: string().required(),
  siret: string().when('addressCountrycode', (addressCountrycode, schema) => {
    if (!AddressCountryCodeEU?.includes(addressCountrycode)) {
      return schema;
    }
    return yupVATSiret;
  }),
  tva: string().notRequired().matches(euVATRegex, 'Société cliente: TVA au mauvais format'),
  addressName: string().required('Société cliente: adresse manquante'),
  addressPart1: string().required('Société cliente: adresse manquante'),
  addressZip: string().required('Société cliente: code postal manquant'),
  addressTown: string().required('Société cliente: ville manquante'),
  addressCountrycode: string()
    .length(2, "Le code pays addressCountryCode de l'adresse de la societé doit contenir deux caractères")
    .optional(),
  billingContact: schemas.contact.required('Société cliente: contact de facturation manquant'),
});

schemas.pricing = object({
  purchasePricePerDayWithoutTaxes: number().required('Prix: Achat manquant'),
  salePricePerDayWithoutTaxes: number().required('Prix: Vente manquante'),
  astreinteCoef: number().required('Prix: AstreinteCoef manquant'),
  startDate: string().optional(),
});

schemas.mission = object({
  bloomer: schemas.bloomer.required('Mission: Bloomer manquant'),
  label: string().required('Mission: Nom de mission manquant'),
  pricing: schemas.pricing.required('Mission: Prix manquant'),
  beginMission: string().required('Mission: Début de mission manquant'),
  endMission: string().required('Mission: Fin de mission manquant'),
  clientSociety: schemas.society.required('Mission: Société manquante'),
  contactOperations: schemas.contact.required('Mission: Contact opérationnel manquant'),
  externalId: string().required(),
  type: string().optional(),
  clientPaydateDays: number().nullable().notRequired(),
  bloomerPaydateDays: number().required('Mission: Délai de réglement Bloomer manquant'),
});

schemas.BPASociety = object({
  name: string().required(),
  siret: string().when('addressCountrycode', (addressCountrycode, schema) => {
    if (!AddressCountryCodeEU?.includes(addressCountrycode)) {
      return schema;
    }
    return yupVATSiret;
  }),
  addressTown: string().required('Société cliente: ville manquante'),
});

schemas.BPAricing = object({
  purchasePricePerDayWithoutTaxes: number().required('Prix: Achat manquant'),
  salePricePerDayWithoutTaxes: number().required('Prix: Vente manquante'),
});

schemas.BPABloomer = object({
  fullname: string().required('Bloomer: Nom complet manquant'),
  email: string().required('Bloomer: Email manquant').email(),
  accountManagerName: string().required('Bloomer: KAM manquant'),
  accountManager: array().of(schemas.KAM.required('Bloomer: KAM manquant')),
});

schemas.BPA = object({
  bloomer: schemas.BPABloomer.required('Mission: Bloomer manquant'),
  label: string().required('Mission: Nom de mission manquant'),
  pricing: schemas.BPAricing.required('Mission: Prix manquant'),
  beginMission: string().required('Mission: Début de mission manquant'),
  clientSociety: schemas.BPASociety.required('Mission: Société manquante'),
  contactOperations: schemas.contact.required('Mission: Contact manquant'),
  bloomerPaydateDays: number().required('Mission: Délai de réglement Bloomer manquant'),
});

schemas.recruitement = object({
  Description: string().required('Description manquante'),
  Type: string().required('Type manquant'),
  BillingDate: string().required('Date de démarrage manquante'),
  TrialPeriodEndDate: string().required("Date de fin de période d'essaie manquante"),
  Customer: array().of(string()),
  SalesPriceWithoutTaxes: number().required(),
  BillingContact: array().of(string()).required('Contact de facturation manquant'),
  KAM: array().of(string().required('Account Manager manquant')),
  PaydateDays: number().optional(),
  externalId: string().required(),
});

schemas.pipefyCardData = object({
  id: number().required(),
  pipe_id: string().required(),
  title: string().required(),
});

schemas.pipefyColumn = object({
  id: number().required(),
  name: string().required(),
});

schemas.pipefyUser = object({
  id: number().required(),
  email: string().required(),
  username: string().required(),
});

schemas.moveDealPipefy = object({
  data: object({
    action: string().test('card.move', 'wrong action type', (value) => value === 'card.move'),
    card: schemas.pipefyCardData.required('missing card data'),
    from: schemas.pipefyColumn.required('missing from data'),
    to: schemas.pipefyColumn.required('missing to data'),
    moved_by: schemas.pipefyUser.required('missing moved_by data'),
  }),
});

export const getSchema = (schemaName: string) => {
  const schema = schemas[schemaName];

  if (schema) return schema;

  throw new Error(`Yup schema not found ${schemaName}`);
};
