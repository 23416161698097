import { Title } from '../atoms/Title';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { styled } from '@mui/material/styles';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import ServiceDirectJobTitles from '../../services/serviceDirectJobTitles';
import DirectJobTitleManagement from '../organisms/DirectJobTitleManagement';

const Container = styled('div')(({ theme }) => ({
  width: '90%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
}));

const AdminDirectJobTitleManagment = (): JSX.Element => {
  const AdminJobTitle = ServiceDirectJobTitles(DirectJobTitleManagement);

  return (
    <div>
      <Title id="adminJobTitle" textTitle={'Administration des positions titles'} />
      <SingleLineText id="descAdminDirectJobTitle" text={'Administration des positionements de poste'} />
      <Container>
        <AdminJobTitle />
      </Container>
    </div>
  );
};

export default WithErrorBoundary(AdminDirectJobTitleManagment);
