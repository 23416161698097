import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { ITag } from '@bloomays-lib/types.shared';
import { useTheme } from '@mui/material/styles';

export type AutoCompleteTagProps = {
  setTag: React.Dispatch<React.SetStateAction<ITag[]>>;
  collectTags: (input: string) => Promise<ITag[]>;
  loading?: boolean;
  label: string;
  placeholder: string;
};

const AutoCompleteTag = ({ collectTags, loading, setTag, label, placeholder }: AutoCompleteTagProps) => {
  const [options, setOptions] = React.useState<readonly ITag[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const { t } = useTranslation(['random']);
  const theme = useTheme();
  const fetchTags = React.useMemo(
    () =>
      throttle((args: { input: string }, callback: (results?: readonly ITag[]) => void) => {
        collectTags(args.input).then(callback).catch(console.error);
      }, 500),
    [collectTags],
  );

  React.useEffect(() => {
    let newOptions: readonly ITag[] = [];
    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetchTags({ input: inputValue }, (results?: readonly ITag[]) => {
      if (results) {
        newOptions = [...newOptions, ...results];
        setOptions(results);
      }
    });
  }, [inputValue, fetchTags]);

  return (
    <Autocomplete
      loading={loading}
      loadingText={t('loadingText')}
      noOptionsText={t('noOptionsText')}
      fullWidth={true}
      size="small"
      sx={{
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.dark,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.palette.secondary.dark,
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.dark,
        },
      }}
      multiple
      limitTags={3}
      filterOptions={(x) => x}
      id="multiple-limit-tags"
      options={options}
      getOptionLabel={(option) => {
        if (option.value) {
          return option.value;
        }
        return '';
      }}
      onChange={(event: any, newValue: readonly ITag[]) => {
        if (newValue) {
          setOptions(newValue.concat(options));
          setTag([...newValue]);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderTags={(value: { value: string }[], getTagProps: (element: any) => any) => {
        return value.map((option, index: number) => {
          return <Chip key={index} label={option.value} {...getTagProps({ index })} />;
        });
      }}
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
    />
  );
};

export default AutoCompleteTag;
export { AutoCompleteTag };
