import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { notify } from '../helpers/toastify';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { ISkill } from '@bloomays-lib/types.shared';
import { UPDATE_SKILL, MERGE_SKILL_BY_ID, DELETE_SKILL, SKILLS, FLAT_SKILL } from '@bloomays-lib/adapter.api-talent';
import { LabelManagmentProps } from '../components/organisms/LabelManagment';
import { errorLogger } from '../helpers/error';

const ServiceSkills = (WrappedComponent: React.ComponentType<any>) =>
  function Component() {
    const {
      loading: loadingSkills,
      error: errorSkills,
      data: dataSkills,
    } = useQuery<{ listSkills: ISkill[] }>(SKILLS, {
      context: { clientName: 'api.talents' },
    });

    const [mergeSkillsById] = useMutation<{
      mergeSkillsById: ISkill;
    }>(MERGE_SKILL_BY_ID, { context: { clientName: 'api.talents' } });

    const [updateSkill] = useMutation<{
      updateSkill: ISkill;
    }>(UPDATE_SKILL, { context: { clientName: 'api.talents' } });

    const [flatSkill] = useMutation<{
      flatSkills: ISkill[];
    }>(FLAT_SKILL, { context: { clientName: 'api.talents' } });

    const [deleteSkill] = useMutation<{
      deleteSkill: ISkill;
    }>(DELETE_SKILL, { context: { clientName: 'api.talents' } });

    function notifyError(error: any) {
      errorLogger(error, {
        extraInfos: 'Internal server error, impossible to relaunch skills !',
      });
      notify('error', error.message, error);
    }

    async function onFlat(skillId: string) {
      return (
        await flatSkill({
          variables: {
            dryRun: false,
            skillId: skillId,
          },
        })
      ).data?.flatSkills;
    }

    async function onUpdate(newSkill: ISkill, oldSkill: ISkill) {
      // mergeSkillsById()
      return (
        await updateSkill({
          variables: {
            skill: newSkill,
          },
        })
      ).data?.updateSkill;
    }

    async function onDelete(skillId: string) {
      // mergeSkillsById()
      return (
        await deleteSkill({
          variables: {
            skillId: skillId,
          },
        })
      ).data?.deleteSkill;
    }

    async function onMerge(mergeToSkillId: string, mergeInSkillId: string) {
      // mergeSkillsById()
      return (
        await mergeSkillsById({
          variables: {
            dryRun: false,
            mergeInSkillId: mergeInSkillId,
            mergeToSkillId: mergeToSkillId,
          },
        })
      ).data?.mergeSkillsById;
    }

    useEffect(() => {
      if (!errorSkills) return;
      notifyError(errorSkills);
    }, [errorSkills]);

    if (loadingSkills) {
      return <LoaderSkeleton height={600} width={800} />;
    }

    const skills = dataSkills?.listSkills ?? [];

    const injectedProps: LabelManagmentProps<ISkill> = {
      labels: skills,
      onError: notifyError,
      onUpdate: onUpdate,
      onDelete: onDelete,
      onMerge: onMerge,
      onFlat: onFlat,
    };

    return <WrappedComponent {...injectedProps} />;
  };

export default ServiceSkills;
export { ServiceSkills };
