import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';

export type CardItemProps = {
  variant: 'pink' | 'purple' | 'grey' | 'blue';
  size: 'extraSmall' | 'small' | 'medium';
  emoji: React.ReactNode;
  onClick?: () => void;
  rounded?: boolean;
  sx?: SxProps<Theme>;
};

const getColor = (variant: string, theme: Theme) => {
  switch (variant) {
    case 'purple':
      return {
        bgColor: theme.palette.secondary[600],
        color: theme.palette.secondary.light,
      };
    case 'pink':
      return {
        color: theme.palette.primary.main,
        bgColor: theme.palette.secondary[400],
      };
    case 'blue':
      return {
        color: theme.palette.secondary.dark,
        bgColor: theme.palette.secondary[500],
      };
    default:
      return {
        bgColor: theme.palette.grey[200],
        color: theme.palette.grey[800],
      };
  }
};

const getSize = (size: string) => {
  switch (size) {
    case 'extraSmall':
      return '35px';
    case 'small':
      return '40px';
    case 'medium':
    default:
      return '46px';
  }
};

const getRounded = (rounded?: boolean) => {
  if (rounded) return '50px';
  return '5px';
};

const CardItem = ({ variant, size, emoji, onClick, rounded, sx }: CardItemProps) => {
  const theme = useTheme();
  const { color, bgColor } = getColor(variant, theme);
  const width = getSize(size);
  const height = getSize(size);
  const borderRadius = getRounded(rounded);
  const StyledTypography = styled(Typography)(
    () => `
        color: ${color};
        background-color: ${bgColor};
        width: ${width};
        min-width: ${width};
        height: ${height};
        border-radius: ${borderRadius};
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 24px;

        &:hover {
          cursor: 'pointer';
        }
      `,
  );

  return (
    <StyledTypography sx={sx} onClick={onClick}>
      {emoji}
    </StyledTypography>
  );
};

export default CardItem;
export { CardItem };
