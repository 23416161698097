import PropTypes from 'prop-types';
import { Auth0Provider, Auth0Context, AppState } from '@auth0/auth0-react';
import { Children } from '../types';
const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID, VITE_BLOOMERS_API } = import.meta.env;

export { Auth0Context };

type Auth0ProviderWithNavigateProps = {
  children: Children;
};

export const Auth0ProviderWithNavigate = ({ children }: Auth0ProviderWithNavigateProps): JSX.Element => {
  const domain = VITE_AUTH0_DOMAIN;
  const clientId = VITE_AUTH0_CLIENT_ID;
  const audience = VITE_BLOOMERS_API;

  const onRedirectCallback = (appState?: AppState) => {
    window.location.href = appState?.returnTo || appState?.target || window.location.pathname;
  };

  if (!(domain && clientId)) {
    return <p>error missing VITE_AUTH0_DOMAIN and VITE_AUTH0_CLIENT_ID</p>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <>{children}</>
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.object,
};
