import { useSearchParams } from 'react-router-dom';
import { State } from '../types/talents';

export const useSearchTalentsParams = (initValue: State) => {
  const [getSearchParams] = useSearchParams();
  const stateStr = getSearchParams.get('state');
  let stateAny: State;
  if (stateStr) {
    stateAny = JSON.parse(stateStr) as State;
  } else {
    stateAny = initValue;
  }

  const setSearchTalentsParams = (state: State) => {
    const stateStr = JSON.stringify(state);
    window.history.pushState(
      stateStr,
      '',
      `${window.location.protocol}//${window.location.host}${window.location.pathname}?state=${encodeURIComponent(
        stateStr,
      )}`,
    );
  };

  return { searchTalentParams: stateAny, setSearchTalentsParams };
};
