import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';

import { SingleLineText } from '@bloomays-lib/ui.shared';
import { TitlePart, LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { GET_JOBS_HISTORICAL } from '@bloomays-lib/adapter.api-bloomer';
import { errorLogger } from '../../helpers/error';
import { notify } from '../../helpers/toastify';
import { JobHistoricalGQL } from '@bloomays-lib/types.shared';

export type ChronologicalHistoryProps = {
  missionId: string;
  play?: boolean;
};

const ContainerHistory = styled('div')(({ theme }) => ({
  textAlign: 'left',
  margin: '2em 0',
}));

const DataContainer = (props: { data: JobHistoricalGQL | null }) => {
  const { data } = props;
  if (!data) return <SingleLineText text={"Pas d'historique de relance pour ce CRA !"} />;

  return (
    <>
      <SingleLineText
        text={`➜ Date de la dernière relance :`}
        span={convertDateToStringFormat(data.processedOn, 'dd/MM/yyyy')}
      />
      {data.error && <SingleLineText text={`➜ Raison de l'échec :`} span={data.error} />}
      {data.data.contact !== '' ? (
        <>
          <SingleLineText text={`➜ Envoyé à :`} span={data?.data?.contact} />
          <SingleLineText text={`➜ Statut :`} span={data?.progress === 100 ? 'Envoyé 🎉' : 'Pas envoyé ❌'} />
        </>
      ) : (
        <SingleLineText text={`➜ Mode "Ne pas déranger" activé 🔕`} />
      )}
    </>
  );
};

const ChronologicalHistory = ({ missionId, play = true }: ChronologicalHistoryProps) => {
  const { loading, data, error } = useQuery<{
    jobHistorical: JobHistoricalGQL;
  }>(GET_JOBS_HISTORICAL, {
    variables: {
      id: missionId,
      jobName: 'notification',
    },
    context: { clientName: 'api.bloomers' },
  });

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve jobs data !',
    });
    notify('error', "Oups, j'ai pas réussi à récupérer l'historique, ré-essaye plus tard ! 😓", error);
  }

  return (
    <div>
      <TitlePart textTitle={'Historique de relance 🗒'} />
      {loading && (
        <LoaderSkeleton play={play} height={'80px'} width={'100%'} label={"Chargement de l'historique ..."} />
      )}
      {!loading && !error && (
        <ContainerHistory>
          <DataContainer data={data?.jobHistorical || null} />
        </ContainerHistory>
      )}
      {error && (
        <ContainerHistory>
          <SingleLineText text="🚧 Error de récuperation de données" />
          <SingleLineText text={`Message : ${error}`} />
        </ContainerHistory>
      )}
    </div>
  );
};

export default ChronologicalHistory;
export { ChronologicalHistory };
