import { useTheme } from '@mui/material/styles';
import TwoDTable, { Columns } from '../molecules/TwoDTable';
import { DashboardMissionData, KPIType } from '@bloomays-lib/types.shared';
import { buildMonthYearInterval } from '@bloomays-lib/utils.shared';
import { formatMissionPlanning } from '../../helpers/missionPlanning';
import { useTranslation } from 'react-i18next';

export type ColorPalette = {
  M: string;
  D: string;
  NS: string;
  F: string;
  EB: string;
  BE: string;
  BR: string;
  white: string;
  red: string;
  green: string;
};

export type MissionPlanningProps = {
  rawData: DashboardMissionData;
  isLoading?: boolean;
  startDate: Date;
  openMissionsPopin: (kpi: KPIType, month: string, missionIds: string[], estimatedMissionIds: string[]) => void;
};

const MissionPlanning = ({
  rawData,
  isLoading,
  startDate = new Date(),
  openMissionsPopin,
}: MissionPlanningProps): JSX.Element => {
  const { t } = useTranslation(['random', 'KPI']);
  const theme = useTheme();

  const columns: Columns[] = [{ columnName: 'KPI', title: t('KPI', { ns: 'random' }) }];
  const interval = buildMonthYearInterval(startDate, {
    fullYear: true,
  });
  interval.forEach((column) => {
    columns.push({ columnName: column, title: column });
  });
  columns.push({
    columnName: 'average',
    title: t('average', { ns: 'random' }),
  });

  const colorPalette: ColorPalette = {
    M: theme.palette.primary.dark,
    D: theme.palette.secondary.dark,
    NS: theme.palette.tertiary.blue,
    F: theme.palette.tertiary.yellowStar,
    EB: theme.palette.tertiary.yellowMedium,
    BE: theme.palette.tertiary.yellow,
    BR: theme.palette.tertiary.purple,
    white: theme.palette.paper.white,
    green: '#08a81c',
    red: theme.palette.tertiary.red,
  };

  const rows = formatMissionPlanning(rawData, colorPalette, openMissionsPopin);

  return <TwoDTable columns={columns} isLoading={isLoading} rows={rows} />;
};

export default MissionPlanning;
export { MissionPlanning };
