import { ErrorBoundary } from '@bloomays-lib/common.monitoring/browser';
import { useAuth } from '../../auth/AuthProvider';

import Fallback from '../pages/Fallback';
import { Auth0User } from '@bloomays-lib/types.shared';

const WithErrorBoundary = (WrappedComponent: () => JSX.Element) =>
  function Component() {
    const auth = useAuth();
    let user: Auth0User | null | undefined;
    if (auth) {
      user = auth.user;
    }

    return (
      <ErrorBoundary
        fallback={<Fallback />}
        beforeCapture={(scope) => {
          scope.setUser({
            id: user?.email,
            email: user?.email,
            username: user?.displayName,
          });
        }}
      >
        <WrappedComponent />
      </ErrorBoundary>
    );
  };

export { WithErrorBoundary };
export default WithErrorBoundary;
