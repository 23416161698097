import { useEffect } from 'react';
import useCustomLocalStorage from '../../customHooks/useCustomLocalStorage';
import { ALL_MISSIONS } from '@bloomays-lib/adapter.api-bloomer';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useAuth } from '../../auth/AuthProvider';

import BloomerHome from '../organisms/BloomerHome';
import FreelanceDashboard from '../organisms/FreelanceDashboard';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { AllMissionGQLItemResult, Role } from '@bloomays-lib/types.shared';
import { notify } from '../../helpers/toastify';
import { errorLogger } from '../../helpers/error';

const Dashboard = () => {
  const auth = useAuth();
  let user;
  if (auth) {
    user = auth.user;
  }

  const [active, setActive] = useCustomLocalStorage('checked', {
    checked: true,
  });

  const [fetchMissions, { error: errorMissions, data: dataMissions, loading: loadingMissions }] = useLazyQuery<{
    allMissions: { results: AllMissionGQLItemResult[] };
  }>(ALL_MISSIONS, {
    variables: {
      isActive: active?.checked,
    },
    fetchPolicy: 'network-only',
  });

  const onChange = (event: { target: { checked: boolean } }) => {
    setActive({ checked: event.target.checked });
  };

  const notifyError = (e: ApolloError) => {
    errorLogger(e, {
      extraInfos: 'Internal server error, impossible to retrieve data about bloomers and their infos !',
    });
    notify('error', e.message, e);
  };

  useEffect(() => {
    if (!errorMissions) return;
    notifyError(errorMissions);
  }, [errorMissions]);

  const adminComponent = (
    <FreelanceDashboard
      onChange={onChange}
      loading={loadingMissions}
      checked={active?.checked}
      dataMissions={dataMissions?.allMissions?.results || []}
      fetchMissions={fetchMissions}
    />
  );
  let component;

  if (user?.roles) {
    if (user.roles.includes(Role.Admin)) {
      component = adminComponent;
    }
    if (user.roles.includes(Role.Bloomer)) {
      component = <BloomerHome />;
    }
    if (user.roles.includes(Role.Admin) && user.roles.includes(Role.Bloomer)) {
      component = (
        <div>
          <BloomerHome />
          <hr></hr>
          {adminComponent}
        </div>
      );
    }
  }

  return <div>{component}</div>;
};

export default WithErrorBoundary(Dashboard);
