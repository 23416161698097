import { BrowserBloomer } from '@bloomays-lib/types.shared';
import RawCard from '@mui/material/Card';
import RawCardHeader from '@mui/material/CardHeader';
import RawPermIdentityIcon from '@mui/icons-material/PermIdentity';
import RawMailOutlineIcon from '@mui/icons-material/MailOutline';
import RawFileOpenIcon from '@mui/icons-material/FileOpen';
import RawPhoneIcon from '@mui/icons-material/Phone';
import RawVisibilityIcon from '@mui/icons-material/Visibility';
import RawPeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Modal from '../atoms/Modal';
import { useEffect, useState } from 'react';
import { Button, FileViewer } from '@bloomays-lib/ui.shared';
import RawIconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import { UpdateBloomerForm } from './UpdateBloomerForm';
import { Theme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useAuth } from '../../auth/AuthProvider';

type BloomerCardProps = {
  bloomer: BrowserBloomer;
  filePath: string | undefined;
  setFilePath: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const BloomerCard = ({ bloomer, filePath, setFilePath }: BloomerCardProps) => {
  const [openPopin, setOpenPopin] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user?.token) {
      setToken(auth.user?.token);
    }
  }, [auth]);
  return (
    <Card>
      <Modal
        open={!!filePath}
        onClose={() => setFilePath(undefined)}
        additionnalCSS={{
          width: '822px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        
        <FileViewer width="100%" height="1000px" url={filePath as string} accessToken={token} />
      </Modal>
      <Modal
        open={openPopin}
        onClose={() => {
          setOpenPopin(false);
        }}
        additionnalCSS={{
          padding: '20px 30px',
          width: '100%',
          maxWidth: '512px',
        }}
      >
        <UpdateBloomerForm
          callback={() => {
            setOpenPopin(false);
          }}
          bloomer={bloomer}
        />
      </Modal>
      <CardContent>
        <CardHeader title={'Le bloomer'} />
        <ContainerData>
          <ContainerLine>
            <CenteredLabel id="fullnameBloomerCard" variant="body1Light" component="p">
              <PermIdentityIcon />
              Nom complet:
            </CenteredLabel>
            <DarkTypo id="fullnameBloomerCardValue" variant="body1Light">
              {bloomer.fullname || (bloomer.firstname && `${bloomer.firstname} ${bloomer.lastname}`)}
            </DarkTypo>
          </ContainerLine>
          <ContainerLine>
            <CenteredLabel id="emailBloomerCard" variant="body1Light" component="p">
              <MailOutlineIcon />
              Email:
            </CenteredLabel>
            <DarkTypo id="emailBloomerCardValue" variant="body1Light">
              <HrefLine href={`mailto:${bloomer?.email}`}>{bloomer?.email}</HrefLine>
            </DarkTypo>
          </ContainerLine>
          <ContainerLine>
            <CenteredLabel id="emailSecBloomerCard" variant="body1Light" component="p">
              <MailOutlineIcon />
              Email secondaire:
            </CenteredLabel>
            <DarkTypo id="emailSecBloomerCardValue" variant="body1Light">
              <HrefLine href={`mailto:${bloomer?.secondaryEmail}`}>{bloomer?.secondaryEmail}</HrefLine>
            </DarkTypo>
          </ContainerLine>
          <ContainerLine>
            <CenteredLabel id="phoneNumberBloomerCard" variant="body1Light" component="p">
              <PhoneIcon />
              Téléphone:
            </CenteredLabel>
            <DarkTypo id="phoneNumberBloomerCardValue" variant="body1Light">
              <HrefLine href={`tel:${bloomer?.phoneNumber}`}>{bloomer?.phoneNumber}</HrefLine>
            </DarkTypo>
          </ContainerLine>
          <ContainerLine>
            <CenteredLabel id="AMBloomerCard" variant="body1Light" component="p">
              <PeopleOutlineIcon />
              Account manager:
            </CenteredLabel>
            <DarkTypo id="AMBloomerCardValue" variant="body1Light">
              {bloomer?.accountManager?.fullname}
            </DarkTypo>
          </ContainerLine>
          {bloomer?.credentialID && (
            <ContainerLine>
              <CenteredLabel variant="body1Light" component="p">
                <FileOpenIcon />
                CI :
              </CenteredLabel>
              <IconButton
                onClick={() => setFilePath(bloomer?.credentialID?.toString())}
                title={bloomer?.credentialID?.toString()}
              >
                <VisibilityIcon />
              </IconButton>
            </ContainerLine>
          )}
        </ContainerData>
      </CardContent>
      <Button
        sx={{ position: 'absolute', right: 10, bottom: 10 }}
        textButton="Modifier"
        onClick={() => {
          setOpenPopin(true);
        }}
      />
    </Card>
  );
};

const iconCSS = (args: { theme: Theme }) => ({
  paddingRight: 10,
  color: args.theme.palette.grey[500],
  width: 20,
});

const iconVCSS = (args: { theme: Theme }) => ({
  paddingTop: 0,
  color: args.theme.palette.grey[500],
  width: 20,
});

const Card = styled(RawCard)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  maxWidth: 400,
  margin: 20,
  [theme.breakpoints.up('lg')]: {
    maxWidth: 600,
  },
}));

const CardHeader = styled(RawCardHeader)(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'left',
}));

const ContainerLine = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const ContainerData = styled('div')(() => ({
  paddingLeft: 5,
}));

const CenteredLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  minWidth: 'auto',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'normal',
  },
})) as typeof Typography;

const DarkTypo = styled(Typography)(({ theme }) => ({
  paddingLeft: 5,
  color: theme.palette.secondary.dark,
}));

const HrefLine = styled('a')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const PermIdentityIcon = styled(RawPermIdentityIcon)(iconCSS);
const MailOutlineIcon = styled(RawMailOutlineIcon)(iconCSS);
const PhoneIcon = styled(RawPhoneIcon)(iconCSS);
const PeopleOutlineIcon = styled(RawPeopleOutlineIcon)(iconCSS);
const FileOpenIcon = styled(RawFileOpenIcon)(iconCSS);
const VisibilityIcon = styled(RawVisibilityIcon)(iconVCSS);
const IconButton = styled(RawIconButton)(({ theme }) => ({
  paddingTop: '0px',
  paddingBottom: '0px',
}));
