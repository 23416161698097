const minDate = new Date(0);

export const ensureDateNotUndefined = (date: string | Date | undefined): Date => {
  if (!date) {
    return minDate;
  }
  return new Date(date);
};

export const sortDescendingAirtablePricingByStartDate = <T extends { startDate?: string | Date }>(
  pricings: T[],
): T[] => {
  return pricings.sort(
    (a, b) => ensureDateNotUndefined(b.startDate).getTime() - ensureDateNotUndefined(a.startDate).getTime(),
  );
};

export const getFirstValidPrice = <T extends { startDate?: string | Date }>(
  prices: T[],
  currentDate: Date,
): T | undefined => {
  return sortDescendingAirtablePricingByStartDate<T>(prices).find(
    (price) => ensureDateNotUndefined(price.startDate) <= currentDate,
  );
};
