import React from 'react';
import RawTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import RawTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import RawTableHead from '@mui/material/TableHead';
import RawTableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

export type BasicTableProps = {
  columns: (string | JSX.Element)[];
  children: React.ReactNode;
};

const BasicTable = ({ columns, children }: BasicTableProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns?.map((title, i) => (
              <TableCell key={`${i} ${title}`}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export { BasicTable };
export default BasicTable;

const TableRow = styled(RawTableRow)(() => ({
  height: 60,
}));

const Table = styled(RawTable)(() => ({
  margin: '1em auto',
  width: '90%',
  boxShadow: '2px 2px 20px lightgrey',
  borderRadius: '10px',
}));

const TableHead = styled(RawTableHead)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  whiteSpace: 'nowrap',
}));

const TableCell = styled(RawTableCell)(({ theme }) => ({
  color: `${theme.palette.paper.white} !important`,
  textAlign: 'center !important' as CanvasTextAlign,
}));
