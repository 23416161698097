import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ReceiptRawIcon from '@mui/icons-material/Receipt';
import NotificationsRawIcon from '@mui/icons-material/NotificationsActive';
import { convertDateToStringFormat, lastDateOfMonth } from '@bloomays-lib/utils.shared';

import { CRAStatus, InvoiceStatus } from '@bloomays-lib/types.shared';

type CraRelaunchActionProps = {
  missionId: string;
  activityId?: string;
  CRAStatus: CRAStatus;
  validated?: boolean;
  billed?: boolean;
  signingDocumentId?: string;
  clientInvoiceStatus?: InvoiceStatus;
  sendBloomerRelaunch: (v: { variables: { missionId: string | undefined; month: string } }) => void;
  sendClientRelaunch: (v: { variables: { signingDocumentId?: string | null } }) => void;
  month: Date;
};

const RelaunchButton = (props: {
  type: 'client' | 'bloomer';
  missionId: string;
  relaunches: string[];
  onClick: () => void;
}) => {
  const { type, onClick, relaunches, missionId } = props;
  if (relaunches.includes(missionId))
    return (
      <ActionButton>
        <span role="img" aria-label="Déjà relancé">
          ✅
        </span>
      </ActionButton>
    );

  return (
    <ActionButton onClick={onClick}>
      <abbr title={`Relancer le ${type}`}>
        <NotificationIcon sx={{ color: type === 'client' ? '#AECEE7' : '#FFB9D5' }} />
      </abbr>
    </ActionButton>
  );
};

const CraRelaunchAction = ({
  missionId,
  activityId,
  CRAStatus,
  validated = false,
  billed = false,
  signingDocumentId,
  clientInvoiceStatus,
  sendClientRelaunch,
  sendBloomerRelaunch,
  month,
}: CraRelaunchActionProps) => {
  const relaunches = useMemo<any>(() => [], []);

  switch (CRAStatus) {
    case 'Non crée': {
      const onClickTelephoneRelauch = () => {
        relaunches.push(missionId);
        void sendBloomerRelaunch({
          variables: {
            missionId,
            month: convertDateToStringFormat(lastDateOfMonth(new Date(month))),
          },
        });
      };
      return (
        <RelaunchButton
          type="bloomer"
          onClick={onClickTelephoneRelauch}
          relaunches={relaunches}
          missionId={missionId}
        />
      );
    }
    case 'Attente signature Client': {
      const onClickWaitingClient = () => {
        relaunches.push(missionId);
        sendClientRelaunch({
          variables: { signingDocumentId: signingDocumentId },
        });
      };
      return (
        <RelaunchButton type="client" onClick={onClickWaitingClient} relaunches={relaunches} missionId={missionId} />
      );
    }
    case 'Attente signature Bloomer': {
      const onClickWaitingBloomer = () => {
        relaunches.push(missionId);
        sendBloomerRelaunch({
          variables: {
            missionId,
            month: convertDateToStringFormat(lastDateOfMonth(month)),
          },
        });
      };
      return (
        <RelaunchButton type="bloomer" onClick={onClickWaitingBloomer} relaunches={relaunches} missionId={missionId} />
      );
    }
    case 'Signature non créee' || undefined: {
      const onClickBloomer = () => {
        relaunches.push(missionId);
        void sendBloomerRelaunch({
          variables: {
            missionId,
            month: convertDateToStringFormat(lastDateOfMonth(new Date(month))),
          },
        });
      };
      return <RelaunchButton type="bloomer" onClick={onClickBloomer} relaunches={relaunches} missionId={missionId} />;
    }
    default:
      return;
  }
};

export default CraRelaunchAction;
export { CraRelaunchAction as GetAction };

const ReceiptIcon = styled(ReceiptRawIcon)(({ theme }) => ({
  color: theme.palette.tertiary.pinkLight,
  cursor: 'pointer',
}));

const NotificationIcon = styled(NotificationsRawIcon)(({ theme }) => ({
  color: theme.palette.tertiary.pinkLight,
  cursor: 'pointer',
}));

const ActionButton = styled('div')(() => ({
  margin: '0',
  width: '50%',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
}));
