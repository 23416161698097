import { useParams } from 'react-router-dom';
import TalentSheet from '../organisms/TalentSheet';
import { useState } from 'react';

const TalentView = (): JSX.Element => {
  const [openSyncTalent, setOpenSyncTalent] = useState(false);
  const { id } = useParams<{ id: string }>();

  const handleCloseSyncTalent = () => {
    setOpenSyncTalent(false);
  };

  const handleOpenSyncTalent = () => {
    setOpenSyncTalent(true);
  };

  return (
    <TalentSheet
      origin="talentPage"
      openSyncTalent={openSyncTalent}
      handleOpenSyncTalent={handleOpenSyncTalent}
      id={id as string}
      handleCloseSyncTalent={handleCloseSyncTalent}
    />
  );
};

export default TalentView;
