import FilterStatus from '../molecules/FilterStatus';
import { Logo } from '@bloomays-lib/ui.shared';
import cross from '../../graphic-charter/icons/cross.svg';
import SearchBar from '../atoms/SearchBar';
import { styled } from '@mui/material/styles';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { ItemJobs, ItemSkills, Status } from '@bloomays-lib/types.shared';
import { ChangeEvent, useState } from 'react';
import React from 'react';
import FilterJobTalent from '../molecules/FilterJobTalent';
import FilterSkill from '../molecules/FilterSkill';
import FilterDisponibility, { FilterDisponibilityProps } from '../molecules/FilterDisponibility';
import FilterRemote, { FilterRemoteProps } from '../molecules/FilterRemote';
import FilterOwner, { FilterOwnerProps } from '../molecules/FilterOwner';
import { useLazyQuery } from '@apollo/client';
import { LIST_LANGUAGES, LIST_OWNERS } from '@bloomays-lib/adapter.api-talent';
import FilterBloomer, { FilterBloomerProps } from '../molecules/FilterBloomer';
import FilterTab from '../atoms/FilterTab';
import FilterLanguages, { FilterLanguagesProps } from '../molecules/FilterLanguages';
import { Action, ActionType, State } from '../../types/talents';

export type HeaderTalentToolProps = {
  onSearchSkill: (skill: string) => void;
  onSearchJobTitle?: (jobTitle: string) => void;
  jobsState?: any[];
  skillsState?: any[];
  state: State;
  dispatch: React.Dispatch<Action>;
  loadingSkills: boolean;
  searchTalents: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  loadingJobs: boolean;
  jobTitles?: ItemJobs[];
};

const getWindowSize = () => {
  const { innerWidth } = window;
  return { innerWidth };
};

const HeaderTalentTool = ({
  onSearchSkill,
  onSearchJobTitle,
  jobsState = [],
  skillsState = [],
  state,
  dispatch,
  loadingSkills,
  loadingJobs,
  searchTalents,
  setPage,
  jobTitles = [],
}: HeaderTalentToolProps) => {
  const [getOwners, { loading, data: dataOwners }] = useLazyQuery<{
    listOwners: { label: string; count: number }[];
  }>(LIST_OWNERS, {
    context: { clientName: 'api.talents' },
  });
  const [getLanguages, { loading: loadingLanguages, data: dataLanguages }] = useLazyQuery<{
    listLanguages: { label: string }[];
  }>(LIST_LANGUAGES, {
    context: { clientName: 'api.talents' },
  });

  const { t } = useTranslation(['talentTool']);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [fulltext, setFulltext] = useState<string>(state.search.fulltext || '');
  const [allSkills, setAllSkills] = useState<ItemSkills[]>();
  const [allOwners, setAllOwners] = useState<{ label: string; count: number }[] | undefined>([]);
  const [allLanguages, setAllLanguages] = useState<{ label: string }[] | undefined>([]);
  const [allJobs, setAllJobs] = useState<ItemJobs[]>(jobTitles);
  const [searchSkillValue, setSearchSkillValue] = useState<string>('');
  const [searchJobValue, setSearchJobValue] = useState<string>('');
  const [searchOwnerValue, setSearchOwnerValue] = useState<string>('');
  const [searchLanguageValue, setSearchLanguageValue] = useState<string>('');
  const [availability, setAvailability] = useState({
    today: false,
    available30: false,
    available60: false,
  });
  const [remoteWork, setRemoteWork] = useState({
    remote: false,
    onSite: false,
    flexible: false,
  });
  const [bloomerStatus, setBloomerStatus] = useState({
    bloomer: false,
    superBloomer: false,
  });
  const status = state.search.status;
  const [freelance, setFreelance] = useState(status === Status.FreeAndCDI || status === Status.Freelance);
  const [open, setOpen] = useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const getIsFiltered = (state: State) => {
    if (
      (Object.keys(state.search).length > 0 &&
        state?.search?.skills?.skills &&
        state?.search?.skills?.skills?.length > 0) ||
      (state?.search?.jobs?.jobs && state?.search?.jobs?.jobs?.length > 0) ||
      state?.search?.status ||
      state.search?.availabilityDate ||
      state.search?.remoteWork ||
      state.search?.bloomerStatus ||
      state.search?.owners ||
      state.search?.availabilityDate ||
      (state.search?.languages && state.search?.languages?.length > 0)
    ) {
      return true;
    }
    return false;
  };
  const [isFiltered, setIsFiltered] = useState<boolean>(getIsFiltered(state));
  const [cdi, setCdi] = useState(status === Status.FreeAndCDI || status === Status.CDI);
  const [selectedJobsList, setSelectedJobsList] = useState<ItemJobs[]>([]);
  const [selectedOwnersList, setSelectedOwnersList] = useState<{ id: string; label: string }[]>([]);
  const [selectedLanguagesList, setSelectedLanguagesList] = useState<{ id: string; label: string }[]>([]);
  const [selectedSkillsList, setSelectedSkillsList] = useState<ItemSkills[]>([]);

  const resetFilters = () => {
    setFulltext('');
    setCdi(false);
    setFreelance(false);
    setAllSkills(skillsState);
    setAllJobs(jobsState);
    setAvailability({ today: false, available30: false, available60: false });
    dispatch({ type: ActionType.RESET_SEARCH });
    searchTalents();
    setPage(1);
    setSelectedJobsList([]);
    setSelectedSkillsList([]);
    setSelectedOwnersList([]);
    setSelectedLanguagesList([]);
    setSearchOwnerValue('');
  };

  const getNumberOfFilters = (conditions: boolean[]) => {
    return conditions.reduce((count, condition) => (condition ? count + 1 : count), 0);
  };

  const getNBMoreFilters1100 = () => {
    const conditions = [
      availability.today || availability.available30 || availability.available60,
      selectedOwnersList.length > 0,
      selectedLanguagesList.length > 0,
      remoteWork.flexible || remoteWork.onSite || remoteWork.remote,
      bloomerStatus.bloomer || bloomerStatus.superBloomer,
    ];

    return getNumberOfFilters(conditions);
  };

  const getNBMoreFilters1300 = () => {
    const conditions = [
      selectedOwnersList.length > 0,
      selectedLanguagesList.length > 0,
      remoteWork.flexible || remoteWork.onSite || remoteWork.remote,
      bloomerStatus.bloomer || bloomerStatus.superBloomer,
    ];

    return getNumberOfFilters(conditions);
  };

  const getNBMoreFilters1500 = () => {
    const conditions = [
      selectedOwnersList.length > 0,
      selectedLanguagesList.length > 0,
      bloomerStatus.bloomer || bloomerStatus.superBloomer,
    ];

    return getNumberOfFilters(conditions);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFulltext(event.target.value);
    dispatch({ type: ActionType.TEXT_SEARCH, fulltext: event.target.value });
    if (event.target.value.length === 0) {
      setPage(1);
    }
  };

  React.useEffect(() => {
    const filters = getIsFiltered(state);
    if (filters) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [state]);

  const onSearchOwners = (search: string) => {
    if (search) {
      setAllOwners(allOwners?.filter((element) => element.label.toLowerCase().includes(search.toLowerCase())));
    } else {
      setAllOwners(dataOwners?.listOwners);
    }
  };

  const onSearchLanguages = (search: string) => {
    if (search) {
      setAllLanguages(allLanguages?.filter((element) => element.label.toLowerCase().includes(search.toLowerCase())));
    } else {
      setAllLanguages(dataLanguages?.listLanguages);
    }
  };

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const injectedPropsOwner: FilterOwnerProps = {
    searchOwnerValue: searchOwnerValue,
    setSearchOwnerValue: setSearchOwnerValue,
    setPage: setPage,
    state: state,
    onSwitch: dispatch,
    loadingOwners: loading,
    allOwners: allOwners,
    setAllOwners: setAllOwners,
    selectedOwnersList: selectedOwnersList,
    setSelectedOwnersList: setSelectedOwnersList,
    getOwners: getOwners,
    dataOwners: dataOwners,
    onSearchOwners: onSearchOwners,
  };

  const injectedPropsLanguage: FilterLanguagesProps = {
    searchLanguageValue: searchLanguageValue,
    setSearchLanguageValue: setSearchLanguageValue,
    setPage: setPage,
    state: state,
    dispatch: dispatch,
    loadingLanguages: loadingLanguages,
    allLanguages: allLanguages,
    setAllLanguages: setAllLanguages,
    selectedLanguagesList: selectedLanguagesList,
    setSelectedLanguagesList: setSelectedLanguagesList,
    getLanguages: getLanguages,
    dataLanguages: dataLanguages,
    onSearchLanguages: onSearchLanguages,
  };

  const injectedPropsRemote: FilterRemoteProps = {
    setPage: setPage,
    setRemoteWork: setRemoteWork,
    remoteWork: remoteWork,
    onSwitch: dispatch,
    state: state,
  };

  const injectedPropsDisponibility: FilterDisponibilityProps = {
    setPage: setPage,
    setAvailability: setAvailability,
    availability: availability,
    onSwitch: dispatch,
    state: state,
  };

  const injectedPropsBloomer: FilterBloomerProps = {
    setPage: setPage,
    setBloomerStatus: setBloomerStatus,
    bloomerStatus: bloomerStatus,
    onSwitch: dispatch,
    state: state,
  };

  return (
    <div>
      <SearchBar
        placeholder={t('searchByMultipleField', { ns: 'talentTool' })}
        onChange={handleChange}
        searchValue={fulltext}
      />
      <StyledContainerFilter>
        <StyledFilterList>
          <FilterStatus
            setPage={setPage}
            setFreelance={setFreelance}
            setCdi={setCdi}
            cdi={cdi}
            freelance={freelance}
            onSwitch={dispatch}
            state={state}
          />
          <FilterJobTalent
            searchJobValue={searchJobValue}
            setSearchJobValue={setSearchJobValue}
            setPage={setPage}
            onSearchJobTalent={onSearchJobTitle}
            jobs={jobsState}
            state={state}
            dispatch={dispatch}
            loadingJobs={loadingJobs}
            allJobs={allJobs}
            setAllJobs={setAllJobs}
            selectedJobsList={selectedJobsList}
            setSelectedJobsList={setSelectedJobsList}
          />
          <FilterSkill
            searchSkillValue={searchSkillValue}
            setSearchSkillValue={setSearchSkillValue}
            setPage={setPage}
            onSearchSkill={onSearchSkill}
            skills={skillsState}
            state={state}
            dispatch={dispatch}
            loadingSkills={loadingSkills}
            allSkills={allSkills}
            setAllSkills={setAllSkills}
            selectedSkillsList={selectedSkillsList}
            setSelectedSkillsList={setSelectedSkillsList}
          />
          {windowSize.innerWidth > 1100 && <FilterDisponibility {...injectedPropsDisponibility} />}
          {windowSize.innerWidth > 1300 && <FilterRemote {...injectedPropsRemote} />}

          {windowSize.innerWidth < 1100 && (
            <div>
              <FilterTab
                label={'+ de filtres'}
                numberOfFilter={getNBMoreFilters1100()}
                onClick={() => setOpen(!open)}
                filled={false}
              />
              {open && (
                <StyledContainerMoreFilters ref={ref}>
                  <FilterOwner withTab={false} {...injectedPropsOwner} />
                  <FilterLanguages withTab={false} {...injectedPropsLanguage} />
                  <FilterDisponibility {...injectedPropsDisponibility} withTab={false} />
                  <FilterRemote {...injectedPropsRemote} withTab={false} />
                  <FilterBloomer withTab={false} {...injectedPropsBloomer} />
                </StyledContainerMoreFilters>
              )}
            </div>
          )}
          {windowSize.innerWidth < 1300 && windowSize.innerWidth > 1100 && (
            <div>
              <FilterTab
                label={'+ de filtres'}
                numberOfFilter={getNBMoreFilters1300()}
                onClick={() => setOpen(!open)}
                filled={false}
              />
              {open && (
                <StyledContainerMoreFilters ref={ref}>
                  <FilterOwner withTab={false} {...injectedPropsOwner} />
                  <FilterLanguages withTab={false} {...injectedPropsLanguage} />
                  <FilterRemote {...injectedPropsRemote} withTab={false} />
                  <FilterBloomer withTab={false} {...injectedPropsBloomer} />
                </StyledContainerMoreFilters>
              )}
            </div>
          )}
          {windowSize.innerWidth < 1700 && windowSize.innerWidth > 1300 && (
            <div>
              <FilterTab
                label={'+ de filtres'}
                numberOfFilter={getNBMoreFilters1500()}
                onClick={() => setOpen(!open)}
                filled={false}
              />
              {open && (
                <StyledContainerMoreFilters ref={ref}>
                  <FilterOwner withTab={false} {...injectedPropsOwner} />
                  <FilterLanguages withTab={false} {...injectedPropsLanguage} />
                  <FilterBloomer withTab={false} {...injectedPropsBloomer} />
                </StyledContainerMoreFilters>
              )}
            </div>
          )}
        </StyledFilterList>
        {windowSize.innerWidth > 1700 && (
          <StyledFilterList>
            <FilterBloomer {...injectedPropsBloomer} />
            <FilterOwner {...injectedPropsOwner} />
            <FilterLanguages {...injectedPropsLanguage} />
          </StyledFilterList>
        )}
      </StyledContainerFilter>
      {isFiltered && (
        <StyledResetFilters onClick={() => resetFilters()}>
          <Logo image={cross} />
          <StyledResetFiltersText variant="body2SemiBold" text={t('resetFilters', { ns: 'talentTool' })} />
        </StyledResetFilters>
      )}
    </div>
  );
};

export default HeaderTalentTool;
export { HeaderTalentTool };

const StyledContainerFilter = styled('div')(
  () => `
  gap: 12px;
  display: flex;
  flex-flow: row nowrap;
`,
);

const StyledFilterList = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  padding: 16px 0px;
`,
);

const StyledResetFilters = styled('div')(
  () => `
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 175px;
    padding-bottom: 16px;
    &:hover {
      cursor: pointer;
    }
  `,
);

const StyledResetFiltersText = styled(SingleLineText)(
  ({ theme }) => `
    text-decoration: underline;
    color: ${theme.palette.grey[100]}
  `,
);

const StyledContainerMoreFilters = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 20px;
  padding: 10px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 420px;
  position: absolute;
  right: 10px;
  z-index: 1;
`,
);
