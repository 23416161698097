import React from 'react';
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { lastDateOfMonth } from '@bloomays-lib/utils.shared';

import DatePicker from '../atoms/DatePicker';
import { LoaderSkeleton, TitlePart } from '@bloomays-lib/ui.shared';
import MainCard from '../atoms/MainCard';
import { styled } from '@mui/material/styles';

export type OverviewChartProps = {
  isLoading: boolean;
  beginValue: Date;
  setBeginValue: (date: Date) => void;
  endValue: Date;
  setEndValue: (date: Date) => void;
  chartData: { options: any };
};

const GridContainerDate = styled(Grid)(() => ({
  display: 'flex !important',
  flexFlow: 'row wrap',
}));

const ContainerLabelAndDate = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '0.4em',
  justifyContent: 'flex-start',
}));

const ContainerDate = styled('div')(() => ({
  width: '60%',
}));

const OverviewChart = ({
  isLoading,
  beginValue,
  setBeginValue,
  endValue,
  setEndValue,
  chartData,
}: OverviewChartProps) => {
  const theme = useTheme();

  const { primary } = theme.palette.text;
  const grey200 = theme.palette.grey[200];

  const primary200 = theme.palette.secondary.main;
  const primaryDark = theme.palette.secondary.dark;
  const secondaryMain = theme.palette.tertiary.yellow;
  const secondaryLight = theme.palette.secondary.light;
  const grey500 = theme.palette.grey[500];

  React.useEffect(() => {
    const newChartData = {
      ...chartData.options,
      colors: [primary200, primaryDark, secondaryMain, secondaryLight],
      xaxis: {
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      grid: {
        borderColor: grey200,
      },
      tooltip: {
        theme: 'light',
      },
      legend: {
        labels: {
          colors: grey500,
        },
      },
    };

    // do not load chart when loading
    if (!isLoading) {
      ApexCharts.exec(`bar-chart`, 'updateOptions', newChartData);
    }
  }, [primary200, primaryDark, secondaryMain, secondaryLight, primary, grey200, isLoading, grey500, chartData.options]);

  return isLoading ? (
    <LoaderSkeleton label="" height={500} width={800} />
  ) : (
    <MainCard contentSX={{ minHeight: 500 }}>
      <Grid container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <TitlePart textTitle="Vue d'ensemble" />
          </Grid>
          <GridContainerDate>
            <ContainerLabelAndDate>
              <ContainerDate>
                <DatePicker
                  label="Date de début"
                  value={lastDateOfMonth(new Date(beginValue), 1)}
                  handleChange={(date) => {
                    if (date) {
                      setBeginValue(lastDateOfMonth(new Date(date), -1));
                    }
                  }}
                />
              </ContainerDate>
            </ContainerLabelAndDate>
            <ContainerLabelAndDate>
              <ContainerDate>
                <DatePicker
                  label="Date de fin"
                  value={lastDateOfMonth(new Date(endValue))}
                  handleChange={(date) => {
                    if (date) {
                      setEndValue(lastDateOfMonth(new Date(date)));
                    }
                  }}
                />
              </ContainerDate>
            </ContainerLabelAndDate>
          </GridContainerDate>
        </Grid>
        <Grid item xs={12}>
          <Chart {...chartData} />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default OverviewChart;
export { OverviewChart };
