import WithErrorBoundary from '../organisms/ErrorBoundary';
import LoginForm from '../organisms/LoginForm';
import { useAuth } from '../../auth/AuthProvider';
import { Navigate } from 'react-router-dom';

const LoginFormBloomers = (): JSX.Element => {
  const auth = useAuth();
  if (auth && auth.isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <LoginForm />;
};

export default WithErrorBoundary(LoginFormBloomers);
