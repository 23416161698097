import React, { useRef, useState } from 'react';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { Upload } from './Upload';
import { Button } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { UploadFileActivity } from '../../types/components';
import { BrowserUploadFile } from '@bloomays-lib/types.shared';
import { FileRejection } from 'react-dropzone';
export type InvoicesUploadProps = {
  urlInvoicesS3?: string;
  urlAdditionalInvoicesS3?: string;
  responseUploadCRA: {
    status: string;
    idRow: string | null;
  };
  uploadInvoice: UploadFileActivity;
  id?: string | null;
};

const InvoicesUpload = ({
  responseUploadCRA,
  uploadInvoice,
  id,
  urlInvoicesS3,
  urlAdditionalInvoicesS3,
}: InvoicesUploadProps) => {
  const [invoices, setInvoices] = useState<null | {
    urlInvoicesS3?: BrowserUploadFile;
    urlAdditionalInvoicesS3?: BrowserUploadFile;
  }>({
    urlAdditionalInvoicesS3: urlAdditionalInvoicesS3 ? { url: urlAdditionalInvoicesS3 } : undefined,
    urlInvoicesS3: urlInvoicesS3 ? { url: urlInvoicesS3 } : undefined,
  });
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const InvoiceFRef = useRef(null);
  const InvoiceFADRef = useRef(null);
  React.useEffect(() => {
    if (responseUploadCRA.status === 'send') {
      setInvoices(null);
    }
  }, [responseUploadCRA.status]);

  const getDisabledBtn = () => {
    return (
      !invoices ||
      responseUploadCRA.status === 'loading' ||
      responseUploadCRA.status === 'send' ||
      rejectedFiles?.length > 0
    );
  };

  return (
    <Container>
      <H3Title>Uploader ma/mes facture.s</H3Title>
      <StyledContainerText>
        <SingleLineText
          variant={'caption'}
          text="Pour finir le processus de paiement, on va avoir besoin que tu nous envoie ta facture !"
        />
        <SingleLineText variant={'caption'} text="Tu vas pouvoir uploader jusqu'à deux fichiers maximum !" />
      </StyledContainerText>
      <StyledContainerUpload>
        <Upload
          ref={InvoiceFRef}
          id="urlInvoicesS3"
          placeholder="Facture principale"
          textLabel={`Facture principale`}
          name={'urlInvoicesS3'}
          fileTypes={['.pdf']}
          maxFiles={1}
          maxLength={5000000}
          displayIcons={true}
          handleChange={(files, reject) => {
            setRejectedFiles(reject);
            setInvoices((prevState) => ({
              ...prevState,
              urlInvoicesS3: files?.[0],
            }));
          }}
          autoload={true}
          preloadedFiles={invoices?.urlInvoicesS3 ? [invoices?.urlInvoicesS3] : []}
        />
        <Upload
          ref={InvoiceFADRef}
          id="urlAdditionalInvoicesS3"
          placeholder="Facture additonnelle ou frais de déplacement"
          textLabel={`Facture additonnelle ou frais de déplacement`}
          name={'urlAdditionalInvoicesS3'}
          fileTypes={['.pdf']}
          maxFiles={1}
          maxLength={5000000}
          displayIcons={true}
          handleChange={(files, reject) => {
            setRejectedFiles(reject);
            setInvoices((prevState) => ({
              ...prevState,
              urlAdditionalInvoicesS3: files?.[0],
            }));
          }}
          autoload={true}
          preloadedFiles={invoices?.urlAdditionalInvoicesS3 ? [invoices?.urlAdditionalInvoicesS3] : []}
        />
      </StyledContainerUpload>
      <Button
        textButton="Uploader !"
        onClick={() => {
          if (id && invoices) {
            void uploadInvoice(id, invoices);
          }
        }}
        disable={getDisabledBtn()}
      />
    </Container>
  );
};

export default InvoicesUpload;
export { InvoicesUpload };

const Container = styled('div')(() => ({
  margin: 'auto',
  width: '90%',
  display: 'flex',
  textAlign: 'left !important' as CanvasTextAlign,
  flexDirection: 'column',
}));

const StyledContainerText = styled('div')(() => ({
  paddingBottom: '12px',
}));

const StyledContainerUpload = styled('div')(() => ({
  padding: '12px 0',
}));

const H3Title = styled('h3')(({ theme }) => ({
  textAlign: 'left !important' as CanvasTextAlign,
  paddingBottom: '1.5em',
}));
