import Tag from '../atoms/Tag';
import { useTheme } from '@mui/material/styles';
import { MissionType } from '@bloomays-lib/types.shared';

type TagMissionTypeProps = {
  type: MissionType;
};

const TagMissionType = ({ type }: TagMissionTypeProps) => {
  const theme = useTheme();
  return (
    <Tag
      text={type}
      aria-label={type}
      backgroundColor={type === MissionType.IT ? theme.palette.secondary.main : theme.palette.primary.main}
      color={theme.palette.common.white}
    />
  );
};

export default TagMissionType;
