import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

const BlueSpan = styled('span')(() => ({
  fontWeight: 500,
  fontFamily: '"Poppins Medium", sans-serif',
  fontSize: 14,
  lineHeight: '19.6px',
}));

export type SingleLineTextProps = {
  text?: string | string[] | React.ReactNode;
  span?: any;
  onClick?: any;
  id?: string;
  variant?: any;
  sx?: SxProps<Theme>;
  sxSpan?: SxProps<Theme>;
};

const SingleLineText = ({
  text,
  span,
  onClick,
  id,
  variant = 'body1Light',
  sx,
  sxSpan,
}: SingleLineTextProps): JSX.Element => {
  return (
    <Typography id={id} variant={variant} sx={sx} onClick={onClick}>
      {text} <BlueSpan sx={sxSpan}>{span}</BlueSpan>
    </Typography>
  );
};

export default SingleLineText;
export { SingleLineText };
