import React, { FocusEventHandler } from 'react';
import TextField from '@mui/material/TextField';
import { ColorInput } from '@bloomays-lib/ui.shared';

export type InputFieldProps = {
  variant?: string;
  helperText?: string | React.ReactNode;
  color?: ColorInput;
  error?: boolean;
  id?: string;
  handleChange: (e: any) => void;
  name?: string;
  value: string | number;
  type?: string;
  required?: boolean;
  min?: string;
  max?: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  maxLength?: number;
  placeholder?: string;
  readOnly?: boolean;
  minLength?: number;
  pattern?: string;
  fullWidth?: boolean;
  naked?: boolean;
  classname?: string;
  checked?: boolean;
  label?: string;
};

export const InputField = ({
  variant,
  helperText,
  color,
  error,
  id,
  name,
  value,
  type = 'text',
  handleChange,
  required = false,
  min,
  max,
  onBlur,
  maxLength,
  placeholder,
  readOnly,
  minLength,
  pattern,
  fullWidth = true,
  ...props
}: InputFieldProps): JSX.Element => {
  return (
    <TextField
      spellCheck={false}
      helperText={helperText}
      color={color}
      error={error}
      id={id}
      type={type}
      name={name}
      value={value || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
      required={required}
      placeholder={placeholder}
      fullWidth={fullWidth}
      {...props}
      inputProps={{
        min,
        max,
        onBlur,
        minLength,
        maxLength,
        readOnly,
        pattern,
        ...props,
      }}
    />
  );
};
