import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { Logo as RawLogo } from '@bloomays-lib/ui.shared';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import Title from '../atoms/Title';
import WithErrorBoundary from '../organisms/ErrorBoundary';

import Banniere from '../../graphic-charter/banniere.png';
import { Button } from '@bloomays-lib/ui.shared';

const FallbackConnection = (): JSX.Element => {
  const { t } = useTranslation(['random']);
  return (
    <div>
      <ContainerDiv>
        <Title textTitle={t('notReconizeTitle', { ns: 'firstCo' })} />
        <SingleLineText text={t('notReconize', { ns: 'firstCo' })} />
        <ContainerRedirection>
          <Button
            textButton={t('searchJob', { ns: 'random' })}
            onClick={() => window.open('https://www.bloomays.com/opened-jobs', '_blank')}
          />
          <Button
            style={{ marginTop: '10px' }}
            textButton={t('searchTalent', { ns: 'random' })}
            onClick={() => window.open('https://www.bloomays.com/trouvez-votre-talent', '_blank')}
          />
        </ContainerRedirection>
        <Logo image={Banniere} />
      </ContainerDiv>
    </div>
  );
};

export default WithErrorBoundary(FallbackConnection);

const Logo = styled(RawLogo)(() => ({
  paddingTop: '2em',
  width: '100%',
}));

const ContainerDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: theme.palette.paper.white,
  boxShadow: `2px 2px 20px ${theme.palette.grey[300]}`,
  borderRadius: '10px',
  width: '45%',
  margin: '1em auto',
  padding: '1em',
  [theme.breakpoints.down(915)]: {
    width: '95%',
  },
}));

const ContainerRedirection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: '3em',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-betwen',
    alignItems: 'center',
    marginTop: '3em',
  },
}));
