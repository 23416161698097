import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SwitchItem from '../atoms/SwitchItem';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import FilterTab from '../atoms/FilterTab';
import { Action, ActionType, State } from '../../types/talents';

export type FilterDisponibilityProps = {
  onSwitch: React.Dispatch<Action>;
  withTab?: boolean;
  state: State;
  availability: { today: boolean; available30: boolean; available60: boolean };
  setAvailability: React.Dispatch<
    React.SetStateAction<{
      today: boolean;
      available30: boolean;
      available60: boolean;
    }>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const FilterDisponibility = ({
  onSwitch,
  state,
  availability,
  withTab = true,
  setAvailability,
  setPage,
}: FilterDisponibilityProps) => {
  const availabilityDate = state.search.availabilityDate;
  const [open, setOpen] = useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, availabilityDate]);

  const handleSwitchToday = (checked: boolean, availabilityValue: Date) => {
    setAvailability({ ...availability, today: checked });

    if (checked) {
      setAvailability((prevState) => {
        return { ...prevState, available30: false, available60: false };
      });
    }

    onSwitch({
      type: ActionType.AVAILABILITY_SEARCH,
      availabilityDate: checked ? availabilityValue : null,
    });
    setPage(1);
  };

  const handleSwitch30 = (checked: boolean, availabilityValue: Date) => {
    setAvailability({ ...availability, available30: checked });

    if (checked) {
      setAvailability((prevState) => {
        return { ...prevState, today: false, available60: false };
      });
    }

    onSwitch({
      type: ActionType.AVAILABILITY_SEARCH,
      availabilityDate: checked ? availabilityValue : null,
    });
    setPage(1);
  };

  const handleSwitch60 = (checked: boolean, availabilityValue: Date) => {
    setAvailability({ ...availability, available60: checked });

    if (checked) {
      setAvailability((prevState) => {
        return { ...prevState, available30: false, today: false };
      });
    }

    onSwitch({
      type: ActionType.AVAILABILITY_SEARCH,
      availabilityDate: checked ? availabilityValue : null,
    });
    setPage(1);
  };

  const getNumberFilter = () => {
    let numb = 0;
    if (availability.today) {
      numb = numb + 1;
    }
    if (availability.available30) {
      numb = numb + 1;
    }
    if (availability.available60) {
      numb = numb + 1;
    }
    if (availabilityDate) {
      numb = 1;
    }
    return numb;
  };

  const StyledContainer = styled('div')(
    ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 20px;
  padding: 24px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 399px;
  position: ${withTab ? 'absolute' : 'none'};
  z-index: ${withTab ? 1 : 0};
`,
  );

  const actualDate = new Date(new Date().setHours(0, 0, 0));
  return (
    <div>
      {withTab ? (
        <>
          <FilterTab
            label={'Disponibilité'}
            numberOfFilter={getNumberFilter()}
            onClick={() => setOpen(!open)}
            filled={false}
          />
          {open && (
            <StyledContainer ref={ref}>
              <StyledText text="Disponibilité" variant={'subtitle2Medium'} />
              <StyledList>
                <SwitchItem
                  checked={availability.today}
                  label="Disponibile aujourd'hui"
                  onSwitch={() => handleSwitchToday(!availability.today, actualDate)}
                />
                <SwitchItem
                  checked={availability.available30}
                  label="Disponible dans 30 jours"
                  onSwitch={() =>
                    handleSwitch30(!availability.available30, new Date(actualDate.setDate(actualDate.getDate() + 30)))
                  }
                />
                <SwitchItem
                  checked={availability.available60}
                  label="Disponible dans 60 jours"
                  onSwitch={() =>
                    handleSwitch60(!availability.available60, new Date(actualDate.setDate(actualDate.getDate() + 60)))
                  }
                />
              </StyledList>
            </StyledContainer>
          )}
        </>
      ) : (
        <StyledContainer ref={ref}>
          <StyledText text="Disponibilité" variant={'subtitle2Medium'} />
          <StyledList>
            <SwitchItem
              checked={availability.today}
              label="Disponibile à partir aujourd'hui"
              onSwitch={() => handleSwitchToday(!availability.today, actualDate)}
            />
            <SwitchItem
              checked={availability.available30}
              label="Disponible dans 30 jours"
              onSwitch={() =>
                handleSwitch30(!availability.available30, new Date(actualDate.setDate(actualDate.getDate() + 30)))
              }
            />
            <SwitchItem
              checked={availability.available60}
              label="Disponible dans 60 jours"
              onSwitch={() =>
                handleSwitch60(!availability.available60, new Date(actualDate.setDate(actualDate.getDate() + 60)))
              }
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterDisponibility;
export { FilterDisponibility };

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
