import { User } from '@auth0/auth0-react';
import React, { createContext, useContext, useEffect } from 'react';
import { Auth0UserType } from '@bloomays-lib/types.shared';
import { UserFactory } from './authService';
import { Logger } from '../services/serviceLogger';
const logger = Logger('AuthProvider');

export const AuthContext = createContext<Auth0UserType | null>(null);

export const AuthProvider = (props: {
  children: React.ReactNode;
  location: string;
  userFactory?: any;
  user0: User | undefined;
  isAuthenticated0: boolean;
  isLoading0: boolean;
  error0: Error | undefined;
  logout0: (e: { returnTo: string }) => void;
  loginWithRedirect0: (e: { appState: { target: string } }) => void;
  getAccessTokenSilently0?: () => Promise<string>;
}): JSX.Element => {
  const [auth0UserType, setAuth0UserType] = React.useState<Auth0UserType | null>(null);
  useEffect(() => {
    (async () => {
      const factoryFn = props.userFactory || UserFactory;
      const result = factoryFn({
        ...props,
      }) as Auth0UserType;
      logger.debug('AuthProvider', result);
      if (!result.isAuthenticated) {
        setAuth0UserType(result);
        return;
      }
      // sync token
      if (result.user && result.user.token) {
        setAuth0UserType(result);
        return;
      }
      // async token
      if (result.getAccessTokenSilently && result.user && !result.user.token) {
        result.user.token = await result.getAccessTokenSilently();
        setAuth0UserType(result);
        return;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return <AuthContext.Provider value={auth0UserType}>{props.children}</AuthContext.Provider>;
};

export const useAuth = (): Auth0UserType | null => {
  return useContext(AuthContext);
};
