import { FocusEventHandler, ReactNode, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme } from '@mui/material/styles';
import { ColorInput, VariantInput } from '@bloomays-lib/ui.shared';

export type Option = {
  label: string | ReactNode;
  value: string | number;
  disabled?: boolean;
};

export type SelectFieldProps = {
  id?: string;
  value: string | number | string[] | undefined | undefined[] | readonly string[];
  name: string;
  handlechange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  optionValue?: string[];
  options?: Option[];
  required?: boolean;
  classname?: string;
  defaultOption?: boolean;
  multiple?: boolean;
  label?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  'aria-label'?: string;
  variant?: VariantInput;
  color?: ColorInput;
  errored?: boolean;
  hidden?: boolean;
  size?: 'medium' | 'small';
};

export const SelectField = ({
  id,
  value,
  handlechange,
  optionValue,
  options,
  name,
  required = false,
  onBlur,
  label,
  defaultOption = false,
  multiple,
  'aria-label': ariaLabel,
  variant,
  color,
  errored,
  size,
  hidden,
  ...props
}: SelectFieldProps): JSX.Element => {
  const [error, setError] = useState<boolean>(errored === true || (required && !value) || (required && value === ''));
  useEffect(() => {
    if (errored !== undefined) {
      setError(errored);
    }
  }, [errored]);
  return (
    <FormControl
      fullWidth
      sx={{
        marginTop: '5px',
        marginBottom: '20px',
      }}
    >
      {hidden ? null : (
        <>
          <InputLabel
            sx={(theme) => ({
              color: `${theme.palette.grey[800]}`,
              '&.Mui-focused': {
                color: theme.palette.secondary.dark,
              },
              '& input:valid:focus + fieldset': {
                borderColor: theme.palette.secondary.dark,
                borderWidth: '2px',
              },
            })}
            id={`${label}-select-label`}
          >
            {required && label ? `${label} *` : label}
          </InputLabel>
          <StyledSelect
            error={error}
            variant={variant}
            color={color}
            id={id}
            label={required && label ? `${label} *` : label}
            required={required}
            multiple={multiple}
            name={name}
            value={value}
            size={size}
            onChange={(event: SelectChangeEvent<any>, child: ReactNode) => {
              const value = (event.target as HTMLInputElement).value;
              if (required && !value) {
                setError(true);
              } else {
                setError(false);
              }
              return handlechange(event, child);
            }}
            onBlur={onBlur}
            aria-label={ariaLabel}
            sx={(theme: Theme) => ({
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.secondary.dark}`,
              },
            })}
            {...props}
          >
            {defaultOption && (
              <MenuItem key="" value="">
                -Choisir une option-
              </MenuItem>
            )}
            {optionValue &&
              optionValue.map((option, i) => (
                <StyledMenuItem key={i} value={option}>
                  {option}
                </StyledMenuItem>
              ))}
            {options &&
              options.map((option: Option, i: number) => (
                <StyledMenuItem key={i} value={option.value} disabled={option.disabled === true}>
                  {option.label}
                </StyledMenuItem>
              ))}
          </StyledSelect>
        </>
      )}
    </FormControl>
  );
};

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.grey[500],
  backgroundColor: theme.palette.paper.white,
  '& input:valid + fieldset': {
    borderColor: 'green',
    borderWidth: 2,
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 2,
  },
  '& input:valid:focus + fieldset': {
    borderLeftWidth: 6,
    padding: '4px !important', // override inline-style
  },
}));

const StyledMenuItem = styled(MenuItem)(
  () => `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  `,
);
