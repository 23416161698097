export class BloomaysCustomError extends Error {
  args;
  constructor(message: string, args: Record<any, any>) {
    super(message);
    this.name = 'BloomaysCustomError';
    this.args = args;

    // Ensure the error prototype chain works correctly
    Object.setPrototypeOf(this, BloomaysCustomError.prototype);
  }
}

export const getErrorStatusCode = (err: any): number => {
  const statusCode =
    err.status ||
    err.statusCode ||
    err?.extensions?.status ||
    err?.extensions?.exception?.status ||
    err?.networkError?.statusCode;
  return statusCode ? parseInt(statusCode, 10) : 500;
};
