import { EU_VAT_REGEX_STRING } from '@bloomays-lib/types.shared';
import { FR_SIRET_REGEX_STRING } from '@bloomays-lib/types.shared';
import { TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';

export type SocietyRegistrationNumberTextFieldMode = 'siret' | 'european' | 'foreign';

export type SocietyRegistrationNumberTextFieldProps = {
  name?: string;
  required?: boolean;
  value: string;
  onChange?: (e: { target: { value: string; [key: string]: any } }) => void;
  id?: string;
  hidden?: boolean;
  formElement?: boolean;
  fieldMode: SocietyRegistrationNumberTextFieldMode;
};

const SocietyRegistrationNumberTextField = (props: SocietyRegistrationNumberTextFieldProps): JSX.Element => {
  let label = "Siret ou numéro d'Entreprise Individuelle";
  let placeholder = '14 chiffres';
  let pattern = FR_SIRET_REGEX_STRING;
  switch (props.fieldMode) {
    case 'european':
      label = 'Numéro de TVA intracommunautaire';
      placeholder = "Prefix Pays 2 LETTRES+ Numéro d'identification";
      pattern = EU_VAT_REGEX_STRING;
      break;
    case 'foreign':
      label = "Numéro d'entreprise étrangère (DUNS, etc.)";
      placeholder = 'XXXXXX';
      pattern = '(\\d||\\w)+';
      break;
  }
  return (
    <ValidationTextField
      label={label}
      hidden={props.hidden}
      type={TypeInput.text}
      placeholder={placeholder}
      pattern={pattern}
      formElement={props.formElement}
      {...props}
    />
  );
};

export default SocietyRegistrationNumberTextField;
export { SocietyRegistrationNumberTextField };
