import { NotificationCenterItem } from '@bloomays-lib/ui.shared';

export type Action =
  | {
      type: 'READ_ALL' | 'DELETE_ALL';
    }
  | {
      type: 'FETCH';
      notifications: NotificationCenterItem[];
    }
  | {
      type: 'READ' | 'DELETE' | 'ADD';
      notification: NotificationCenterItem;
    };

export const reducerNotificationCenter = (prevState: NotificationCenterItem[], action: Action) => {
  switch (action.type) {
    case 'READ':
      return prevState.map((prevNotif) => {
        if (prevNotif.id === action.notification.id) {
          return { ...prevNotif, read: true };
        } else {
          return prevNotif;
        }
      });
    case 'READ_ALL':
      return prevState.map((prevNotif) => {
        return { ...prevNotif, read: true };
      });
    case 'DELETE_ALL':
      return [];
    case 'FETCH':
      return [...action.notifications];
    default:
      throw new Error(`Unknown action ${action.type} in reducerNotificationCenter`);
  }
};
