import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton } from '@bloomays-lib/ui.shared';

export type InformationTipProps = {
  content: string;
};

const InformationTip = ({ content }: InformationTipProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick} color="secondary" variant='contained' role="tooltip">
        <StyledIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant="body1Regular" sx={{ p: 1 }}>
          {content}
        </Typography>
      </Popover>
    </div>
  );
};

export default InformationTip;
export { InformationTip };

const StyledIcon = styled(HelpOutlineIcon)(
  ({ theme }) => `
    width:20px;
    height:20px;
    `,
);
