import React from 'react';
import { Id, toast } from 'react-toastify';
import { Toast } from '../components/molecules/Toast';
import { NotificationCenterItemData } from '@bloomays-lib/types.shared';

type StatusTypeOptions = 'info' | 'success' | 'warning' | 'error';

export const notify = (
  status: StatusTypeOptions,
  text: string | React.ReactNode,
  error?: any,
  options?: {
    hideProgressBar: boolean;
    autoClose?: number | false;
    toastId?: string;
    closeOnClick?: boolean;
    pauseOnHover?: boolean;
  },
  link?: string,
) => {
  const component = <Toast text={text} link={link} error={error} />;
  const tToast = toast[status]<NotificationCenterItemData>(component, {
    autoClose: 2000,
    pauseOnHover: true,
    toastId: Date.now().toString(),
    ...options,
    data: {
      link,
    },
  });
  return tToast;
};

export const cancel = (toastId?: string | number) => {
  toast.clearWaitingQueue();
  return toast.dismiss(toastId);
};

export const done = (toastId: Id) => {
  return toast.done(toastId);
};
