import { styled } from '@mui/material/styles';
import RawTextareaAutosize from '@mui/material/TextareaAutosize';
import { SxProps } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

const TextareaAutosize = styled(RawTextareaAutosize)(({ theme }) => ({
  borderColor: theme.palette.grey[400],
  borderRadius: '5px',
  height: '2.5em',
  width: '98%',
  paddingLeft: '10px',
  minHeight: '100px',
  padding: '0.5em',
  fontFamily: '"Poppins Medium", sans-serif',
}));

export type TextAreaProps = {
  handleChange: any;
  name: string;
  value: string;
  minRows?: number;
  maxRows?: number;
  required: boolean;
  placeholder?: string;
  sx?: SxProps<Theme>;
  maxLength?: number;
  readOnly?: boolean;
};

const TextArea = ({
  name,
  minRows = 1,
  maxRows = 10,
  value,
  handleChange,
  required,
  placeholder = 'Type something',
  sx,
  maxLength = 2000,
  readOnly = false,
}: TextAreaProps): JSX.Element => {
  return (
    <TextareaAutosize
      name={name}
      minRows={minRows}
      maxRows={maxRows}
      sx={sx}
      value={value}
      onChange={handleChange}
      required={required}
      placeholder={placeholder}
      maxLength={maxLength}
      readOnly={readOnly}
    />
  );
};

export default TextArea;
export { TextArea };
