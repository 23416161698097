import Backdrop from '@mui/material/Backdrop';
import SingleLineText from '../atoms/SingleLineText';
import LoadingLogo from '../atoms/LoadingLogo';

export type PendingProps = {
  text: string;
  rotate?: boolean;
  open?: boolean;
};

export const LoaderPending = ({ text, rotate = true, open = true }: PendingProps) => {
  return (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200, userSelect: 'none' }}>
      <>
        <LoadingLogo rotate={rotate} />
        <SingleLineText text={text} sx={{ marginLeft: '10px' }} variant="body2SemiBold" />
      </>
    </Backdrop>
  );
};
