import { SyntheticEvent } from 'react';
import bloomer from '../../assets/bloomer.png';

export type LogoProps = {
  image?: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
};

const Logo = (props: LogoProps): JSX.Element => {
  const { image } = props;
  const addDefaultSrc = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
    ev.currentTarget.src = bloomer;
  };

  return <img onError={addDefaultSrc} alt={props?.alt} src={image} {...props} />;
};

export default Logo;
export { Logo };
