/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import FilterTab from '../atoms/FilterTab';
import { useTranslation } from 'react-i18next';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import MultipleSelectSearch from './MultipleSelectSearch';
import { Action, ActionType, State } from '../../types/talents';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';

export type FilterOwnerProps = {
  withTab?: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  state: State;
  onSearchOwners: (e: string) => void;
  onSwitch: React.Dispatch<Action>;
  allOwners: { label: string; count: number; selected?: boolean }[] | undefined;
  setAllOwners: React.Dispatch<
    React.SetStateAction<{ label: string; count: number; selected?: boolean }[] | undefined>
  >;
  loadingOwners: boolean;
  searchOwnerValue: string;
  setSearchOwnerValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectedOwnersList: React.Dispatch<React.SetStateAction<{ id: string; label: string; count?: number }[]>>;
  selectedOwnersList: { label: string; count?: number; id: string }[];
  getOwners: LazyQueryExecFunction<
    {
      listOwners: {
        label: string;
        count: number;
      }[];
    },
    OperationVariables
  >;
  dataOwners?: { listOwners: { label: string; count: number }[] };
};

const FilterOwner = ({
  onSwitch,
  state,
  loadingOwners,
  allOwners,
  setAllOwners,
  setPage,
  searchOwnerValue,
  setSearchOwnerValue,
  setSelectedOwnersList,
  selectedOwnersList,
  getOwners,
  dataOwners,
  onSearchOwners,
  withTab = true,
}: FilterOwnerProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('talentTool');
  const globalStateOwners = state.search.owners;

  React.useEffect(() => {
    if (dataOwners?.listOwners && dataOwners?.listOwners?.length > 0) {
      setAllOwners(
        dataOwners?.listOwners?.map((owner) => {
          return {
            ...owner,
            selected: globalStateOwners ? globalStateOwners?.some((GOwner) => owner.label === GOwner) : false,
          };
        }),
      );
    }
  }, [dataOwners?.listOwners, globalStateOwners]);

  const updateState = (e: any) => {
    let isSelected: boolean;
    setAllOwners(
      allOwners?.map((owner) => {
        if (owner.label === e.label) {
          isSelected = !owner?.selected;
          if (!isSelected) {
            onSwitch({
              type: ActionType.RM_OWNERS_SEARCH,
              owners: [e.label],
            });
            return { ...owner, selected: isSelected };
          } else {
            onSwitch({
              type: ActionType.ADD_OWNERS_SEARCH,
              owners: [e.label],
            });
            return { ...owner, selected: isSelected };
          }
        } else {
          return owner;
        }
      }),
    );
    setPage(1);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, globalStateOwners]);

  React.useEffect(() => {
    if (open || !withTab) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getOwners();
    }
  }, [open]);

  React.useEffect(() => {
    if (globalStateOwners) {
      setSelectedOwnersList(
        globalStateOwners.map((owner) => {
          return { label: owner, id: owner };
        }),
      );
    }
  }, [globalStateOwners, setSelectedOwnersList]);

  const StyledContainer = styled('div')(
    ({ theme }) => `
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border-radius: 20px;
    padding: 24px 24px 32px 24px;
    background-color: ${theme.palette.paper.white};
    gap: 10px;
    box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
    width: 399px;
    max-height: 323px;
    position: ${withTab ? 'absolute' : 'none'};
    z-index: ${withTab ? 1 : 0};
    overflow-X: auto;
  `,
  );

  return (
    <div>
      {withTab ? (
        <>
          <FilterTab
            label={t('owner')}
            numberOfFilter={globalStateOwners?.length}
            onClick={() => {
              setOpen(!open);
            }}
            filled={false}
          />
          {open && (
            <StyledContainer ref={ref}>
              <StyledText text={t('owner')} variant={'subtitle2Medium'} />
              <StyledList>
                <MultipleSelectSearch
                  onSearch={onSearchOwners}
                  setSearch={setSearchOwnerValue}
                  search={searchOwnerValue}
                  loading={loadingOwners}
                  onSwitch={updateState}
                  items={allOwners?.map((owner) => {
                    return { ...owner, id: owner.label };
                  })}
                  selectedItemsList={selectedOwnersList}
                  setSelectedItemsList={setSelectedOwnersList}
                  withLevel={false}
                />
              </StyledList>
            </StyledContainer>
          )}
        </>
      ) : (
        <StyledContainer ref={ref}>
          <StyledText text={t('owner')} variant={'subtitle2Medium'} />
          <StyledList>
            <MultipleSelectSearch
              onSearch={onSearchOwners}
              setSearch={setSearchOwnerValue}
              search={searchOwnerValue}
              loading={loadingOwners}
              onSwitch={updateState}
              items={allOwners?.map((owner) => {
                return { ...owner, id: owner.label };
              })}
              selectedItemsList={selectedOwnersList}
              setSelectedItemsList={setSelectedOwnersList}
              withLevel={false}
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterOwner;
export { FilterOwner };

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
