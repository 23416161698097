import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';
import { notify } from '../../helpers/toastify';

import { GET_ELECTRONICSIGN_LINK } from '@bloomays-lib/adapter.api-bloomer';

import Iframe from '../atoms/Iframe';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import FallbackError from '../organisms/FallbackError';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { errorLogger, getApolloErrorCode } from '../../helpers/error';
import { getErrorStatusCode } from '@bloomays-lib/utils.shared';
import { Logger } from '../../services/serviceLogger';
const logger = Logger('ElectornicSign');

const ElectronicSign = (): JSX.Element => {
  const [result, setResult] = useState<string>();
  const { externalDocumentId, signerId } = useParams<{
    externalDocumentId: string;
    signerId: string;
  }>();

  if (!externalDocumentId) {
    throw new Error('Missing externalDocumentId params');
  }

  if (!signerId) {
    throw new Error('Missing signerId params');
  }
  const { t } = useTranslation(['notify', 'random']);

  const [getElectronicSignLink, { loading, error }] = useMutation<
    { getElectronicSignLink: string },
    { externalDocumentId: string; signerId: string }
  >(GET_ELECTRONICSIGN_LINK);

  React.useEffect(() => {
    const getLink = async () => {
      try {
        const getEmbedLink = await getElectronicSignLink({
          variables: {
            externalDocumentId: externalDocumentId,
            signerId: signerId,
          },
        });
        setResult(getEmbedLink?.data?.getElectronicSignLink);
      } catch (err: any) {
        const statusCode = getErrorStatusCode(err);
        if (statusCode >= 500) {
          logger.error(err);
        } else {
          logger.warn(err);
        }
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getLink();
  }, [externalDocumentId, signerId, getElectronicSignLink]);

  React.useEffect(() => {
    if (error) {
      const code = getApolloErrorCode(error);
      switch (code) {
        case 'TOKEN_EXPIRED':
          notify('warning', t('expiredLink'));
          break;
        case 'OBJECT_NOT_FOUND':
          notify('warning', t('invalidInviteLink'));
          break;
        default:
          errorLogger(error);
          notify('error', t('linkError'), error);
      }
    }
  }, [error, t]);

  const code = getApolloErrorCode(error);
  if (code === 'OBJECT_NOT_FOUND') {
    return <FallbackError errorText={t('invalidDoc')} />;
  }

  if (loading) return <LoaderSkeleton label={t('loadingText', { ns: 'random' })} height={600} width={800} />;

  if (!result) {
    return <LoaderSkeleton label={t('linkError')} height={600} width={800} />;
  } else {
    return <Iframe url={result} />;
  }
};

export default WithErrorBoundary(ElectronicSign);
