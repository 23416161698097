import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Accept, FileError, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { lookup } from 'mime-types';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, SingleLineText, CardItem } from '@bloomays-lib/ui.shared';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

export const FILE_INVALID_TYPE = 'file-invalid-type';
export const FILE_TOO_LARGE = 'file-too-large';
export const FILE_TOO_SMALL = 'file-too-small';
export const TOO_MANY_FILES = 'too-many-files';

const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`);
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

export type DropzoneProps = {
  id: string;
  fileTypes: string[];
  onUpload: (id: string, acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  isLoading: boolean;
  title?: string;
  placeholder?: string;
  maxFiles: number;
  maxLength: number;
  autoload: boolean;
  noDrag?: boolean;
};

const Dropzone = forwardRef(
  (
    {
      id,
      onUpload,
      isLoading,
      fileTypes,
      title,
      maxFiles = 1,
      maxLength = 20000000,
      placeholder,
      autoload,
      noDrag = false,
    }: DropzoneProps,
    ref: ForwardedRef<{ open: () => void }>,
  ) => {
    const theme = useTheme();
    const { t } = useTranslation('random');

    const baseStyle = {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      borderWidth: 4,
      borderRadius: 20,
      borderColor: theme.palette.grey[300],
      borderStyle: 'dashed',
      backgroundColor: theme.palette.grey[200],
      outline: 'none',
      transition: 'border .24s ease-in-out',
      cursor: 'pointer',
    };

    const activeStyle = {
      borderColor: theme.palette.secondary.dark,
    };

    const acceptStyle = {
      borderColor: theme.palette.tertiary.greenMedium,
    };

    const rejectStyle = {
      borderColor: theme.palette.tertiary.red,
    };
    const onDrop = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
      if (autoload && onUpload) {
        onUpload(id, acceptedFiles, i18n(fileRejections));
      }
    };

    const i18n = (fileRejections: FileRejection[]) => {
      return fileRejections.map((fileRejection: FileRejection) => {
        fileRejection.errors = fileRejection.errors.map((e: { code: string; message: string }) => {
          switch (e.code) {
            case FILE_INVALID_TYPE:
              e.message = `le type de fichier doit être ${fileTypes.join(', ')}`;
              break;
            case FILE_TOO_LARGE:
              e.message = `Le fichier ne doit pas dépasser ${bytesToSize(maxLength)}`;
              break;
            case FILE_TOO_SMALL:
              e.message = `Le fichier doit pas dépasser un poids de ${bytesToSize(maxLength)}`;
              break;

            case TOO_MANY_FILES:
              e.message = `Maximum ${maxFiles} fichier${maxFiles > 1 ? 's' : ''}`;
              break;

            default:
              break;
          }
          return e;
        });
        return fileRejection;
      });
    };

    const accept: Accept = {};
    fileTypes.forEach((fileType) => {
      const mimeType = lookup(fileType);
      if (!mimeType) {
        throw new Error(`Unknown mime type ${fileType}`);
      }
      if (accept[mimeType]) {
        accept[mimeType].push(fileType);
      } else {
        accept[mimeType] = [fileType];
      }
    });

    const {
      acceptedFiles,
      getRootProps,
      getInputProps,
      fileRejections,
      isFocused,
      isDragAccept,
      isDragReject,
      open: openDZ,
    } = useDropzone({
      accept: accept,
      maxFiles,
      disabled: isLoading,
      maxSize: maxLength,
      onDrop,
      noDrag,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(ref, () => ({
      open() {
        return openDZ();
      },
    }));

    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
      <li key={file.path}>
        <SingleLineText text={`${file.path} - `} span={`${file.size} bytes`} />
      </li>
    ));

    const fileRejectionItems = i18n(fileRejections).map(
      ({ file, errors }: { file: FileWithPath; errors: FileError[] }) => (
        <li key={file.path}>
          <SingleLineText text={`${file.path} - `} span={`${file.size} bytes`} />
          <ul>
            {errors.map(
              (e: {
                code: React.Key | null | undefined;
                message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
              }) => (
                <li key={e.code}>{e.message}</li>
              ),
            )}
          </ul>
        </li>
      ),
    );

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isFocused, isDragReject, isDragAccept],
    );

    return (
      <div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <StyledContainerTextEmoji>
            <CardItem variant={'blue'} size={'small'} rounded={true} emoji={<UploadFileIcon />} />
            <div>
              <SingleLineText text={t('textDropzone')} />
              <SingleLineText text={`${fileTypes.join(', ')} (max ${bytesToSize(maxLength)})`} variant={'body1Light'} />
            </div>
          </StyledContainerTextEmoji>
        </div>

        <div className="containerUploadValidator">
          {acceptedFileItems.length > 0 && (
            <aside>
              <h4>Fichier ajouté :</h4>
              <ul>{acceptedFileItems}</ul>
            </aside>
          )}
          {fileRejectionItems.length > 0 && (
            <aside>
              <h4>Fichier rejeté : </h4>
              <ul className="red">{fileRejectionItems}</ul>
            </aside>
          )}
          {!autoload && (
            <Button
              textButton={title || ''}
              onClick={() => onUpload(id, acceptedFiles, fileRejections || [])}
              disable={isLoading}
              title={title}
            />
          )}
        </div>
      </div>
    );
  },
);

export default Dropzone;

export { Dropzone };

const StyledContainerTextEmoji = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  `,
);
