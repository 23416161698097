import React from 'react';
import { styled } from '@mui/material/styles';
import { SingleLineText, TextArea } from '@bloomays-lib/ui.shared';
import NoteSystem from '../molecules/NoteSystem';
import { Button } from '@bloomays-lib/ui.shared';
import { HrefLink } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';

export type NPSMissionProps = {
  noteBloomer?: string;
  commentBloomer?: string;
  selected?: number;
  setSelected: (e: number) => void;
  comment?: string;
  setComment: (value: React.SetStateAction<string | undefined>) => void;
  onClick: (args: { selected?: number; comment?: string }) => Promise<void>;
  texts: {
    title: string;
    placeholder: string;
    button: string;
    details: string;
    nameLink: string;
    url: string;
    btnTitle: string;
    feedbackBloomerTitle?: string;
  };
  stateNPS: { loading: boolean };
};

const NPSMission = ({
  selected,
  setSelected,
  comment,
  setComment,
  texts,
  onClick,
  stateNPS,
  noteBloomer,
  commentBloomer,
}: NPSMissionProps) => {
  const { t } = useTranslation('NPSMission');

  return (
    <div>
      <SingleLineText
        sx={{
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'flex-start',
        }}
        variant={'subtitle2Medium'}
        text={texts.title}
      />
      {noteBloomer ? (
        <StyledSharedInfo>
          <BloomHelpSingleLineText
            sx={(theme) => ({
              color: theme.palette.grey[800],
              paddingRight: '10px',
            })}
            text={t('infoNotSharedToBloomer')}
          />
        </StyledSharedInfo>
      ) : (
        <StyledSharedInfo>
          <SingleLineText
            sx={(theme) => ({
              color: theme.palette.grey[800],
              paddingRight: '10px',
            })}
            text={t('infoSharedToClient')}
          />
        </StyledSharedInfo>
      )}

      <NoteSystem selected={selected} setSelected={setSelected} />
      <StyledTextArea
        handleChange={setComment}
        name={'NPSMission'}
        value={comment || ''}
        required={false}
        placeholder={texts.placeholder}
      />
      <ContainerBloomerSign>
        <ContainerTextHelp>
          <SingleLineText
            sx={(theme) => ({
              paddingRight: '4px',
              color: theme.palette.grey[800],
            })}
            text={texts.details}
          />
          <BloomHelpHref href={texts.url} text={texts.nameLink} />
        </ContainerTextHelp>
        {noteBloomer && (
          <NoteBloomerContainer>
            <NoteBloomerDescription>{texts.feedbackBloomerTitle}</NoteBloomerDescription>
            <StyledNPSBloomer>
              <SingleLineText
                sx={(theme) => {
                  return {
                    color: theme.palette.paper.white,
                    backgroundColor: theme.palette.secondary.dark,
                    borderRadius: 50,
                    width: '45px',
                    height: '45px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                      color: theme.palette.grey[100],
                      cursor: 'pointer',
                    },
                  };
                }}
                text={noteBloomer}
                variant={'subtitle2Medium'}
              />
              <StyledCNPSBloomerComment>
                <SingleLineText text={commentBloomer ? commentBloomer : t('noComment')} />
              </StyledCNPSBloomerComment>
            </StyledNPSBloomer>
          </NoteBloomerContainer>
        )}

        <Button
          title={texts.btnTitle}
          disable={(!selected && selected !== 0) || stateNPS.loading}
          textButton={stateNPS.loading ? 'Envoi en cours' : texts.button}
          onClick={async () => await onClick({ selected: selected, comment })}
        />
      </ContainerBloomerSign>
    </div>
  );
};

export default NPSMission;
export { NPSMission };

const NoteBloomerContainer = styled('div')(
  () => `
    display: flex;
    flex-direction: column;
  `,
);

const NoteBloomerDescription = styled('span')(
  () => `
  width: 100%;
  text-align: left;
  font-size: small;
  `,
);

const StyledCNPSBloomerComment = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  background-color: ${theme.palette.grey[200]};
  border-radius: 10px;
  width: 90%;
  margin-left: 12px;
  `,
);

const StyledNPSBloomer = styled('div')(
  () => `
    display: flex;
    flex-direction: row nowrap;
    align-items: center;
    margin-bottom: 20px;
  `,
);

const StyledSharedInfo = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 24px;
  `,
);

const BloomHelpSingleLineText = styled(SingleLineText)(({ theme }) => ({
  color: theme.palette.grey[800],
  paddingRight: '10px',
}));

const StyledTextArea = styled(TextArea)(() => ({
  marginTop: '24px',
  borderRadius: '5px',
  width: '95%',
  minHeight: '50px',
  padding: '16px 12px',
}));

const ContainerBloomerSign = styled('div')(() => ({
  textAlign: 'right',
  paddingTop: '24px',
}));

const ContainerTextHelp = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-end',
  paddingBottom: '20px',
}));

const BloomHelpHref = styled(HrefLink)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textDecoration: 'none',
  paddingBottom: '24px',
}));
