import { BrowserBloomer, IBloomer } from './types.shared';
import type { BaseContext } from '@apollo/server';
import type { Request } from 'express';

export type AuthInfos = {
  user: IUser;
  token?: string;
};

export type AuthRequest = Request & {
  user: AuthInfos;
};

export type AuthContext = BaseContext & {
  req: AuthRequest;
};

export enum Role {
  Admin = 'Admin',
  Anonymous = 'Anonymous',
  Bloomer = 'Bloomer',
  System = 'System',
}

export type Auth0User = {
  email: string;
  id: string;
  picture?: string;
  name?: string;
  provider?: string;
  roles?: Role[];
  displayName: string;
  'https://www.bloomays.com/roles': Role[];
  token?: string;
  email_verified: boolean;
  sub?: {
    exp?: number;
    iat?: number;
    statebag?: Record<string, unknown>;
  };
};

export type Auth0UserType = {
  user: Auth0User | null | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  error: Error | null;
  logout: (e: { returnTo: string }) => void;
  loginWithRedirect: (e: { appState: { target: string } }) => void;
  getAccessTokenSilently?: () => Promise<string>;
};

export type GetAuth0User0 = {
  user0: Auth0User;
  isAuthenticated0: boolean;
  isLoading0: boolean;
  error0: Error;
  location: string;
  getAccessTokenSilently0: () => Promise<string>;
  loginWithRedirect0: () => void;
  logout0: () => void;
};

export interface Profil {
  id: string;
  provider: any;
  roles: Role;
  name: Name;
  email: string;
}

export interface BrowserMe {
  profile: Profil;
  bloomer: BrowserBloomer;
}

export type Name = {
  familyName?: string;
  givenName?: string;
};

import { JwtPayload } from 'jwt-decode';

export interface AnonymousJwtPayload extends JwtPayload {
  statebag: any;
  user?: Auth0User;
}

export interface ClientEmbedInvitePayload extends AnonymousJwtPayload {
  statebag: {
    signingEmbedClientId: string;
    activityRecordId: string;
  };
}

export interface JobGuestHouseInvitePayload extends AnonymousJwtPayload {
  statebag: {
    jobId: string;
  };
}

export interface DecodedAnynomousToken extends AnonymousJwtPayload {
  token: string;
}

export interface LegalContractPayload extends AnonymousJwtPayload {
  statebag: {
    externalDocumentId: string;
    signerId: string;
  };
}

export interface IContext {
  bypass?: boolean | null;
  user?: IUser;
  args?: any;
  bloomer?: {
    recordId: string;
    mission?: string[];
    email: string;
    updateCache: (arg: IBloomer) => void;
  };
  token?: string | undefined;
  decodedToken?: JwtPayload;
}

export interface IUser {
  phoneNumber?: string;
  roles: string[];
  email: string;
  displayName: string;
  name?: {
    givenName?: string;
    familyName?: string;
  };
}
