/// <reference types="chrome"/>
/*

if (!globalThis.document) {
  // @ts-expect-error partial polyfill for @datadog/browser-logs
  globalThis.document = {
    // @ts-expect-error partial polyfill for @datadog/browser-logs
    referrer: `chrome://${chrome.runtime.id}`,
  };
}

if (!globalThis.window) {
  globalThis.window = {
    // @ts-expect-error partial polyfill for @datadog/browser-logs
    location: {
    // @ts-expect-error partial polyfill for @datadog/browser-logs
    href: `chrome://${chrome.runtime.id}`,
    },
  }
}
*/

// please defined an URL as you want , or simply you can use "chrome.getURL('')" to use the "chrome-extension://*" protocol
// const url = `https://hostname.com/service-worker/${chrome.runtime.id}`;

if (!globalThis.document) {
  // @ts-expect-error partial polyfill for @datadog/browser-logs
  globalThis.document = {
    readyState: 'complete',
    getElementsByTagName: () => [] as any,
    location: {
      hostname: 'hostname.com',
      href: chrome.runtime.getURL(''),
      ancestorOrigins: undefined as any,
      hash: '',
      host: '',
      origin: '',
      pathname: '',
      port: '',
      protocol: '',
      search: '',
      assign: function (url: string | URL): void {
        throw new Error('Function not implemented.');
      },
      reload: function (): void {
        throw new Error('Function not implemented.');
      },
      replace: function (url: string | URL): void {
        throw new Error('Function not implemented.');
      },
    },
    // I didn't implement the cookie to use chrome.cookies extension api under the hood, because the usage for document.cookie is sync but the extension api to get cookies is async, so it could not work as expected.
    get cookie() {
      return '';
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set cookie(cookie) {},
  };
}

// we patch the TextEncoder into window patched object to use the TextEncoder from the Service Worker environment, so we can use self. in order to be the same for window environment and service worker environment

// the same above for fetch patching
if (!globalThis.window) {
  globalThis.window = self;
}

if (!globalThis.performance.timing) {
  // @ts-expect-error partial polyfill for @datadog/browser-logs
  globalThis.performance.timing = globalThis.performance.timeOrigin;
}

class XMLHttpRequestWithFetch {
  method: string;
  url: string;
  status: number;
  listeners: {
    [key: string]: ((response: Response) => void)[];
  };

  constructor() {
    this.method = 'GET';
    this.url = '';
    this.status = 500;
    this.listeners = {};
  }

  open(method: string, url: string) {
    this.method = method;
    this.url = url;
  }

  addEventListener(eventName: string, cb: () => void) {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName].push(cb);
  }

  removeEventListener(eventName: string, cb: () => void) {
    const handlers = this.listeners[eventName];
    if (handlers) {
      const restOfHandlers = handlers.filter((callback) => callback !== cb);
      if (restOfHandlers && restOfHandlers.length) {
        this.listeners[eventName] = restOfHandlers;
      } else {
        delete this.listeners[eventName];
      }
    }
  }

  send(data: object | string) {
    let _body;
    if (typeof data === 'object') {
      _body = JSON.stringify(data);
    } else {
      _body = data;
    }
    fetch(this.url, {
      method: this.method,
      body: _body,
    })
      .then((response) => {
        this.status = response.status;
        // notify all listeners that we're done
        Object.keys(this.listeners).forEach((event) => {
          this.listeners[event].forEach((handler) => handler(response));
        });
      })
      .catch(() => {
        // @TODO: probably we should handle the failing case.
      });
  }
}

if (!globalThis.XMLHttpRequest) {
  // @ts-expect-error partial polyfill for @datadog/browser-logs
  globalThis.XMLHttpRequest = XMLHttpRequestWithFetch;
}
