import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PreviewIcon from '@mui/icons-material/Visibility';
import IconButton from '../atoms/IconButton';
import ForumIcon from '@mui/icons-material/Forum';
import { AriaRole } from 'react';
import Button from '../atoms/Button';
import { SvgIconProps } from '@mui/material/SvgIcon';

export type ActionButtonType = 'Preview' | 'Upload' | 'Edit' | 'Delete' | 'Slack';
export type ActionButtonSize = 'small' | 'medium' | 'large';

export type ActionButtonProps = ConditionalProps & {
  // specific props
  actionType: ActionButtonType;
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';

  // props from Button drilldowned to child components
  onClick?: (event: any) => void;
  disable?: boolean;
  title?: string;
  id?: string;
  role?: AriaRole | undefined;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  loading?: boolean;
};

type ConditionalProps =
  // If i pass the icon prop i can't pass the src prop
  | {
      text?: string;
      variant?: never;
    }
  // If i pass the src prop i can't pass the icon prop
  | {
      variant?: 'button' | 'contained';
      text?: never;
    };

const getIcon = (actionType: ActionButtonType, size: ActionButtonSize) => {
  const iconProps: SvgIconProps = {
    fontSize: size,
  };
  switch (actionType) {
    case 'Preview':
      return <PreviewIcon {...iconProps} />;
    case 'Upload':
      return <UploadFileIcon {...iconProps} />;
    case 'Edit':
      return <EditIcon {...iconProps} />;
    case 'Delete':
      return <DeleteIcon {...iconProps} />;
    case 'Slack':
      return <ForumIcon {...iconProps} />;
  }
};

export const ActionButton = ({
  text,
  variant = 'button',
  actionType,
  loading,
  color,
  size = 'medium',
  ...props
}: ActionButtonProps) => {
  const icon = actionType && getIcon(actionType, size);

  if (text && text !== '') {
    return (
      <Button textButton={text} emoji={icon} size={size} color={color} {...(loading ? { loading } : {})} {...props} />
    );
  }
  return <IconButton children={icon} variant={variant} color={color} loading={loading} {...props} />;
};
