import { ItemTalentLevel, ItemTalentLevelProps, SingleLineText } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { getSkillsSorted } from '../../helpers/skills';

export type ItemTalentLevelListProps = {
  items?: ItemTalentLevelProps[];
  reduced?: boolean;
};

const ItemTalentLevelList = ({ items = [], reduced = false }: ItemTalentLevelListProps) => {
  return (
    <StyledFilterList>
      {reduced && items.length > 5 ? (
        <StyledFilterList>
          {getSkillsSorted(items)
            .slice(0, 5)
            .map((item, i) => {
              return <ItemTalentLevel {...item} key={`${i}`} />;
            })}
          <SingleLineText text={`+ ${items.length - 5} autres`} />
        </StyledFilterList>
      ) : (
        getSkillsSorted(items).map((item, i) => {
          return <ItemTalentLevel {...item} key={`${i}`} />;
        })
      )}
    </StyledFilterList>
  );
};

export default ItemTalentLevelList;
export { ItemTalentLevelList };

const StyledFilterList = styled('div')(
  () => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 8px;
`,
);
