import Box from '@mui/material/Box';
import SliderMui from '@mui/material/Slider';
import { SxProps, Theme, styled } from '@mui/material/styles';
import React from 'react';

const valuetext = (value: number) => {
  return `${value}km`;
};

export type SliderProps = {
  defaultValue: number;
  label: string;
  value?: number;
  valueLabelDisplay?: 'auto' | 'on' | 'off' | undefined;
  min: number;
  max: number;
  onChange?: (event: Event, value: number | number[], activeThumb: number) => void;
  onChangeCommitted?: (event: React.SyntheticEvent | Event, value: number | number[]) => void;
  step: number;
  marks: { value: number; label: string }[];
  sx?: SxProps<Theme>;
};

const Slider = ({
  defaultValue,
  label,
  value,
  min,
  max,
  step,
  onChange,
  onChangeCommitted,
  valueLabelDisplay = 'auto',
  marks,
  sx,
}: SliderProps) => {
  return (
    <Box sx={sx}>
      <StyledSliderMui
        aria-label={label}
        defaultValue={defaultValue}
        getAriaValueText={valuetext}
        value={value}
        marks={marks}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay={valueLabelDisplay}
        color="secondary"
      />
    </Box>
  );
};

const memo = React.memo(Slider);

export default memo;
export { memo as Slider };

const StyledSliderMui = styled(SliderMui)(
  ({ theme }) => `
  color: ${theme.palette.secondary.dark};
  `,
);
