import { useQuery } from '@apollo/client';
import { ME_FULL } from '@bloomays-lib/adapter.api-bloomer';

import ListMissionChoice from '../organisms/ListMissionChoice';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { BrowserMe } from '@bloomays-lib/types.shared';

interface MissionChoiceData {
  me: BrowserMe;
}

const MissionChoice = (): JSX.Element => {
  const { loading, error, data } = useQuery<MissionChoiceData>(ME_FULL);

  return <ListMissionChoice data={data?.me?.bloomer?.mission} loading={loading} error={error} />;
};

export default WithErrorBoundary(MissionChoice);
