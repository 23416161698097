import React from 'react';
import { convertDateToStringFormat, getFirstValidPrice, greaterDate } from '@bloomays-lib/utils.shared';
import RawCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import RawCardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Button as RawButton } from '@bloomays-lib/ui.shared';
import { useNavigate } from 'react-router-dom';
import { IMission, IPricing } from '@bloomays-lib/types.shared';
import { HrefLink as RawHrefLink } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';

export type BloomerMissionProps = {
  mission: IMission;
};

const BloomerMission = ({ mission }: BloomerMissionProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardContent>
        <StyledTypo>{`${mission?.label} chez ${mission?.clientSociety?.name}`}</StyledTypo>
        <ContainerData>
          <ContainerTextLabel>
            <CenteredSingleLineText
              text={`CCM en charge : `}
              span={mission?.CSM?.fullname ? `${mission?.CSM?.fullname} ` : 'Non attribué '}
            />
            <HrefLink text=" ☎️" href={'https://bit.ly/bloomays-help'} />
          </ContainerTextLabel>
          <ContainerTextLabel>
            <CenteredSingleLineText
              text={`TJM :`}
              span={`${
                getFirstValidPrice<IPricing>(
                  [...(mission?.pricing || [])],
                  greaterDate(new Date(mission?.beginMission as string), new Date()),
                )?.purchasePricePerDayWithoutTaxes
              } €`}
            />
          </ContainerTextLabel>
          <ContainerTextLabel>
            <CenteredSingleLineText
              text={`Début :`}
              span={`${convertDateToStringFormat(mission?.beginMission, 'dd/MM/yyyy')}`}
            />
          </ContainerTextLabel>
          <ContainerTextLabel>
            <CenteredSingleLineText
              text={`Fin :`}
              span={`${convertDateToStringFormat(mission?.endMission, 'dd/MM/yyyy')}`}
            />
          </ContainerTextLabel>
        </ContainerData>
      </CardContent>
      <CardActions>
        <Button id="handleCra" onClick={() => navigate(`/mission/${mission?.recordId}`)} textButton="Gérer les CRA" />
      </CardActions>
    </Card>
  );
};

export default BloomerMission;
export { BloomerMission };

const Card = styled(RawCard)(() => ({
  width: '100%',
  margin: '1.5em 0',
  paddingBottom: 10,
  paddingTop: 10,
  boxShadow: '2px 2px 20px lightgrey !important',
  borderRadius: '10px !important',
}));

const StyledTypo = styled(Typography)(() => ({
  padding: '0px 0px 16px 16px',
  fontWeight: 'bold',
}));

const ContainerData = styled('div')(() => ({
  paddingLeft: 16,
}));

const CenteredSingleLineText = styled(SingleLineText)(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '.5em 0',
}));

const CardActions = styled(RawCardActions)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const ContainerTextLabel = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const Button = styled(RawButton)(({ theme }) => ({
  border: 'none',
  padding: '0.7em 1.4em',
  marginRight: 10,
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.paper.white,
  boxShadow: '2px 2px 5px rgb(201, 201, 201)',
  paddingRight: '16px',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.white,
    color: theme.palette.secondary.dark,
    transition: '0.52s ease',
  },
}));

const HrefLink = styled(RawHrefLink)(() => ({
  paddingLeft: 7,
}));
