import { Item, Paginate } from '@bloomays-lib/types.shared';
import { Action, State } from '../types/talents';
import set from 'lodash/fp/set';

export const reducerTalents = (state: State, action: Action) => {
  let newSkills: Item[] = [];
  let newOwners: string[] = [];
  let newLanguages: { label: string }[] = [];
  const skillsState = state.search.skills?.skills || [];
  const ownersState = state.search.owners || [];
  const languagesState = state.search.languages || [];
  let skillsToRm: string[];
  let newJobs: Item[] = [];
  let jobsToRm: string[];
  let ownersToRm: string[];
  let languagesToRm: string[];
  const jobsState = state.search.jobs?.jobs || [];
  let skillToUpdate: Item | undefined;
  let ownerToUpdate: string | undefined;
  let languageToUpdate: { label: string } | undefined;
  let jobToUpdate: Item | undefined;
  let ownersfilterd;

  switch (action.type) {
    case 'OPERATOR_SKILLS_SEARCH': {
      const orOperator = action.or;
      return set(['search', 'skills', 'or'], orOperator, state);
    }
    case 'ADD_SKILLS_SEARCH': {
      if (action.skills) {
        newSkills = action.skills;
        skillToUpdate = skillsState?.find((skill) => skill.id === newSkills[0].id);
      }
      const skills = [...skillsState.filter((skill) => skill.id !== skillToUpdate?.id), ...newSkills];
      return set(['search', 'skills', 'skills'], skills, state);
    }
    case 'RM_SKILLS_SEARCH': {
      if (action.skillsId) {
        skillsToRm = action.skillsId;
      }
      const skills = skillsState?.filter((skill) => !skillsToRm?.includes(skill.id));
      return set(['search', 'skills', 'skills'], skills, state);
    }
    case 'ADD_OWNERS_SEARCH':
      if (action.owners) {
        newOwners = action.owners;
        ownerToUpdate = ownersState?.find((owner) => owner === newOwners[0]);
      }
      return {
        ...state,
        search: {
          ...state.search,
          owners: [...ownersState.filter((owner) => owner !== ownerToUpdate), ...newOwners],
        },
      };
    case 'RM_OWNERS_SEARCH':
      if (action.owners) {
        ownersToRm = action.owners;
        ownersfilterd = ownersState?.filter((owner) => {
          return !ownersToRm?.includes(owner);
        });
        return {
          ...state,
          search: {
            ...state.search,
            owners: ownersfilterd?.length > 0 ? ownersfilterd : undefined,
          },
        };
      }
      return state;
    case 'ADD_LANGUAGES_SEARCH':
      if (action.languages) {
        newLanguages = action.languages;
        languageToUpdate = languagesState?.find((lang) => lang.label === newLanguages[0].label);
      }
      return {
        ...state,
        search: {
          ...state.search,
          languages: [...languagesState.filter((lang) => lang !== languageToUpdate), ...newLanguages],
        },
      };
    case 'RM_LANGUAGES_SEARCH':
      if (action.languagesLabel) {
        languagesToRm = action.languagesLabel;
      }
      return {
        ...state,
        search: {
          ...state.search,
          languages: languagesState?.filter((lang) => !languagesToRm?.includes(lang.label)),
        },
      };
    case 'ADD_JOBS_SEARCH':
      if (action.jobs) {
        newJobs = action.jobs;
        jobToUpdate = jobsState?.find((job) => job.id === newJobs[0].id);
      }
      return {
        ...state,
        search: {
          ...state.search,
          jobs: {
            jobs: [...jobsState.filter((job) => job.id !== jobToUpdate?.id), ...newJobs],
          },
        },
      };
    case 'RM_JOBS_SEARCH':
      if (action.jobsId) {
        jobsToRm = action.jobsId;
      }
      return {
        ...state,
        search: {
          ...state.search,
          jobs: {
            jobs: jobsState?.filter((job) => !jobsToRm?.includes(job.id)),
          },
        },
      };
    case 'TEXT_SEARCH':
      return {
        ...state,
        search: { ...state.search, fulltext: action.fulltext },
      };
    case 'STATUS_SEARCH':
      return {
        ...state,
        search: { ...state.search, status: action.status },
      };
    case 'AVAILABILITY_SEARCH':
      return {
        ...state,
        search: { ...state.search, availabilityDate: action.availabilityDate },
      };
    case 'REMOTE_WORK_SEARCH':
      return {
        ...state,
        search: { ...state.search, remoteWork: action.remoteWork },
      };
    case 'BLOOMER_STATUS_SEARCH':
      return {
        ...state,
        search: { ...state.search, bloomerStatus: action.bloomerStatus },
      };
    case 'PAGINATE':
      return {
        ...state,
        paginate: action.paginate as Paginate,
      };
    case 'RESET_SEARCH':
      return {
        ...state,
        search: {},
      };
    default:
      throw new Error('Unknon action');
  }
};

export const initialStateTalents = {
  search: {},
  paginate: {
    take: 20,
    skip: 0,
  },
};
