import { useAuth } from '../../auth/AuthProvider';
import BloomiesSettings from '../organisms/BloomiesSettings';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { BloomerSettings } from '../organisms/BloomerSettings';
import { Auth0User, Role } from '@bloomays-lib/types.shared';

const Settings = (): JSX.Element => {
  const auth = useAuth();
  let user: Auth0User | undefined | null;
  if (auth) {
    user = auth.user;
  }

  return (
    <>
      {user?.roles?.includes(Role.Admin) && <BloomiesSettings />}
      {user?.roles?.includes(Role.Bloomer) && <BloomerSettings />}
    </>
  );
};

export default WithErrorBoundary(Settings);
