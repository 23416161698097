import Notification from '../molecules/Notification';
import { styled } from '@mui/material/styles';
import { NotificationCenterContext } from '../types';
import LoaderSkeleton from '../molecules/LoaderSkeleton';

export type NotificationDashboardProps = {
  notificationCenterContext: NotificationCenterContext;
};

const NotificationDashboard = ({ notificationCenterContext }: NotificationDashboardProps) => {
  if (notificationCenterContext.loading) {
    return <LoaderSkeleton width={750} height={90} label={''} />;
  }
  return notificationCenterContext.notifications
    ?.filter((n) => n.read !== true)
    .sort((a, b) => b.createdAt - a.createdAt)
    .slice(0, 1)
    .map((notification, i) => {
      return (
        <Div key={notification.id || i}>
          <Notification notification={notification} onRead={notificationCenterContext.read} variant={'outlined'} />
        </Div>
      );
    });
};

const Div = styled('div')(
  () => `
  margin-bottom: 15px;
  margin-top: 5px;
`,
);

export default NotificationDashboard;
export { NotificationDashboard };
