import styled from '@emotion/styled';
import { AlertMessageAction } from './AlertMessageAction';
import RawWarningIcon from '@mui/icons-material/Warning';
import { ActionButtonType } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';

export type AlertMessageActionType = {
  title: string;
  text: string;
  type: ActionButtonType;
  onClick?: (event: any) => void;
};

export const DashboardAlertMessages = ({ fields }: { fields: AlertMessageActionType[] }) => {
  const { t } = useTranslation(['mission']);

  return (
    <Dashboard>
      <DashboardTitle>
        <IconBox>
          <WarningIcon />
        </IconBox>
        <h3>{t('missingData')}</h3>
      </DashboardTitle>
      <DashboardText>{t('missingDataDescription')}</DashboardText>
      <AlertsContainer>
        {fields &&
          fields.map((field) => (
            <AlertMessageAction title={field.title} text={field.text} type={field.type} onClick={field.onClick} />
          ))}
      </AlertsContainer>
    </Dashboard>
  );
};

const Dashboard = styled('section')(() => ({
  padding: '5px',
}));

const DashboardTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '10px',
}));

const IconBox = styled('div')(() => ({
  backgroundColor: '#FFECF4',
  width: '40px',
  height: '40px',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const iconCSS = () => ({
  color: '#FE83B6',
  width: 20,
});
const WarningIcon = styled(RawWarningIcon)(iconCSS);

const DashboardText = styled('div')(() => ({
  textAlign: 'left',
}));

const AlertsContainer = styled('div')(() => ({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));
