import React from 'react';
import DetailInfo from './DetailInfo';
import { styled } from '@mui/material/styles';

export type ListDetailInfoProps = {
  list: { icon?: string; text: string; span?: string }[];
};

const StyledContainer = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
  display: flex;
  flex-direct: row nowrap;
  align-items: center;
`,
);

type DivProps = {
  first: boolean;
};

const StyledContainerDetail = styled('div')<DivProps>(
  ({ theme, first }) => `
  padding-right: 0.5em;
  border-left: ${first ? '' : `1px solid ${theme.palette.grey[500]}`}};
  height: 21px;
`,
);

const ListDetailInfo = ({ list }: ListDetailInfoProps) => {
  return (
    <StyledContainer>
      {list.map((element, i) => {
        return (
          <StyledContainerDetail first={i === 0 ? true : false} key={i}>
            <DetailInfo emoji={element?.icon} text={element.text} span={element.span} />
          </StyledContainerDetail>
        );
      })}
    </StyledContainer>
  );
};

export default ListDetailInfo;
export { ListDetailInfo };
