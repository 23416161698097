import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

export type LevelProps = {
  level?: number;
  levelMatching?: 'perfectMatch' | 'middleMatch' | 'noMatch';
  clickable?: boolean;
  onClick?: (e: any) => void;
};

export type BarProps = {
  number?: number;
  color: string;
  clickable?: boolean;
  onClick?: (e: any) => void;
};

type DivProps = {
  selected: boolean;
  clickable?: boolean;
};

const SmallBar = ({ number, color, clickable, onClick }: BarProps) => {
  return (
    <StyledSmallBar
      onClick={onClick ? () => onClick(0.1) : undefined}
      clickable={clickable}
      color={color}
      selected={number !== undefined && number > 0 ? true : false}
    ></StyledSmallBar>
  );
};

const MediumBar = ({ number, color, clickable, onClick }: BarProps) => {
  return (
    <StyledMediumBar
      onClick={onClick ? () => onClick(0.4) : undefined}
      clickable={clickable}
      color={color}
      selected={number && number >= 0.4 ? true : false}
    ></StyledMediumBar>
  );
};

const LargeBar = ({ number, color, clickable, onClick }: BarProps) => {
  return (
    <StyledLargeBar
      onClick={onClick ? () => onClick(0.7) : undefined}
      color={color}
      clickable={clickable}
      selected={number && number >= 0.7 ? true : false}
    ></StyledLargeBar>
  );
};

const Level = ({ level, levelMatching, clickable, onClick }: LevelProps) => {
  const theme = useTheme();

  const getByMatchColor = (match?: 'perfectMatch' | 'middleMatch' | 'noMatch') => {
    switch (match) {
      case 'perfectMatch':
        return theme.palette.tertiary.positive;
      case 'middleMatch':
        return theme.palette.tertiary.yellowStar;
      case 'noMatch':
        return theme.palette.grey[900];
      default:
        return theme.palette.secondary.dark;
    }
  };

  return (
    <div>
      {level !== 0 && (
        <StyledDivContainer>
          <SmallBar onClick={onClick} color={getByMatchColor(levelMatching)} clickable={clickable} number={level} />
          <MediumBar onClick={onClick} color={getByMatchColor(levelMatching)} clickable={clickable} number={level} />
          <LargeBar onClick={onClick} color={getByMatchColor(levelMatching)} clickable={clickable} number={level} />
        </StyledDivContainer>
      )}
    </div>
  );
};

export default Level;
export { Level };

const StyledSmallBar = styled('div')<DivProps>(
  ({ theme, selected, color, clickable }) => `
  background-color: ${selected ? `${color}` : `${theme.palette.grey[900]}`};
  width: 5.18px;
  height: 9.07px;

  &:hover {
    background-color: ${clickable ? `${theme.palette.secondary.light}` : ``};
    cursor: ${clickable && 'pointer'};
  }
  `,
);

const StyledMediumBar = styled('div')<DivProps>(
  ({ theme, selected, clickable, color }) => `
  background-color: ${selected ? `${color}` : `${theme.palette.grey[900]}`};
  width: 5.18px;
  height: 14.25px;

  &:hover {
    background-color: ${clickable ? `${theme.palette.secondary.light}` : ``};
    cursor: ${clickable && 'pointer'};
  }
  `,
);

const StyledLargeBar = styled('div')<DivProps>(
  ({ theme, selected, clickable, color }) => `
  background-color: ${selected ? `${color}` : `${theme.palette.grey[900]}`};
  width: 5.18px;
  height: 18.14px;

  &:hover {
    background-color: ${clickable ? `${theme.palette.secondary.light}` : ``};
    cursor: ${clickable && 'pointer'};
  }
  `,
);

const StyledDivContainer = styled('div')(
  () => `
  gap: 2.59px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  `,
);
