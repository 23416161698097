import { SetStateAction } from 'react';
import useLocalStorage from 'use-local-storage';

export type Setter<T> = (value: SetStateAction<T | undefined>) => void;

const useCustomLocalStorage = <T,>(key: string, value: T): [T, Setter<T>] => {
  return useLocalStorage(key, value);
};

export default useCustomLocalStorage;
