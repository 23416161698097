export const testLKUrl = (url: string) => {
  const LKRegex =
    /^https?:\/\/www.linkedin\.com\/(?:[^\\/\n\s]+\/){1,2}(?:(?:[\wÀ-ÖØ-öø-ÿ%]+-){0,2}[\wÀ-ÖØ-öø-ÿ%]+(?:\.[\wÀ-ÖØ-öø-ÿ]+)*\/?)[^a-z]$/;
  return LKRegex.test(url);
};

export const testGHUrl = (url: string) => {
  const GHRegex =
    /^https?:\/\/(?:[a-z0-9]+\.)?github\.com\/(?:(?:[\wÀ-ÖØ-öø-ÿ-]+-){0,2}[\wÀ-ÖØ-öø-ÿ]+(?:\.[\wÀ-ÖØ-öø-ÿ]+)*\/?)?$/;
  return GHRegex.test(url);
};
