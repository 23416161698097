import { useState } from 'react';
import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';
import RawTableRow from '@mui/material/TableRow';
import RawTableCell from '@mui/material/TableCell';
import RawTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import BasicTable from '../molecules/BasicTable';
import { LoaderSkeleton, SingleLineText, Switch } from '@bloomays-lib/ui.shared';
import { IBilling } from '@bloomays-lib/types.shared';
import DatePicker from '../atoms/DatePicker';

export type BillingDashboardProps = {
  billings?: IBilling[];
  isTrialPeriod: boolean;
  onChange: () => void;
  loading: boolean;
  updateBilling: (billing: IBilling) => Promise<void>;
};

const BillingDashboard = ({ billings, isTrialPeriod, onChange, loading, updateBilling }: BillingDashboardProps) => {
  const [billingDate, setBillingDate] = useState<{
    date: Date | null;
    id?: string;
  }>({ date: null, id: undefined });
  const [trialDate, setTrialDate] = useState<{
    date: Date | null;
    id?: string;
  }>({ date: null, id: undefined });
  const columns = ['Description', 'Client', 'Début', "Fin de la période d'essai", 'Commission (HT)', 'Next step'];

  const onCloseTrial = (billing: IBilling) => {
    if (trialDate?.date && trialDate?.id === billing.recordId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateBilling({
        ...billing,
        TrialPeriodEndDate: convertDateToStringFormat(trialDate?.date),
      });
    }
  };

  const onCloseBilling = (billing: IBilling) => {
    if (billingDate?.date && billingDate?.id === billing.recordId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateBilling({
        ...billing,
        BillingDate: convertDateToStringFormat(billingDate?.date),
      });
    }
  };

  return (
    <div>
      <ContainerToggleText>
        <Switch id="isTrialPeriod" checked={isTrialPeriod} onSwitch={onChange} label={''} />
        <SingleLineText text="Afficher uniquement les bloomers en période d'essai" />
      </ContainerToggleText>
      {loading ? (
        <LoaderSkeleton height={800} width={1200} />
      ) : (
        <BasicTable columns={columns}>
          {billings?.map((billing, i) => {
            return (
              <TableRow id="Description" key={i}>
                <TableCell>{billing?.Description}</TableCell>
                <TableCell id="CustomerName">{billing?.CustomerName}</TableCell>
                <TableCell id={`${billing?.BillingDate}-${billing?.recordId}`}>
                  <DatePicker
                    views={['day', 'month', 'year']}
                    disabled={billing.Billed ? true : false}
                    onClose={() => onCloseBilling(billing)}
                    value={
                      billingDate?.date && billingDate.id === billing.recordId
                        ? billingDate.date
                        : new Date(convertDateToStringFormat(billing?.BillingDate))
                    }
                    handleChange={(e) => {
                      setBillingDate({ date: e, id: billing?.recordId });
                    }}
                  />
                  {billing.Billed && (
                    <NoEditSingleLineText text={'Pour changer cette date, rapproche toi de la compta'} />
                  )}
                </TableCell>
                <TableCell id="TrialPeriodEndDate">
                  <DatePicker
                    openTo="day"
                    views={['day', 'month', 'year']}
                    disabled={billing.Billed ? true : false}
                    onClose={() => onCloseTrial(billing)}
                    value={
                      trialDate?.date && trialDate.id === billing.recordId
                        ? trialDate.date
                        : new Date(convertDateToStringFormat(billing?.TrialPeriodEndDate))
                    }
                    handleChange={(e) => {
                      setTrialDate({ date: e, id: billing?.recordId });
                    }}
                  />
                  {billing.Billed && (
                    <NoEditSingleLineText text={'Pour changer cette date, rapproche toi de la compta'} />
                  )}
                </TableCell>
                <TableCell id="SalesPriceWithoutTaxes">{`${billing?.SalesPriceWithoutTaxes} €`}</TableCell>
                <TableCellBody id="nextStep">
                  <Typography variant="body1Light">{billing?.nextStep || 'Pas de next step'}</Typography>
                </TableCellBody>
              </TableRow>
            );
          })}
        </BasicTable>
      )}
    </div>
  );
};

export default BillingDashboard;
export { BillingDashboard };

const TableRow = styled(RawTableRow)(() => ({
  height: 45,
}));

const TableCell = styled(RawTableCell)(() => ({
  padding: '0.1em',
  textAlign: 'center !important' as CanvasTextAlign,
}));

const TableCellBody = styled(RawTableCell)(() => ({
  textAlign: 'center !important' as CanvasTextAlign,
}));

const NoEditSingleLineText = styled(SingleLineText)(() => ({
  color: 'red',
  fontSize: 11,
}));

const ContainerToggleText = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  width: '90%',
  margin: '1em auto',
}));

const Typography = styled(RawTypography)(() => ({
  backgroundColor: 'lightgray',
  display: 'inline-block',
  margin: 'auto',
  color: 'gray',
  padding: '0 0.5em',
}));
