import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { styled, useTheme } from '@mui/material/styles';

export type Cell = {
  columnName: string;
  value: number | string | null;
  backgroundColor?: string;
  color?: string;
  onClick?: () => void;
};

export type Columns = { columnName: string; title: string };

export type TwoDTableProps = {
  columns: Columns[];
  rows: Cell[][];
  isLoading?: boolean;
};

const TwoDTable = ({ columns, rows, isLoading = false }: TwoDTableProps): JSX.Element => {
  const theme = useTheme();
  return (
    <div>
      {isLoading ? (
        <LoaderSkeleton height={450} width={1200} />
      ) : (
        <StyledTableContainer>
          <Table>
            <StyledTableHeader>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.columnName}>{column.title}</StyledTableCell>
                ))}
              </TableRow>
            </StyledTableHeader>
            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow key={`row-${rowIndex}`}>
                    {row.map((element, i) => (
                      <StyledTableCell
                        key={`${element.columnName}-${i}`}
                        style={{
                          backgroundColor: element.backgroundColor
                            ? element.backgroundColor
                            : theme.palette.paper.white,
                          color: element.color ? element.color : theme.palette.grey[50],
                        }}
                      >
                        {element.onClick ? (
                          <StyledClickable onClick={element.onClick}>{element.value}</StyledClickable>
                        ) : (
                          element.value
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </div>
  );
};

export default TwoDTable;
export { TwoDTable };

const StyledTableContainer = styled(TableContainer)(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  border-radius: 12px;
  min-width: 690px;
  box-shadow: 0px 4.38px 24.1px rgba(75, 75, 75, 0.09);
  background-color: ${theme.palette.paper.white};
  `,
);

const StyledTableCell = styled(TableCell)(
  () => `
  text-align: center;
  `,
);

const StyledClickable = styled('div')(
  () => `
  cursor: pointer;
  `,
);

const StyledTableHeader = styled(TableHead)(
  ({ theme }) => `
  background-color: ${theme.palette.tertiary.purple};
  `,
);
