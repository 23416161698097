import { createCommonLogger } from '@bloomays-lib/common.logger/browser';
import { BrowserConfig, ILogger } from '@bloomays-lib/types.shared';
import config from './configService';

const userAgent = navigator.userAgent;
let rootLogger: ILogger;

const prepareLogger = (conf: BrowserConfig) => {
  if (rootLogger) {
    return rootLogger;
  }
  return createCommonLogger({
    datadog: {
      userAgent: userAgent,
      apiKey: conf.logger.datadog.apiKey,
      service: conf.service,
      source: 'browser',
      level: conf.logger.level || 'info',
      env: conf.env,
      version: conf.release,
    },
    userAgent,
    env: conf.env,
    release: conf.release || 'localhost',
    isTest: conf.env === 'test',
    level: conf.logger.level || 'info',
  });
};

const Logger = (moduleName?: string): ILogger => {
  if (!rootLogger) {
    rootLogger = prepareLogger(config());
  }
  return moduleName && rootLogger.child ? rootLogger.child(moduleName) : rootLogger;
};

export { Logger };
