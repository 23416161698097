import IconRead from '@mui/icons-material/MarkEmailRead';
import IconUnRead from '@mui/icons-material/MarkEmailUnread';
import Alert, { AlertColor } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { TypeOptions } from 'react-toastify';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { dateFormat, fromDate } from '@bloomays-lib/utils.shared';
import Button from '../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { NotificationCenterItem } from '../types';
import { AlertMessage } from '../atoms/AlertMessage';

export type NotificationProps = {
  notification: NotificationCenterItem;
  onClose?: (e: React.MouseEvent<HTMLDivElement>, notification: NotificationCenterItem) => void;
  onRead: (notification: NotificationCenterItem) => Promise<boolean>;
  variant?: 'standard' | 'outlined';
};

const notificationPriority2AlertColor = (prio?: TypeOptions): AlertColor => {
  switch (prio) {
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    case 'info':
      return 'success';
    default:
      return 'info';
  }
};

const Notification = ({ notification, onRead, variant = 'standard' }: NotificationProps) => {
  const navigate = useNavigate();
  const [reading, setReading] = useState(false);
  return (
    <Alert
      sx={{ textAlign: 'left' }}
      variant={variant}
      severity={notificationPriority2AlertColor(notification.type)}
      action={
        <ActionBox direction={'column'} alignItems={'flex-end'} justifyContent={'space-between'} id="stack-button">
          <IconButton
            size="small"
            color="primary"
            aria-label="Marquer comme lu"
            aria-roledescription="button"
            component="div"
            id="read-notification"
            disabled={notification.read}
            onClick={async (e) => {
              setTimeout(() => setReading(true), 1);
              const result = await onRead(notification);
              setReading(result);
            }}
          >
            {notification.read || reading ? <IconRead color="primary" /> : <IconUnRead />}
          </IconButton>
          {notification?.data?.link !== undefined ? (
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                if (notification?.data?.link?.startsWith('http')) {
                  return window.open(notification?.data?.link, '_blank');
                }
                navigate(notification?.data?.link as string);
              }}
              textButton="Voir"
            />
          ) : undefined}
        </ActionBox>
      }
    >
      <AlertMessage title={notification.data?.title as string} description={notification.content as string} />
      <DateBox direction={'row'} justifyContent={'space-between'} alignItems="center" spacing={2}>
        {fromDate(new Date(notification.createdAt))} ({dateFormat(notification.createdAt, 'dd/MM/yyyy')})
      </DateBox>
    </Alert>
  );
};

const DateBox = styled(Stack)(({ theme }) => ({
  color: theme.palette.grey.A400,
  paddingTop: '10px',
  width: '100%',
}));

const ActionBox = styled(Stack)(({ theme }) => ({
  height: '100%',
}));

export default Notification;
export { Notification };
