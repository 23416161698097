import logo from '../../assets/iconBloomays.png';
import { styled } from '@mui/material/styles';

type LoadingLogoProps = {
  rotate?: boolean;
};

const LoadingLogo = ({ rotate = true }) => {
  return rotate ? (
    <RotateLogo src={logo} className="App-logo" alt="logo" />
  ) : (
    <Logo src={logo} className="App-logo" alt="logo" />
  );
};

export default LoadingLogo;
export { LoadingLogo };

const Logo = styled('img')(
  () => `
  height: 6vmin;
  pointer-events: none;
`,
);

const RotateLogo = styled(Logo)(
  () => `
  height: 6vmin;
  pointer-events: none;
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`,
);
