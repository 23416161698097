import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export type AutoCompleteOption = {
  title: string;
};

export type AutoCompleteInputProps<T extends AutoCompleteOption> = {
  data: AutoCompleteOption[];
  label: string;
  setValue: React.Dispatch<React.SetStateAction<T>>;
};

const AutoCompleteInput = <T extends AutoCompleteOption>({ data, label, setValue }: AutoCompleteInputProps<T>) => {
  const { t } = useTranslation(['random']);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly AutoCompleteOption[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (() => {
      if (active && data) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [data, loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={`asynchronous-${label}`}
      open={open}
      loadingText={t('loadingText')}
      noOptionsText={t('noOptionsText')}
      fullWidth
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event: any, newValue: AutoCompleteOption | null) => {
        if (newValue?.title) {
          setValue(newValue as T);
        }
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      autoHighlight
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteInput;
export { AutoCompleteInput };
