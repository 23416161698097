import { ConsolaInstance, ConsolaReporter, consola } from 'consola';
import type { Env, ILogger, LogLevel } from '@bloomays-lib/types.shared';
import { DatadogTransport, DatadogTransportOptions } from './datadog';
import { logLevelToConsolaLevel } from './utils';
export { LogLevel };

const mergeTags = (tags: string[], opts: LoggerOptions) => {
  return [`env:${opts.env}`, `version:${opts.release}`].concat(tags);
};
export interface ConsoleTransportOptions {
  level: LogLevel;
}

let consoleWraped = false;

export interface LoggerOptions {
  console?: ConsoleTransportOptions;
  datadog?: DatadogTransportOptions;
  isTest: boolean;
  userAgent: string;
  env: Env;
  release: string;
  level: LogLevel;
}

function createDatadogTransport(options: DatadogTransportOptions, rootOptions: LoggerOptions) {
  return new DatadogTransport({
    ...options,
    tags: mergeTags(options.tags || [], rootOptions),
  });
}

function createClosureConsola(consa: ConsolaInstance, moduleName?: string): ILogger {
  return {
    info: !moduleName
      ? consa.info
      : (message: string, ...meta: any[]) => {
          return consa.info(`[${moduleName}] ${message}`, ...meta);
        },
    debug: !moduleName
      ? consa.debug
      : (message: string, ...meta: any[]) => {
          return consa.debug(`[${moduleName}] ${message}`, ...meta);
        },
    warn: !moduleName
      ? consa.warn
      : (message: string, ...meta: any[]) => {
          return consa.warn(`[${moduleName}] ${message}`, ...meta);
        },
    error: !moduleName
      ? consa.error
      : (message: string, context?: object | undefined, error?: Error | undefined) => {
          return consa.error(`[${moduleName}] ${message}`, context, error);
        },
    child: (moduleName: string) => {
      return createClosureConsola(consa, moduleName);
    },
  };
}

export const createCommonLogger = (options: LoggerOptions): ILogger => {
  const reporters: ConsolaReporter[] = [];
  let ddLogger: DatadogTransport | undefined;
  if (options.datadog?.apiKey) {
    ddLogger = createDatadogTransport(options.datadog, options);
    reporters.push(ddLogger);
  }

  reporters.forEach((rep) => {
    consola.addReporter(rep);
  });

  // avoid double wrap
  if (!consoleWraped) {
    consola.wrapConsole();
    consoleWraped = true;
  }

  consola.level = logLevelToConsolaLevel(options.level);

  return {
    ...createClosureConsola(consola),
    drain: () => {
      ddLogger?.drain();
    },
  };
};
