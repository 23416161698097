import { useRef, useState, forwardRef, useImperativeHandle, ForwardedRef } from 'react';
import { BrowserUploadFile, BrowserBloomer, BrowserMe, Profil } from '@bloomays-lib/types.shared';
import { formatSpace, removePointsAndSpaces, validatePhoneForE164Regex } from '@bloomays-lib/utils.shared';
import { styled } from '@mui/material/styles';

import { Button, TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';
import { Upload } from '../molecules/Upload';
import { TitlePart } from '@bloomays-lib/ui.shared';
import path from 'path';

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: theme.palette.paper.white,
  boxShadow: '2px 2px 20px lightgrey',
  borderRadius: '10px',
  width: '55%',
  margin: '1em auto',
  padding: '1.5em',
}));

export type EditPersonnalInfosProps = {
  onValidated: (data: BrowserBloomer) => Promise<BrowserBloomer | undefined>;
  me?: BrowserMe;
  hideSubmitButton?: boolean;
};

const EditPersonnalInfos = forwardRef(
  ({ onValidated, me, hideSubmitButton }: EditPersonnalInfosProps, ref: ForwardedRef<HTMLDivElement>) => {
    const prepareBloomer = (_me?: { bloomer: BrowserBloomer; profile?: Profil }): any => {
      const _currentProfile = _me?.profile;
      const _currentBloomer = _me?.bloomer;
      return {
        recordId: _currentBloomer?.recordId,
        firstname: _currentBloomer?.firstname || _currentProfile?.name?.givenName,
        lastname: _currentBloomer?.lastname || _currentProfile?.name?.familyName,
        email: _currentBloomer?.email || _currentProfile?.email,
        secondaryEmail: _currentBloomer?.secondaryEmail,
        phoneNumber: _currentBloomer?.phoneNumber ? removePointsAndSpaces(_currentBloomer?.phoneNumber) : undefined,
        slack: _currentBloomer?.slack || false,
        accountManager: _currentBloomer?.accountManager?.fullname,
        accountManagerId: _currentBloomer?.accountManager?.recordId,
      };
    };

    const initFiles = (_me?: { bloomer: BrowserBloomer }): { credentialID?: BrowserUploadFile } => {
      const _currentBloomer = _me?.bloomer;
      return {
        credentialID:
          typeof _currentBloomer?.credentialID === 'string'
            ? {
                path: path.basename(_currentBloomer.credentialID),
                url: _currentBloomer.credentialID,
                size: 0,
              }
            : _currentBloomer?.credentialID,
      };
    };

    const UploadCIRef = useRef<any>(null);
    const [uploading, setUploading] = useState(false);
    const [bloomer, setBloomer] = useState<any>(prepareBloomer(me));
    const [files, setFiles] = useState(initFiles(me));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(ref, () => ({
      validate(e: any) {
        return onSubmit(e);
      },
    }));

    const onSubmit = async (e: { preventDefault: () => void }) => {
      e?.preventDefault();
      if (!UploadCIRef.current.validate()) {
        UploadCIRef?.current?.scrollIntoView();
        return false;
      }

      try {
        const data = Object.assign(bloomer, files);
        setUploading(true);
        await onValidated(data);
        setUploading(false);
      } catch (err) {
        setUploading(false);
      }
      return true;
    };

    return (
      <FormContainer>
        <TitlePart textTitle="À propos de" span="toi" />
        <ValidationTextField
          aria-label="personnalinfos-firstname"
          name="firstname"
          label="Prénom"
          value={bloomer.firstname || ''}
          type={TypeInput.text}
          required={true}
          onChange={(e) => {
            setBloomer({
              ...bloomer,
              firstname: e.target.value,
            });
          }}
          formElement={true}
        />
        <ValidationTextField
          aria-label="personnalinfos-lastname"
          name="lastname"
          label="Nom"
          value={bloomer.lastname || ''}
          type={TypeInput.text}
          required={true}
          onChange={(e) =>
            setBloomer({
              ...bloomer,
              lastname: e.target.value,
            })
          }
          formElement={true}
        />
        <ValidationTextField
          aria-label="personnalinfos-primaryemail"
          name="primaryemail"
          label="Email"
          value={bloomer.email || ''}
          type={TypeInput.email}
          readOnly={true}
          role={'textbox'}
          formElement={true}
        />
        <ValidationTextField
          aria-label="personnalinfos-secondaryemail"
          name="secondaryemail"
          label="Email secondaire"
          value={bloomer.secondaryEmail || ''}
          type={TypeInput.email}
          onChange={(e) =>
            setBloomer({
              ...bloomer,
              secondaryEmail: formatSpace(e.target.value),
            })
          }
          formElement={true}
        />
        <ValidationTextField
          aria-label="personnalinfos-phonenumber"
          name="phoneNumber"
          label="Téléphone"
          placeholder="+33600000000"
          value={bloomer.phoneNumber || ''}
          helperText="Ton numéro doit comporter le caratère + et l'indicatif de ton pays. Ex: +33600000000"
          type={TypeInput.tel}
          pattern={validatePhoneForE164Regex}
          onChange={(e) => {
            setBloomer({
              ...bloomer,
              phoneNumber: removePointsAndSpaces((e.target as HTMLInputElement).value),
            });
          }}
          formElement={true}
        />
        <Upload
          aria-label="personnalinfos-credentialid"
          ref={UploadCIRef}
          id="credentialId"
          placeholder="Pièce d'identité"
          textLabel="Ajouter ma pièce d'identité"
          name="credentialId"
          fileTypes={['.pdf']}
          required={true}
          maxFiles={1}
          maxLength={5000000}
          preloadedFiles={files?.credentialID ? [files?.credentialID] : []}
          autoload={true}
          displayIcons={true}
          handleChange={(acceptedFiles: any[]) =>
            setFiles({
              ...files,
              credentialID: acceptedFiles[0],
            })
          }
        />

        {!hideSubmitButton && (
          <Button
            buttonType="submit"
            none={'none'}
            textButton={uploading ? "En cours d'envoi" : 'Sauvegarder mes données'}
            disable={uploading}
          />
        )}
      </FormContainer>
    );
  },
);

export default EditPersonnalInfos;

export { EditPersonnalInfos };

EditPersonnalInfos.displayName = 'EditPersonnalInfos';
