import React from 'react';
import RawTableContainer from '@mui/material/TableContainer';

import { SingleLineText } from '@bloomays-lib/ui.shared';
import TableMissions from '../molecules/TableMissions';
import { TitlePart, Switch } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { AllMissionGQLItemResult } from '@bloomays-lib/types.shared';

export type FreelanceDashboardProps = {
  dataMissions: AllMissionGQLItemResult[];
  checked: boolean;
  loading: boolean;
  onChange: (event: { target: { checked: boolean } }) => void;
  fetchMissions: () => void;
};

const FreelanceDashboard = ({ checked, loading, onChange, dataMissions, fetchMissions }: FreelanceDashboardProps) => {
  React.useEffect(() => {
    fetchMissions();
  }, [checked, fetchMissions]);

  return (
    <div>
      <TitlePart id="titleAdminDashboard" span="Toutes nos missions" />
      <SingleLineText text="Par défaut, tu vois toutes les missions qui sont en cours !" />
      <SingleLineText text="Avec le bouton toggle en haut à gauche du tableau, tu peux voir TOUTES les missions signées chez nous !" />
      <SingleLineText
        text="Tu as la possibilité de visionner quels bloomers partiront le mois prochain en cliquant sur "
        span={"'Le mois prochain'"}
      />
      <SingleLineText span="À toi de jouer !" />
      <ContainerAboveTable>
        <div>
          <ContainerToggleText>
            <SingleLineText text="Tous" />
            <Switch checked={checked} onSwitch={onChange} label={''} />
            <SingleLineText text="Actifs seulement" />
          </ContainerToggleText>
        </div>
      </ContainerAboveTable>
      <TableContainer>
        <TableMissions fetchLoading={loading} data={dataMissions} />
      </TableContainer>
    </div>
  );
};

export default FreelanceDashboard;
export { FreelanceDashboard };

const TableContainer = styled(RawTableContainer)(() => ({
  width: '90% !important',
  margin: '0 auto',
}));

const ContainerAboveTable = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
  margin: '0.5em auto',
  alignItems: 'flex-end',
  width: '90%',
}));

const ContainerToggleText = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
}));
