import React from 'react';
import { Avatar } from '@bloomays-lib/ui.shared';
import RawButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { SingleLineText } from '@bloomays-lib/ui.shared';

export type buttonSize = 'large' | 'medium' | 'small';
export type AvatarUsernameProps = {
  name: string;
  jobTitle?: string;
  image?: string;
  size: buttonSize;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button = styled(RawButton)(({ theme, ...props }) => ({
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  height: props.size === 'large' ? '60px' : '44px',
  color: theme.palette.grey[500],
  textTransform: 'none',
  '&:hover': {
    borderRadius: '5px',
    backgroundColor: props.onClick ? theme.palette.grey[200] : 'transparent',
    cursor: props.onClick ? 'pointer' : 'auto',
  },
}));

const AvatarUsername = ({ name, jobTitle, image, size, onClick }: AvatarUsernameProps) => {
  return (
    <Button size={size} onClick={onClick ? (e) => onClick(e) : undefined}>
      <Avatar image={image} size={size} alt={name} />
      <StyledDiv>
        <SingleLineText variant={size === 'medium' ? 'body2Medium' : 'subtitle2Medium'} text={name} />
        {jobTitle && <SingleLineText variant={size === 'medium' ? 'caption' : 'body1Light'} text={jobTitle} />}
      </StyledDiv>
    </Button>
  );
};

export default AvatarUsername;
export { AvatarUsername };

const StyledDiv = styled('div')(() => ({
  paddingLeft: '10px',
  textAlign: 'left',
}));
