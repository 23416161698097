import { FocusEventHandler, ReactNode } from 'react';
import { LabelOfInput } from '../atoms/LabelOfInput';
import { Option, SelectField as RawSelectField } from '../atoms/SelectField';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';

const SelectField = styled(RawSelectField)(() => ({}));

type LabelAndSelectFieldProps = {
  value: string | number | readonly string[] | undefined;
  name: string;
  textLabel: string;
  optionValue?: string[];
  options?: Option[];
  handlechange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  required: boolean;
  sx?: SxProps<Theme>;
  defaultOption: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
};

export const LabelAndSelectField = ({
  name,
  value,
  handlechange,
  optionValue,
  options,
  textLabel,
  required,
  sx,
  defaultOption,
  onBlur,
}: LabelAndSelectFieldProps) => {
  return (
    <Container>
      <LabelOfInput textLabel={textLabel} name={textLabel} required={required} />
      <InputContainer>
        <SelectField
          sx={sx}
          name={name}
          value={value}
          optionValue={optionValue}
          options={options}
          handlechange={handlechange}
          required={required}
          defaultOption={defaultOption}
          onBlur={onBlur}
        />
        <span className="validity"></span>
      </InputContainer>
    </Container>
  );
};

export default LabelAndSelectField;

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const InputContainer = styled('div')(() => ({
  width: '65%',
}));
