import React, { Reducer, useEffect, useReducer, useState } from 'react';
import debounce from 'lodash/debounce';
import { SEARCH_TALENT, FIND_SKILLS_BY_LABEL, LIST_JOBS } from '@bloomays-lib/adapter.api-talent';
import HeaderTalentTool from '../organisms/HeaderTalentTool';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import TalentsList from '../organisms/TalentsList';
import { notify } from '../../helpers/toastify';
import Pagination from '../atoms/PaginationV2';
import { useLazyQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { initialStateTalents, reducerTalents } from '../../reducers/reducerTalents';
import { useSearchTalentsParams } from '../../customHooks/useSearchTalentsParams';
import { IJobTitle, ISkill } from '@bloomays-lib/types.shared';
import { LoaderSkeleton, SingleLineText } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { errorLogger } from '../../helpers/error';
import { Action, State } from '../../types/talents';
import { getOr } from 'lodash/fp';

const TalentSearch = (): JSX.Element => {
  const { t } = useTranslation(['random', 'talentTool']);

  const { searchTalentParams, setSearchTalentsParams } = useSearchTalentsParams(initialStateTalents);

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducerTalents, searchTalentParams);

  const [searchTalents, { data, loading, error }] = useLazyQuery(SEARCH_TALENT, {
    variables: {
      search: state.search,
      paginate: state.paginate,
    },
    context: { clientName: 'api.talents' },
    fetchPolicy: 'network-only',
  });

  const [listJobTitles, { data: dataListJobTitles, loading: loadingListJobTitles }] = useLazyQuery<{
    listJobTitles: IJobTitle[];
  }>(LIST_JOBS, {
    fetchPolicy: 'network-only',
    context: { clientName: 'api.talents' },
    variables: {
      iso: true,
    },
  });

  const [findManySkillFromLabel, { data: dataSkillsFromLabel, loading: loadingSkillsFromLabel }] = useLazyQuery<{
    findManySkillsFromLabel: ISkill[];
  }>(FIND_SKILLS_BY_LABEL, {
    fetchPolicy: 'network-only',
    context: { clientName: 'api.talents' },
  });

  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(data?.searchTalents?.count);
  const [jobsState, setJobsState] = useState<IJobTitle[] | undefined>(dataListJobTitles?.listJobTitles);
  const [skillsState, setSkillsState] = useState<ISkill[] | undefined>(
    dataSkillsFromLabel?.findManySkillsFromLabel || (getOr([], 'search.skills.skills', state) as ISkill[]),
  );

  const debounceDispatch = debounce(dispatch, 500);
  const limit = 20;

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await listJobTitles();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setSkillsState(dataSkillsFromLabel?.findManySkillsFromLabel);
  }, [dataSkillsFromLabel]);

  React.useEffect(() => {
    setJobsState(dataListJobTitles?.listJobTitles);
  }, [dataListJobTitles]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    if (event) {
      event.preventDefault();
    }
    if (page < newPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      searchTalents({
        variables: {
          search: state.search,
          paginate: {
            skip: limit * (newPage - 1),
            take: limit,
          },
        },
      });
    } else if (page > newPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      searchTalents({
        variables: {
          search: state.search,
          paginate: {
            skip: (newPage - 1) * limit,
            take: limit,
          },
        },
      });
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    searchTalents();
  }, [searchTalents, state]);

  React.useEffect(() => {
    if (page === 1) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      searchTalents({
        variables: {
          search: state.search,
          paginate: {
            skip: 0,
            take: limit,
          },
        },
      });
    }
  }, [searchTalents, page, state.search]);

  React.useEffect(() => {
    setCountPage(data?.searchTalents?.count);
  }, [data?.searchTalents?.count]);

  useEffect(() => {
    setSearchTalentsParams(state);
  }, [state, setSearchTalentsParams]);

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve talents list !',
    });
    notify('error', "Oups, j'ai pas réussi à récupérer les talents, ré-essaye plus tard ! 😓", error);
  }

  const getSkills = async (skillSearched: string) => {
    await findManySkillFromLabel({
      variables: { label: skillSearched.toLocaleLowerCase() },
      context: { clientName: 'api.talents' },
    });
  };

  const getSkillsDebounced = debounce(getSkills, 500);

  const onSearchSkill = async (search: string) => {
    if (search.length >= 3) {
      await getSkillsDebounced(search);
    }

    if (search.length === 0) {
      setSkillsState([]);
    }
  };
  return (
    <StyledTalentToolContainer>
      <HeaderTalentTool
        setPage={setPage}
        dispatch={debounceDispatch}
        state={state}
        searchTalents={searchTalents}
        jobsState={jobsState}
        skillsState={skillsState}
        onSearchSkill={onSearchSkill}
        loadingSkills={loadingSkillsFromLabel}
        loadingJobs={loadingListJobTitles}
      />
      <StyledContainerCount>
        <SingleLineText
          text={`${t('talentCount', { ns: 'talentTool' })}: ${data?.searchTalents.count || '-'} talents 🥳`}
        />
      </StyledContainerCount>
      {loading ? (
        <LoaderSkeleton label={t('loadingText', { ns: 'random' })} height={600} width={1000} />
      ) : (
        <TalentsList talents={data?.searchTalents?.talents} />
      )}
      <StyledContainerPagination>
        <Pagination count={Math.ceil(countPage / limit)} page={page} onChange={handleChangePage} />
      </StyledContainerPagination>
    </StyledTalentToolContainer>
  );
};

export default WithErrorBoundary(TalentSearch);

const StyledContainerPagination = styled('div')(
  () => `
  display: flex;
  justify-content: flex-end;
  margin: 24px auto auto auto;
  width: 100%;
`,
);

const StyledTalentToolContainer = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 80%;
    margin: auto;
    min-height: 700px;
  `,
);

const StyledContainerCount = styled('div')(
  () => `
    display: flex;
    flex-direction: flex-start;
    padding-left: 3px;
    margin: 8px 0px;
  `,
);
