import search from '../../graphic-charter/icons/search.svg';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { Logo } from '@bloomays-lib/ui.shared';
import { forwardRef, useEffect } from 'react';

export type SearchBarProps = {
  placeholder: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  ref?: React.RefObject<HTMLInputElement>;
  searchValue?: string;
};

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.secondary.dark,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.paper.white,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.dark,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
    '&.Mui-focused': {
      color: theme.palette.grey[100],
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.grey[200],
    height: '42px',
    color: theme.palette.grey[100],
    borderRadius: '5px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[900],
    },
    '&.Mui-disabled fieldset': {
      borderColor: 'white',
    },
  },
}));

const SearchBar = forwardRef(({ placeholder, onChange, disabled, searchValue }: SearchBarProps, ref: any) => {
  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  return (
    <CssTextField
      inputRef={ref}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Logo image={search} />
          </InputAdornment>
        ),
      }}
      size="small"
      placeholder={placeholder}
      value={searchValue}
      onChange={onChange}
      fullWidth
    />
  );
});

export default SearchBar;
export { SearchBar };
