import styled from '@emotion/styled';
import RawErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RawInfoIcon from '@mui/icons-material/Info';
import { ReactNode } from 'react';

type AlertMessageProps = {
  icon?: 'info' | 'error';
  title: string;
  description: ReactNode | string;
};

const getIcon = (icon: string) => {
  switch (icon) {
    case 'info':
      return <InfoIcon />;
    case 'error':
      return <ErrorOutlineIcon />;
    default:
      return <InfoIcon />;
  }
};

export const AlertMessage = ({ icon, title, description }: AlertMessageProps) => {
  return (
    <AletMessageContainer>
      {icon && getIcon(icon)}
      <TextWrapper>
        <Title>{title}</Title>
        <span>{description}</span>
      </TextWrapper>
    </AletMessageContainer>
  );
};

const AletMessageContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'start',
  gap: '10px',
}));

const iconCSS = () => ({
  width: 20,
});

const ErrorOutlineIcon = styled(RawErrorOutlineIcon)(iconCSS);
const InfoIcon = styled(RawInfoIcon)(iconCSS);

const TextWrapper = styled('div')(() => ({
  textAlign: 'left',
}));

const Title = styled('h4')(() => ({
  margin: 0,
}));
