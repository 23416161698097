import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export type MenuItemProps = {
  shape: 'square' | 'roundUp' | 'roundBottom';
  url: string;
  name: string;
};

const getShape = (shape: string) => {
  switch (shape) {
    case 'roundBottom':
      return '0px 0px 5px 5px;';
    case 'roundUp':
      return '5px 5px 0px 0px';
    case 'square':
    default:
      return '0px 0px 0px 0px';
  }
};

const StyledNavLink = styled(
  NavLink,
  {},
)(({ theme }) => {
  return {
    minHeight: '0px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingLeft: '24px',
    color: theme.palette.grey[100],
    backgroundColor: theme.palette.paper.white,
    height: '55px',
    fontFamily: theme.typography.body1Regular.fontFamily,
    fontSize: theme.typography.body1Regular.fontSize,
    fontWeight: theme.typography.body1Regular.fontWeight,
    width: '100%',
    margin: '0px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary[500],
      color: theme.palette.secondary.dark,
      fontFamily: theme.typography.body2SemiBold.fontFamily,
      fontSize: theme.typography.body2SemiBold.fontSize,
      fontWeight: theme.typography.body2SemiBold.fontWeight,
    },
  };
});

const MenuItem = ({ shape, url, name }: MenuItemProps) => {
  return (
    <StyledNavLink
      sx={() => {
        const borderRadius = getShape(shape);
        return {
          borderRadius: borderRadius,
        };
      }}
      to={url}
    >
      {name}
    </StyledNavLink>
  );
};

export default MenuItem;
export { MenuItem };
