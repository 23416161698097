import React, { useCallback, useDeferredValue, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

import {
  GridActionsCellItem,
  GridEventListener,
  GridRenderCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridColDef,
  GridRowParams,
  GridCellParams,
  GridValueGetterParams,
  gridClasses,
  useGridApiRef,
  GridColTypeDef,
  useGridApiContext,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import RawDataGrid from '../molecules/DataGrid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import CancelIcon from '@mui/icons-material/Close';
import { ILabels } from '@bloomays-lib/types.shared';
import Checkbox from '@mui/material/Checkbox';
import { Button, LoaderPending } from '@bloomays-lib/ui.shared';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import { FreeSoloAutoComplete } from '../atoms/FreeSoloAutoComplete';
import { IFuseOptions } from 'fuse.js';
import { Slider } from '../atoms/Slider';
import useFuse from '../../customHooks/useFuse';
import { notify } from '../../helpers/toastify';
const DEFAULT_MERGE_VALUE = 'choose_iso';

export const cleanEditableLabelBeforeUpdate = <T extends ILabels>(row: ILabelEditable<T>): T => {
  let alt = row.alternateLabels as unknown as string[] | string | undefined;
  if (alt) {
    if (!Array.isArray(alt)) {
      const alts = alt.split ? alt.split(',') : [];
      alt = alts.filter((l) => {
        return !!l && l !== '';
      });
    } else {
      // avoid readlony object
      alt = [...row.alternateLabels];
    }
    alt = uniq(flatMap(alt, (a) => a.split('\n')));
    return omit({ ...row, alternateLabels: alt }, ['merge', 'isNew']) as unknown as T;
  }
  return omit({ ...row }, ['merge', 'isNew']) as unknown as T;
};

export const cleanEditableLabelBeforeCreate = <T extends ILabels>(row: ILabelEditable<T>): T => {
  const cleanRow = cleanEditableLabelBeforeUpdate(row);
  return omit(cleanRow, ['merge', 'isNew', 'id']) as unknown as T;
};

type ILabelEditable<T extends ILabels> = T & {
  isNew?: boolean;
  merge?: string;
};

export type LabelManagmentProps<T extends ILabels> = {
  labels: T[];
  onError: (error: any) => void;
  onUpdate: (newRow: T, oldRow: T) => Promise<T | undefined>;
  onCreate?: (newRow: T) => Promise<T | undefined>;
  onFlat?: (labelId: string) => Promise<T[] | undefined>;
  onDelete: (labelId: string) => Promise<T | undefined>;
  onMerge: (mergeToLabelId: string, mergeInLabelId: string) => Promise<T | undefined>;
  onDeleteUnusedLabels?: () => Promise<number>;
};

const DataGrid = styled(RawDataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const LabelManagment = <T extends ILabels>({
  labels,
  onError,
  onUpdate,
  onDelete,
  onMerge,
  onCreate,
  onFlat,
  onDeleteUnusedLabels,
}: LabelManagmentProps<T>): JSX.Element => {
  const [rows, setRows] = React.useState<ILabelEditable<T>[]>(labels);
  const [pending, setPending] = React.useState<boolean>(false);
  const [isoRows, setIsoRows] = React.useState<ILabelEditable<T>[]>(labels.filter((s) => s.iso));
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [rowsSelected, setRowsSelected] = React.useState<ILabelEditable<T>[]>([]);
  const [rowsSelectedLabel, setRowsSelectedLabel] = React.useState<string>();
  const resolveRows = useCallback(() => Promise.resolve(isoRows), [isoRows]);
  const [sliderValue, setSliderValue] = React.useState<number>(0.2);
  const [fuseThreshold, setFuseThreshold] = React.useState<number>(0.2);
  const [fuseOptions, setFuseOptions] = React.useState<IFuseOptions<ILabelEditable<T>>>({});
  const deferredFuseThreshold = useDeferredValue(fuseThreshold);

  const apiRef = useGridApiRef();

  React.useEffect(() => {
    const handleEvent: GridEventListener<'rowSelectionChange'> = (
      params, // GridRowSelectionCheckboxParams
      event, // MuiEvent<React.ChangeEvent<HTMLElement>>
      details, // GridCallbackDetails
    ) => {
      setRowsSelected(rows.filter((t) => params.includes(t.id || 0)));
    };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent('rowSelectionChange', handleEvent);
  }, [apiRef, rows]);

  useEffect(() => {
    const initRowModel: GridRowModesModel = {};
    labels.forEach((t) => {
      if (t.id) {
        initRowModel[t.id] = { mode: GridRowModes.Edit };
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFuseOptions({
      keys: ['label', 'alternateLabels'],
      threshold: deferredFuseThreshold,
      shouldSort: true,
    });
  }, [deferredFuseThreshold]);

  const searchFuse = useFuse<ILabelEditable<T>>(isoRows, fuseOptions);

  const EditToolbar = useCallback(() => {
    const handleClick = () => {
      const id = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      setRows((oldRows) => [
        {
          id,
          label: '',
          alternateLabels: [],
          iso: false,
          isNew: true,
        } as unknown as any,
        ...oldRows,
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'label' },
      }));
    };

    return onCreate && <Button key={'customHeaderButton'} onClick={handleClick} textButton="Add record"></Button>;
  }, [onCreate]);

  useEffect(() => {
    setIsoRows(rows.filter((s) => s.iso));
  }, [setIsoRows, rows]);

  const setPendingNow = (pendingState: boolean) => {
    setTimeout(() => {
      setPending(pendingState);
    }, 0);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params: any, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.View } }));
  };

  const handleFlatClick = (id: GridRowId) => async () => {
    setPendingNow(true);
    if (onFlat === undefined) {
      return;
    }
    const flatedLabels = await onFlat(id.toString());
    setPendingNow(false);
    const newRows =
      flatedLabels?.length && flatedLabels?.length > 0
        ? rows.filter((row) => row.id.toString() !== id.toString())
        : rows;
    if (flatedLabels) {
      flatedLabels.forEach((flatedLabel) => {
        // un flat peu renvoyer une row deja existante, ne pas la rajouter
        if (!newRows.find((row) => row.id === flatedLabel.id)) {
          newRows.push({ ...flatedLabel, isNew: false });
        }
      });
    }

    setRows(newRows);
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    setPendingNow(true);
    const deletedLabel = await onDelete(id.toString());
    setPendingNow(false);
    if (deletedLabel) {
      setRows(rows.filter((row) => row.id.toString() !== deletedLabel.id));
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: ILabelEditable<T>, originalRow: T) => {
    setPendingNow(true);
    if (newRow.merge && newRow.merge !== DEFAULT_MERGE_VALUE && newRow.merge !== '') {
      return onMerge(originalRow.id, newRow.id).then((s) => {
        setRows(rows.filter((row) => row.id.toString() !== originalRow.id));
        setPendingNow(false);
        return s;
      });
    }

    if (newRow.isNew && onCreate) {
      const serverNewRow = await onCreate(cleanEditableLabelBeforeCreate(newRow));
      setPendingNow(false);
      if (serverNewRow) {
        setRows(rows.map((row) => (row.id === newRow.id ? { ...newRow, id: serverNewRow.id, isNew: false } : row)));
      }
      return serverNewRow;
    }

    setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
    const cleanedNewRow = cleanEditableLabelBeforeUpdate(newRow);
    const cleanedOriginalRow = cleanEditableLabelBeforeUpdate(originalRow);
    const resUp = await onUpdate(cleanedNewRow, cleanedOriginalRow);
    setPendingNow(false);
    return resUp;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const selectedRowUpdate = useCallback(() => {
    setRows(
      rows.map((row) => {
        if (rowsSelected.some((rS) => rS.id === row.id)) {
          return { ...row, merge: rowsSelectedLabel };
        }
        return row;
      }),
    );
    // turn row on edit mode
    const editableRows: GridRowModesModel = {};
    rowsSelected.forEach((rS) => {
      if (rS.id && rows.find((r) => r.id === rS.id)) {
        editableRows[rS.id as string] = { mode: GridRowModes.Edit };
      }
    });
    setRowModesModel((prev) => ({ ...rowModesModel, ...editableRows }));
  }, [rows, rowsSelected, rowModesModel, rowsSelectedLabel]);

  const handleSliderChange = useCallback(
    (event: Event, value: number | number[]) => {
      setSliderValue(Array.isArray(value) ? value?.[0] || 0 : value);
    },
    [setSliderValue],
  );

  const handleSliderCommited = useCallback(
    (event: React.SyntheticEvent | Event, value: number | number[]) => {
      setFuseThreshold(Array.isArray(value) ? value?.[0] || 0 : value);
    },
    [setFuseThreshold],
  );

  const setTagFromFreeSolo = useCallback(
    (tags: string[]) => {
      const label = tags?.[0] ?? undefined;
      setRowsSelectedLabel(label);
    },
    [setRowsSelectedLabel],
  );

  const SliderFuse = useCallback(() => {
    const sliderMarks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
    ];
    return (
      <>
        {rowsSelected?.length > 0 && (
          <>
            <FreeSoloAutoComplete
              key={'customHeaderFreeSolo'}
              tags={rowsSelectedLabel ? [rowsSelectedLabel] : []}
              loading={false}
              multiple={false}
              freeSolo={false}
              setTag={setTagFromFreeSolo}
              collectTags={resolveRows}
              label={''}
              placeholder={''}
              initTags={isoRows}
            />
            <Button disable={rowsSelectedLabel === undefined} textButton={'Appliquer'} onClick={selectedRowUpdate} />
          </>
        )}
        <Slider
          key={'customHeaderSlider'}
          value={sliderValue}
          defaultValue={sliderValue}
          label={'Sensibilité matching'}
          min={0.0}
          max={1}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderCommited}
          step={0.01}
          marks={sliderMarks}
          sx={{ width: 150, padding: '4px 20px 0px 10px' }}
        />
      </>
    );
  }, [
    handleSliderChange,
    handleSliderCommited,
    isoRows,
    resolveRows,
    rowsSelected,
    rowsSelectedLabel,
    selectedRowUpdate,
    setTagFromFreeSolo,
    sliderValue,
  ]);

  function CustomEditAutoComplete(props: GridRenderEditCellParams<ILabelEditable<T>>) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef();

    const setTag = useCallback(
      (tags: string[]) => {
        const jobTitle = tags?.[0] ?? undefined;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        apiRef.current.setEditCellValue({
          id,
          field,
          value: jobTitle,
          debounceMs: 200,
        });
      },
      [apiRef, field, id],
    );

    React.useLayoutEffect(() => {
      if (hasFocus) {
        (ref.current as any)?.focus();
      }
    }, [hasFocus]);
    return (
      <FreeSoloAutoComplete
        tags={value ? [value] : []}
        loading={false}
        multiple={false}
        freeSolo={false}
        setTag={setTag}
        collectTags={resolveRows}
        label={''}
        placeholder={''}
        initTags={isoRows}
      />
    );
  }

  const isoJobTitle: GridColTypeDef<ILabelEditable<T>> = {
    type: 'string',
    width: 130,
    renderEditCell: (params: GridRenderCellParams<ILabelEditable<T>>) => {
      return <CustomEditAutoComplete {...params} />;
    },
    valueGetter: (params: GridValueGetterParams<ILabelEditable<T>>) => {
      const label = params.row.label;
      if (!label || params.row.iso === true) {
        return undefined;
      }

      if (params.value) {
        return params.value;
      }
      const search = searchFuse(label);
      return search?.[0]?.item.label || undefined;
    },
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      editable: true,
      minWidth: 300,
    },
    {
      field: 'label',
      headerName: 'Label',
      sortable: true,
      editable: true,
      minWidth: 300,
    },
    {
      field: 'iso',
      headerName: 'Normalisé (iso)',
      sortable: false,
      groupable: false,
      filterable: true,
      minWidth: 150,
      align: 'center',
      editable: true,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams<T>) => {
        return <Checkbox name={'iso'} checked={params.row.iso} disabled={true} />;
      },
    },
    {
      field: 'alternateLabels',
      headerName: 'Labels Alternatifs',
      sortable: false,
      filterable: false,
      editable: true,
      minWidth: 300,
      valueGetter: (value: GridValueGetterParams<T>) => {
        if (value.row.alternateLabels && value.row.alternateLabels.join) {
          return value.row.alternateLabels?.join(',') || '';
        }
      },
      valueParser: (value: string) => {
        return value?.split(',') || [];
      },
    },
    {
      field: 'merge',
      headerName: 'Fusionner avec...',
      editable: true,
      sortable: false,
      filterable: false,
      minWidth: 400,
      ...isoJobTitle,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      filterable: false,
      width: 150,
      headerName: 'Actions',
      getActions: (params: GridRowParams<T>) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              role="button"
              aria-label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<AltRouteIcon />}
              label="Split"
              role="button"
              disabled={onFlat === undefined}
              aria-label="Split"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleFlatClick(params.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              role="button"
              aria-label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            role="button"
            aria-label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            disabled={params.row.iso}
            label="Delete"
            role="button"
            aria-label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <div>
      <LoaderPending text="Mise à jour en cours, veuillez patienter ..." open={pending} />
      {labels && labels.length > 0 && (
        <DataGrid
          uniqueDatagridId="label-managment"
          apiRef={apiRef}
          childrenToolBar={
            <>
              {SliderFuse()}
              {EditToolbar()}
            </>
          }
          isCellEditable={(params: GridCellParams<T>) => {
            if (params.field === 'merge' && params.row.iso) {
              return false;
            }
            return true;
          }}
          pagination={true}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          hideFooterPagination={false}
          rows={rows}
          getRowId={(row: T) => {
            return row.id;
          }}
          columns={columns}
          pageSize={50}
          sx={{
            width: '100%',
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={onError}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          checkboxSelection
        />
      )}
      {onDeleteUnusedLabels && (
        <>
          <br />
          <br />
          <Button
            textButton="Supprimer les items non utiliséss"
            onClick={async () => {
              const deleted = await onDeleteUnusedLabels();
              notify('success', `${deleted || 0} items suppriméss`, null, {
                hideProgressBar: true,
                autoClose: 2000,
              });
            }}
          />
        </>
      )}
    </div>
  );
};
export { LabelManagment };
export default LabelManagment;
