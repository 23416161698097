import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import RawMainCard from '../atoms/MainCard';
import { LoaderSkeleton, SingleLineText, TitlePart } from '@bloomays-lib/ui.shared';
import RawCallMadeIcon from '@mui/icons-material/CallMade';
import RawCallReceivedIcon from '@mui/icons-material/CallReceived';
import RawLoopIcon from '@mui/icons-material/Loop';

export type EarningCardTypes = {
  isLoading: boolean;
  data: string;
  title: string;
  up: boolean;
  percentageDif: string;
  month: string;
  beforeLastMonthName: string;
};

const EarningCard = ({
  isLoading,
  title,
  data,
  up,
  percentageDif,
  month,
  beforeLastMonthName,
}: EarningCardTypes): JSX.Element => {
  let iconEvolution = <CallReceivedIcon />;

  if (up) {
    iconEvolution = <CallMadeIcon />;
  }
  if (Number(percentageDif) === 0) {
    iconEvolution = <LoopIcon />;
  }

  return (
    <div>
      {isLoading ? (
        <LoaderSkeleton label="" height={160} width={550} />
      ) : (
        <MainCard sx={{ padding: '20px !important' }}>
          <Grid container direction="column">
            <SingleLineText sx={{ color: 'grey', textAlign: 'left' }} variant={'subtitle2'} text={month} />
            <SingleLineText sx={{ color: 'grey', textAlign: 'left' }} variant={'h4'} text={data} />
            <ContainerIcon>
              {iconEvolution}
              <SingleLineText
                sx={{ color: 'grey' }}
                variant={'subtitle2'}
                text={`${percentageDif} % versus ${beforeLastMonthName.toLowerCase()}`}
              />
            </ContainerIcon>
            <SubHeadingTitlePart textTitle={title} />
          </Grid>
        </MainCard>
      )}
    </div>
  );
};

export default EarningCard;
export { EarningCard };

const MainCard = styled(RawMainCard)(({ theme }) => ({
  color: theme.palette.paper.white,
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '210px',
    height: '210px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: '-85px',
    right: '-95px',
    [theme.breakpoints.down('sm')]: {
      top: '-105px',
      right: '-140px',
    },
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '210px',
    height: '210px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: '-125px',
    right: '-15px',
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: '-155px',
      right: '-70px',
    },
  },
}));

const SubHeadingTitlePart = styled(TitlePart)(({ theme }) => ({
  color: theme.palette.grey[400],
  paddingTop: '1em',
}));

const CallMadeIcon = styled(RawCallMadeIcon)(({ theme }) => ({
  color: theme.palette.tertiary.greenMedium,
}));

const CallReceivedIcon = styled(RawCallReceivedIcon)(({ theme }) => ({
  color: theme.palette.tertiary.redLight,
}));

const LoopIcon = styled(RawLoopIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const ContainerIcon = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
}));
