import RawCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import RawCardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Button as RawButton } from '@bloomays-lib/ui.shared';
import { CRAStatus, InvoiceStatus } from '@bloomays-lib/types.shared';
import { styled } from '@mui/material/styles';
import Checkbox from '../atoms/Checkbox';
import { useState } from 'react';

export type ActionForceCRAProps = {
  activityId: string;
  craStatus: CRAStatus;
  clickHandler: (force: boolean) => Promise<void>;
};

const ActionForceCRA = ({ activityId, craStatus, clickHandler }: ActionForceCRAProps): JSX.Element => {
  const [force, setForce] = useState<boolean>(false);
  const [bypass, setBypass] = useState<boolean>(false);
  return (
    <Card>
      <CardContent>
        <StyledTypo>{activityId}</StyledTypo>
        <ContainerData>
          <ContainerTextLabel>
            <CenteredSingleLineText text={`Etat : `} span={craStatus} />
          </ContainerTextLabel>
          <ContainerTextLabel>
            <CenteredSingleLineText text={`ID activité :`} span={activityId} />
          </ContainerTextLabel>
        </ContainerData>
      </CardContent>
      <CardActions>
        <Checkbox
          label={'Forcer la validation'}
          id={'force'}
          name={'force'}
          checked={force}
          onChange={(e, checked) => setForce(checked)}
        />
        <Button id="handleInvoice" onClick={() => clickHandler(force)} textButton="Valider le CRA" />
      </CardActions>
    </Card>
  );
};

export default ActionForceCRA;
export { ActionForceCRA };

const Card = styled(RawCard)(() => ({
  width: '100%',
  margin: '1.5em 0',
  paddingBottom: 10,
  paddingTop: 10,
  boxShadow: '2px 2px 20px lightgrey !important',
  borderRadius: '10px !important',
}));

const StyledTypo = styled(Typography)(() => ({
  padding: '0px 0px 16px 16px',
  fontWeight: 'bold',
}));

const ContainerData = styled('div')(() => ({
  paddingLeft: 16,
}));

const CenteredSingleLineText = styled(SingleLineText)(() => ({
  display: 'flex',
  margin: '.5em 0',
}));

const LeftSingleLineText = styled(SingleLineText)(() => ({
  display: 'flex',
  margin: '.5em 0',
}));

const CardActions = styled(RawCardActions)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const ContainerTextLabel = styled('div')(() => ({
  display: 'flex',
}));

const Button = styled(RawButton)(({ theme }) => ({
  border: 'none',
  padding: '0.7em 1.4em',
  marginRight: 10,
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.paper.white,
  boxShadow: '2px 2px 5px rgb(201, 201, 201)',
  paddingRight: '16px',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.white,
    color: theme.palette.secondary.dark,
    transition: '0.52s ease',
  },
}));
