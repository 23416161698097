import React from 'react';
import MuiTabs from '@mui/material/Tabs';
import RawMuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Theme, styled } from '@mui/material/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export type TabsProps = {
  titles: string[];
  children: React.ReactNode;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  variant: 'navbar' | 'menu' | 'smallMenu' | 'sidePage';
};

const getCss = (variant: string, theme: Theme) => {
  switch (variant) {
    case 'navbar':
      return {
        padding: '8px',
        span: '0px',
        flexFlow: 'row nowrap',
        height: '36px',
        borderRadius: '0px',
        bgColorHovered: theme.palette.paper.white,
      };
    case 'menu':
      return {
        span: '6px',
        padding: '8px',
        flexFlow: 'row nowrap',
        borderRadius: '4px',
        height: '40px',
        bgColorHovered: theme.palette.secondary[500],
      };
    case 'smallMenu':
      return {
        span: '0px',
        padding: '6px 2px 6px 2px',
        flexFlow: 'row nowrap',
        height: '36px',
        width: '42px',
        borderRadius: '4px',
        bgColorHovered: theme.palette.secondary[500],
      };
    case 'sidePage':
    default:
      return {
        span: '0px',
        padding: '6px 10px 6px 10px',
        flexFlow: 'column nowrap',
        borderRadius: '10px 0px 0px 10px',
        height: '57px',
        width: '40px',
        bgColorHovered: theme.palette.secondary[500],
      };
  }
};

const MuiTab = styled(RawMuiTab)(({ theme }) => {
  return {
    backgroundColor: theme.palette.secondary[500],
    color: theme.palette.secondary.dark,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '20px',
    marginBottom: '16px',
    '&:hover': {
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
    '&:focus': {
      color: theme.palette.secondary.dark,
      cursor: 'pointer',
    },
  };
});

const Tabs = ({ titles, value, setValue, children, variant }: TabsProps) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (setValue) setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          textColor={'inherit'}
          value={value}
          selectionFollowsFocus={true}
          onChange={handleChange}
          aria-label="tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'transparent',
            },
          }}
        >
          {titles.map((title, i) => {
            return (
              <MuiTab
                key={`tabId-${i}`}
                sx={(theme) => {
                  const props = getCss(variant, theme);
                  return {
                    width: props?.width ? props.width : 'auto',
                    flexFlow: props.flexFlow,
                    borderRadius: props.borderRadius,
                    padding: props.padding,
                    '&:hover': {
                      backgroundColor: props.bgColorHovered,
                    },
                    '&:focus': {
                      backgroundColor: props.bgColorHovered,
                    },
                  };
                }}
                label={title}
                {...a11yProps(i)}
              />
            );
          })}
        </MuiTabs>
      </Box>
      {children}
    </Box>
  );
};

export default Tabs;
export { Tabs, TabPanel };
