import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { initMonit } from './helpers/monitoring';
import App from './app/app';
import { Theming } from '@bloomays-lib/ui.shared';
import CssBaseline from '@mui/material/CssBaseline';
import './main.css';

import { AuthProvider } from './auth/AuthProvider';
import { Auth0ProviderWithNavigate, Auth0Context } from './auth/Auth0ProviderWithNavigate';

import TagManager from 'react-gtm-module';
import AuthorizedApolloProvider from './auth/AuthorizedApolloProvider';
import { Logger } from './services/serviceLogger';
import { NotificationProvider } from './contexts/NotificationsProvider';

if (import.meta.env.VITE_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-58SDQL4',
  };
  TagManager.initialize(tagManagerArgs);
}

const logger = Logger('Main');

initMonit(logger);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Auth0ProviderWithNavigate>
      <Auth0Context.Consumer>
        {({ user, isAuthenticated, isLoading, error, getAccessTokenSilently, loginWithRedirect, logout }) => (
          <Theming>
            <CssBaseline />
            <AuthProvider
              location={window.location.href}
              user0={user}
              isAuthenticated0={isAuthenticated}
              isLoading0={isLoading}
              error0={error}
              getAccessTokenSilently0={getAccessTokenSilently}
              loginWithRedirect0={loginWithRedirect}
              logout0={logout}
            >
              <AuthorizedApolloProvider location={window.location.href}>
                <NotificationProvider>
                  <App />
                </NotificationProvider>
              </AuthorizedApolloProvider>
            </AuthProvider>
          </Theming>
        )}
      </Auth0Context.Consumer>
    </Auth0ProviderWithNavigate>
  </StrictMode>,
);
