import * as React from 'react';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

export type PaginationProps = {
  count: number;
  page: number;
  onChange: ((event: React.ChangeEvent<unknown>, page: number) => void) | undefined;
};

const StyledPaginationItem = styled(PaginationItem)(
  ({ theme }) => `
  color: ${theme.palette.grey[100]};
  `,
);

const Pagination = ({ count, page, onChange }: PaginationProps) => {
  const theme = useTheme();
  return (
    <MuiPagination
      count={count || 0}
      page={page}
      onChange={onChange}
      sx={{
        '& .MuiPaginationItem-page': {
          backgroundColor: theme.palette.grey[200],
          width: '40px',
          height: '40px',
          borderRadius: '80px',
          '&.Mui-selected': {
            background: theme.palette.secondary.dark,
            color: 'white',
            '&:hover': {
              background: theme.palette.secondary.dark,
              color: 'white',
            },
          },
          '&:hover': {
            background: theme.palette.secondary[500],
            color: theme.palette.grey[100],
          },
        },
      }}
      renderItem={(item) => <StyledPaginationItem {...item} />}
    />
  );
};

export default Pagination;
export { Pagination };
