import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SwitchItem from '../atoms/SwitchItem';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import FilterTab from '../atoms/FilterTab';
import { RemoteWork } from '@bloomays-lib/types.shared';
import { Action, ActionType, State } from '../../types/talents';

export type FilterRemoteProps = {
  withTab?: boolean;
  onSwitch: React.Dispatch<Action>;
  state: State;
  remoteWork: { flexible: boolean; remote: boolean; onSite: boolean };
  setRemoteWork: React.Dispatch<
    React.SetStateAction<{
      flexible: boolean;
      remote: boolean;
      onSite: boolean;
    }>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const FilterRemote = ({ onSwitch, state, remoteWork, setRemoteWork, withTab = true, setPage }: FilterRemoteProps) => {
  const status = state.search.availabilityDate;
  const [open, setOpen] = useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, status]);

  const handleSwitchFlexible = (checked: boolean) => {
    setRemoteWork({ ...remoteWork, flexible: checked });

    if (checked) {
      setRemoteWork((prevState) => {
        return { ...prevState, remote: false, onSite: false };
      });
    }

    onSwitch({
      type: ActionType.REMOTE_WORK_SEARCH,
      remoteWork: checked ? RemoteWork.Flexible : undefined,
    });
    setPage(1);
  };

  const handleSwitchRemote = (checked: boolean) => {
    setRemoteWork({ ...remoteWork, remote: checked });

    if (checked) {
      setRemoteWork((prevState) => {
        return { ...prevState, onSite: false, flexible: false };
      });
    }

    onSwitch({
      type: ActionType.REMOTE_WORK_SEARCH,
      remoteWork: checked ? RemoteWork.Remote : undefined,
    });
    setPage(1);
  };

  const handleSwitchOnSite = (checked: boolean) => {
    setRemoteWork({ ...remoteWork, onSite: checked });

    if (checked) {
      setRemoteWork((prevState) => {
        return { ...prevState, flexible: false, remote: false };
      });
    }

    onSwitch({
      type: ActionType.REMOTE_WORK_SEARCH,
      remoteWork: checked ? RemoteWork.OnSite : undefined,
    });
    setPage(1);
  };

  const getNumberFilter = () => {
    let numb = 0;
    if (remoteWork.flexible) {
      numb = numb + 1;
    }
    if (remoteWork.remote) {
      numb = numb + 1;
    }
    if (remoteWork.onSite) {
      numb = numb + 1;
    }
    return numb;
  };

  const StyledContainer = styled('div')(
    ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 20px;
  padding: 24px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 399px;
  position: ${withTab ? 'absolute' : 'none'};
  z-index: ${withTab ? 1 : 0};
`,
  );

  return (
    <div>
      {withTab ? (
        <>
          <FilterTab
            label={'Remote'}
            numberOfFilter={getNumberFilter()}
            onClick={() => setOpen(!open)}
            filled={false}
          />
          {open && (
            <StyledContainer ref={ref}>
              <StyledText text="Remote" variant={'subtitle2Medium'} />
              <StyledList>
                <SwitchItem
                  checked={remoteWork.flexible}
                  label="Flexible"
                  onSwitch={() => handleSwitchFlexible(!remoteWork.flexible)}
                />
                <SwitchItem
                  checked={remoteWork.onSite}
                  label="Présentiel"
                  onSwitch={() => handleSwitchOnSite(!remoteWork.onSite)}
                />
                <SwitchItem
                  checked={remoteWork.remote}
                  label="Remote"
                  onSwitch={() => handleSwitchRemote(!remoteWork.remote)}
                />
              </StyledList>
            </StyledContainer>
          )}
        </>
      ) : (
        <StyledContainer ref={ref}>
          <StyledText text="Remote" variant={'subtitle2Medium'} />
          <StyledList>
            <SwitchItem
              checked={remoteWork.flexible}
              label="Flexible"
              onSwitch={() => handleSwitchFlexible(!remoteWork.flexible)}
            />
            <SwitchItem
              checked={remoteWork.onSite}
              label="Présentiel"
              onSwitch={() => handleSwitchOnSite(!remoteWork.onSite)}
            />
            <SwitchItem
              checked={remoteWork.remote}
              label="Remote"
              onSwitch={() => handleSwitchRemote(!remoteWork.remote)}
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterRemote;
export { FilterRemote };

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
