import { ReactNode } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { styled } from '@mui/material/styles';
import 'react-alice-carousel/lib/alice-carousel.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export type CarouselProps = {
  childs: ReactNode[];
};

const SliderStyled = styled(AliceCarousel)(({ theme }) => ({}));

const SliderContainerStyled = styled('div')(({ theme }) => ({}));

export const Carousel = ({ childs }: CarouselProps): JSX.Element => {
  const settings = {
    disableButtonsControls: true,
  };
  return (
    <SliderContainerStyled>
      <SliderStyled {...settings} items={childs}></SliderStyled>
    </SliderContainerStyled>
  );
};
