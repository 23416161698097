import { SxProps, Theme, styled } from '@mui/material/styles';
import React, { ForwardedRef, forwardRef } from 'react';
import { Link as RawLink, LinkProps } from 'react-router-dom';
import Typography from '@mui/material/Typography';
const Link = styled(RawLink)<LinkProps>(({ theme, color }) => ({
  marginRight: '0.1em',
  display: 'flex',
  alignItems: 'center',
  color: color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
}));

const StyledIcon = styled('span')(
  () => `
  margin-right: 0.70em;
  position: relative;
  top: 0.15em;
  `,
);

export type HrefLinkProps = {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  text?: string;
  classname?: string;
  href: string;
  id?: string;
  title?: string;
  sx?: SxProps<Theme>;
  color?: 'primary' | 'secondary';
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
};

export const HrefLink = forwardRef(
  (
    { text, href, icon, id, title, children, sx, target = '_blank', color = 'primary' }: HrefLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ): JSX.Element => {
    return (
      <Link id={id} to={href} title={title || ''} target={target} rel="noreferrer" sx={sx} ref={ref} color={color}>
        <Typography variant="body1Light" color={color}>
          {icon && <StyledIcon>{icon}</StyledIcon>}
          {text || children}
        </Typography>
      </Link>
    );
  },
);
