import { INotification } from './types.shared';

export enum NotificationType {
  craToValidate = 'craToValidate',
  missingCRA = 'missingCRA',
  reminderCRA = 'reminderCRA',
  relaunchSignClient = 'relaunchSignClient',
  urssaf = 'urssaf',
  signCRA = 'signCRA',
  win = 'win',
  contractSigned = 'contractSigned',
  responseSMS = 'responseSMS',
  signClient = 'signClient',
  newLink = 'newLink',
  guestHouse = 'guestHouse',
  talentBoard = 'talentBoard',
  finishersExtension = 'finishersExtension',
  missionExtended = 'missionExtended',
  remoteLife = 'remoteLife',
}

export enum NotificationPriority {
  info = 'info',
  important = 'important',
  urgent = 'urgent',
}

export enum NotificationCanal {
  sms = 'sms',
  whatsapp = 'whatsapp',
  slack = 'slack',
  doNotDisturbed = 'doNotDisturbed',
  email = 'email',
}

export type NotificationCenterItemData = Partial<INotification> & {
  link?: string;
};
