import DashboardFinance from '../organisms/DashboardFinance';
import { TitlePart } from '@bloomays-lib/ui.shared';
import WithErrorBoundary from '../organisms/ErrorBoundary';

import Box from '@mui/material/Box';

const Finance = (): JSX.Element => {
  return (
    <div>
      <TitlePart id="financeTitle" textTitle={'Les finances de Bloomays'} />
      <Box
        sx={{
          width: '90%',
          margin: '4em auto',
        }}
      >
        <DashboardFinance />
      </Box>
    </div>
  );
};

export default WithErrorBoundary(Finance);
