import React from 'react';
import { Button as RawButton } from './Button';
import { styled } from '@mui/material/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const Button = styled(RawButton)(({ theme }) => ({
  border: 'none',
  padding: '0.7em 3.5em',
  borderRadius: 20,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.paper.white,
  boxShadow: '2px 2px 5px rgb(201, 201, 201)',
  margin: '0.8em auto',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.white,
    color: theme.palette.primary.main,
    transition: '0.5s ease',
  },
}));

export type UseAuthProps = {
  login: (e: {
    appState: {
      target: string;
    };
  }) => void;
  style?: Record<string, unknown>;
};

const LoginButton = ({ login, style }: UseAuthProps): JSX.Element => {
  return <Button style={style} onClick={login} textButton="Me connecter ou m'inscrire" emoji={<ArrowRightAltIcon />} />;
};

export default LoginButton;
export { LoginButton };
