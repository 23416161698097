import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Level } from './Level';
import { Item } from '@bloomays-lib/types.shared';

export type ItemTalentLevelProps = {
  skill: { id?: string; label: string; iso?: boolean }; // TODO !! ceci ne doit pas s'appelle skill mais item !
  onClick?: (e?: any) => void;
  level?: number;
  match?: any;
  disabled?: boolean;
  clickable?: boolean;
  clickableLevel?: boolean;
  current?: boolean;
  onClickCross?: (item: Item) => void;
};

type DivProp = {
  round?: boolean;
  disabled?: boolean;
};

const StyledLabel = styled(Typography)<DivProp>(
  ({ theme, disabled }) => `
  color:  ${disabled ? `${theme.palette.grey[800]}` : `${theme.palette.grey[100]}`};
`,
);

const StyledDiv = styled('div')<DivProp>(
  ({ theme, round, disabled }) => `
  background-color: ${disabled ? `${theme.palette.grey[300]}` : `${theme.palette.secondary[500]}`};
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: center;
  border-radius: ${round ? '30px' : '5px'};
  padding: 4px 6px 4px 6px;
  width: fit-content;
  gap: 7.5px;
  height: 24px;
`,
);

const StyledCloseIcon = styled(CloseIcon)(
  () => `
  &:hover {
    cursor: pointer;
  }
  `,
);

const ItemTalentLevel = ({
  skill,
  onClick,
  clickable,
  level,
  disabled,
  clickableLevel,
  onClickCross,
}: ItemTalentLevelProps) => {
  return (
    <StyledDiv disabled={disabled} round={clickable}>
      <StyledLabel disabled={disabled} variant="body2Medium">
        {skill.label}
      </StyledLabel>
      {(level || level === 0) && <Level onClick={onClick} level={level} clickable={clickableLevel} />}
      {clickable && (
        <StyledCloseIcon
          fontSize="small"
          onClick={
            onClickCross
              ? () => {
                  onClickCross(skill as any);
                }
              : undefined
          }
        />
      )}
    </StyledDiv>
  );
};

export default ItemTalentLevel;
export { ItemTalentLevel };
