import { HrefLink } from '@bloomays-lib/ui.shared';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import Box from '@mui/material/Box';

export type ToastProps = {
  text: string | React.ReactNode | JSX.Element | EmotionJSX.Element;
  link?: string;
  error?: any;
};

const Toast = ({ text, link, error }: ToastProps): JSX.Element => {
  return (
    <>
      {text}
      {error ? (
        <>
          <br />
          <i>{error.toString()}</i>
        </>
      ) : undefined}
      {link ? (
        <HrefLink
          text="👉 Cliquez ici"
          href={link}
          target="_self"
          sx={{
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        />
      ) : undefined}
    </>
  );
};

export default Toast;
export { Toast };
