import { styled } from '@mui/material/styles';
import LoadingLogo from '../atoms/LoadingLogo';

const LoaderGlobal = () => {
  return (
    <LoaderContainer>
      <LoadingLogo />
    </LoaderContainer>
  );
};

export default LoaderGlobal;
export { LoaderGlobal };

const LoaderContainer = styled('div')(() => ({
  height: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
