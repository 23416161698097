import * as React from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';
import memoize from 'lodash/memoize';

/**
 * A React Hook that filters an array using the Fuse.js fuzzy-search library.
 *
 * @param list The array to filter.
 * @param searchTerm The search term to filter by.
 * @param fuseOptions Options for Fuse.js.
 *
 * @returns The filtered array.
 *
 * @see https://fusejs.io/
 */
function useFuse<T>(list: T[], fuseOptions?: IFuseOptions<T>) {
  const fuse = React.useMemo(() => {
    return new Fuse(list, fuseOptions);
  }, [list, fuseOptions]);

  const searchFuse = React.useMemo(() => {
    return memoize(fuse.search.bind(fuse)<T>);
  }, [fuse]);

  return searchFuse;
}

export default useFuse;
