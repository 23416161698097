import CardTalent from '../molecules/CardTalent';
import { TalentProcess } from '@bloomays-lib/types.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

export type TalentGridProps = {
  candidates?: TalentProcess[];
};

const getNotValidatedCandidates = (candidates?: TalentProcess[]) => {
  if (!candidates) return [];
  const notValidated: TalentProcess[] = [];

  candidates.forEach((candidate) => {
    if (candidate.phase === 'NotValidated' || candidate.phase === 'Archive') {
      notValidated.push(candidate);
    }
  });
  return notValidated;
};

const getOnProcessCandidates = (candidates?: TalentProcess[]) => {
  if (!candidates) return [];
  const validated: TalentProcess[] = [];

  candidates.forEach((candidate) => {
    if (candidate.phase !== 'NotValidated' && candidate.phase !== 'Archive' && candidate.phase !== 'Validated') {
      validated.push(candidate);
    }
  });
  return validated;
};

const getValidatedCandidates = (candidates?: TalentProcess[]) => {
  if (!candidates) return [];
  const validated: TalentProcess[] = [];

  candidates.forEach((candidate) => {
    if (candidate.phase === 'Validated') {
      validated.push(candidate);
    }
  });
  return validated;
};

const TalentGrid = ({ candidates }: TalentGridProps): JSX.Element => {
  const { t } = useTranslation(['talents']);

  const notValidatedCandi = getNotValidatedCandidates(candidates);
  const validatedCandi = getValidatedCandidates(candidates);
  const onProcess = getOnProcessCandidates(candidates);

  return (
    <ContainerGrid>
      {validatedCandi.length === 0 ? (
        ''
      ) : (
        <>
          <ContainerAnimatedValidated>
            <TitlePart textTitle={`🎉 ${t(`validated`)}`} />
          </ContainerAnimatedValidated>
          <ContainerValidated>
            {validatedCandi?.map((candidate) => (
              <CardTalent key={candidate?.emailMessagingAddress} candidate={candidate} />
            ))}
          </ContainerValidated>
        </>
      )}
      {onProcess.length === 0 ? (
        ''
      ) : (
        <>
          <TitlePart textTitle={`⏳ ${t('process', { ns: 'talents' })}`} />
          <ContainerHorizontalScroll>
            {onProcess?.map((candidate) => (
              <CardTalent key={candidate?.emailMessagingAddress} candidate={candidate} />
            ))}
          </ContainerHorizontalScroll>
        </>
      )}
      {notValidatedCandi.length === 0 ? (
        ''
      ) : (
        <>
          <TitlePart textTitle={`❌ ${t(`profilNotValidated`)} - (${notValidatedCandi?.length} profils)`} />
          <ContainerHorizontalScroll>
            {notValidatedCandi?.map((candidate) => (
              <CardTalent key={candidate?.emailMessagingAddress} candidate={candidate} />
            ))}
          </ContainerHorizontalScroll>
        </>
      )}
    </ContainerGrid>
  );
};

export default TalentGrid;
export { TalentGrid };

const ContainerGrid = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column wrap',
  justifyContent: 'center',
}));

const ContainerHorizontalScroll = styled('div')(() => ({
  display: 'flex',
  overflowX: 'auto',
  width: '80%',
  margin: 'auto',
}));

const ContainerValidated = styled('div')(() => ({
  display: 'flex',
  width: '80%',
  margin: 'auto',
  justifyContent: 'center',
}));

const ContainerAnimatedValidated = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  margin: '2em auto 0',
  alignItems: 'center',
}));
