import React from 'react';
import { InputField } from '../atoms/InputField';
import { Checkbox } from '../atoms/Checkbox';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export type LabelAndInputProps = {
  name: string;
  textLabel: string;
  value?: string;
  type: string;
  required?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  checked?: boolean;
  sx?: SxProps<Theme>;
  readOnly: boolean;
};

export const LabelAndInput = ({
  name,
  textLabel,
  value = '',
  type,
  required,
  handleChange,
  id,
  checked,
  sx,
  readOnly,
  ...props
}: LabelAndInputProps) => {
  return (
    <Box sx={sx}>
      <Container>
        {type !== 'file' && type !== 'checkbox' && (
          <InputField
            fullWidth={true}
            id={id}
            name={name}
            value={value}
            type={type}
            label={textLabel}
            handleChange={handleChange}
            checked={checked}
            required={required}
            readOnly={readOnly}
            {...props}
          />
        )}
        {type !== 'file' && type === 'checkbox' && (
          <Checkbox
            id={id}
            label={textLabel}
            name={name}
            onChange={handleChange}
            checked={checked}
            required={required}
            {...props}
          />
        )}
      </Container>
    </Box>
  );
};

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '5px',
}));
