import { SingleLineText } from '@bloomays-lib/ui.shared';

export type TagProps = {
  text: any;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
};

const Tag = ({ text, disabled, backgroundColor, color }: TagProps) => {
  const styleProps = {
    backgroundColor: backgroundColor || '#ea90b8',
    color: color || 'black',
  };
  return (
    <SingleLineText
      text={text}
      sx={(theme) => {
        if (disabled) {
          return {
            padding: '4.5px 16px',
            borderRadius: '12px',
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.grey[50],
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
          };
        }
        return {
          padding: '4.5px 16px',
          borderRadius: '5px',
          color: styleProps.color,
          backgroundColor: styleProps.backgroundColor,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
        };
      }}
    />
  );
};

export default Tag;
export { Tag };
