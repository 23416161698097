import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../atoms/Modal';
import { Button, SingleLineText, TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';
import { Logger } from '../../services/serviceLogger';

const logger = Logger('DeleteActionPopin');

type DeleteActionPopinProps = {
  opened: boolean;
  onClose: () => void;
  deleteConfirmLabel: string;
  confirmLabel: string;
  expectedValue: string;
  deleteAction: () => Promise<void>;
};

const DeleteActionPopin = ({
  opened,
  onClose,
  confirmLabel,
  deleteAction,
  expectedValue,
  deleteConfirmLabel,
}: DeleteActionPopinProps) => {
  const { t } = useTranslation(['random']);
  const [value, setValue] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const handleOnClick = async () => {
    setDisableButton(true);
    try {
      await deleteAction();
    } catch (err: any) {
      setDisableButton(false);
      logger.error(err);
    }
  };

  return (
    <Modal
      additionnalCSS={{
        overflowY: 'auto',
        width: '65%',
        padding: '32px',
        border: 'none',
        borderRadius: '20px',
      }}
      open={opened}
      onClose={onClose}
    >
      <SingleLineText variant={'body2Medium'} text={deleteConfirmLabel} />
      <ValidationTextField
        type={TypeInput.email}
        label={confirmLabel}
        onChange={(e: any) => {
          const inputValue = e.target.value.trim() as string;
          setValue(inputValue);
          setDisableButton(inputValue !== expectedValue);
        }}
        value={value}
        size={'small'}
        placeholder={confirmLabel}
        error={false}
      />
      <Button onClick={handleOnClick} disable={disableButton} textButton={t('delete', { ns: 'random' })} />
    </Modal>
  );
};

export default DeleteActionPopin;
