import { SingleLineText } from '@bloomays-lib/ui.shared';

import { styled } from '@mui/material/styles';

export type DetailsSectionCardProps = {
  title: string;
  details?: string;
  text?: string;
};

const DetailsSectionCard = ({ title = '', details = '', text }: DetailsSectionCardProps): JSX.Element => {
  return (
    <Container>
      <div>
        <TitleSingleLineText text={title} variant="heading5" />
        <TextDetailsSingleLineText text={details} />
      </div>
      <SingleLineText text={text} />
    </Container>
  );
};

export default DetailsSectionCard;
export { DetailsSectionCard };

const TitleSingleLineText = styled(SingleLineText)(({ theme }) => ({
  color: 'rgb(54, 54, 54)',
}));

const TextDetailsSingleLineText = styled(SingleLineText)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderLeft: `4px solid ${theme.palette.secondary.dark}`,
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-between',
  padding: '1em 1.5em',
  margin: '2em auto',
  textAlign: 'left',
}));
