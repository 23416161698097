import manArt from '../../assets/man_design_paint_art.png';
import { styled } from '@mui/material/styles';
import SingleLineText from '../atoms/SingleLineText';
import Logo from '../atoms/Logo';

export type EmptyStateProps = {
  text: string;
  image?: string;
};

const EmptyState = ({ text, image = manArt }: EmptyStateProps) => {
  return (
    <ContainerSuccess>
      <StyledLogo image={image} />
      <SingleLineText text={text} variant="body1Light" />
    </ContainerSuccess>
  );
};

export { EmptyState };
export default EmptyState;

const StyledLogo = styled(Logo)(() => ({
  width: '153px',
  height: '153px',
  paddingBottom: '20px',
}));

const ContainerSuccess = styled('div')(({ theme }) => ({
  width: '355px',
  color: theme.palette.grey[500],
  display: 'flex',
  flexFlow: 'column nowrap',
  alignContent: 'center',
  alignItems: 'center',
}));
