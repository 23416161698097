/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import FilterTab from '../atoms/FilterTab';
import { useTranslation } from 'react-i18next';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import MultipleSelectSearch from './MultipleSelectSearch';
import { Action, ActionType, State } from '../../types/talents';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';

export type FilterLanguagesProps = {
  withTab?: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  state: State;
  onSearchLanguages: (e: string) => void;
  dispatch: React.Dispatch<Action>;
  allLanguages: { label: string; selected?: boolean }[] | undefined;
  setAllLanguages: React.Dispatch<React.SetStateAction<{ label: string; selected?: boolean }[] | undefined>>;
  loadingLanguages: boolean;
  searchLanguageValue: string;
  setSearchLanguageValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectedLanguagesList: React.Dispatch<React.SetStateAction<{ id: string; label: string; count?: number }[]>>;
  selectedLanguagesList: { label: string; count?: number; id: string }[];
  getLanguages: LazyQueryExecFunction<
    {
      listLanguages: {
        label: string;
      }[];
    },
    OperationVariables
  >;
  dataLanguages?: { listLanguages: { label: string }[] };
};

const FilterLanguages = ({
  dispatch,
  state,
  loadingLanguages,
  allLanguages,
  setAllLanguages,
  setPage,
  searchLanguageValue,
  setSearchLanguageValue,
  setSelectedLanguagesList,
  selectedLanguagesList,
  getLanguages,
  dataLanguages,
  onSearchLanguages,
  withTab = true,
}: FilterLanguagesProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('talentTool');
  const globalStateLanguages = state.search.languages;

  React.useEffect(() => {
    if (dataLanguages?.listLanguages && dataLanguages?.listLanguages?.length > 0) {
      setAllLanguages(
        dataLanguages?.listLanguages?.map((lang) => {
          return {
            ...lang,
            selected: globalStateLanguages ? globalStateLanguages?.some((GLang) => lang.label === GLang.label) : false,
          };
        }),
      );
    }
  }, [dataLanguages?.listLanguages, globalStateLanguages]);

  const updateState = (e: any) => {
    let isSelected: boolean;
    setAllLanguages(
      allLanguages?.map((lang) => {
        if (lang.label === e.label) {
          isSelected = !lang?.selected;
          if (!isSelected) {
            dispatch({
              type: ActionType.RM_LANGUAGES_SEARCH,
              languagesLabel: [e.label],
            });
            return { ...lang, selected: isSelected };
          } else {
            dispatch({
              type: ActionType.ADD_LANGUAGES_SEARCH,
              languages: [{ label: e.label }],
            });
            return { ...lang, selected: isSelected };
          }
        } else {
          return lang;
        }
      }),
    );
    setPage(1);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  React.useEffect(() => {
    if (open || !withTab) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getLanguages();
    }
  }, [open]);

  const StyledContainer = styled('div')(
    ({ theme }) => `
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border-radius: 20px;
    padding: 24px 24px 32px 24px;
    background-color: ${theme.palette.paper.white};
    gap: 10px;
    box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
    width: 399px;
    max-height: 323px;
    position: ${withTab ? 'absolute' : 'none'};
    z-index: ${withTab ? 1 : 0};
    overflow-X: auto;
  `,
  );

  React.useEffect(() => {
    if (globalStateLanguages) {
      setSelectedLanguagesList(
        globalStateLanguages.map((lang) => {
          return { label: lang.label, id: lang.label };
        }),
      );
    }
  }, [globalStateLanguages, setSelectedLanguagesList]);

  return (
    <div>
      {withTab ? (
        <>
          <FilterTab
            label={t('lang')}
            numberOfFilter={globalStateLanguages?.length}
            onClick={() => {
              setOpen(!open);
            }}
            filled={false}
          />
          {open && (
            <StyledContainer ref={ref}>
              <StyledText text={t('lang')} variant={'subtitle2Medium'} />
              <StyledList>
                <MultipleSelectSearch
                  onSearch={onSearchLanguages}
                  setSearch={setSearchLanguageValue}
                  search={searchLanguageValue}
                  loading={loadingLanguages}
                  onSwitch={updateState}
                  items={allLanguages?.map((lang) => {
                    return { ...lang, id: lang.label };
                  })}
                  selectedItemsList={selectedLanguagesList}
                  setSelectedItemsList={setSelectedLanguagesList}
                  withLevel={false}
                />
              </StyledList>
            </StyledContainer>
          )}
        </>
      ) : (
        <StyledContainer ref={ref}>
          <StyledText text={t('lang')} variant={'subtitle2Medium'} />
          <StyledList>
            <MultipleSelectSearch
              onSearch={onSearchLanguages}
              setSearch={setSearchLanguageValue}
              search={searchLanguageValue}
              loading={loadingLanguages}
              onSwitch={updateState}
              items={allLanguages?.map((lang) => {
                return { ...lang, id: lang.label };
              })}
              selectedItemsList={selectedLanguagesList}
              setSelectedItemsList={setSelectedLanguagesList}
              withLevel={false}
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterLanguages;
export { FilterLanguages };

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
