import { useState } from 'react';
import Logo from './Logo';
import { styled } from '@mui/material/styles';
import link from '../../assets/link.svg';
import check from '../../assets/roundCheck.svg';

export type ClipboardCopyProps = {
  copyText: string;
};

const ClipboardCopy = ({ copyText }: ClipboardCopyProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (textToCopy: string) => {
    await navigator.clipboard.writeText(textToCopy);
  };

  const handleCopyClick = async () => {
    await copyTextToClipboard(copyText);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 1000);
  };

  const element = isCopied ? <Logo image={check} /> : <StyledClipboardCopy image={link} onClick={handleCopyClick} />;

  return element;
};

export default ClipboardCopy;
export { ClipboardCopy };

const StyledClipboardCopy = styled(Logo)(
  () => `
  &:hover {
    cursor: pointer;
  }
`,
);
