import { ITalent } from '@bloomays-lib/types.shared';

export const jobTitleTalent = (talent: ITalent): string => {
  if (talent?.positionTitle?.jobTitle?.label) {
    return talent?.positionTitle?.jobTitle?.label;
  }
  if (talent?.jobTitle && talent?.jobTitle?.length > 252) {
    return `${talent?.jobTitle?.substring(0, 252)}...`;
  }
  return talent.jobTitle;
};
