import RawMuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
export type AvatarProps = {
  size: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';
  alt: string;
  image?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const MuiAvatar = styled(RawMuiAvatar)(
  ({ theme }) => `
    backgroundColor: ${theme.palette.secondary.main}
    `,
);

const Avatar = ({ size, alt, image, onClick }: AvatarProps) => {
  const getSize = (size: string) => {
    switch (size) {
      case 'extraSmall':
        return { width: '24px', height: '24px', fontSize: '12px' };
      case 'small':
        return { width: '32px', height: '32px', fontSize: '16px' };
      case 'large':
        return { width: '60px', height: '60px', fontSize: '18px' };
      case 'extraLarge':
        return { width: '80px', height: '80px', fontSize: '18px' };
      case 'medium':
      default:
        return { width: '40px', height: '40px', fontSize: '18px' };
    }
  };

  function stringAvatar(name: string) {
    if (name && name.split(' ')?.[1]?.[0]) {
      return `${name.split(' ')?.[0]?.[0]}${name.split(' ')?.[1]?.[0]}`;
    } else if (name && name.split(' ')?.[0]?.[0]) {
      return `${name.split(' ')?.[0]?.[0]}`;
    }
  }

  return (
    <MuiAvatar
      onClick={onClick ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick(e) : undefined}
      alt={alt}
      src={image}
      style={getSize(size)}
    >
      {image !== undefined ? stringAvatar(alt) : image}
    </MuiAvatar>
  );
};

export default Avatar;
export { Avatar };
