import { styled } from '@mui/material/styles';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBar from '../atoms/SearchBar';
import { Location } from '@bloomays-lib/types.shared';
import Slider from '../atoms/Slider';
import { Button } from '@bloomays-lib/ui.shared';

export type SelectReasearchProps = {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  loading: boolean;
  allItems?: Location[];
  setAllItems: React.Dispatch<React.SetStateAction<Location[] | undefined>>;
  onSearch: (e: any) => void;
  validateLocation: (e: any) => void;
  value?: string;
  existingLocation?: Location;
};

const SelectReasearch = ({
  setSearchValue,
  searchValue,
  loading,
  allItems,
  onSearch,
  setAllItems,
  value,
  validateLocation,
  existingLocation,
}: SelectReasearchProps) => {
  const [open, setOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [range, setRange] = useState<undefined | number>(existingLocation?.range || 10);
  const [selected, setSelected] = useState<Location | undefined>(existingLocation);
  const { t } = useTranslation(['talentSheet', 'random']);
  const ref = useRef<HTMLDivElement>(null);
  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (existingLocation) {
      setSelected(existingLocation);
      setRange(existingLocation?.range);
    }
  }, [existingLocation]);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
        setSearchValue('');
        setAllItems([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setAllItems, setSearchValue]);

  const sliderMarks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  return (
    <div>
      <StyledDivInput>
        <StyledDivLikeInput
          onClick={() => {
            setOpen(!open);
          }}
        >
          {value ? (
            <StyledSingleLineTextValue variant={'body1Regular'} text={value} />
          ) : (
            <StyledSingleLineText variant={'body1Regular'} text={t('local')} />
          )}
        </StyledDivLikeInput>
      </StyledDivInput>
      {open && (
        <StyledContainer ref={ref}>
          <StyledList>
            <SearchBar
              placeholder={t('search', { ns: 'random' })}
              searchValue={searchValue}
              onChange={(e) => {
                onSearch(e);
                setSearchValue(e.target.value);
              }}
              ref={inputReference}
            />
            {loading && <SingleLineText text={t('loadingText', { ns: 'random' })} />}
            {allItems?.map((item) => {
              return (
                <SingleLineText
                  sx={(theme) => ({
                    width: '270px',
                    padding: '10px',
                    textAlign: 'left',
                    backgroundColor: `${
                      selected?.city === item.city ? theme.palette.secondary[500] : theme.palette.paper.white
                    }`,
                    ' &:hover': {
                      cursor: 'pointer',
                      backgroundColor: `${theme.palette.secondary[500]}`,
                    },
                  })}
                  onClick={() => {
                    setSelected(item);
                    setHasChanged(true);
                  }}
                  text={item.address}
                />
              );
            })}
            <StyledBarXP></StyledBarXP>
            <StyledContainerTitleRange>
              <SingleLineText text={t('range')} />
              <SingleLineText variant={'body2SemiBold'} text={`${range === null ? 0 : range}km`} />
            </StyledContainerTitleRange>
            <StyledSliderContainer>
              <Slider
                defaultValue={10}
                value={range}
                min={0}
                marks={sliderMarks}
                max={100}
                step={5}
                label={'range'}
                onChange={(e, value: number | number[]) => {
                  setRange(Array.isArray(value) ? value?.[0] || 0 : value);
                  setHasChanged(true);
                }}
              />
            </StyledSliderContainer>
            <StyledSaveButton
              onClick={() => {
                validateLocation({ ...selected, range });
                setOpen(!open);
                setSearchValue('');
                setAllItems([]);
                setHasChanged(false);
              }}
              textButton={t('validate', { ns: 'random' })}
              disable={loading || !hasChanged}
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default SelectReasearch;
export { SelectReasearch };

const StyledContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 4px;
  padding: 24px 24px 32px 24px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 290px;
  max-height: 323px;
  position: absolute;
  z-index: 2;
  overflow-X: auto;
`,
);

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledDivLikeInput = styled('div')(
  ({ theme }) => `
  border: solid ${theme.palette.grey[800]} 1px;
  border-radius: 4px;
  width: 317px;
  padding: 7px 10px;
  margin-top: -5px;
  background-color: ${theme.palette.paper.white};
  text-align: left;
  &:hover {
    cursor: pointer;
  }

   &:active {
    border: solid ${theme.palette.secondary.dark} 1px;
  }
`,
);

const StyledSingleLineText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
`,
);

const StyledSingleLineTextValue = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);

const StyledSaveButton = styled(Button)(
  ({ theme }) => `
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  background-color: ${theme.palette.secondary[600]};
  color: ${theme.palette.secondary.light};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  width: 100%;
  &:hover {
    cursor: 'pointer';
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.paper.white};
    transition: 'ease 0.3s';
  }
  &:disabled {
    backgroundColor: ${theme.palette.grey[300]};
    color: ${theme.palette.grey[800]};
    cursor: not-allowed;
    pointerEvents: auto;
  }
`,
);

const StyledBarXP = styled('div')(
  ({ theme }) => `
  height: 0px;
  width: 100%;
  border: 1px solid ${theme.palette.grey[400]};
  `,
);

const StyledContainerTitleRange = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  width: 100%;

  justify-content: space-between;
  `,
);

const StyledSliderContainer = styled('div')(
  () => `
  width: 100%;

  `,
);

const StyledDivInput = styled('div')(
  () => `
  margin-bottom: 18px;

  `,
);
