import { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import { TFunction, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import CardTalent from './CardTalent';
import RawMainCard from '../atoms/MainCard';
import { HrefLink } from '@bloomays-lib/ui.shared';
import AutoCompleteInput from '../atoms/AutoCompleteInput';
import { Button as RawButton } from '@bloomays-lib/ui.shared';
import { UPSERT_TALENT_QUALIF, JOBS_CARDS } from '@bloomays-lib/adapter.api-bloomer';
import { cancel, notify } from '../../helpers/toastify';
import { styled } from '@mui/material/styles';
import { Candidat, IJobFunnel, SyncCandidatJobInput } from '@bloomays-lib/types.shared';
import { TalentProcess } from '@bloomays-lib/types.shared';
import { getApolloErrorCode } from '../../helpers/error';
import { LoaderPending } from '@bloomays-lib/ui.shared';
interface IJobFunnelLabel extends IJobFunnel {
  name: string;
}

export type SyncTalentQualificationProps = {
  candidate: Candidat;
  handleClose: () => void;
};

const toastId = 'toastSyncTalentNotification';
const toastOptions = { toastId, hideProgressBar: true };

const handleUpsertCandiError = (t: TFunction<'notify', undefined>) => (e: any) => {
  const errorCode = getApolloErrorCode(e);
  switch (errorCode) {
    case 'TOO_MUCH_OCCURENCE': {
      notify('error', t('tooMuchRecord', { ns: 'notify' }), e, toastOptions);
      return;
    }
    case 'PIPEFY_UPSERT_RECORD_ERROR': {
      notify(
        'error',
        "Nous avons eu un souci avec la mise à jour des données du Talent sur la base de données de contacts de Pipefy. Voici le message d'erreur :" +
          e.message +
          '. Vérifie les données de ton profil dans la fiche talent et corrige si nécessaire. Sinon contact les tech.',
        null,
        toastOptions,
      );
      return;
    }
    case 'OBJECT_NOT_FOUND':
    case 'AIRTABLE_MISSING_DATA':
    case 'PIPEFY_UNEXPECTED_ERROR': {
      notify(
        'error',
        "Nous avons pas pu créer le carte Pipiefy sur le Candifunnel pour ce Talent et ce job. Voici le message d'erreur :" +
          e.message +
          '. Contact les tech.',
        null,
        toastOptions,
      );
      return;
    }
    default:
      notify('error', t('randomUpdateError', { ns: 'notify' }), e, toastOptions);
  }
};

const SyncTalentQualification = ({ candidate, handleClose }: SyncTalentQualificationProps): JSX.Element => {
  const { data: dataJobs } = useQuery<{ getJobCards: IJobFunnelLabel[] }>(JOBS_CARDS);
  const [upsertTalentQualif, { loading: savingTalent }] = useMutation<
    { upsertTalentQualification: string },
    { input: SyncCandidatJobInput }
  >(UPSERT_TALENT_QUALIF);
  const { t } = useTranslation(['synchronizationTalent', 'notify']);
  const [animationData, setAnimationData] = useState<any>();
  const [jobValue, setJobValue] = useState<IJobFunnelLabel>({
    title: '',
    url: '',
    id: '',
    clientName: '',
    name: '',
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    import('../../graphic-charter/animations/arrowToRight.json').then(setAnimationData);
  }, []);

  const upsertCandiQualif = async () => {
    let candiQualif;
    if (!candidate.email || !candidate.jobTitle) {
      notify('error', t('missingCandidateInfos', { ns: 'notify' }), null, toastOptions);
      return;
    }
    try {
      candiQualif = await upsertTalentQualif({
        variables: {
          input: {
            ...candidate,
            jobId: jobValue.id,
          },
        },
      });
      handleClose();
      if (candiQualif) {
        cancel();
        notify(
          'success',
          <>
            Carte de Préqualification créée avec succes. Voir{' '}
            <HrefLink href={candiQualif.data?.upsertTalentQualification || ''} text={`👉 ${t('cardUrl')}`} />
          </>,
          null,
          toastOptions,
        );
      }
    } catch (e: any) {
      cancel();
      handleUpsertCandiError(t)(e);
    }
  };

  const job: IJobFunnelLabel[] =
    dataJobs?.getJobCards.map((job) => {
      return {
        ...job,
        title: `${job.title} chez ${job.clientName}`,
        name: job.title,
      };
    }) || [];

  return (
    <div>
      <div>
        {savingTalent && <LoaderPending text={t('syncCandiFunnelInProgress', { ns: 'notify' })} />}
        <TitlePart textTitle={t('title')} />
        <SingleLineText text={t('subtitle')} />
        <ContainerJobChoices>
          <AutoCompleteInput data={job} label={t('jobChoice')} setValue={setJobValue} />
          {/* synchro_talent used by marketing*/}
          <Button
            id="synchro_talent"
            textButton={t('validate')}
            disable={!jobValue?.id || savingTalent}
            onClick={() => upsertCandiQualif()}
          />
        </ContainerJobChoices>
        <ContainerCards>
          <CardTalent candidate={candidate as unknown as TalentProcess} />
          <Lottie animationData={animationData} play={true} style={{ height: 200 }} />
          <MainCard contentSX={{ padding: '20px !important' }} title="Job 💼">
            <ContainerChildrenCard>
              <SingleLineText text={`📌 Job :`} span={jobValue?.title ? jobValue.name : '-'} />
              <SingleLineText text={`📌 Client :`} span={jobValue?.clientName ? jobValue.clientName : '-'} />
              <SingleLineText
                text={`📌 Url :`}
                span={jobValue?.url ? <DarkHrefLink text={`#${jobValue.id}`} href={jobValue.url} /> : '-'}
              />
            </ContainerChildrenCard>
          </MainCard>
        </ContainerCards>
      </div>
    </div>
  );
};

export default SyncTalentQualification;
export { SyncTalentQualification };

const MainCard = styled(RawMainCard)(({ theme }) => ({
  color: 'black',
  overflow: 'hidden',
  position: 'relative',
  maxWidth: 300,
  minWidth: 300,
  maxHeight: 410,
  minHeight: 410,
  textAlign: 'left',
  margin: '10px 10px 10px 10px',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  borderRadius: '4px',
  '&:after': {
    content: '""',
    zIndex: '34',
    position: 'absolute',
    width: '210px',
    height: '210px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: '-85px',
    right: '-95px',
    [theme.breakpoints.down('sm')]: {
      top: '-105px',
      right: '-140px',
    },
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '210px',
    height: '210px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    top: '-125px',
    right: '-15px',
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      top: '-155px',
      right: '-70px',
    },
  },
}));

const ContainerChildrenCard = styled('div')(() => ({
  paddingTop: '1.5em',
  paddingRight: '0.5em',
  minWidth: '250px',
}));

const ContainerCards = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContainerJobChoices = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const Button = styled(RawButton)(({ theme }) => ({
  marginLeft: '10px',
  border: 'none',
  padding: '2em 1.4em',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.paper.white,
  boxShadow: '2px 2px 5px rgb(201, 201, 201)',
  margin: '0.8em auto 0.8em 1em',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.paper.white,
    color: theme.palette.primary.main,
    transition: 'ease 0.3s',
  },
  '&:disabled': {
    border: `1px solid ${theme.palette.grey[500]}`,
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[400],
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
}));

const DarkHrefLink = styled(HrefLink)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  '&:visited': {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
  },
  textDecoration: 'underline',
}));
