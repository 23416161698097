import { Theme, styled } from '@mui/material/styles';
import { Level, SingleLineText, Switch } from '@bloomays-lib/ui.shared';

export type SwitchItemProps = {
  label: string;
  withLevel?: boolean;
  onSwitch: (e: any, checked: boolean) => void;
  levelSelected?: number;
  onClickLevel?: (e: any) => void;
  checked?: boolean;
  count?: number;
};

const SwitchItem = ({ withLevel, label, onSwitch, levelSelected, onClickLevel, checked, count }: SwitchItemProps) => {
  return (
    <StyledItem>
      <Switch checked={checked} label={label} onSwitch={onSwitch} />
      {count && (
        <SingleLineText
          text={`${count} talents`}
          sx={(theme: Theme) => {
            return {
              color: theme.palette.grey[800],
            };
          }}
          variant={'caption'}
        />
      )}
      {withLevel && (
        <StyledLevelContainer>
          <Level level={levelSelected} clickable={true} onClick={onClickLevel} />
        </StyledLevelContainer>
      )}
    </StyledItem>
  );
};

export default SwitchItem;
export { SwitchItem };

const StyledItem = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: ${theme.palette.secondary[500]};
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
  }
`,
);

const StyledLevelContainer = styled('div')(
  () => `
  padding-right: 6.8px;
`,
);
