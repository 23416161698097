import React, { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { common } from '@mui/material/colors';
import { FINANCES, FINANCE_ONE_MONTH } from '@bloomays-lib/adapter.api-bloomer';
import { notify } from '../../helpers/toastify';

import Camembert from '../molecules/Camembert';
import EarningCard from '../molecules/EarningCard';
import OverviewChart from '../molecules/OverviewChart';
import { AirtableFinance } from '@bloomays-lib/types.shared';
import { ChartData } from 'chart.js';
import { styled, useTheme } from '@mui/material/styles';
import { lastDateOfMonth } from '@bloomays-lib/utils.shared';
import {
  getFinanceByType,
  getTotal,
  getEvolutionPercentage,
  getArrayPrice,
  calculBenefits,
  calculPercentage,
  regroupByType,
  monthOverview,
  monthAbbreviation,
} from '../../helpers/finances';
import { errorLogger } from '../../helpers/error';
import { months } from '../../helpers/CRA';

const GridEarningsCamembert = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const DashboardFinance = () => {
  const theme = useTheme();
  const [isLoading, setLoading] = useState(true);
  const [isLoadingOverview, setLoadingOverview] = useState(true);
  const [beginDate, setBeginDate] = useState(lastDateOfMonth(new Date(), -4));
  const [endDate, setEndDate] = useState(lastDateOfMonth(new Date(), 3));
  const [monthWanted, setMonthWanted] = useState(lastDateOfMonth(new Date(), -1));

  const {
    data: dataExpenses,
    error: errorExpenses,
    loading: loadingExpenses,
  } = useQuery(FINANCES, {
    variables: {
      beginDate,
      endDate,
      isExpense: true,
    },
  });
  const {
    data: dataEarnings,
    error: errorEarnings,
    loading: loadingEarnings,
  } = useQuery(FINANCES, {
    variables: {
      beginDate,
      endDate,
      isExpense: false,
    },
  });
  const { data: dataLastMonth, error: errorLastMonth } = useQuery(FINANCE_ONE_MONTH, {
    variables: {
      date: lastDateOfMonth(new Date(), -2),
    },
  });

  const {
    data: dataCurrentMonth,
    error: errorCurrentMonth,
    loading: loadingCurrentMonth,
  } = useQuery(FINANCE_ONE_MONTH, {
    variables: {
      date: lastDateOfMonth(new Date(), -1),
    },
  });

  const [
    getEarningOneMonth,
    { data: dataEarningsWantedMonth, error: errorEarningsWantedMonth, loading: loadingEarningsWantedMonth },
  ] = useLazyQuery(FINANCE_ONE_MONTH, {
    variables: {
      date: monthWanted,
      isExpense: false,
    },
  });

  const lastMonthEarnings = getFinanceByType(dataLastMonth?.getFinanceByMonth, null);
  const lastMonthExpenses = getFinanceByType(dataLastMonth?.getFinanceByMonth, true);
  const currentMonthEarnings = getFinanceByType(dataCurrentMonth?.getFinanceByMonth, null);

  const currentMonthExpenses = getFinanceByType(dataCurrentMonth?.getFinanceByMonth, true);

  const earningDetailsOneMonth = dataEarningsWantedMonth?.getFinanceByMonth;

  const totalLastMonthEarnings = getTotal(lastMonthEarnings);
  const totalLastMonthExpenses = getTotal(lastMonthExpenses);
  const totalcurrentMonthEarnings = getTotal(currentMonthEarnings);
  const totalcurrentMonthExpenses = getTotal(currentMonthExpenses);

  const totalMonthWantedEarnings = getTotal(earningDetailsOneMonth);

  const currentTotalBenefits = totalcurrentMonthEarnings - totalcurrentMonthExpenses;

  const lastTotalMonthBenefits = totalLastMonthEarnings - totalLastMonthExpenses;

  const evolutionEarnings = getEvolutionPercentage(totalLastMonthEarnings, totalcurrentMonthEarnings);
  const evolutionExpenses = getEvolutionPercentage(totalLastMonthExpenses, totalcurrentMonthExpenses);
  const evolutionBenefits = getEvolutionPercentage(lastTotalMonthBenefits, currentTotalBenefits);

  React.useEffect(() => {
    if (!loadingCurrentMonth) {
      setLoading(false);
    }
    if (!dataEarningsWantedMonth) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getEarningOneMonth();
    }
  }, [dataEarningsWantedMonth, getEarningOneMonth, loadingCurrentMonth]);

  React.useEffect(() => {
    if (loadingExpenses || loadingEarnings) {
      setLoadingOverview(true);
    } else {
      setLoadingOverview(false);
    }
  }, [loadingExpenses, loadingEarnings]);

  const error = errorEarningsWantedMonth || errorExpenses || errorEarnings || errorLastMonth || errorCurrentMonth;

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve data about finances !',
    });
    notify('error', 'Impossible de récupérer les finances. Erreur du server, essaie plus tard ! 😓', error);
  }

  const earnings = dataEarnings?.getFinances;
  const expenses = dataExpenses?.getFinances;

  const chartData = {
    height: 400,
    type: 'line',
    options: {
      chart: {
        id: 'line-chart',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        type: 'category',
        categories: monthOverview(monthAbbreviation, earnings),
      },
      legend: {
        show: true,
        fontFamily: '"Poppins Medium", sans-serif',
        markers: {
          width: 16,
          height: 16,
          radius: 5,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8,
        },
      },
      grid: {
        show: true,
      },
    },
    series: [
      {
        name: 'Revenus (marges) HT',
        data: getArrayPrice(earnings),
      },
      {
        name: 'Dépenses HT',
        data: getArrayPrice(expenses),
      },
      {
        name: 'Bénéfices HT',
        data: calculBenefits(getArrayPrice(earnings), getArrayPrice(expenses)),
      },
    ],
  };

  const getDataNumber = (data: any) => {
    if (!data) return;
    return data.map((finance: any) => {
      return calculPercentage(finance, totalMonthWantedEarnings);
    });
  };

  const getDataCamembert = (finance: AirtableFinance) => {
    switch (finance?.financeType) {
      case 'freelance':
      case 'IT':
        return {
          title: 'Free IT',
          value: calculPercentage(finance, totalMonthWantedEarnings),
          color: theme.palette.secondary.light,
        };
      case 'RPO':
        return {
          title: 'Free RPO',
          value: calculPercentage(finance, totalMonthWantedEarnings),
          color: theme.palette.secondary.main,
        };
      case 'CDI':
        return {
          title: 'CDI',
          value: calculPercentage(finance, totalMonthWantedEarnings),
          color: theme.palette.secondary.dark,
        };
      case 'People':
        return {
          title: 'People',
          value: calculPercentage(finance, totalMonthWantedEarnings),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: theme.palette.secondary[200],
        };
      case 'other':
      default:
        return {
          title: 'Autres',
          value: calculPercentage(finance, totalMonthWantedEarnings),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: theme.palette.secondary[200],
        };
    }
  };

  const createCamembertData = (data: AirtableFinance[]): { color: string; title: string; value: string }[] => {
    if (!data) return [];

    const financeRegrouped = regroupByType(data);
    return (
      financeRegrouped?.map((finance: any) => {
        return getDataCamembert(finance);
      }) || []
    );
  };
  const camembertData = createCamembertData(earningDetailsOneMonth);

  const data = {
    datasets: [
      {
        data: getDataNumber(regroupByType(earningDetailsOneMonth)),
        backgroundColor: camembertData?.map((element) => element?.color),
        borderWidth: 8,
        borderColor: common.white,
        hoverBorderColor: common.white,
      },
    ],
    labels: camembertData?.map((element) => element?.title),
  } as unknown as ChartData<'doughnut', number[], unknown>;

  const handleChangeCamembertDate = (e: Date | null) => {
    if (e) {
      setMonthWanted(lastDateOfMonth(e));
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getEarningOneMonth();
    }
  };

  const lastMonthName = months[lastDateOfMonth(new Date(), -1).getMonth()];
  const beforeLastMonthName = months[lastDateOfMonth(new Date(), -2).getMonth()];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EarningCard
              isLoading={isLoading}
              title="Total des revenus (marges) HT"
              data={`${totalcurrentMonthEarnings.toLocaleString()} €`}
              up={totalLastMonthEarnings < totalcurrentMonthEarnings}
              percentageDif={evolutionEarnings.toString()}
              month={lastMonthName}
              beforeLastMonthName={beforeLastMonthName}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EarningCard
              isLoading={isLoading}
              title="Total des dépenses HT"
              data={`${totalcurrentMonthExpenses.toLocaleString()} €`}
              up={totalLastMonthExpenses < totalcurrentMonthExpenses}
              percentageDif={evolutionExpenses.toString()}
              month={lastMonthName}
              beforeLastMonthName={beforeLastMonthName}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EarningCard
              isLoading={isLoading}
              title="Total du bénéfice HT"
              data={`${currentTotalBenefits.toLocaleString()} €`}
              up={lastTotalMonthBenefits < currentTotalBenefits}
              percentageDif={evolutionBenefits.toString()}
              month={lastMonthName}
              beforeLastMonthName={beforeLastMonthName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GridEarningsCamembert container spacing={3}>
          <Grid item xs={12} md={8}>
            <OverviewChart
              chartData={chartData}
              isLoading={isLoadingOverview}
              beginValue={beginDate}
              setBeginValue={setBeginDate}
              endValue={endDate}
              setEndValue={setEndDate}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Camembert
              subTitle={earningDetailsOneMonth?.[0]?.isForecast ? 'Prévisionnel' : 'Réel'}
              title={'Répartition des revenus'}
              camembertData={createCamembertData(dataEarningsWantedMonth?.getFinanceByMonth)}
              isLoading={loadingEarningsWantedMonth}
              data={data}
              handleChange={handleChangeCamembertDate}
              value={lastDateOfMonth(new Date(monthWanted))}
            />
          </Grid>
        </GridEarningsCamembert>
      </Grid>
    </Grid>
  );
};

export default DashboardFinance;
