import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import FilterTab from '../atoms/FilterTab';
import { useTranslation } from 'react-i18next';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import MultipleSelectSearch from './MultipleSelectSearch';
import { Item, ItemJobs } from '@bloomays-lib/types.shared';
import { Action, ActionType, State } from '../../types/talents';

export type FilterJobTalentProps = {
  onSearchJobTalent?: (jobSearch: string) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  jobs: ItemJobs[];
  state: State;
  dispatch: React.Dispatch<Action>;
  allJobs: ItemJobs[];
  setAllJobs: React.Dispatch<React.SetStateAction<ItemJobs[]>>;
  loadingJobs: boolean;
  searchJobValue: string;
  setSearchJobValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectedJobsList: React.Dispatch<React.SetStateAction<ItemJobs[]>>;
  selectedJobsList: ItemJobs[];
};

const FilterJobTalent = ({
  dispatch,
  jobs,
  onSearchJobTalent,
  state,
  loadingJobs,
  allJobs,
  setAllJobs,
  setPage,
  searchJobValue,
  setSearchJobValue,
  setSelectedJobsList,
  selectedJobsList,
}: FilterJobTalentProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('talentTool');
  const globalStateJobs = state.search.jobs?.jobs;

  const onClickLevelJob = (item: ItemJobs, level: number) => {
    const newJob: Item = { id: item.id, minLevel: level, label: item.label };
    setAllJobs(
      allJobs.map((job) => {
        if (job.id === item.id) {
          dispatch({
            type: ActionType.ADD_JOBS_SEARCH,
            jobs: [newJob],
          });
          setSelectedJobsList([
            ...selectedJobsList.filter((selected) => selected.label !== item.label),
            { ...item, level: level },
          ]);
          return { ...job, selected: true, level };
        } else {
          return job;
        }
      }),
    );
  };

  React.useEffect(() => {
    if (jobs.length > 0) {
      setAllJobs(
        jobs?.map((job) => {
          return {
            ...job,
            selected: globalStateJobs ? globalStateJobs?.some((GJob) => job.id === GJob.id) : false,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllJobs, jobs]);

  React.useEffect(() => {
    if (globalStateJobs) {
      setSelectedJobsList(globalStateJobs);
    }
  }, [globalStateJobs, setSelectedJobsList]);

  const updateLocalState = (e: ItemJobs) => {
    const newJob: Item = { id: e.id, minLevel: e.level || 0, label: e.label };
    let isSelected: boolean;
    setAllJobs(
      allJobs.map((job) => {
        if (job.id === e.id) {
          isSelected = !job?.selected;
          if (!isSelected) {
            dispatch({
              type: ActionType.RM_JOBS_SEARCH,
              jobsId: [e.id],
            });
            return { ...job, selected: isSelected, level: undefined };
          } else {
            dispatch({
              type: ActionType.ADD_JOBS_SEARCH,
              jobs: [newJob],
            });
            return { ...job, selected: isSelected, level: e.level };
          }
        } else {
          return job;
        }
      }),
    );
    setPage(1);
  };

  const updateLocalStateMultiple = (items: ItemJobs[], unSelect?: boolean) => {
    const newJobs: Item[] = items.map((item) => {
      return { id: item.id, minLevel: item.level || 0, label: item.label };
    });
    const allJobsSelected = items.map((item) => {
      return { ...item, selected: true };
    });
    if (unSelect) {
      setAllJobs(allJobsSelected);
      dispatch({
        type: ActionType.ADD_JOBS_SEARCH,
        jobs: newJobs,
      });
    } else {
      const idJobs = items.map((job) => {
        return job.id;
      });
      setAllJobs([]);
      dispatch({
        type: ActionType.RM_JOBS_SEARCH,
        jobsId: idJobs,
      });
    }
    setPage(1);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, globalStateJobs]);

  return (
    <div>
      <FilterTab
        label={'Poste'}
        numberOfFilter={globalStateJobs?.length}
        onClick={() => {
          setOpen(!open);
        }}
        filled={false}
      />
      {open && (
        <StyledContainer ref={ref}>
          <StyledText text={t('jobName')} variant={'subtitle2Medium'} />
          <StyledList>
            <MultipleSelectSearch
              setSearch={setSearchJobValue}
              search={searchJobValue}
              loading={loadingJobs}
              onClickLevel={onClickLevelJob}
              onSwitch={updateLocalState}
              onSwitchMultiple={updateLocalStateMultiple}
              items={allJobs}
              onSearch={onSearchJobTalent}
              selectedItemsList={selectedJobsList}
              setSelectedItemsList={setSelectedJobsList}
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterJobTalent;
export { FilterJobTalent };

const StyledContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border-radius: 20px;
  padding: 24px 24px 32px 24px;
  background-color: ${theme.palette.paper.white};
  gap: 10px;
  box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
  width: 399px;
  max-height: 323px;
  position: absolute;
  z-index: 1;
  overflow-X: auto;
`,
);

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
