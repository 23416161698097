import React, { useEffect, useRef, useState } from 'react';

import { Button, SingleLineText, LoaderSkeleton } from '@bloomays-lib/ui.shared';
import Iframe from '../atoms/Iframe';
import Modal from '../atoms/Modal';
import Box from '@mui/material/Box';
import { IActivity, IMission } from '@bloomays-lib/types.shared';
import { Upload } from './Upload';
import { UploadFileActivity } from '../../types/components';

export type ActionCraProps = {
  responseSign: { status: string; idRow: string | number | null };
  CRASignSent: boolean;
  cancelFunc: () => void;
  CRACanSendInvite: boolean;
  sendFunc: () => void;
  CRAUploaded: boolean;
  modalUploadCRA: { show: boolean; id?: string | null };
  responseUploadCRA: { status: string };
  handleCloseAction: () => void;
  uploadCRA: UploadFileActivity;
  CRAUrlEmbed: string | null;
  activity: IActivity;
  refetchActivities: () => void;
  mission: IMission;
  setResponseSign: React.Dispatch<
    React.SetStateAction<{
      status: string;
      idRow: string | number | null;
    }>
  >;
};

const ActionCRA = ({
  responseSign,
  CRASignSent,
  cancelFunc,
  CRACanSendInvite,
  sendFunc,
  handleCloseAction,
  CRAUploaded,
  modalUploadCRA,
  responseUploadCRA,
  uploadCRA,
  CRAUrlEmbed,
  activity,
  refetchActivities,
  setResponseSign,
  mission,
}: ActionCraProps): JSX.Element => {
  const [, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
  const CRAFRef = useRef(null);
  const [loadingButtons, setLoadingButtons] = useState({ view: false, sign: false, cancel: false });

  const handleOpen = () => {
    setOpen(true);
  };

  const [openEmbed, setOpenEmbed] = useState(false);

  const handleCloseEmbed = async () => {
    setOpenEmbed(false);
    setResponseSign({ ...responseSign, status: 'loading' });
    await refetchActivities();
    setResponseSign({ ...responseSign, status: 'send' });
  };

  const handleOpenEmbed = async () => {
    setOpenEmbed(true);
    await sendFunc();
  };

  React.useEffect(() => {
    if (modalUploadCRA.show === true) {
      handleOpen();
    }
  }, [modalUploadCRA]);

  useEffect(() => {
    if (responseSign.status !== 'loading') {
      setLoadingButtons({ view: false, sign: false, cancel: false });
    }
  }, [responseSign]);

  const SignatureBloomays = () => {
    const buttonDisabled = !CRACanSendInvite || !CRAUploaded || responseSign.status === 'loading';
    return (
      <div>
        <div>
          {CRASignSent ? (
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'space-evenly',
                height: '250px',
              }}
            >
              <SingleLineText text="Ici, je crée ma demande de signature, signe, annule ma création de signature !" />
              {activity.signedCount === 0 &&
                (activity?.signingEmbedBloomerId && activity?.signingDocumentId ? (
                  <Button
                    textButton={'Demande créee, je visualise mon CRA 👈'}
                    onClick={async () => {
                      setLoadingButtons((prev) => ({ ...prev, view: true }));
                      await handleOpenEmbed();
                    }}
                    disable={buttonDisabled}
                    loading={loadingButtons.view}
                  />
                ) : (
                  <SingleLineText text="Tu devrais avoir reçu un email pour signer ton CRA !" />
                ))}
              <Button
                textButton={'Annuler ma demande de signature ❌'}
                disable={buttonDisabled}
                onClick={async () => {
                  setLoadingButtons((prev) => ({ ...prev, cancel: true }));
                  await cancelFunc();
                }}
                loading={loadingButtons.cancel}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'space-evenly',
                height: '250px',
              }}
            >
              <SingleLineText
                text={
                  loadingButtons.sign
                    ? 'Création de la signature électronique en cours...'
                    : "Mon compte rendu d'activité n'est pas encore signé !"
                }
              />
              <Button
                textButton={'Je signe mon CRA ✒️'}
                disable={buttonDisabled}
                onClick={async () => {
                  setLoadingButtons((prev) => ({ ...prev, sign: true }));
                  await handleOpenEmbed();
                }}
                loading={loadingButtons.sign}
              />
            </Box>
          )}
        </div>
      </div>
    );
  };

  const title = mission.enableCRAUpload ? 'Upload' : 'Signature';

  const getDisabledBtn = () => {
    return (
      !files ||
      responseUploadCRA.status === 'loading' ||
      responseUploadCRA.status === 'send' ||
      files?.length > 2 ||
      files?.length === 0 ||
      rejectedFiles?.length > 0
    );
  };

  return (
    <div>
      <Modal open={openEmbed} additionnalCSS={{ width: '80%', minWidth: '740px' }} onClose={handleCloseEmbed}>
        {!CRAUrlEmbed ? (
          <LoaderSkeleton width="100%" height="740px" label="Création de la signature électronique en cours.." />
        ) : (
          <Iframe url={CRAUrlEmbed} />
        )}
      </Modal>

      <div>
        <SingleLineText sx={{ textAlign: 'left' }} variant={'heading3'} text={`${title} du compte rendu d'activité`} />
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            margin: '1em 0',
          }}
        >
          {!mission.enableCRAUpload ? (
            <SignatureBloomays />
          ) : (
            <div
              style={{
                width: '100%',
              }}
            >
              <div
                style={{
                  margin: '0 auto 0 0',
                  textAlign: 'left',
                }}
              >
                <SingleLineText variant={'caption'} text={'Uploader mon CRA signé en entreprise'} />
                <Upload
                  ref={CRAFRef}
                  id="CRAS3"
                  placeholder="Compte rendu d'activité"
                  textLabel={`Choisir mon CRA`}
                  name={'CRAS3'}
                  fileTypes={['.pdf']}
                  maxFiles={1}
                  maxLength={5000000}
                  displayIcons={true}
                  handleChange={(files: any, reject: any) => {
                    setRejectedFiles(reject);
                    setFiles(files);
                  }}
                  autoload={true}
                  preloadedFiles={files || []}
                />
              </div>
              <Button
                textButton="Uploader !"
                onClick={async () => {
                  await uploadCRA(activity.recordId, { urlCraS3: files[0] });
                  handleCloseAction();
                }}
                disable={getDisabledBtn()}
              />
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default ActionCRA;

export { ActionCRA };
