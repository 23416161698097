import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import processBackground from '../../graphic-charter/bloomaysProcessBG.png';
import RawPaper from '@mui/material/Paper';
import { Header } from '@bloomays-lib/ui.shared';

const BucketLogin = (): JSX.Element => {
  return (
    <Paper>
      <Header />
      <div>
        <Outlet />
      </div>
    </Paper>
  );
};

export default BucketLogin;

const Paper = styled(RawPaper)(() => ({
  margin: 0,
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  display: 'grid',
  gridAutoColumns: 'minmax(auto, 100%)',
  gridTemplateRows: 'auto auto auto',
  backgroundImage: `url(${processBackground})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  position: 'absolute',
}));
