import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { ActivityLog, EvenementType } from '@bloomays-lib/types.shared';
import { SingleLineText, TalentActivityLogsForm } from '@bloomays-lib/ui.shared';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_ACTIVITY_LOG, GET_LOGS_BY_TALENT } from '@bloomays-lib/adapter.api-talent';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import uniqBy from 'lodash/fp/uniqBy';
import reverse from 'lodash/fp/reverse';

export const NB_LOGS_PAGINATION = 20;

export type TalentSheetActivityLogProps = {
  talentId: string;
  updatedAt: Date | null;
};

const TalentSheetActivityLog = ({ talentId, updatedAt }: TalentSheetActivityLogProps) => {
  const [logs, setLogs] = useState<ActivityLog[]>([]);
  const [count, setCount] = useState<number>(0);
  const [paginateHistory, setPaginateHistory] = useState<{ skip?: number; take?: number }>({
    skip: 0,
    take: NB_LOGS_PAGINATION,
  });

  const [getActivityLogs, { data: dataActivityLogs, loading: loadingActivityLogs }] = useLazyQuery<{
    getLogsByTalent: { logs: ActivityLog[]; count: number };
  }>(GET_LOGS_BY_TALENT, {
    variables: {
      talentId,
      paginate: {
        skip: paginateHistory.skip || 0,
        take: paginateHistory.take || NB_LOGS_PAGINATION,
      },
    },
    fetchPolicy: 'network-only',
    context: { clientName: 'api.talents' },
  });

  const [addComment, { loading: savingComment }] = useMutation<{
    createActivityLog: null;
  }>(CREATE_ACTIVITY_LOG, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: GET_LOGS_BY_TALENT,
        variables: {
          talentId,
          paginate: {
            skip: paginateHistory.skip,
            take: paginateHistory.take,
          },
        },
        context: { clientName: 'api.talents' },
      },
    ],
  });

  const onAddComment = async (comment: string) => {
    setLogs([]);
    await addComment({
      variables: {
        params: {
          data: JSON.stringify({ comment: comment }),
          source: 'App',
          type: EvenementType.addComment,
          talentId: talentId,
        },
      },
    });
    setPaginateHistory({
      skip: 0,
      take: NB_LOGS_PAGINATION + 1,
    });
  };

  useEffect(() => {
    const fetchLogs = async () => {
      if (paginateHistory.skip !== undefined && paginateHistory.take !== undefined) {
        await getActivityLogs({
          variables: {
            talentId: talentId,
            paginate: {
              skip: paginateHistory.skip,
              take: paginateHistory.take,
            },
          },
        });
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchLogs();
  }, [talentId, paginateHistory, getActivityLogs]);

  useEffect(() => {
    setPaginateHistory({
      skip: 0,
      take: NB_LOGS_PAGINATION,
    });
    setLogs([]);
  }, [updatedAt]);

  useEffect(() => {
    if (dataActivityLogs && dataActivityLogs.getLogsByTalent) {
      setLogs((prevLogs) => {
        const logs = pipe(
          uniqBy((log: ActivityLog) => log.id),
          sortBy((log: ActivityLog) => log.date),
          reverse,
        )([...prevLogs, ...dataActivityLogs.getLogsByTalent.logs]);
        return logs;
      });
      setCount(dataActivityLogs.getLogsByTalent.count);
    }
  }, [dataActivityLogs]);

  return (
    <StyledXP>
      <StyledContainerSkillDetails>
        <TalentActivityLogsForm savingComment={savingComment} onAddComment={onAddComment} logs={logs} />
        <div style={{ textAlign: 'center' }}>
          {count > logs.length ? (
            logs.filter((log: ActivityLog) => log.type === EvenementType.createTalent)?.length === 0 && (
              <StyledContainerSeeMore>
                {loadingActivityLogs ? (
                  <StyledSeeMore text={'Chargement...'} />
                ) : (
                  <StyledSeeMore
                    text={'Voir plus'}
                    onClick={() => {
                      setPaginateHistory((prevState) => ({
                        skip: (prevState.skip || 0) + NB_LOGS_PAGINATION,
                        take: NB_LOGS_PAGINATION,
                      }));
                    }}
                  />
                )}
                <KeyboardArrowDownIcon />
              </StyledContainerSeeMore>
            )
          ) : (
            <StyledEndHistory text="Fin de l'historique" />
          )}
        </div>
      </StyledContainerSkillDetails>
    </StyledXP>
  );
};

export default TalentSheetActivityLog;
export { TalentSheetActivityLog };

const StyledXP = styled('div')(
  () => `
  box-shadow: 0px 4.30769px 23.6923px rgba(75, 75, 75, 0.09);
  border-radius: 10px;
  text-align: left;
  padding: 14px 14px 22px 14px;
  `,
);

const StyledContainerSkillDetails = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap; 
  gap: 12px;
  `,
);

const StyledContainerSeeMore = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  `,
);

const StyledEndHistory = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
  text-align: center;
  `,
);

const StyledSeeMore = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[100]};
  text-align: center;
  text-decoration: underline;
  `,
);
