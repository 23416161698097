import React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

export type ModalMissionCompleteProps = {
  data: Record<string, any>;
  recordId: string;
};

const ModalMissionComplete = ({ data, recordId }: ModalMissionCompleteProps) => {
  const { t } = useTranslation(['airtable']);
  const [open, setOpen] = React.useState(null);

  const handleClick = (categorie: string) => {
    const newObj: any = {};
    newObj[categorie] = !open?.[categorie];
    setOpen(newObj);
  };
  const completeRate = data?.complete;
  const categories = Object.keys(data)?.filter((key) => data[key] instanceof Array);
  return (
    <RootList
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Mission complète à {completeRate} %
        </ListSubheader>
      }
    >
      {categories.map((categorie) => (
        <div key={recordId + '-' + categorie}>
          <ListItem button onClick={() => handleClick(categorie)}>
            <ListItemIcon>
              {data?.[categorie]?.length === 0 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText primary={t(categorie)} />
            {data[categorie].length > 0 && (open?.[categorie] === true ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open?.[categorie]} timeout="auto" unmountOnExit>
            <PaddingListItem component="div" disablePadding>
              {data?.[categorie]?.map((subCategory: string) => (
                <ListItem key={recordId + '-' + subCategory} button>
                  <ListItemIcon>
                    <CheckBoxOutlineBlankIcon />
                  </ListItemIcon>
                  <ListItemText primary={t(subCategory)} />
                </ListItem>
              ))}
            </PaddingListItem>
          </Collapse>
        </div>
      ))}
    </RootList>
  );
};

export default ModalMissionComplete;

export { ModalMissionComplete };

const RootList = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: 360,
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  maxHeight: 400,
})) as typeof List;

const PaddingListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
})) as unknown as typeof ListItem;
