import MuiStepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';

export type StepperProps = {
  steps: string[];
  active?: number;
  title?: string;
  sx?: SxProps<Theme>;
};

const Stepper = ({ steps, title = '', active = 0, sx }: StepperProps): JSX.Element => {
  return (
    <Container sx={sx}>
      <Title>{title}</Title>
      <MuiStepper activeStep={active} alternativeLabel>
        {steps.map((label) => (
          <MuiStep key={label}>
            <StepLabel
              StepIconProps={{
                sx: (theme) => {
                  return {
                    color: `${theme.palette.primary.main} !important`,
                  };
                },
              }}
            >
              {label}
            </StepLabel>
          </MuiStep>
        ))}
      </MuiStepper>
    </Container>
  );
};

export default Stepper;
export { Stepper };

const Container = styled('div')(({ theme }) => ({
  width: '95% !important',
  margin: '2em auto',
}));

const Title = styled('div')(() => ({
  margin: '0.8em 0',
  fontSize: '20px',
  fontWeight: 'bold',
}));
