import { useNavigate } from 'react-router-dom';
import { notify } from '../../helpers/toastify';
import { styled, useTheme } from '@mui/material/styles';
import { LoaderSkeleton, SingleLineText } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { IMission } from '@bloomays-lib/types.shared';
import { ApolloError } from '@apollo/client';
import { errorLogger } from '../../helpers/error';

type ListMissionChoiceProps = {
  error: ApolloError | undefined;
  loading: boolean;
  data?: IMission[];
};

const ListMissionChoice = ({ error, loading, data }: ListMissionChoiceProps): JSX.Element => {
  const navigate = useNavigate();

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve data about current bloomer',
    });
    notify('error', 'Impossible de récupérer tes données. Erreur du server, essaie plus tard ! 😓 ', error);
  }
  const theme = useTheme();

  return (
    <div>
      <TitlePart span="Choix de la mission" />
      <SingleLineText
        text="En cliquant sur la mission de ton choix, tu pourras ajouter un CRA à cette même"
        span="mission"
      />
      <Container>
        <ul>
          {loading ? (
            <LoaderSkeleton width={300} height={100} />
          ) : (
            <ul>
              {data?.map((mission: IMission, i: number) => {
                return (
                  <StyledLi
                    key={i}
                    sx={() => {
                      if (!mission.isActive) {
                        return {
                          textDecoration: 'line-through',
                        };
                      }
                      return {};
                    }}
                  >
                    <SingleLineText
                      sx={{
                        '&:hover': {
                          color: theme.palette.primary.main,
                          cursor: 'pointer',
                        },
                      }}
                      id={`missionListButton${i}`}
                      text={`${mission?.label} chez ${mission?.clientSociety?.name}`}
                      onClick={() => {
                        navigate(`/mission/${mission?.recordId}`);
                      }}
                    />
                  </StyledLi>
                );
              })}
            </ul>
          )}
        </ul>
      </Container>
    </div>
  );
};

export default ListMissionChoice;

const StyledLi = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  alignItems: 'center',
  justifyContent: 'center',
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  backgroundColor: theme.palette.paper.white,
  boxShadow: `2px 2px 20px ${theme.palette.grey[300]}`,
  borderRadius: '10px',
  width: '40%',
  margin: '1em auto',
  padding: '1em',
}));
