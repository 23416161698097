import { ActionButton, AlertMessage } from '@bloomays-lib/ui.shared';
import { AlertMessageActionType } from './DashboardAlertMessages';
import { styled } from '@mui/material/styles';

export const AlertMessageAction = ({ title, text, type, onClick }: AlertMessageActionType) => {
  return (
    <AlertContainer>
      <AlertMessage title={title} description={text} icon="error" />
      <ActionButton
        actionType={type}
        text={type === 'Slack' ? 'Voir CCM' : 'Compléter'}
        color={type === 'Slack' ? 'secondary' : 'primary'}
        onClick={onClick}
      />
    </AlertContainer>
  );
};

const AlertContainer = styled('div')(() => ({
  backgroundColor: '#EABC6D',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  padding: '10px',
  borderRadius: '5px',
}));
