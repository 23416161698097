import addMonths from 'date-fns/addMonths';
import addSeconds from 'date-fns/addSeconds';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import compareAsc from 'date-fns/compareAsc';
import fr from 'date-fns/locale/fr';
import enUS from 'date-fns/locale/en-US';
import parse from 'date-fns/parse';
import addHours from 'date-fns/addHours';
import intervalToDuration from 'date-fns/intervalToDuration';
import { IPeriod } from '@bloomays-lib/types.shared';
import add from 'date-fns/fp/add';
import eachMonthOfInterval from 'date-fns/fp/eachMonthOfInterval';
import map from 'lodash/fp/map';
import formatDistance from 'date-fns/formatDistance';
import startOfTomorrow from 'date-fns/startOfTomorrow';

export const isDate = (dateToParse: string | number | Date | Record<string, unknown>): boolean => {
  switch (typeof dateToParse) {
    case 'number': {
      const parsed = new Date(dateToParse * 1000);
      return !isNaN(parsed as unknown as number) && parsed !== null;
    }
    case 'string': {
      const parsed = Date.parse(dateToParse);
      return !isNaN(parsed) && parsed !== null;
    }
    case 'object':
      if (dateToParse instanceof Date) {
        return !isNaN(dateToParse.getTime());
      }
      return false;
    default:
      return false;
  }
};

export const convertDateToStringFormat = (
  givenDate?: string | number | Date | null,
  formatStr = 'yyyy-MM-dd',
): string => {
  if (givenDate === '' || !givenDate) return '';
  if (typeof givenDate === 'string' && givenDate !== '') {
    const convertGivenDate = new Date(givenDate);
    return format(convertGivenDate, formatStr);
  }
  const convertGivenDate = new Date(givenDate);
  return format(convertGivenDate, formatStr);
};

export const convertDateToIso = (givenDate: string | number | Date): string | null => {
  if (givenDate === '' || !isDate(givenDate)) return null;
  const convertGivenDate = new Date(givenDate);
  return convertGivenDate.toISOString();
};

export const parseStringToDate = (str: string, format: string): Date => {
  return parse(str, format, new Date());
};

export const dateStrToUTCDate = (str: string, format: string): Date => {
  const date = parseStringToDate(str, format);
  return new Date(
    `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).slice(-2)}-${('00' + date.getDate()).slice(
      -2,
    )}T00:00:00.000`,
  );
};

export const timestampToFromNewYears = (timestamp: number): number => {
  return new Date(Date.now()).getFullYear() - new Date(timestamp * 1000).getFullYear();
};

export const sortByDate = (arrayOfDateString: any[] | null | undefined): Record<string, unknown>[] => {
  if (!arrayOfDateString || arrayOfDateString.length === 0) return [];
  return arrayOfDateString.sort((a: Record<string, unknown>, b: Record<string, unknown>) => {
    return +new Date(Object.keys(a)[0]) - +new Date(Object.keys(b)[0]);
  });
};

export const isISO8601 = (date: string): boolean => {
  const regex =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
  if (date.match(regex)) {
    return true;
  } else {
    return false;
  }
};

export const detectUSDate = (date: string) => {
  const USDate = /^\d{4}(-|.)(0?[1-9]|1[012])(-|.)(0?[1-9]|[12][0-9]|3[01])$/gm;
  const dateTested = USDate?.test(date);
  if (dateTested) {
    return format(new Date(date), 'dd/MM/yyyy');
  }
  if (date.indexOf('/') === -1) {
    return date;
  }
  const datePart = date.split('/');
  if (datePart.length === 3 && Number(datePart[1]) > 12) {
    return format(new Date(date), 'dd/MM/yyyy');
  }
  return date;
};

export const lastDateOfMonth = (givenDate: Date, nbMonth = 0, dayAfter = false, seconds = -1, hours = 0): Date => {
  const isAfter = dayAfter ? 1 : 0;
  const month = nbMonth + 1;
  const addMonth = addMonths(givenDate, month);
  const y = addMonth.getFullYear();
  const m = addMonth.getMonth();
  let lastDay = new Date(y, m, 1 + isAfter, 0, 0, 0);
  lastDay = addSeconds(lastDay, seconds);
  lastDay = addHours(lastDay, hours);
  return lastDay;
};

export const dateFormat = (dateInput: Date | string | number, pattern: string, locale: 'fr' | 'enUS' = 'fr') => {
  let date: Date;
  if (typeof dateInput === 'string') {
    date = parseISO(dateInput);
    if (date.toString() === 'Invalid Date') {
      date = new Date(dateInput);
    }
  } else {
    if (typeof dateInput === 'number') {
      date = new Date(dateInput);
    } else {
      date = dateInput;
    }
  }
  return format(date, pattern, { locale: locale === 'fr' ? fr : enUS });
};

export const dateToUTC = (date: Date) => {
  return new Date(
    `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).slice(-2)}-${('00' + date.getDate()).slice(
      -2,
    )}T12:00:00.000Z`,
  );
};

export const fromDate = (date: Date, locale: 'fr' | 'en' = 'fr') => {
  return formatDistance(date, new Date(), { addSuffix: true, locale: locale === 'fr' ? fr : enUS });
};

export const formatStrDate = (date: string) => {
  if (!date) {
    return;
  }
  let formatedDateStr = date;
  if (date.includes('-')) {
    // 2021-04-16 ==> 04/16/2021
    const splitDate = date.split('-');
    formatedDateStr = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }
  return formatedDateStr;
};

export const strDateToUTC = (date: string) => {
  const formatedDateStr = formatStrDate(date);
  if (!formatedDateStr) {
    return;
  }
  return dateToUTC(new Date(formatedDateStr));
};

export const timestampToUnix = (ts: number) => {
  return Math.round(ts / 1000);
};

export const constructDateDetails = (period?: IPeriod, current = false) => {
  let intervalYear;
  let intervalMonth;

  const startDate = convertDateToStringFormat(period?.startDate, 'MMM yyyy');
  const endDate = convertDateToStringFormat(period?.endDate, 'MMM yyyy');

  if (period?.startDate && period?.endDate && !current) {
    const intervalDuration = intervalToDuration({
      start: new Date(period?.startDate),
      end: new Date(period.endDate),
    });
    intervalYear = intervalDuration.years;
    intervalMonth = intervalDuration.months;
    const duration = intervalYear && intervalYear > 1 ? 'ans' : 'an';

    return `${startDate} - ${endDate} ${intervalYear ? `- ${intervalYear}` : ''} ${intervalYear ? duration : ''} ${
      intervalYear && intervalMonth ? 'et' : ''
    } ${!intervalYear && intervalMonth ? '-' : ''} ${intervalMonth ? `${intervalMonth} mois` : ''}`;
  }

  if (period?.startDate && (!period?.endDate || current)) {
    const intervalDuration = intervalToDuration({
      start: new Date(period?.startDate),
      end: new Date(),
    });
    intervalYear = intervalDuration.years;
    intervalMonth = intervalDuration.months;
    const duration = intervalYear && intervalYear > 1 ? 'ans' : 'an';
    return `${startDate} - ${"Aujourd'hui"} ${intervalYear ? `- ${intervalYear}` : ''} ${
      intervalYear ? duration : ''
    } ${intervalYear && intervalMonth ? 'et' : ''} ${!intervalYear && intervalMonth ? '-' : ''} ${
      intervalMonth ? `${intervalMonth} mois` : ''
    }`;
  }
  return '-';
};

export const convertFromFrenchDate = (dateString: string): Date => {
  dateString = detectUSDate(dateString);
  const [day, month, year] = dateString.replaceAll('.', '/').replaceAll('-', '/').split('/');
  let yearN: number;
  if (year && year.length === 2) {
    yearN = Number(year) + 2000;
  } else {
    yearN = Number(year);
  }
  const newFrDate = new Date(yearN, Number(month) - 1, Number(day));
  if (isDate(newFrDate)) {
    return newFrDate;
  }
  const newFrStringDate = parse(dateString, 'do MMMM yyyy', new Date(), {
    locale: fr,
  });

  if (isDate(newFrStringDate)) {
    return newFrStringDate;
  }

  return new Date(dateString);
};

export const buildMonthYearInterval = (startDate: Date, options?: { fullYear?: boolean; endDate?: Date }): string[] => {
  const interval = {
    start: startDate,
    end: startDate,
  };
  if (options) {
    const { fullYear, endDate } = options;
    if (fullYear) {
      interval.end = add({ months: 11 }, startDate);
    }
    if (endDate) {
      interval.end = endDate;
    }
  }

  const monthDates = eachMonthOfInterval(interval);
  const monthYearInternal = map((date: Date) => format(date, 'MM/yyyy'))(monthDates);
  return monthYearInternal;
};

export const addDayToDate = (date: Date, days: number) => {
  return addDays(date, days);
};

export const diffDays = (dateleft: Date, dateRight: Date) => {
  return differenceInDays(dateleft, dateRight);
};

export const greaterDate = (dateLeft: Date, dateRight: Date) => {
  if (compareAsc(dateLeft, dateRight) === 1) {
    return dateLeft;
  }
  return dateRight;
};

export const getTomorrowFormatedDate = (): string => {
  return format(startOfTomorrow(), 'yyyy-MM-dd');
};
