import { ITalent } from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../helpers/toastify';
import { useMutation } from '@apollo/client';
import { DELETE_TALENT_V2 } from '@bloomays-lib/adapter.api-talent';
import DeleteActionPopin from '../molecules/DeleteActionPopin';

type DeleteTalentPopinProps = {
  talent: ITalent;
  opened: boolean;
  origin: 'searchPage' | 'talentPage';
  openPopin: (param: boolean) => void;
};

const DeleteTalentPopin = ({ talent, opened, openPopin, origin }: DeleteTalentPopinProps) => {
  const { t } = useTranslation(['talentSheet', 'random']);
  const navigate = useNavigate();

  const [deleteTalent] = useMutation(DELETE_TALENT_V2, {
    context: { clientName: 'api.talents' },
  });

  const handleOnClick = async () => {
    try {
      await deleteTalent({
        variables: {
          id: talent.id, // c'est pas censé être ? !
          isRGPD: true,
        },
      });
      if (origin === 'searchPage') {
        navigate(0);
      } else {
        navigate('/talents');
      }
    } catch (err: any) {
      notify('error', 'SOME ERROR', null, {
        hideProgressBar: true,
        autoClose: 5000,
      });
    }
  };

  return (
    <DeleteActionPopin
      opened={opened}
      onClose={() => {
        openPopin(false);
      }}
      deleteConfirmLabel={t('deleteConfirmLabel', { ns: 'talentSheet' })}
      confirmLabel={t('emailConfirm', { ns: 'talentSheet' })}
      expectedValue={talent.email as string}
      deleteAction={handleOnClick}
    />
  );
};

export default DeleteTalentPopin;
