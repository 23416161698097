import { useEffect, useState } from 'react';
import { notify } from '../../helpers/toastify';
import MissionDetailCard from '../molecules/MissionDetailCard';
import { TitlePart } from '@bloomays-lib/ui.shared';
import {
  BrowserBloomer,
  IContact,
  IMissionCardDetails,
  ISociety,
  IBloomerCompletion,
} from '@bloomays-lib/types.shared';
import { ApolloError } from '@apollo/client';
import Popover from '@mui/material/Popover';
import { Button } from '@bloomays-lib/ui.shared';
import ModalMissionComplete from '../molecules/ModalMissionComplete';
import { styled } from '@mui/material/styles';
import { errorLogger } from '../../helpers/error';
import Stack from '@mui/material/Stack';
import MissionCard, { PopinType } from '../molecules/MissionCard';
import Modal from '../atoms/Modal';
import { AlertMessageActionType, DashboardAlertMessages } from '../molecules/DashboardAlertMessages';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export type ContextMissionDetailsProps = {
  error?: ApolloError;
  contextBloomer: BrowserBloomer;
  contextMission: IMissionCardDetails;
  contextContactBloomer: IContact;
  contextContactBillingClient: IContact | IContact[];
  contextSocietyBloomer: ISociety;
  contextSocietyClient: ISociety;
  contextSocietyClientBilling: ISociety;
  contextMissionCompletion: IBloomerCompletion;
  contextContactOperationnal: IContact;
};

const getMissingFields = (
  t: TFunction,
  contextMission: IMissionCardDetails,
  setMissionPopinToOpen: (type: PopinType) => void,
): AlertMessageActionType[] => {
  const { clientSociety, pricing, contractType, bloomerBillingSociety, contactOperations, billingContacts } =
    contextMission;

  const slackUrl = `slack://channel?team=T010RDTFK9A&id=C02DDHHSU9F`;

  const missingFields: AlertMessageActionType[] = [];

  if (!clientSociety) {
    missingFields.push({
      title: t('clientSociety'),
      text: t('clientSocietyText'),
      type: 'Slack',
      onClick: () => {
        window.location.href = slackUrl;
      },
    });
  }

  if (!pricing) {
    missingFields.push({
      title: t('pricing'),
      text: t('pricingText'),
      type: 'Slack',
      onClick: () => {
        window.location.href = slackUrl;
      },
    });
  }

  if (!contractType) {
    missingFields.push({
      title: t('contractType'),
      text: t('contractTypeText'),
      type: 'Edit',
      onClick: () => {
        setMissionPopinToOpen('modify');
      },
    });
  }

  if (isEmpty(bloomerBillingSociety)) {
    missingFields.push({
      title: t('bloomerBillingSociety'),
      text: t('bloomerBillingSocietyText'),
      type: 'Slack',
      onClick: () => {
        window.location.href = slackUrl;
      },
    });
  }

  if (!contactOperations) {
    missingFields.push({
      title: t('contactOperations'),
      text: t('contactOperationsText'),
      type: 'Slack',
      onClick: () => {
        window.location.href = slackUrl;
      },
    });
  }

  if (isEmpty(billingContacts)) {
    missingFields.push({
      title: t('billingContacts'),
      text: t('billingContactsText'),
      type: 'Slack',
      onClick: () => {
        window.location.href = slackUrl;
      },
    });
  }
  return missingFields;
};

const ContextMissionDetails = ({
  error,
  contextBloomer,
  contextMission,
  contextContactBloomer,
  contextContactBillingClient,
  contextSocietyBloomer,
  contextSocietyClient,
  contextSocietyClientBilling,
  contextContactOperationnal,
}: ContextMissionDetailsProps) => {
  const { t } = useTranslation(['dashboardMissingFields']);

  const [completeModal, setCompleteModal] = useState<{
    id?: string;
    content?: any;
    recordId?: string;
  } | null>(null);
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false);
  const [missionPopinToOpen, setMissionPopinToOpen] = useState<PopinType>(null);
  const [alertFields, setAlertFields] = useState<AlertMessageActionType[]>([]);

  useEffect(() => {
    const missingFields = getMissingFields(t, contextMission, (type: PopinType) => setMissionPopinToOpen(type));
    if (!isEmpty(missingFields)) {
      setAlertFields(missingFields);
      setOpenAlertModal(true);
    }
  }, [contextMission]);

  const handleClose = () => {
    setCompleteModal(null);
  };

  const open = Boolean(completeModal?.id);
  const id = open ? 'simple-popover' : undefined;

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to retrieve data about bloomers and their infos !',
    });
    notify('error', 'Impossible de récupérer la liste des bloomers. Erreur du server, essaie plus tard ! 😓', error);
  }

  return (
    <ContainerSuccess>
      <TitlePart textTitle="Récapitulatif de la mission" />
      <Stack direction="row" spacing={2} justifyContent="center">
        {contextMission?.replacement && (
          <Button
            id={`btnReplacement${contextMission?.recordId}`}
            style={{
              backgroundColor: 'blue',
              marginLeft: '20px',
            }}
            textButton={`Remplacement`}
          ></Button>
        )}
      </Stack>

      <ContainerDataCardList direction="row" useFlexGap flexWrap="wrap" justifyContent="space-around">
        <MissionDetailCard type="bloomer" data={{ bloomer: contextBloomer }} />
        <MissionCard
          mission={contextMission}
          popinToOpen={missionPopinToOpen}
          resetPopinToOpen={() => {
            setMissionPopinToOpen(null);
          }}
        />
        <MissionDetailCard type="society" data={{ society: contextSocietyBloomer, extraInfoTitle: 'du bloomer' }} />
        <MissionDetailCard
          type="contact"
          data={{ contact: contextContactBloomer, extraInfoTitle: 'bloomer de facturation' }}
        />
        <MissionDetailCard type="society" data={{ society: contextSocietyClient, extraInfoTitle: 'du client' }} />
        <MissionDetailCard
          type="society"
          data={{ society: contextSocietyClientBilling, extraInfoTitle: 'de facturation du client' }}
        />
        <MissionDetailCard
          type="contact"
          data={{ contact: contextContactBillingClient, extraInfoTitle: 'client de facturation' }}
        />
        <MissionDetailCard
          type="contact"
          data={{ contact: contextContactOperationnal, extraInfoTitle: 'client opérationnel' }}
        />
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={completeModal?.id as unknown as Element}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {completeModal?.content && completeModal?.recordId && (
            <ModalMissionComplete
              recordId={completeModal?.recordId}
              data={completeModal?.content}
            ></ModalMissionComplete>
          )}
        </Popover>
      </ContainerDataCardList>
      <Modal
        additionnalCSS={{ borderRadius: '5px' }}
        open={openAlertModal === true}
        onClose={() => {
          setOpenAlertModal(false);
        }}
      >
        <DashboardAlertMessages fields={alertFields} />
      </Modal>
    </ContainerSuccess>
  );
};

export default ContextMissionDetails;

const ContainerSuccess = styled('div')(() => ({
  backgroundColor: 'rgb(255, 255, 255)',
  width: '100%',
  margin: 'auto',
}));

const ContainerDataCardList = styled(Stack)(() => ({}));
