import { ChangeEventHandler } from 'react';

import { LabelOfInput } from '../atoms/LabelOfInput';
import { TextArea } from '@bloomays-lib/ui.shared';

type LabelAndTextAreaProps = {
  name: string;
  textLabel: string;
  value: string;
  required: boolean;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  classname?: string;
  maxLength?: number;
  readOnly?: boolean;
};

const LabelAndTextArea = ({
  name,
  textLabel,
  value,
  required,
  handleChange,
  classname,
  maxLength,
  readOnly = false,
}: LabelAndTextAreaProps) => {
  return (
    <div className={classname}>
      <LabelOfInput name={name} textLabel={textLabel} required={required} />
      <div>
        <TextArea
          readOnly={readOnly}
          name={name}
          value={value}
          handleChange={handleChange}
          required={required}
          maxLength={maxLength}
        />
        <span className="validity"></span>
      </div>
    </div>
  );
};

export default LabelAndTextArea;
