import React from 'react';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import BloomaysLogo from '../../graphic-charter/bloomaysLogo.svg';
import { styled } from '@mui/material/styles';
import { Logo } from '@bloomays-lib/ui.shared';
import { BloomerStatus } from '@bloomays-lib/types.shared';

export type NameTalentProps = {
  name: string;
  statusBloomer?: BloomerStatus;
};

const StyledContainer = styled('div')(
  `
  display: flex;
  align-items: center;
  gap: 8px;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);

const NameTalent = ({ name, statusBloomer }: NameTalentProps) => {
  return (
    <StyledContainer>
      <StyledText variant={'body2Medium'} text={name} />
      {statusBloomer && (
        <abbr title={statusBloomer}>
          <Logo image={BloomaysLogo} alt={statusBloomer} />
        </abbr>
      )}
    </StyledContainer>
  );
};

export default NameTalent;
export { NameTalent };
