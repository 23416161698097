import { styled } from '@mui/material/styles';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import VeryDissatisfiedRawIcon from '@mui/icons-material/SentimentVeryDissatisfiedSharp';
import VerySatisfiedRawIcon from '@mui/icons-material/SentimentVerySatisfiedSharp';

export type NoteSystemProps = {
  number?: number;
  selected?: number;
  setSelected: (e: number) => void;
};

const NoteSystem = ({ number = 10, selected, setSelected }: NoteSystemProps) => {
  const array = [];
  for (let i = 0; i <= number; i++) {
    array.push(i);
  }
  return (
    <Container>
      <ContainerNote>
        {array?.map((nb, i) => {
          return (
            <SingleLineText
              sx={(theme) => {
                if (selected === nb) {
                  return {
                    color: theme.palette.paper.white,
                    backgroundColor: theme.palette.secondary.dark,
                    borderRadius: 50,
                    width: '45px',
                    height: '45px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                      color: theme.palette.grey[100],
                      cursor: 'pointer',
                    },
                  };
                }
                return {
                  color: theme.palette.grey[100],
                  backgroundColor: theme.palette.grey[200],
                  borderRadius: 50,
                  width: '45px',
                  height: '45px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary[500],
                    cursor: 'pointer',
                  },
                };
              }}
              key={i}
              text={nb.toString()}
              variant={'subtitle2Medium'}
              onClick={() => setSelected(i)}
            />
          );
        })}
      </ContainerNote>
      <ContainerIcons>
        <VeryDissatisfiedIcon />
        <VerySatisfiedIcon />
      </ContainerIcons>
    </Container>
  );
};

export default NoteSystem;
export { NoteSystem };

const ContainerNote = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
}));

const ContainerIcons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  margin: '20px 0 20px 0',
}));

const VeryDissatisfiedIcon = styled(VeryDissatisfiedRawIcon)(({ theme }) => ({
  color: theme.palette.tertiary.red,
  transform: 'scale(2)',
  marginLeft: '8px',
  cursor: 'pointer',
}));

const VerySatisfiedIcon = styled(VerySatisfiedRawIcon)(({ theme }) => ({
  color: theme.palette.tertiary.greenMedium,
  cursor: 'pointer',
  marginRight: '8px',
  transform: 'scale(2)',
}));
