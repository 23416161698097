import { useMemo } from 'react';
import { Option, SelectField } from '../atoms/SelectField';
import countries from 'i18n-iso-countries';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import { styled } from '@mui/material/styles';

export type Country = {
  iso2: string;
  name: string;
};

countries.registerLocale(frLocale);
export type CountrySelectorProps = {
  value?: string;
  name: string;
  required?: boolean;
  onChange: (country?: Country) => void;
  id: string;
  hidden?: boolean;
  allowedCountryCode?: (code: string) => boolean;
};

const CountrySelector = ({
  value,
  hidden,
  id,
  required,
  name,
  onChange,
  allowedCountryCode = () => true,
}: CountrySelectorProps) => {
  const getOptions = useMemo((): Option[] => {
    const collator = new Intl.Collator();
    const keyValues = countries.getNames('fr', { select: 'official' });
    return Object.keys(keyValues)
      .filter(allowedCountryCode)
      .map((key) => ({ iso2: key, name: countries.getName(key, 'fr') }))
      .sort((a, b) => collator.compare(a.name, b.name))
      .map(({ iso2, name }) => {
        return {
          value: iso2,
          label: (
            <>
              <FlagImg
                loading="lazy"
                srcSet={`https://flagcdn.com/w40/${iso2.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${iso2.toLowerCase()}.png`}
                alt={name}
              />
              {name}
            </>
          ),
        };
      });
  }, [allowedCountryCode]);
  return (
    <SelectField
      hidden={hidden}
      data-testid={`countrySelector-test-${id}`}
      aria-label={`countrySelector-test-${name}`}
      id={id}
      name={name}
      label="Pays de la société"
      value={value || ''}
      required={required}
      options={getOptions}
      handlechange={(e) => {
        if (e.target.value) {
          const name = countries.getName(e.target.value, 'fr');
          return onChange({ iso2: e.target.value, name });
        }
        onChange();
      }}
    />
  );
};

const FlagImg = styled('img')(
  () => `
  margin-right: 10px;
  width: 20px;
`,
);

export default CountrySelector;
export { CountrySelector };
