import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IMultipleSelectItem } from './MultipleSelectSearch';
import { SingleLineText, ItemTalentLevel } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';

type MultipleSelectedItemsBoxProps = {
  items: IMultipleSelectItem[];
  handleChange: (item: IMultipleSelectItem, c: boolean) => void;
};

const MultipleSelectedItemsBox = ({ items, handleChange }: MultipleSelectedItemsBoxProps) => {
  const { t } = useTranslation(['talentSheet']);

  return (
    <StyledBoxSelected>
      {items.length === 0 ? (
        <SingleLineText text={t('noItemSelected', { ns: 'talentSheet' })} />
      ) : (
        <>
          {items.map((item: IMultipleSelectItem) => (
            <ItemTalentLevel
              level={item.level}
              clickable={true}
              key={item.label}
              skill={{
                label: item.label,
              }}
              onClickCross={() => handleChange({ ...item}, false)}
            />
          ))}
        </>
      )}
    </StyledBoxSelected>
  );
};

export default MultipleSelectedItemsBox;

const StyledBoxSelected = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 4px;
    min-height: 32px;
    max-height: 128px;
    overflow-y: scroll;
    padding: 4px 0px;
    background-color: ${theme.palette.grey[200]};
    padding-left: 0.5em; 
   `,
);
