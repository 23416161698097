import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export type FilterTabProps = {
  label: string;
  numberOfFilter?: number;
  onClick: () => void;
  filled: boolean;
};

type FilterProps = {
  filled: boolean;
};

const FilterTab = ({ label, numberOfFilter = 0, onClick, filled }: FilterTabProps) => {
  const title = numberOfFilter > 0 ? `${label} (${numberOfFilter})` : label;
  const variant = numberOfFilter > 0 ? 'body2Medium' : 'body1Regular';

  return (
    <StyledButtonFilter onClick={onClick} filled={filled}>
      {/* succes_filter and data-aria-label used by marketing*/}
      {numberOfFilter > 0 && <StyledActiveChip className="succes_filter" data-aria-label={label} />}
      <Typography variant={variant}>{title}</Typography>
    </StyledButtonFilter>
  );
};

export default FilterTab;
export { FilterTab };

const StyledButtonFilter = styled('div')<FilterProps>(
  ({ theme, filled }) => `
  border-radius: 58px;
  border: ${filled ? `1.2px solid ${theme.palette.grey[200]}` : `1.2px solid ${theme.palette.grey[800]}`};
  width: 165px;
  height: 34px;
  color: ${theme.palette.grey[500]};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${filled ? `${theme.palette.grey[200]}` : ''};

  &:hover {
    background-color: ${filled ? `${theme.palette.grey[300]}` : ''};
    border: ${filled ? `1.2px solid ${theme.palette.grey[300]}` : `1.2px solid ${theme.palette.grey[500]}`};
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;
  }
`,
);

const StyledActiveChip = styled(FiberManualRecordIcon)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  display: flex;
  font-size: 14px;
  align-items: center;
  align-content: center;
  padding-right: 4px;
`,
);
