import { gql } from '@apollo/client';

export const JOBS_COMPLETED_SUBSCRIPTION = gql`
  subscription jobCompleted($jobId: ID) {
    jobCompleted(jobId: $jobId) {
      jobId
      jobQueueName
    }
  }
`;

export const JOBS_ERRORED_SUBSCRIPTION = gql`
  subscription jobErrored($jobId: ID) {
    jobErrored(jobId: $jobId) {
      jobId
      jobQueueName
      jobError {
        name
        message
      }
    }
  }
`;
