import StepIcon from '@mui/material/StepIcon';

export type StepProps = {
  step: string;
};

const Step = ({ step }: StepProps): JSX.Element => {
  return (
    <StepIcon
      icon={step}
      sx={(theme) => {
        return {
          color: `${theme.palette.primary.main} !important`,
        };
      }}
    ></StepIcon>
  );
};

export default Step;
export { Step };
