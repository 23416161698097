import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import Stack from '@mui/material/Stack';
import Notification from '../molecules/Notification';
import Button from '../atoms/Button';
import { NotificationCenterItemData } from '@bloomays-lib/types.shared';
import TitlePart from '../atoms/TitlePart';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import NotificationBadge from '../molecules/NotificationBadge';
import { NotificationCenterItem } from '../types';
import Divider from '@mui/material/Divider';
import Switch from '../atoms/Switch';

export type NotificationCenterProps = {
  initNotifications: NotificationCenterItem[];
  onRead?: (notification: NotificationCenterItem) => Promise<boolean>;
  onReadAll?: () => Promise<number>;
  onClearAll?: () => Promise<number>;
  open?: boolean;
};

export const NotificationCenter = ({
  initNotifications,
  onRead,
  onClearAll,
  onReadAll,
  open = false,
}: NotificationCenterProps): JSX.Element => {
  const { notifications, clear, markAllAsRead, markAsRead, unreadCount, add } =
    useNotificationCenter<NotificationCenterItemData>();
  const anchorRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    initNotifications.forEach((v) => {
      const existingNotif = notifications.find((n) => n.id === v.id);
      if (!existingNotif) {
        add(v);
      } else {
        // avoid max depth error
        if (existingNotif.read !== v.read) {
          markAsRead(v.id);
        }
      }
    });
    // intentionel ne surtout pas mettre notifications qui ne doit pas relancer le peuplement, seulement initNotifications
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initNotifications]);

  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const toggleFilter = (e: React.ChangeEvent) => {
    setShowUnreadOnly(!showUnreadOnly);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsOpen(false);
  };

  const toggleNotificationCenter = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsOpen(false);
    } else if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  return (
    <>
      <NotificationBadge
        ref={anchorRef}
        notificationsUnread={unreadCount}
        toggleNotificationCenter={toggleNotificationCenter}
      />
      <Popper open={isOpen} anchorEl={anchorRef.current} transition sx={{ margin: '8px', zIndex: 2 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={10}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box
                    onKeyDown={handleListKeyDown}
                    sx={{
                      background: 'white',
                      padding: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <TitlePart textTitle={`Notifications (${unreadCount})`} variant={'heading5'} />
                    <Switch onSwitch={toggleFilter} checked={showUnreadOnly} label="Non lus" />
                  </Box>
                  <Stack
                    sx={{
                      height: '400px',
                      width: 'min(60ch, 100ch)',
                      padding: '12px',
                      background: 'white',
                      borderRadius: '8px',
                      overflowY: 'auto',
                    }}
                    spacing={2}
                  >
                    {(!notifications.length || (unreadCount === 0 && showUnreadOnly)) && (
                      <h4>
                        Aucune notification{' '}
                        <span role="img" aria-label="dunno what to put">
                          🎉
                        </span>
                      </h4>
                    )}
                    {(showUnreadOnly ? notifications.filter((v) => !v.read) : notifications).map((notification) => {
                      return (
                        notification && (
                          <>
                            <Notification
                              notification={notification}
                              onRead={async () => {
                                markAsRead(notification.id);
                                if (onRead) {
                                  return await onRead(notification);
                                }
                                return true;
                              }}
                              key={notification.id}
                            />
                            <Divider />
                          </>
                        )
                      );
                    })}
                  </Stack>
                  <Box
                    sx={{
                      background: 'white',
                      padding: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      disable={!notifications.length}
                      onClick={async (e) => {
                        e.preventDefault();
                        clear();
                        if (onClearAll) {
                          return await onClearAll();
                        }
                        return true;
                      }}
                      textButton="Tout supprimer"
                    ></Button>

                    <Button
                      disable={unreadCount === 0}
                      onClick={async (e) => {
                        e.preventDefault();
                        markAllAsRead();
                        if (onReadAll) {
                          return await onReadAll();
                        }
                        return true;
                      }}
                      textButton="Tout marquer comme lus"
                    ></Button>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
