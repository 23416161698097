import { styled } from '@mui/material/styles';
import TitlePart from '../atoms/TitlePart';
import { TextArea } from '../atoms/TextArea';
import CardItem from '../atoms/CardItem';
import SingleLineText from '../atoms/SingleLineText';
import Button from '../atoms/Button';
import Log from '../molecules/Log';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { ActivityLog } from '@bloomays-lib/types.shared';
import { useState } from 'react';

export type TalentActivityLogsFormProps = {
  savingComment: boolean;
  onAddComment: (comment: string) => Promise<void>;
  logs: ActivityLog[];
};

const TalentActivityLogsForm = ({ savingComment, onAddComment, logs }: TalentActivityLogsFormProps) => {
  const [comment, setComment] = useState('');

  return (
    <>
      <TitlePart variant="subtitle2Medium" textTitle="Historique des activités" />
      <StyledCalculInformation>
        <StyledContainer>
          <CardItem variant={'grey'} size={'small'} rounded={true} emoji={<ChatBubbleOutlineIcon />} />
          <StyledAddComment>
            <SingleLineText variant={'body2SemiBold'} text="Ajouter un commentaire" />
            <TextArea
              placeholder="Un petit mot doux ?"
              handleChange={(e: { target: { value: React.SetStateAction<string> } }) => setComment(e.target.value)}
              name={'sweetWord-textArea'}
              value={comment}
              required={false}
            />
            <StyledButtonDiv>
              <Button
                disable={savingComment || comment.length === 0}
                textButton="Ajouter un commentaire"
                onClick={async () => {
                  await onAddComment(comment);
                  setComment('');
                }}
              />
            </StyledButtonDiv>
          </StyledAddComment>
        </StyledContainer>
        {logs.length > 0 &&
          logs.map((log: ActivityLog) => {
            return (
              <div style={{ width: '100%' }}>
                <Log activityLog={log} />
              </div>
            );
          })}
      </StyledCalculInformation>
    </>
  );
};

const StyledCalculInformation = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 12px;
  border-radius: 10px;
  margin-top: 10px;
  `,
);

const StyledContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-bottom: 0.2em;
`,
);

const StyledAddComment = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`,
);

const StyledButtonDiv = styled('div')(
  () => `
  display: flex;
  width: 100%;
  justify-content: flex-end;
`,
);

export default TalentActivityLogsForm;
export { TalentActivityLogsForm };
