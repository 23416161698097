import { useMutation, useQuery } from '@apollo/client';
import { cancel, notify } from '../../helpers/toastify';
import useCustomLocalStorage from '../../customHooks/useCustomLocalStorage';

import BillingDashboard from '../organisms/BillingDashboard';
import { TitlePart } from '@bloomays-lib/ui.shared';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { RECRUITEMENTS, UPSERT_BILLING } from '@bloomays-lib/adapter.api-bloomer';
import { IBilling } from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import { errorLogger } from '../../helpers/error';

interface BillingActivityVars {
  isTrialPeriod: boolean;
}
interface BillingActivityData {
  getRecruitements: IBilling[];
}

interface UpsertBillingVars {
  input: IBilling;
}
interface BillingActivityData {
  upsertBilling: IBilling;
}

const BillingActivity = (): JSX.Element => {
  const { t } = useTranslation(['notify']);
  const [isTrialPeriod, setIsTrialPeriod] = useCustomLocalStorage('isTrialPeriod', false);
  const { data, loading, error } = useQuery<BillingActivityData, BillingActivityVars>(RECRUITEMENTS, {
    variables: {
      isTrialPeriod,
    },
  });
  const [upsertBilling] = useMutation<BillingActivityData, UpsertBillingVars>(UPSERT_BILLING);

  const updateBilling = async (billing: IBilling) => {
    notify('info', `${t('updateInProgress')}`);
    try {
      const dataBilling = await upsertBilling({
        variables: { input: billing },
      });
      if (dataBilling?.data?.upsertBilling) {
        cancel();
        notify('success', `${t('updateSuccess')}`);
      }
    } catch (e: any) {
      cancel();
      notify('error', `${t('randomUpdateError')}`, e);
    }
  };

  if (error) {
    errorLogger(error, {
      extraInfos: 'Internal server error, impossible to get recruitements !',
      state: {},
    });
    cancel();
    notify('error', 'Impossible de récupérer la liste des CDIs ! Erreur du server, essaie plus tard ! 😓', error);
  }

  return (
    <div>
      <TitlePart id="CDIActivityTitle" span="Dashboard de l'activité en CDI" />
      <SingleLineText
        id="descriptionCDIDasboard"
        text="Ici tu retrouveras toutes les missions de nos clients et bloomers en CDI !"
      />
      <BillingDashboard
        loading={loading}
        billings={data?.getRecruitements}
        isTrialPeriod={isTrialPeriod}
        onChange={() => setIsTrialPeriod(!isTrialPeriod)}
        updateBilling={updateBilling}
      />
    </div>
  );
};

export default BillingActivity;
