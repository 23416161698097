import React from 'react';
import { Button as RawButton } from './Button';

type LogoutButtonProps = {
  textButton?: string;
  type?: 'link' | 'button';
  style?: Record<string, unknown>;
  logout?: (e: { returnTo: string }) => void;
};

const LogoutButton = ({ textButton, type, style, logout }: LogoutButtonProps): JSX.Element => {
  return (
    <RawButton
      style={style}
      sx={(theme) => {
        if (type === 'link') {
          return {
            minHeight: '0px',
            display: 'flex',
            flexFlow: 'row nowrap',
            color: theme.palette.grey[100],
            textTransform: 'none',
            height: '55px',
            paddingLeft: '24px',
            width: '100%',
            justifyContent: 'left',
            backgroundColor: theme.palette.paper.white,
            transition: 'none',
            '&:hover': {
              backgroundColor: theme.palette.secondary[500],
              color: theme.palette.secondary.dark,
              fontSize: theme.typography.body2SemiBold.fontSize,
              fontWeight: theme.typography.body2SemiBold.fontWeight,
              fontFamily: theme.typography.body2SemiBold.fontFamily,
            },
          };
        }
        return {
          border: 'none',
          padding: '0.7em 3.5em',
          borderRadius: 20,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.paper.white,
          boxShadow: '2px 2px 5px rgb(201, 201, 201)',
          margin: '0.8em auto',
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.paper.white,
            color: theme.palette.primary.main,
            transition: '0.51s ease',
          },
        };
      }}
      textButton={textButton || 'Se déconnecter'}
      onClick={(e) => {
        sessionStorage.clear();
        localStorage.clear();
        logout && logout(e);
      }}
    />
  );
};

export default LogoutButton;
export { LogoutButton };
