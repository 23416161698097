import LogoBloomays from '../../assets/logoWordmark.svg';
import { Auth0User, Role } from '@bloomays-lib/types.shared';
import { Logo as RawLogo } from '../atoms/Logo';
import LogoutButton from '../atoms/LogoutButton';
import MenuAvatar from './MenuAvatar';
import { styled } from '@mui/material/styles';
import { MenuItem } from '../atoms/MenuItem';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { NotificationCenter } from '../organisms/NotificationCenter';
import { NotificationCenterContext } from '../types';

export type HeaderProps = {
  user?: Auth0User | null;
  logout?: (e: { returnTo: string }) => void;
  invitationFinished?: boolean;
  children?: React.ReactNode;
  returnToDashboard?: () => void;
  notificationCenterContext?: NotificationCenterContext;
};

const Header = ({
  user,
  logout,
  children,
  invitationFinished = true,
  returnToDashboard,
  notificationCenterContext,
}: HeaderProps) => {
  const { t } = useTranslation('menu');
  const [notificationCenterItems, setNotificationCenterItems] = useState(
    notificationCenterContext?.notifications || [],
  );
  const isAdmin = user?.roles?.includes(Role.Admin);
  const isBloomer = user?.roles?.includes(Role.Bloomer);
  const isAnonymous = user?.roles?.includes(Role.Anonymous);
  const isInOnboarding = !invitationFinished;
  if (isAnonymous) {
    returnToDashboard = undefined;
  }

  useEffect(() => {
    setNotificationCenterItems(notificationCenterContext?.notifications || []);
  }, [notificationCenterContext]);

  return (
    <Container>
      <Logo
        alt="logo bloomays"
        image={LogoBloomays}
        sx={
          returnToDashboard && {
            '&:hover': {
              cursor: 'pointer',
            },
          }
        }
        onClick={returnToDashboard ? () => returnToDashboard() : undefined}
      />
      {user && (
        <>
          {notificationCenterContext && notificationCenterItems ? (
            <NotificationCenter
              initNotifications={notificationCenterItems}
              onRead={notificationCenterContext?.read}
              onReadAll={notificationCenterContext?.readAll}
              onClearAll={notificationCenterContext?.deleteAll}
            />
          ) : undefined}
          <MenuAvatar user={user}>
            {!isAnonymous && !isInOnboarding && <MenuItem name={t('dashboard')} url="/dashboard" shape="square" />}
            {isAdmin && (
              <div>
                <MenuItem name={t('planningMission')} url="/planningMission" shape="square" />
                <MenuItem name={t('billingsActivity')} url="/billingsActivity" shape="square" />
                <MenuItem name={t('finance')} url="/finance" shape="square" />
                <MenuItem name={t('talents')} url="/talents" shape="square" />
                <MenuItem name={t('activities')} url="/activities" shape="square" />
                <MenuItem name={t('legalContracts')} url="/contracts" shape="square" />
              </div>
            )}

            {isBloomer && !isInOnboarding && <MenuItem name={t('missions')} url="/missions" shape="square" />}
            {isBloomer && !isInOnboarding && (
              <MenuItem name={t('meInformations')} url="/me/informations" shape="square" />
            )}
            {(isBloomer || isAdmin) && !isInOnboarding && (
              <MenuItem name={t('settings')} url="/settings" shape="square" />
            )}
            {(isBloomer || isAnonymous) && (
              <MenuItem name="BloomHelp" url="https://bit.ly/bloomays-help" shape="square" />
            )}
            {logout && <LogoutButton type="link" logout={logout} />}
          </MenuAvatar>
        </>
      )}
      {children}
    </Container>
  );
};

export default Header;
export { Header };

const Logo = styled(RawLogo)(({ theme }) => ({
  marginRight: 'auto',
  padding: '1em',
  maxWidth: 180,
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '45%',
  },
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.paper.white,
  width: '100%',
  height: '80px',
  marginBottom: '2em',
  boxShadow: '0px 4.380000114440918px 24.100000381469727px 0px rgba(75, 75, 75, 0.09)',
}));
