import { IBloomies } from './domain';
import { NotificationCanal, NotificationPriority, NotificationType } from './notifications';
import { BillingType, ContractType, FinanceType, ServerFieldOrFile } from './types.shared';

export enum MissionType {
  IT = 'IT',
  RPO = 'RPO',
  People = 'People',
}

export interface AirtableMissionPayload {
  label?: string;
  beginMission: string;
  endMission: string;
  statusBloomer?: string;
  statusClient?: string;
  bloomer: string[];
  bloomerFullName?: string[];
  firstname?: string[];
  lastname?: string[];
  activity: string[];
  pricing?: string[];
  clientSociety?: string[];
  clientSocietyName: string[];
  clientBillingSociety?: string[];
  clientBillingSocietyName: string[];
  bloomerBillingSociety?: string[];
  bloomerSocietyName?: string[];
  purchase?: number[];
  sale?: number[];
  salePricePerDayWithoutTaxes?: number[];
  marginAmount?: number[];
  bloomerRecordId: string[];
  contactOperations?: string[];
  isActive?: number;
  completed: number;
  paydateDays?: number[];
  bloomerPaydateDays?: number;
  clientPaydateDays?: number;
  externalId?: string;
  bdc?: string;
  bdcExpirationDate?: string;
  billingContacts?: string[];
  type: MissionType;
  havePreviousMonthActivity?: string[];
  reasearchField?: string;
  jobId?: string;
  contracts?: string[];
  CSM?: string[];
  contractType?: string;
  enableCRAUpload?: boolean;
  createdAt: string;
  replacement?: boolean;
  isDeal?: boolean;
  suiviCardId?: string;
}
export interface AirtableMission extends AirtableMissionPayload {
  id?: number;
  recordId?: string; // TODO : this should not be ?
  modifiedBy: {
    name: string;
  };
}

export type AirTableQueryResult<T> = { count: number; results: T[] };
export type AirTableMissionQueryResult = AirTableQueryResult<AirtableMission>;

export type ContractLegalStatus = 'on going' | 'done';

export type AirtableFile = {
  id: string;
  url: string;
  filename: string;
  size: number;
  type: 'application/pdf';
};

export interface AirtableLegalContractPayload {
  nbSigner: number; // TODO !! this should not be ?
  signerDocId1?: string;
  createdDate: string;
  signedCount?: number; // TODO !! this should not be ?
  signedDate?: string;
  smartFields?: string;
  status: ContractLegalStatus;
  provider?: string;
  worflowExternalId?: string; // pipefyCardId - each contract is supposed to be linked to a pipefy legal funnel card
  contractTemplate?: string[]; // TODO !! this should not be ?
  type: ContractType;
  step: string;
  emptyFile?: Partial<AirtableFile>[];
  signedFile?: Partial<AirtableFile>[];
  externalDocumentId?: string;
}
export interface AirtableLegalContract extends AirtableLegalContractPayload {
  id: number;
  recordId: string;
  createdAt: string;
  emptyFile?: AirtableFile[];
  signedFile?: AirtableFile[];
}

export type CRAStatus =
  | 'Non crée'
  | 'Validé client'
  | 'Attente signature Client'
  | 'Attente signature Bloomer'
  | 'Signature non créee';

export interface AirtableActivity {
  id?: string;
  recordId: string;
  jobId?: string;
  month: string;
  nbDaysWorked: number;
  nbDaysMarkupWorked: number;
  nbDaysMarkup2Worked: number;
  nbDaysDetails: string;
  astreinte: number;
  bloomer?: string[];
  mission: string[];
  validated?: boolean;
  signing?: boolean | null;
  signingDocumentId?: string;
  billed?: boolean;
  signedCount?: number;
  urlCraS3?: string;
  urlInvoicesS3?: string;
  urlAdditionalInvoicesS3?: string;
  bloomerFullname: string[];
  clientMission: string[];
  bloomerRecordId: string | string[];
  marginAmount: number[];
  type?: string[];
  signingEmbedBloomerId?: string;
  signingEmbedClientId?: string;
  recordIdMission: string[];
  payed?: boolean;
  dateUploadInvoice?: string;
  CRAStatus: CRAStatus;
  lastError?: string;
  externalCustomerInvoiceId?: string;
  missionBillingContacts: string[];
  externalSupplierInvoiceId?: string;
  lastSupplierError?: string;
}

export interface AirtableActivityInput extends AirtableActivity {
  missionRecordId: string;
}

export interface AirtableSociety {
  addressPart2?: string;
  id?: string;
  name: string;
  status?: string | 'Auto Entrepreneur' | 'EI' | 'EURL' | 'SARL' | 'SAS' | 'SASU' | 'Autre' | 'SUE' | 'SHUE';
  siret: string;
  isClient?: boolean;
  bloomers?: string[];
  billingContact?: string[];
  mission?: string[];
  recordId?: string;
  KBIS?: string;
  URSSAFVigilanceCertificate?: string;
  URSSAFVCExpirationDate?: string;
  URSSAFVCUploadDate?: string;
  RCPVigilanceCertificate?: string;
  IBAN?: string;
  BIC?: string;
  portage?: boolean | null;
  paydateDays?: number;
  bloomerBillingMission?: string[];
  tva: string;
  addressName?: string;
  addressPart1?: string;
  addressZip?: string;
  addressTown?: string;
  addressCountryCode?: string | null;
  addressCountry?: string;
  externalId?: string;
  autoValidateInvoice?: boolean;
  orderNumberRequired?: boolean;
}

export interface AirtableInvitation {
  recordId?: string;
  email: string;
  token?: string;
  jobTitle?: string;
  clientSocietyName?: string;
  purchasePricePerDayWithoutTaxes?: number;
  missionStartDate?: Date;
  firstname: string;
  lastname: string;
  mission: string[];
  status?: string;
  isNew?: boolean;
}

export interface AirtableBilling {
  recordId?: string;
  externalId?: string;
  Type?: BillingType;
  BillingDate?: string;
  TrialPeriodEndDate?: string;
  Customer?: string[];
  CustomerName?: string;
  SalesPriceWithoutTaxes?: number;
  BillingContact?: string[];
  BillingContactFullname?: string;
  Billed?: boolean | null;
  KAM?: string[];
  PaydateDays?: number;
  Description: string;
  nextStep?: string;
  legalContracts?: string[];
}

export interface AirtableBloomer {
  notification?: string[];
  fullname?: string;
  firstname: string;
  lastname: string;
  email: string;
  secondaryEmail?: string;
  phoneNumber?: string;
  slack?: boolean;
  accountManager?: string[];
  mission?: string[];
  recordId?: string;
  credentialID?: string;
  invitation?: string[];
  communicationType: NotificationCanal;
}

export interface AirtableBloomerInput extends Omit<AirtableBloomer, 'credentialID'> {
  credentialID?: ServerFieldOrFile;
}

export interface AirtableBloomerUpdate extends AirtableBloomer {
  isNew?: boolean;
}

export interface AirtableAccountManager extends IBloomies {
  id?: string;
  fullname?: string;
  recordId?: string;
  bloomers?: string[];
}

export interface AirtableContact {
  id?: string;
  fullname?: string;
  firstname: string;
  lastname: string;
  phoneNumber?: string;
  email: string;
  nameJob: string;
  isBilling: boolean;
  isOperational?: boolean | null;
  society?: string[];
  mission?: string[];
  recordId?: string;
  externalId: string;
  communicationType: NotificationCanal;
}

export interface AirtablePricing {
  id?: string;
  startDate?: string;
  purchasePricePerDayWithoutTaxes: number;
  salePricePerDayWithoutTaxes: number;
  marginAmount?: string;
  marginRate?: string;
  taxes: number;
  activity?: string[];
  mission?: string[];
  recordId?: string;
  astreinteCoef?: number;
  recordIdMission: string[];
}

export interface AirtableSettingFinance {
  name: string;
  type: string;
  price: number;
  date: string;
  recordId?: string;
}

export interface AirtableFinance {
  id?: number;
  recordId?: string;
  year?: number;
  date: string;
  financeType: FinanceType;
  price?: number;
  isExpense: boolean | null;
  isForecast?: boolean;
}

export interface AirtableNotification {
  channel?: string;
  userGroup?: string;
  recordId?: string;
  title?: string;
  description?: string;
  priority: NotificationPriority;
  read: boolean;
  bloomer?: string[];
  society?: string[];
  activity?: string[];
  mission?: string[];
  canal: NotificationCanal;
  creationDate: string;
  createdAt: string;
  contact: string | null;
  type: NotificationType;
  deleted?: boolean;
  canDelete?: boolean;
  sent?: boolean;
  sentDate?: string;
  bloomerRecordId?: string;
  missionRecordId?: string;
  link?: string;
}
