import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth } from './AuthProvider';
import { Auth0User, Role } from '@bloomays-lib/types.shared';
import { useLazyQuery } from '@apollo/client';
import { ME_FULL } from '@bloomays-lib/adapter.api-bloomer';
import { ComponentType } from 'react';
import { Header, LoaderGlobal } from '@bloomays-lib/ui.shared';
import { errorLogger, getApolloErrorCode } from '../helpers/error';
import { Logger } from '../services/serviceLogger';
const logger = Logger('ProtectedRoute');
interface ProtectedRouteProps {
  Component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ Component }: ProtectedRouteProps): JSX.Element => {
  const auth = useAuth();

  let user: Auth0User | undefined | null;
  const [loadUser, { data: dataMe, loading: loadingMe, error: errorMe }] = useLazyQuery(ME_FULL);

  useEffect(() => {
    logger.debug('ProtectedRoute', { auth, isReady: auth && auth.isAuthenticated && auth.user?.token });
    if (!auth?.isAuthenticated) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUser();
  }, [auth, loadUser]);

  if (!auth?.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (auth) {
    user = auth.user;
  }

  if (user?.roles?.includes(Role.Anonymous)) {
    return <Component />;
  }
  if (loadingMe) return <LoaderGlobal />;
  if (errorMe) {
    if (getApolloErrorCode(errorMe) === 'UNKNOWN_BLOOMER') {
      return <Navigate to="/forbidden/notrecognized" />;
    }
    errorLogger(errorMe, {
      extraInfos: 'Internal server error, impossible to retrieve data !',
      state: {},
    });
  }

  if (auth?.isAuthenticated && user?.email_verified === false) {
    return <Navigate to="/forbidden/norole" />;
  }

  if (auth?.isAuthenticated && (!user?.roles || user?.roles?.length < 1)) {
    return <Navigate to="/forbidden/notrecognized" />;
  }

  if (dataMe?.me?.bloomer?.invitation) {
    if (
      dataMe?.me?.bloomer?.invitation?.status !== 'done' &&
      !(window.location.href.indexOf(`${dataMe.me.bloomer.invitation.token}`) > -1)
    ) {
      return <Navigate to={`/invite/${dataMe.me.bloomer.invitation.token}`} />;
    }
  }

  const ComponentAuthenticate = withAuthenticationRequired(Component, {
    onRedirecting: () => (
      <>
        <Header />
        <LoaderGlobal />
      </>
    ),
  });
  return <ComponentAuthenticate />;
};
export default ProtectedRoute;
