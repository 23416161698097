import { styled } from '@mui/material/styles';

export type LabelOfInputProps = {
  name: string | number;
  textLabel: string;
  required: boolean;
};

const StyledLabel = styled('label')(() => ({
  textAlign: 'left',
}));

export const LabelOfInput = ({ name, textLabel = '', required = false }: LabelOfInputProps): JSX.Element => {
  return (
    <StyledLabel htmlFor={name?.toString()}>
      {textLabel} {required && <span title="Champs obligatoire">*</span>}
    </StyledLabel>
  );
};
