import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SwitchItem from '../atoms/SwitchItem';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import FilterTab from '../atoms/FilterTab';
import { BloomerStatus } from '@bloomays-lib/types.shared';
import { Action, ActionType, State } from '../../types/talents';

export type FilterBloomerProps = {
  withTab?: boolean;
  onSwitch: React.Dispatch<Action>;
  state: State;
  bloomerStatus: { bloomer: boolean; superBloomer: boolean };
  setBloomerStatus: React.Dispatch<
    React.SetStateAction<{
      bloomer: boolean;
      superBloomer: boolean;
    }>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const FilterBloomer = ({
  onSwitch,
  state,
  bloomerStatus,
  setBloomerStatus,
  withTab = true,
  setPage,
}: FilterBloomerProps) => {
  const status = state.search.bloomerStatus;
  const [open, setOpen] = useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, status]);

  const handleSwitchBloomer = (checked: boolean) => {
    setBloomerStatus({ ...bloomerStatus, bloomer: checked });

    if (checked) {
      setBloomerStatus((prevState) => {
        return { ...prevState, superBloomer: false };
      });
    }

    onSwitch({
      type: ActionType.BLOOMER_STATUS_SEARCH,
      bloomerStatus: checked ? BloomerStatus.Bloomer : undefined,
    });
    setPage(1);
  };

  const handleSwitchSuperBloomer = (checked: boolean) => {
    setBloomerStatus({ ...bloomerStatus, superBloomer: checked });

    if (checked) {
      setBloomerStatus((prevState) => {
        return { ...prevState, bloomer: false };
      });
    }

    onSwitch({
      type: ActionType.BLOOMER_STATUS_SEARCH,
      bloomerStatus: checked ? BloomerStatus.SuperBloomer : undefined,
    });
    setPage(1);
  };

  const getNumberFilter = () => {
    let numb = 0;
    if (bloomerStatus.bloomer) {
      numb = numb + 1;
    }
    if (bloomerStatus.superBloomer) {
      numb = numb + 1;
    }
    return numb;
  };

  const StyledContainer = styled('div')(
    ({ theme }) => `
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border-radius: 20px;
    padding: 24px;
    background-color: ${theme.palette.paper.white};
    gap: 10px;
    box-shadow: 0px 10px 40px rgba(140, 140, 140, 0.2);
    width: 399px;
    z-index: ${withTab ? 1 : 0};
    position: ${withTab ? 'absolute' : 'none'};
  `,
  );

  return (
    <div>
      {withTab ? (
        <>
          <FilterTab
            label={'Statut Bloomer'}
            numberOfFilter={getNumberFilter()}
            onClick={() => setOpen(!open)}
            filled={false}
          />
          {open && (
            <StyledContainer ref={ref}>
              <StyledText text="Statut Bloomer" variant={'subtitle2Medium'} />
              <StyledList>
                <SwitchItem
                  checked={bloomerStatus.bloomer}
                  label="Bloomer"
                  onSwitch={() => handleSwitchBloomer(!bloomerStatus.bloomer)}
                />
                <SwitchItem
                  checked={bloomerStatus.superBloomer}
                  label="Super Bloomer"
                  onSwitch={() => handleSwitchSuperBloomer(!bloomerStatus.superBloomer)}
                />
              </StyledList>
            </StyledContainer>
          )}
        </>
      ) : (
        <StyledContainer ref={ref}>
          <StyledText text="Statut Bloomer" variant={'subtitle2Medium'} />
          <StyledList>
            <SwitchItem
              checked={bloomerStatus.bloomer}
              label="Bloomer"
              onSwitch={() => handleSwitchBloomer(!bloomerStatus.bloomer)}
            />
            <SwitchItem
              checked={bloomerStatus.superBloomer}
              label="Super Bloomer"
              onSwitch={() => handleSwitchSuperBloomer(!bloomerStatus.superBloomer)}
            />
          </StyledList>
        </StyledContainer>
      )}
    </div>
  );
};

export default FilterBloomer;
export { FilterBloomer };

const StyledList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`,
);

const StyledText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[500]};
`,
);
