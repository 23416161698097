import { ToastContainer as ToastifyContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = (): JSX.Element => {
  return (
    <ToastifyContainer
      position="top-right"
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={10}
      autoClose={false}
      theme="light"
      stacked={true}
    />
  );
};

export default ToastContainer;
export { ToastContainer };
